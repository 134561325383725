module.exports = {
    "type": "FeatureCollection",
    "features": [
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    114.185257,
                    22.3050202,
                    0
                ]
            },
            "properties": {
                "name": "Hung Hom South Road Rest Garden",
                "styleUrl": "#icon-1796-DB4436",
                "styleHash": "cd651b9",
                "styleMapHash": {
                    "normal": "#icon-1796-DB4436-normal",
                    "highlight": "#icon-1796-DB4436-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
                "description": "Triaderna verkar av någon anledning älska att lämna döda kroppar hängande från träd utanför The Royal Peninsula Block. "
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    112.8858948,
                    22.8976832,
                    0
                ]
            },
            "properties": {
                "name": "Gaoming (Kina)",
                "styleUrl": "#icon-1854-DB4436",
                "styleHash": "9b6b256",
                "styleMapHash": {
                    "normal": "#icon-1854-DB4436-normal",
                    "highlight": "#icon-1854-DB4436-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
                "description": "Hela staden är igenbommad och minerad; gryniga spionfoton visar patrullerande stridsrobotar av hittils okänd typ."
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    114.708252,
                    23.7400976,
                    0
                ]
            },
            "properties": {
                "name": "Heyuan (Kina)\n",
                "styleUrl": "#icon-1591-DB4436",
                "styleHash": "74ed52e0",
                "styleMapHash": {
                    "normal": "#icon-1591-DB4436-normal",
                    "highlight": "#icon-1591-DB4436-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
                "description": "Kinesisk militärbas."
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    114.359436,
                    22.4805201,
                    0
                ]
            },
            "properties": {
                "name": "Grass Island Body Farm",
                "styleUrl": "#icon-1796-DB4436",
                "styleHash": "cd651b9",
                "styleMapHash": {
                    "normal": "#icon-1796-DB4436-normal",
                    "highlight": "#icon-1796-DB4436-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
                "description": "Forskningsanläggning där Yan Luo Biotech (delägt av Oasis) utför experiment på lik, i regel oidentifierade döda shackers som förvärvats från Ikeda-ägda bårhus."
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    114.050446,
                    22.536399,
                    0
                ]
            },
            "properties": {
                "name": "Skyglass Tower",
                "styleUrl": "#icon-1547-DB4436",
                "styleHash": "-7f2bffab",
                "styleMapHash": {
                    "normal": "#icon-1547-DB4436-normal",
                    "highlight": "#icon-1547-DB4436-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
                "description": "Gränskommandots HQ."
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    114.5407104,
                    22.7052555,
                    0
                ]
            },
            "properties": {
                "name": "Aotou Harbor (Kina)",
                "styleUrl": "#icon-1564-DB4436",
                "styleHash": "3c8b8efa",
                "styleMapHash": {
                    "normal": "#icon-1564-DB4436-normal",
                    "highlight": "#icon-1564-DB4436-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
                "description": "Strider ryktas pågå mellan Gränskommandot och en okänd gerilla, möjligtvis allierad med Yaogui-krigsherrarna."
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    113.368888888889,
                    23.1647222222222,
                    0
                ]
            },
            "properties": {
                "name": "My little airport\n",
                "styleUrl": "#icon-1750-DB4436",
                "styleHash": "-610c3b05",
                "styleMapHash": {
                    "normal": "#icon-1750-DB4436-normal",
                    "highlight": "#icon-1750-DB4436-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
                "description": "AKA 23°9 East Airfield; Supposedly övergivet flygfält som dock fortfarande ser aktivitet. Styrs av anon-gruppen People's Proxy, och ryktas vara en av flera mer eller mindre illegala (as in, Worldport hatar dem) transportoperationer i Pärldeltat. Mkt. populärt för träning/lek med DIY:ade drönare."
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    114.4535065,
                    22.7907421,
                    0
                ]
            },
            "properties": {
                "name": "Huiyang",
                "styleUrl": "#icon-1564-DB4436",
                "styleHash": "3c8b8efa",
                "styleMapHash": {
                    "normal": "#icon-1564-DB4436-normal",
                    "highlight": "#icon-1564-DB4436-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
                "description": "Rapporter om tungt bombardemang, kanske från kinesiska stealthplan. Infrastrukturen sägs vara helt förstörd."
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    113.5917664,
                    23.5501398,
                    0
                ]
            },
            "properties": {
                "name": "Conghua (Kina)",
                "styleUrl": "#icon-1565-DB4436",
                "styleHash": "51152d99",
                "styleMapHash": {
                    "normal": "#icon-1565-DB4436-normal",
                    "highlight": "#icon-1565-DB4436-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
                "description": "Dyster men naturskön företagsenklav åt Guangxi Gongye, som här tycks odla någon typ av syntetfrukter i gigantiska växthus. Anomalier hos växtlighet i omgivningen har noterats. Området ryktas vara kraftigt minerat."
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    113.07,
                    23.083333,
                    0
                ]
            },
            "properties": {
                "name": "Shadi Airstrip\n",
                "styleUrl": "#icon-1750-DB4436",
                "styleHash": "-610c3b05",
                "styleMapHash": {
                    "normal": "#icon-1750-DB4436-normal",
                    "highlight": "#icon-1750-DB4436-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
                "description": "Ett mindre flygfält som blivit en het potatis i Foshans interna strider. Otaliga rapporter om transporter av mystiskt gods och suspekta, omärkta flygplan. Kontrolleras för tillfället av Prismo Yuens \"Howling Tigers\"-milis."
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    113.8465118,
                    22.7711166,
                    0
                ]
            },
            "properties": {
                "name": "Songgang",
                "styleUrl": "#icon-1588-DB4436",
                "styleHash": "-77f98c08",
                "styleMapHash": {
                    "normal": "#icon-1588-DB4436-normal",
                    "highlight": "#icon-1588-DB4436-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
                "description": "Säkrat av Gränskommandot efter attack från Lónggǎng-gerillan."
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    114.0044403,
                    22.9210851,
                    0
                ]
            },
            "properties": {
                "name": "Huangjiangzhen",
                "styleUrl": "#icon-1564-DB4436",
                "styleHash": "3c8b8efa",
                "styleMapHash": {
                    "normal": "#icon-1564-DB4436-normal",
                    "highlight": "#icon-1564-DB4436-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
                "description": "Rapporter om stridigheter mellan Lónggǎng Dĭkàng Yùndòng och Dongguan-polisen."
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    114.1960144,
                    21.9997185,
                    0
                ]
            },
            "properties": {
                "name": "Chinese naval bases",
                "styleUrl": "#icon-1588-DB4436",
                "styleHash": "-77f98c08",
                "styleMapHash": {
                    "normal": "#icon-1588-DB4436-normal",
                    "highlight": "#icon-1588-DB4436-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
                "description": "Här finns ett antal mindre flottbaser och ett stort nätverk av sensorer, som bildar en kedja runt Hongkong. Detta försäkrar att ingen främmande makt kan tränga in sjövägen utan att stoppas först. Dessutom ger det möjligheten åt Kina att en dag dra åt snaran och de facto stänga alla hamnar. "
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    113.8101196,
                    22.8502345,
                    0
                ]
            },
            "properties": {
                "name": "Sanxing Sòngcháo Guzhen\n",
                "styleUrl": "#icon-1568-DB4436",
                "styleHash": "-714df68a",
                "styleMapHash": {
                    "normal": "#icon-1568-DB4436-normal",
                    "highlight": "#icon-1568-DB4436-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
                "description": "Ofärdiga delar av Samsundais kolossala Songdynasti-nöjespark. Hastigt övergivna; stor rasrisk bland fallfärdiga byggnadsskelett och uppsprängda bergssidor."
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    113.4683662,
                    22.8468358,
                    0
                ]
            },
            "properties": {
                "name": "Nanshahai",
                "styleUrl": "#icon-1556-DB4436",
                "styleHash": "-170b6909",
                "styleMapHash": {
                    "normal": "#icon-1556-DB4436-normal",
                    "highlight": "#icon-1556-DB4436-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
                "description": "Ett vidsträckt översvämmat område. Många fuhuous åker runt här på sina pråmar och husbåtar, bland en labyrint av spruckna huskroppar och farligt bråte precis under vattenytan. "
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    114.500885,
                    22.6235183,
                    0
                ]
            },
            "properties": {
                "name": "Gaoling",
                "styleUrl": "#icon-1588-DB4436",
                "styleHash": "-77f98c08",
                "styleMapHash": {
                    "normal": "#icon-1588-DB4436-normal",
                    "highlight": "#icon-1588-DB4436-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
                "description": "Lónggǎng Dĭkàng Yùndòng sägs ha fortifierade positioner i bergen."
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    113.7826538,
                    22.8856644,
                    0
                ]
            },
            "properties": {
                "name": "Sanxing Sòngcháo Guzhen\n",
                "styleUrl": "#icon-1568-DB4436",
                "styleHash": "-714df68a",
                "styleMapHash": {
                    "normal": "#icon-1568-DB4436-normal",
                    "highlight": "#icon-1568-DB4436-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
                "description": "Enorm, nedlagd wuxia-theme park, ägdes av Samsundai. De spöklika ruinerna populärt turistmål, men många otäcka rykten florerar om platsen."
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    113.4396757,
                    22.3750589,
                    0
                ]
            },
            "properties": {
                "name": "Zhongshandao",
                "styleUrl": "#icon-1541-DB4436",
                "styleHash": "59a489b",
                "styleMapHash": {
                    "normal": "#icon-1541-DB4436-normal",
                    "highlight": "#icon-1541-DB4436-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
                "description": "En ö som bildats av havshöjningen. Nästan helt övergiven på grund av gamla minfält. Ingen har gjort anspråk på området, utöver diverse shacker-gäng. "
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    112.4697876,
                    23.0494074,
                    0
                ]
            },
            "properties": {
                "name": "Zhaoqing (Kina)\n",
                "styleUrl": "#icon-1591-DB4436",
                "styleHash": "74ed52e0",
                "styleMapHash": {
                    "normal": "#icon-1591-DB4436-normal",
                    "highlight": "#icon-1591-DB4436-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
                "description": "Misstänkt kinesisk militärbas."
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    114.2563522,
                    22.9213243,
                    0
                ]
            },
            "properties": {
                "name": "Taylor Netzley's tomb ",
                "styleUrl": "#icon-1599-DB4436",
                "styleHash": "193a47d8",
                "styleMapHash": {
                    "normal": "#icon-1599-DB4436-normal",
                    "highlight": "#icon-1599-DB4436-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
                "description": "Ett improviserat monument markerar graven för Taylor Netzley, en helikopterpilot vid säkerhetsföretaget Erinys International, som senare köptes upp av Royal Group. Hon stred på företagens sida mot Kina under Kollapsen, men kraschlandade i närheten av Zhenlongzhen på grund av friendly fire från en Nimrod-drönare med trasig målidentifiering. Hon och de andra överlevarna från hennes helikopter kunde inte undsättas innan de tillfångatogs och mördades av en kinesisk styrka. <br><br>Med tiden började Taylor Netzley mytologiseras på Lagren, och de vaga uppgifterna om omständigheterna för hennes död broderas ut till något i gränslandet mellan martyrskap och våldsporr. Diverse libertarianer, mils och forms framställde henne som en hjälte och martyr i företagens rättfärdiga kamp mot Kina, och senare skildrades hennes korta liv, kraftigt utbroderat, i Bollywoodfilmen \"The Erinye\", som dock aldrig blev någon hit. <br><br>2064 lokaliserades Taylors grunda grav genom tips från kinesiska hackers. Särskilt dumdristiga urban explorers i PRD har gjort en sport av att ta sig ut till graven och lägga blommor och stenar på den, trots att den är 10+ km in på kinesiskt territorium. Området är ödsligt och ganska naturskönt, och det är inte omöjligt att ta sig dit levande om man utgår från Dongguan, rör sig till fots och är väldigt bra på att ninja förbi Halberd-killerbots och gömma sig för kameror och drönare. "
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    113.912403,
                    22.476872,
                    0
                ]
            },
            "properties": {
                "name": "Shekou Port",
                "styleUrl": "#icon-1569-DB4436",
                "styleHash": "-5cc457eb",
                "styleMapHash": {
                    "normal": "#icon-1569-DB4436-normal",
                    "highlight": "#icon-1569-DB4436-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
                "description": "Adm. av Gränskommandot, men används av InterWorld och deras cert godtas inom ett avspärrat område. "
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    113.3808876,
                    22.6015497,
                    0
                ]
            },
            "properties": {
                "name": "Zhongshanhai",
                "styleUrl": "#icon-1556-DB4436",
                "styleHash": "-170b6909",
                "styleMapHash": {
                    "normal": "#icon-1556-DB4436-normal",
                    "highlight": "#icon-1556-DB4436-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
                "description": "Ett mycket vidsträckt område som är nästan helt under vatten; svårt att navigera på grund av miljontals gamla översvämmade huskroppar som sticker upp ur det grunda vattnet. "
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    114.0814613,
                    22.9155031,
                    0
                ]
            },
            "properties": {
                "name": "Zhangmutouzhen ",
                "styleUrl": "#icon-1854-DB4436",
                "styleHash": "9b6b256",
                "styleMapHash": {
                    "normal": "#icon-1854-DB4436-normal",
                    "highlight": "#icon-1854-DB4436-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
                "description": "Patrulleras av bättre begagnade kinesiska halberd-killerbots. Bra ställe att besöka om man vill försöka hacka en och ta med sig hem, med haken att man först måste riskera att provocera antingen Lónggǎng-gerillan eller Gränskommandot, eller bägge. Ett annat problem är att botsen avfyrar sina HMGs mot alla som kommer inom 1000 meter. "
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    114.501915,
                    22.5607569,
                    0
                ]
            },
            "properties": {
                "name": "Lung Kei \n",
                "styleUrl": "#icon-1569-DB4436",
                "styleHash": "-5cc457eb",
                "styleMapHash": {
                    "normal": "#icon-1569-DB4436-normal",
                    "highlight": "#icon-1569-DB4436-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
                "description": "Misstänkt hamn för smugglare som förser Lónggǎng-gerillan med eqip från okända sponsorer."
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    113.8812477,
                    22.5364876,
                    0
                ]
            },
            "properties": {
                "name": "Guangshen Expressway",
                "styleUrl": "#icon-1538-DB4436",
                "styleHash": "18b369b3",
                "styleMapHash": {
                    "normal": "#icon-1538-DB4436-normal",
                    "highlight": "#icon-1538-DB4436-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
                "description": "Den enda pålitliga landvägen mellan Hongkong och Canaan Market. Spärras icke desto mindre med ojämna mellanrum av gränskommandot, översvämningar eller tyfonvarningar."
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    112.8168762,
                    23.1892358,
                    0
                ]
            },
            "properties": {
                "name": "Ronglu's basecamp",
                "styleUrl": "#icon-1588-DB4436",
                "styleHash": "-77f98c08",
                "styleMapHash": {
                    "normal": "#icon-1588-DB4436-normal",
                    "highlight": "#icon-1588-DB4436-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
                "description": "En fortifierad ö med flera gamla kustartilleripjäser, konstant omgiven av bestyckade husbåtar. Kontrolleras av Li Ronglu , en av Foshan-zonens krigsherrar, som enligt somliga rykten får € och eqip från Kina i utbyte mot att skapa interna motsättningar i zonen."
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    114.4435501,
                    22.6349265,
                    0
                ]
            },
            "properties": {
                "name": "Kuiba Tunnel\n",
                "styleUrl": "#icon-1724-DB4436",
                "styleHash": "7b1b9fb4",
                "styleMapHash": {
                    "normal": "#icon-1724-DB4436-normal",
                    "highlight": "#icon-1724-DB4436-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
                "description": "Mynningen till mystisk tunnel som tros gå djupt in i Kina. Övervakad av Gränskommandot."
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    114.3514997,
                    23.105607,
                    0
                ]
            },
            "properties": {
                "name": "HQ 74th Army",
                "styleUrl": "#icon-1591-DB4436-nodesc",
                "styleHash": "790bb8ad",
                "styleMapHash": {
                    "normal": "#icon-1591-DB4436-nodesc-normal",
                    "highlight": "#icon-1591-DB4436-nodesc-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    113.8005071,
                    22.6499573,
                    0
                ]
            },
            "properties": {
                "name": "SZX (Shenzhen Airport)",
                "styleUrl": "#icon-1504-DB4436",
                "styleHash": "508e4f74",
                "styleMapHash": {
                    "normal": "#icon-1504-DB4436-normal",
                    "highlight": "#icon-1504-DB4436-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
                "description": "Ett billigare alternativ än flygplatsen i HK; men plågat av Gränskommandots byråkrati, arbiträra lockdowns och gränslösa paranoia. "
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    114.1856438,
                    22.2863756,
                    0
                ]
            },
            "properties": {
                "name": "Trust Tower",
                "styleUrl": "#icon-1547-DB4436",
                "styleHash": "-7f2bffab",
                "styleMapHash": {
                    "normal": "#icon-1547-DB4436-normal",
                    "highlight": "#icon-1547-DB4436-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
                "description": "1800m tall skyscraper"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    114.2011992,
                    22.3177086,
                    0
                ]
            },
            "properties": {
                "name": "Yumacom's skyscraper",
                "styleUrl": "#icon-1547-DB4436-nodesc",
                "styleHash": "2e2167a2",
                "styleMapHash": {
                    "normal": "#icon-1547-DB4436-nodesc-normal",
                    "highlight": "#icon-1547-DB4436-nodesc-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    114.1626841,
                    22.2839136,
                    0
                ]
            },
            "properties": {
                "name": "Xifang Gongye skyscraper",
                "styleUrl": "#icon-1547-DB4436-nodesc",
                "styleHash": "2e2167a2",
                "styleMapHash": {
                    "normal": "#icon-1547-DB4436-nodesc-normal",
                    "highlight": "#icon-1547-DB4436-nodesc-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    114.1778761,
                    22.2811736,
                    0
                ]
            },
            "properties": {
                "name": "Farring International unfinished HQ",
                "styleUrl": "#icon-1547-DB4436-nodesc",
                "styleHash": "2e2167a2",
                "styleMapHash": {
                    "normal": "#icon-1547-DB4436-nodesc-normal",
                    "highlight": "#icon-1547-DB4436-nodesc-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    114.0593869,
                    22.2882023,
                    0
                ]
            },
            "properties": {
                "name": "\"Golden\" skyscraper",
                "styleUrl": "#icon-1547-DB4436",
                "styleHash": "-7f2bffab",
                "styleMapHash": {
                    "normal": "#icon-1547-DB4436-normal",
                    "highlight": "#icon-1547-DB4436-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
                "description": "Includes the offices of mysterious Ultech"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    114.0397279,
                    22.4956863,
                    0
                ]
            },
            "properties": {
                "name": "Mai Po Wetlands",
                "styleUrl": "#icon-1556-DB4436",
                "styleHash": "-170b6909",
                "styleMapHash": {
                    "normal": "#icon-1556-DB4436-normal",
                    "highlight": "#icon-1556-DB4436-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
                "description": "Farligt träsk och gränsområde"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    114.2467261,
                    22.3409679,
                    0
                ]
            },
            "properties": {
                "name": "Razor Hill Prison",
                "styleUrl": "#icon-1547-DB4436",
                "styleHash": "-7f2bffab",
                "styleMapHash": {
                    "normal": "#icon-1547-DB4436-normal",
                    "highlight": "#icon-1547-DB4436-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
                "description": "Hongkongs mest ökända fängelse. "
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    114.1903718,
                    22.2334748,
                    0
                ]
            },
            "properties": {
                "name": "Tusitan Orb Arcology",
                "styleUrl": "#icon-1547-DB4436",
                "styleHash": "-7f2bffab",
                "styleMapHash": {
                    "normal": "#icon-1547-DB4436-normal",
                    "highlight": "#icon-1547-DB4436-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
                "description": "Underwater arcology"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    114.1157533,
                    22.359034,
                    0
                ]
            },
            "properties": {
                "name": "Tsuen Wans graveyard",
                "styleUrl": "#icon-1542-DB4436",
                "styleHash": "1a23e73a",
                "styleMapHash": {
                    "normal": "#icon-1542-DB4436-normal",
                    "highlight": "#icon-1542-DB4436-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
                "description": "Känt tillhåll för posergäng som sörjer sina döda celeb-original, med en klassisk vy som syns i nästan varje genererad drama-series avslutande scen."
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    114.2010213,
                    22.3209607,
                    0
                ]
            },
            "properties": {
                "name": "Floating Heights Complex",
                "styleUrl": "#icon-1547-DB4436",
                "styleHash": "-7f2bffab",
                "styleMapHash": {
                    "normal": "#icon-1547-DB4436-normal",
                    "highlight": "#icon-1547-DB4436-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
                "description": "A huge condo complex that sits on a platform above the dirty harbour water."
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    114.1432493,
                    22.3399167,
                    0
                ]
            },
            "properties": {
                "name": "Large camp for \"Climbers\"",
                "styleUrl": "#icon-1772-DB4436",
                "styleHash": "-3ea49345",
                "styleMapHash": {
                    "normal": "#icon-1772-DB4436-normal",
                    "highlight": "#icon-1772-DB4436-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
                "description": "Klättrare"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    114.2060764,
                    22.3329254,
                    0
                ]
            },
            "properties": {
                "name": "East Kowloon Intersection Climbers",
                "styleUrl": "#icon-1772-DB4436",
                "styleHash": "-3ea49345",
                "styleMapHash": {
                    "normal": "#icon-1772-DB4436-normal",
                    "highlight": "#icon-1772-DB4436-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
                "description": "Under motorvägen som skär genom East Kowloon finns en av de största klättrarkolonierna i PRD. Motorvägen är pulsådern mellan de moderna distrikten i Nya Territorierna och Centrala Kowloon, och byggdes i ny sträckning på 30-talet. Kolonin har sitt centrum där New Kowloon Bypass möter Kwun Tong Road."
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    114.1563106,
                    22.2998188,
                    0
                ]
            },
            "properties": {
                "name": "The Pearl",
                "styleUrl": "#icon-1547-DB4436",
                "styleHash": "-7f2bffab",
                "styleMapHash": {
                    "normal": "#icon-1547-DB4436-normal",
                    "highlight": "#icon-1547-DB4436-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
                "description": "A supertall building."
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    113.9200758,
                    22.3124452,
                    0
                ]
            },
            "properties": {
                "name": "Worldport",
                "styleUrl": "#icon-1504-DB4436",
                "styleHash": "508e4f74",
                "styleMapHash": {
                    "normal": "#icon-1504-DB4436-normal",
                    "highlight": "#icon-1504-DB4436-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
                "description": "Airport"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    113.5938035,
                    22.1447975,
                    0
                ]
            },
            "properties": {
                "name": "Macau Airport",
                "styleUrl": "#icon-1504-DB4436-nodesc",
                "styleHash": "-6279a4bf",
                "styleMapHash": {
                    "normal": "#icon-1504-DB4436-nodesc-normal",
                    "highlight": "#icon-1504-DB4436-nodesc-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    113.3065208,
                    23.4010631,
                    0
                ]
            },
            "properties": {
                "name": "Baiyun Airport",
                "styleUrl": "#icon-1504-DB4436",
                "styleHash": "508e4f74",
                "styleMapHash": {
                    "normal": "#icon-1504-DB4436-normal",
                    "highlight": "#icon-1504-DB4436-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
                "description": "Tågförbindelser med Canaan Market via Mainland. Inga stopp på vägen. "
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    114.1390435,
                    22.3179398,
                    0
                ]
            },
            "properties": {
                "name": "ParaMil Protectorate",
                "styleUrl": "#icon-1588-DB4436-nodesc",
                "styleHash": "396f6dc5",
                "styleMapHash": {
                    "normal": "#icon-1588-DB4436-nodesc-normal",
                    "highlight": "#icon-1588-DB4436-nodesc-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    113.1161016,
                    23.0310622,
                    0
                ]
            },
            "properties": {
                "name": "East Renmin Road ",
                "styleUrl": "#icon-1588-DB4436",
                "styleHash": "-77f98c08",
                "styleMapHash": {
                    "normal": "#icon-1588-DB4436-normal",
                    "highlight": "#icon-1588-DB4436-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
                "description": "Yao centralt område. Den kinesisk-irländska warlorden Caoimhe Tsai basar över områdets restauranger och shopping, och ryktas vara i maskopi med triaderna för att sno inflytande från andra av Foshan-zonens ledare. "
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    113.5217529,
                    23.0575443,
                    0
                ]
            },
            "properties": {
                "name": "Huangpu New Port (Worldport)",
                "styleUrl": "#icon-1569-DB4436",
                "styleHash": "-5cc457eb",
                "styleMapHash": {
                    "normal": "#icon-1569-DB4436-normal",
                    "highlight": "#icon-1569-DB4436-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
                "description": "Interworld äger en liten del av denna gudsförgätna, delvis översvämmade containerhamn. De använder den endast i liten skala, men den kommer väl till pass när de vill genomföra transporter långt från Hongkongs svärmar av kameradrönare..."
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    114.2421975,
                    22.2402878,
                    0
                ]
            },
            "properties": {
                "name": "Nimrod Security Zone",
                "styleUrl": "#icon-1588-DB4436-nodesc",
                "styleHash": "396f6dc5",
                "styleMapHash": {
                    "normal": "#icon-1588-DB4436-nodesc-normal",
                    "highlight": "#icon-1588-DB4436-nodesc-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    114.2013421,
                    22.3322903,
                    0
                ]
            },
            "properties": {
                "name": "Sentry ReSector",
                "styleUrl": "#icon-1588-DB4436-nodesc",
                "styleHash": "396f6dc5",
                "styleMapHash": {
                    "normal": "#icon-1588-DB4436-nodesc-normal",
                    "highlight": "#icon-1588-DB4436-nodesc-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    114.3520988,
                    22.2568265,
                    0
                ]
            },
            "properties": {
                "name": "South Ninepin Tin Hau Temple",
                "styleUrl": "#icon-1668-DB4436",
                "styleHash": "-58b183ab",
                "styleMapHash": {
                    "normal": "#icon-1668-DB4436-normal",
                    "highlight": "#icon-1668-DB4436-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
                "description": "En mystisk plats, för det mesta övergiven. Besöks ibland av nomader och nomos som söker stillhet bland Ninepin-öarnas mäktiga stenkolumner. "
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    114.1266089,
                    22.3334018,
                    0
                ]
            },
            "properties": {
                "name": "Worldport",
                "styleUrl": "#icon-1569-DB4436-nodesc",
                "styleHash": "-283dd09e",
                "styleMapHash": {
                    "normal": "#icon-1569-DB4436-nodesc-normal",
                    "highlight": "#icon-1569-DB4436-nodesc-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    113.2860931,
                    22.2346986,
                    0
                ]
            },
            "properties": {
                "name": "Doumen",
                "styleUrl": "#icon-1588-DB4436",
                "styleHash": "-77f98c08",
                "styleMapHash": {
                    "normal": "#icon-1588-DB4436-normal",
                    "highlight": "#icon-1588-DB4436-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
                "description": "Kinesisk gränspostering. En av få vägar in i mainland, om din auto kan hantera stundtals meterdjupt vatten. Normalt sett är en mindre kinesisk styrka posterad i Doumens ruiner, men verkar evakuera området vid dåligt väder. "
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    113.3322998,
                    23.1227844,
                    0
                ]
            },
            "properties": {
                "name": "Zhujiang New Town",
                "styleUrl": "#icon-1642-DB4436",
                "styleHash": "32c05a19",
                "styleMapHash": {
                    "normal": "#icon-1642-DB4436-normal",
                    "highlight": "#icon-1642-DB4436-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
                "description": "Förfallet, nästan övergivet område med kvardröjande radioaktivitet. Känt för spektakulära vyer av övervuxna skyskrapor. "
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    113.8962035,
                    22.8570552,
                    0
                ]
            },
            "properties": {
                "name": "ProtoTerra Pop-up Shop",
                "styleUrl": "#icon-1549-DB4436",
                "styleHash": "-5618c26d",
                "styleMapHash": {
                    "normal": "#icon-1549-DB4436-normal",
                    "highlight": "#icon-1549-DB4436-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
                "description": "Mondes billigaste ProtoTerra-prylar! Enda haken är att ta sig hit...du kan behöva korsa några minfält. <br><br>Stort party varje fredag! shoppen stänger permanent 11/11 2070! "
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    114.2685772,
                    22.3684767,
                    0
                ]
            },
            "properties": {
                "name": "HK Mega-Marina ",
                "styleUrl": "#icon-1623-DB4436",
                "styleHash": "4df58e36",
                "styleMapHash": {
                    "normal": "#icon-1623-DB4436-normal",
                    "highlight": "#icon-1623-DB4436-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
                "description": "Ett en gång lyxat och yao ställe, som dessvärre blivit vanskött av Sai Kungs medborgargarde, och numera är nedgånget och slitet. Används mest av fiskare, båtnomader och  bohemer, som dock flockas hit i tusental. En veritabel och brokig skog av båtar ligger konstant för ankar, och med den kommer gatuförsäljare, gatumusikanter och matserveringar, men ficktjuvar, scams och kapningar är vardagsmat. "
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    114.178669,
                    22.304475,
                    0
                ]
            },
            "properties": {
                "name": "Michael Clinton Swimming Pool",
                "styleUrl": "#icon-1701-DB4436",
                "styleHash": "442a5955",
                "styleMapHash": {
                    "normal": "#icon-1701-DB4436-normal",
                    "highlight": "#icon-1701-DB4436-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
                "description": "En populär simbassäng för HKPUs studenter och personal. Nyligen nedstängd efter att stjärnstudenten Alexander Wing dog i bassängen under mystiska och spektakulärt blodiga omständigheter. Enligt rättsläkaren tycktes skadorna ha orsakats av klor eller tänder. "
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    113.9489484,
                    22.4388551,
                    0
                ]
            },
            "properties": {
                "name": "Lobster-based Analytics Co",
                "styleUrl": "#icon-1572-DB4436",
                "styleHash": "-6fdd7903",
                "styleMapHash": {
                    "normal": "#icon-1572-DB4436-normal",
                    "highlight": "#icon-1572-DB4436-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
                "description": "En mycket kufisk mikrokorp som forskar på hummer-kognition och neutrala matriser. Har en stor anläggning i den gamla wetlands-parken Pak Nai. "
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    114.028599,
                    22.4500849,
                    0
                ]
            },
            "properties": {
                "name": "Karantän!",
                "styleUrl": "#icon-1524-DB4436",
                "styleHash": "49e2b9f6",
                "styleMapHash": {
                    "normal": "#icon-1524-DB4436-normal",
                    "highlight": "#icon-1524-DB4436-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
                "description": "Satt i karantän på grund av viruset Lycovid-70 Tanzie Mindfuck. "
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    113.9871623,
                    22.1898742,
                    0
                ]
            },
            "properties": {
                "name": "Shek Kwu Chau Mega-Incinerator",
                "styleUrl": "#icon-1693-DB4436",
                "styleHash": "-4962fd03",
                "styleMapHash": {
                    "normal": "#icon-1693-DB4436-normal",
                    "highlight": "#icon-1693-DB4436-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
                "description": "En stor anläggning för tvivelaktig avfallshantering. Drivs av Keppel Seghers-Zhen Hua, en mikrokorp under HSTC.  "
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    114.2740212,
                    22.2742749,
                    0
                ]
            },
            "properties": {
                "name": "Pitt's Pork",
                "styleUrl": "#icon-1796-DB4436",
                "styleHash": "cd651b9",
                "styleMapHash": {
                    "normal": "#icon-1796-DB4436-normal",
                    "highlight": "#icon-1796-DB4436-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
                "description": "En \"organisk\" grisfarm ägd av Cantaloupe Pitt, populärt dumpningsställe för piratlorden Triyatnos offer.  "
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    114.4337804,
                    22.5428713,
                    0
                ]
            },
            "properties": {
                "name": "Tung Ping Chau Vector Platform",
                "styleUrl": "#icon-1593-DB4436",
                "styleHash": "-61ff6fe2",
                "styleMapHash": {
                    "normal": "#icon-1593-DB4436-normal",
                    "highlight": "#icon-1593-DB4436-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
                "description": "En vektorplattform, djupt in i skogen. Används ibland för att flyga över turister från Hongkong, men mystiska, oidentifierade vektorjet ryktas ibland landa här i skydd av nattmörkret. "
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    112.8925814,
                    22.9247166,
                    0
                ]
            },
            "properties": {
                "name": "Death Island",
                "styleUrl": "#icon-1556-DB4436",
                "styleHash": "-170b6909",
                "styleMapHash": {
                    "normal": "#icon-1556-DB4436-normal",
                    "highlight": "#icon-1556-DB4436-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
                "description": "Ryktas vara det allra värsta, dödligaste och mest skräckinjagande av Pärldeltats ingenmansland. De söndersprängda och försåtsminerade betongkomplexen påstås vara fulla av loot - och döda kroppar. Ingen tycks verkligen veta vad som skedde här under kollapsen, men det talas om groteska övergrepp med inslag av folkmord. Ön blir ofta överflugen av kinesiska drönare, som anfaller allt som rör sig. "
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    113.3755468,
                    23.0650271,
                    0
                ]
            },
            "properties": {
                "name": "Guanshan Refugee Processing Center",
                "styleUrl": "#icon-1628-DB4436",
                "styleHash": "-4b5a58af",
                "styleMapHash": {
                    "normal": "#icon-1628-DB4436-normal",
                    "highlight": "#icon-1628-DB4436-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
                "description": "Här får många av GHEMCs studenter chansen att öva sig på mandarin, humanekologi, psykologi, och annat praktiskt, och träffa riktiga, fett traumatiserade flyktingar från mainland."
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    113.8644545,
                    22.2539888,
                    0
                ]
            },
            "properties": {
                "name": "Tai O",
                "styleUrl": "#icon-1623-DB4436",
                "styleHash": "4df58e36",
                "styleMapHash": {
                    "normal": "#icon-1623-DB4436-normal",
                    "highlight": "#icon-1623-DB4436-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
                "description": "Ett mycket nedgånget och halvt översvämmat gammalt fiskeläge. Styrs av buddhistiska/daoistiska ghazis, som hankar sig fram på att hyra ut kajplatser och sälja dongxi till diverse båtnomader. Ett mycket bra ställe att diskret skaffa transport om du behöver en båt till något shady, men den lokala milisen är mycket farlig om man fuckar med deras konfucianska idéer om ordning och reda. "
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    114.2202725,
                    22.4061074,
                    0
                ]
            },
            "properties": {
                "name": "Tolo Checkpoint",
                "styleUrl": "#icon-1657-DB4436",
                "styleHash": "161aa875",
                "styleMapHash": {
                    "normal": "#icon-1657-DB4436-normal",
                    "highlight": "#icon-1657-DB4436-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
                "description": "EuroProducts upprätthåller här en gränskontroll, primärt genom drönare, som randomly stoppar passerande fordon. Alla som vill in i Tolo Harbour-zonen måste kunna bevisa sin kreditvärdighet. "
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    114.1092079,
                    23.0515743,
                    0
                ]
            },
            "properties": {
                "name": "Songwuzhou",
                "styleUrl": "#icon-1657-DB4436",
                "styleHash": "161aa875",
                "styleMapHash": {
                    "normal": "#icon-1657-DB4436-normal",
                    "highlight": "#icon-1657-DB4436-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
                "description": "Dongguang-polisen ger ingen annan tillträde till denna ön. Den sägs vara full av hemliga lagerlokaler, där de förvarar dongxi som de byter med korrupta officerare från Mainland. "
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    113.5766652,
                    22.20763,
                    0
                ]
            },
            "properties": {
                "name": "Checkpoint Island",
                "styleUrl": "#icon-1657-DB4436",
                "styleHash": "161aa875",
                "styleMapHash": {
                    "normal": "#icon-1657-DB4436-normal",
                    "highlight": "#icon-1657-DB4436-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
                "description": "Huserar stora Interworld-terminaler, ett kontor för rederiet Aliança, med mera. Interworld har även motvilligt upprättat checkpoints, på begäran av Macao och Zhuhai, där peeps som saknar tillräcklig kreditvärdighet bryskt skickas tillbaka till Hongkong. "
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    113.5171504,
                    22.5073318,
                    0
                ]
            },
            "properties": {
                "name": "Nanlang MFF Factory",
                "styleUrl": "#icon-1693-DB4436",
                "styleHash": "-4962fd03",
                "styleMapHash": {
                    "normal": "#icon-1693-DB4436-normal",
                    "highlight": "#icon-1693-DB4436-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
                "description": "Den största fabriken i hela Pärldeltat. Inkluderat testkörningsbanorna och andra sekundära faciliteter täcker den många kvadratkilometer. Trots detta uppgår antalet arbetare i under 300; robotar har dominerat fordonsindustrin ända sedan medeltiden. Hela området har därför en omänsklig och ödslig känsla."
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    113.3142708,
                    23.1797736,
                    0
                ]
            },
            "properties": {
                "name": "Rustique Handwoven Facial Tissues",
                "styleUrl": "#icon-1686-DB4436",
                "styleHash": "778fa999",
                "styleMapHash": {
                    "normal": "#icon-1686-DB4436-normal",
                    "highlight": "#icon-1686-DB4436-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
                "description": "En av de konstigaste men samtidigt populäraste mikrokorparna i de yttre zonerna. Från sin verkstad i hyfsat pittoreska miljöer i Meihua Garden skickar de bespoke näsdukar med drönare över hela deltat, och monde. Deras enda problem är de ocker-liknande beskyddaravgifterna till Zhiyang Yan, krigsherren som kontrollerar zonen, och diverse bombräder från hans otaliga fiender. "
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    114.2502487,
                    22.5283592,
                    0
                ]
            },
            "properties": {
                "name": "Dinosaur Sighting",
                "styleUrl": "#icon-1775-DB4436",
                "styleHash": "-107b768",
                "styleMapHash": {
                    "normal": "#icon-1775-DB4436-normal",
                    "highlight": "#icon-1775-DB4436-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
                "description": "En \"dinosaurie\" har siktats här (förmodligen antingen ett Lager-hoax, eller ett riktigt djur, kanske en krokodil eller noshörning, som  blivit utsatt i \"naturen\" av Momo's Rewilders). "
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    114.2317872,
                    22.4736535,
                    0
                ]
            },
            "properties": {
                "name": "Tai Mei Tuk",
                "styleUrl": "#icon-1604-DB4436",
                "styleHash": "692ac253",
                "styleMapHash": {
                    "normal": "#icon-1604-DB4436-normal",
                    "highlight": "#icon-1604-DB4436-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
                "description": "En rewilder-by, full av nomader med etniska outfits och exotiska husdjur. Populär barbecue-spot, men också ett bra ställe för illegal djurhandel. "
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    114.2766767,
                    22.5125508,
                    0
                ]
            },
            "properties": {
                "name": "The Shadow Arcology",
                "styleUrl": "#icon-1751-DB4436",
                "styleHash": "-4c829c66",
                "styleMapHash": {
                    "normal": "#icon-1751-DB4436-normal",
                    "highlight": "#icon-1751-DB4436-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
                "description": "Ett mystiskt undervattenshabitat som sägs finnas i vattnen kring Green Peninsula, oftast i Sam i Wan. Rykten har crkulerat på Lagren om denna plats sedan åtminstone 2060, då mystiska foton från undervattensdrönare dök upp på Newsflasher. Den vanligaste versionen av legenden gör gällande att det är en experimentiell kinesisk bas som konverterades till en arkologi, men övergavs under kollapsen, och sedan dess har tagits över av en mystisk fraktion. Denna fraktion sägs omväxlande vara Momo's Rewilders, Pat Sin Leng Rewilders, anon-cellen Ergo Haven, brottorganisationen Syndikatet, aliens från Zeta Reticuli, och en del förslag till. UFO-sightings är faktisk anmärkningsvärt vanliga i detta avskilda hörn av deltat. "
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    114.2690807,
                    22.5185965,
                    0
                ]
            },
            "properties": {
                "name": "Sam A Tsuen",
                "styleUrl": "#icon-1588-DB4436",
                "styleHash": "-77f98c08",
                "styleMapHash": {
                    "normal": "#icon-1588-DB4436-normal",
                    "highlight": "#icon-1588-DB4436-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
                "description": "En fallfärdig by, nästan helt i ruiner, och kan bara nås med båt eller till fots. Bebos av tre åldrade analoger av Tsang-klanen, som en gång blomstrade här. De tycks älska att jävlas med folks drönare med sina AR-15 och Barret M82 och andra retro flingor. De tvekar inte att använda dem på ovälkomna fridstörare heller."
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    114.1748058,
                    22.3052034,
                    0
                ]
            },
            "properties": {
                "name": "Martin Lee Building",
                "styleUrl": "#icon-1547-DB4436",
                "styleHash": "-7f2bffab",
                "styleMapHash": {
                    "normal": "#icon-1547-DB4436-normal",
                    "highlight": "#icon-1547-DB4436-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
                "description": "Zonens högsta skyskrapa. Huserar kontor för Jinkaidun Taktikal, som ger zonens Jammers, Remb, generösa deals på sin kufiska katalog av närstrids- och störningsvapen. Ofta landar också små fraktdrönare från Rustique Handwoven Facial Tissues. Är även de i maskopi med Remb på något vis? "
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    114.2479988,
                    22.2968552,
                    0
                ]
            },
            "properties": {
                "name": "Tseung Kwan O Chinese Permanent Cemetary",
                "styleUrl": "#icon-1796-DB4436",
                "styleHash": "cd651b9",
                "styleMapHash": {
                    "normal": "#icon-1796-DB4436-normal",
                    "highlight": "#icon-1796-DB4436-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
                "description": "Här brukade hela Hongkong ligga och ruttna på en brant bergssida(okej, aska ruttnar inte, men ni fattar). Nu sticker de gamla gravarna istället upp ur högar av sopor och avskräde, blandat med debris från en kraschad Ares-skyttel. Bara reclos och spöken har något att hämta här."
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    114.2450633,
                    22.2947706,
                    0
                ]
            },
            "properties": {
                "name": "The Tseung Kwan O Shuttle",
                "styleUrl": "#icon-1856-DB4436",
                "styleHash": "32c9ef94",
                "styleMapHash": {
                    "normal": "#icon-1856-DB4436-normal",
                    "highlight": "#icon-1856-DB4436-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
                "description": "Här nånstans kraschade en Ares-kapsel vid takeoff och gjorde kaos med zonen. Kapseln är lite av en legend bland Pärldeltats reclos, eftersom den påstås ha innehållit hemlig Ares-tech och en massa exotiska metaller och grejer. Alla som nådde fram till den sägs dock ha blivit offade av dödspatruller från Ares, och nu tycks ingen på Lagren riktigt veta exakt var kapseln kraschade. Men zonens reclos vet nog..."
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    114.0029769,
                    22.26705,
                    0
                ]
            },
            "properties": {
                "name": "Madagaskiska örlogsskepp",
                "styleUrl": "#icon-1588-DB4436",
                "styleHash": "-77f98c08",
                "styleMapHash": {
                    "normal": "#icon-1588-DB4436-normal",
                    "highlight": "#icon-1588-DB4436-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
                "description": "Tre destroyers och en light carrier från Madagaskar har lagt till här, för att skydda sina intressen i zonen. De ryktas ha uppbackning av multipla atomubåtar ute i Sydkinesiska sjön.  EXCOs protester mot Madagaskar tycks ha fallit för döva öron, och många väntar sig en unilateral aktion från Gränskommandot. En sådan har än så länge uteblivit. "
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    114.1501902,
                    22.28395,
                    0
                ]
            },
            "properties": {
                "name": "Man Mo Temple",
                "styleUrl": "#icon-1668-DB4436",
                "styleHash": "-58b183ab",
                "styleMapHash": {
                    "normal": "#icon-1668-DB4436-normal",
                    "highlight": "#icon-1668-DB4436-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
                "description": "Ett mycket klassiskt tempel, omgärdat av en mindre zon styrd av analoger affilierade med Pure Land of the Goddess Tin Hau. Templet är tillägnat Man Tai, daoismens litteratur-gud, och Mo Tai, en krigsgud. "
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    114.1343657,
                    22.3373707,
                    0
                ]
            },
            "properties": {
                "name": "Kui Wu Construction Lot",
                "styleUrl": "#icon-1633-DB4436",
                "styleHash": "-35603c89",
                "styleMapHash": {
                    "normal": "#icon-1633-DB4436-normal",
                    "highlight": "#icon-1633-DB4436-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
                "description": "En av Pärldeltats största byggarbetsplatser, konstruktionen av ett gigantiskt parkeringskomplex. Byggnationen går med regelbundna mellanrum i stå på grund av korruptionen i zonen, och den halvfärdiga mega-byggnaden är full av spektakulära ramper och improviserade broar som zonbossarna Kichigai använder för MC-races och parkour-tävlingar. "
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    114.1288568,
                    22.2595263,
                    0
                ]
            },
            "properties": {
                "name": "AFLHK-attack",
                "styleUrl": "#icon-1564-DB4436",
                "styleHash": "3c8b8efa",
                "styleMapHash": {
                    "normal": "#icon-1564-DB4436-normal",
                    "highlight": "#icon-1564-DB4436-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
                "description": "The Anti-Freak Legion of Hong Kong, baserade på närbelägna Freedom Island, strejfade nyligen strandpromenaden med maskingevärseld från en motorbåt, med sjutton dödsfall som resultat. Det är hittils kulmen på en serie eskalerande konflikter med neopet-zonen Cyberport."
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    114.147761,
                    22.2446415,
                    0
                ]
            },
            "properties": {
                "name": "Elavbrott",
                "styleUrl": "#icon-1660-DB4436",
                "styleHash": "301875d",
                "styleMapHash": {
                    "normal": "#icon-1660-DB4436-normal",
                    "highlight": "#icon-1660-DB4436-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
                "description": "Elnätet i South Horizons har gått ned, efter vad som förmodas vara en hackerattack och hämndaktion efter Anti-Freak Legion of Hong Kongs terrordåd mot neopets i Cyberport. Mycket av infrastrukturen är lamslagen, och AFLHK tycks beredda att eskalera ytterligare. Icke-essentiella besök till South Horizons avrådes ifrån. "
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    114.2257636,
                    22.2383658,
                    0
                ]
            },
            "properties": {
                "name": "Pumping Station Village",
                "styleUrl": "#icon-1604-DB4436",
                "styleHash": "692ac253",
                "styleMapHash": {
                    "normal": "#icon-1604-DB4436-normal",
                    "highlight": "#icon-1604-DB4436-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
                "description": "En liten by med flytbroar, bebodd av ett drygt femtiotal av båtfolket. Det är oklart varför Poly Synapse Labs tolererar dem här, givet Polys enormt hemliga lab i zonen, men en teori är att båtfolket i själva verket är beväpnade Poly-secos deep under cover. Om så är fallet, vad -tusan- är det Poly sysslar med här som är så hemligt?"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    114.2239826,
                    22.2354362,
                    0
                ]
            },
            "properties": {
                "name": "Red Hill",
                "styleUrl": "#icon-1556-DB4436",
                "styleHash": "-170b6909",
                "styleMapHash": {
                    "normal": "#icon-1556-DB4436-normal",
                    "highlight": "#icon-1556-DB4436-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
                "description": "Hela berget tycks vara seedat med smarta landminor och dolda sentry guns, för att skydda Poly Synapse Labs anläggning i zonen. Gör intrång här på egen risk! "
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    114.2232101,
                    22.2362803,
                    0
                ]
            },
            "properties": {
                "name": "The Genetica Abiogenesis Museum",
                "styleUrl": "#icon-1636-DB4436",
                "styleHash": "83c9f54",
                "styleMapHash": {
                    "normal": "#icon-1636-DB4436-normal",
                    "highlight": "#icon-1636-DB4436-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
                "description": "Ett extremt kufiskt och svårt museum, med navelskådande utställningar om Geneticas 2050-talsforskning på abiogenesis och tidigt RNA-baserat liv. Besökarantalet är som bäst några få om dagen. Många tror att muséet i själva verket är ännu en smoke screen för att skydda Poly Synapse Labs anläggningar i zonen, och även utgör en checkpoint för behörig personal. "
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    114.2268307,
                    22.2330985,
                    0
                ]
            },
            "properties": {
                "name": "Poly Synapse Labs",
                "styleUrl": "#icon-1524-DB4436",
                "styleHash": "49e2b9f6",
                "styleMapHash": {
                    "normal": "#icon-1524-DB4436-normal",
                    "highlight": "#icon-1524-DB4436-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
                "description": "HKs kanske hemligaste (eller åtminstone mest paranojjigt bevakade) forskningsanläggning. De enda foton som tycks finnas på Lagren är drönarbilden från ovan, eftersom alla obehöriga som sticker sin näsa i zonen kan räkna med att bli söndersprängda av landminor/slamhounds eller nedmejade av secos/sentry guns. "
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    114.2383089,
                    22.2769118,
                    0
                ]
            },
            "properties": {
                "name": "Jane Doe",
                "styleUrl": "#icon-1796-DB4436",
                "styleHash": "cd651b9",
                "styleMapHash": {
                    "normal": "#icon-1796-DB4436-normal",
                    "highlight": "#icon-1796-DB4436-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
                "description": "En förmodat sydasiatisk zesgo hittades halshuggen i en vattencistern här för bara ett par dagar sedan. Ingen har hittils lyckats identifiera henne. Kan hon ha varit ett av Nanhai-triadens traffickingoffer i närbelägna Shau Kei Wan, som blivit mördad av någon anledning? "
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    114.2063572,
                    22.2209514,
                    0
                ]
            },
            "properties": {
                "name": "Ma Hang Scandinavian Complex",
                "styleUrl": "#icon-1547-DB4436",
                "styleHash": "-7f2bffab",
                "styleMapHash": {
                    "normal": "#icon-1547-DB4436-normal",
                    "highlight": "#icon-1547-DB4436-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
                "description": "En retro-byggnad som huserar Artisanal Bayin Music Workshop, en Daewoo Dojosfilial, en IKEA Pop-Up Store, och flera mer pretto butiker för all things Scandinavian. En populär mötesplats för Scandys och Scandy-fans i Pärldeltat. Strikt PK-policy råder, och alla former av kränkningar, mikroaggressioner etc är strängt förbjudna. "
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    113.1804596,
                    23.1156948,
                    0
                ]
            },
            "properties": {
                "name": "Zmoke",
                "styleUrl": "#icon-1773-DB4436",
                "styleHash": "-2a1af4a6",
                "styleMapHash": {
                    "normal": "#icon-1773-DB4436-normal",
                    "highlight": "#icon-1773-DB4436-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
                "description": "En bland shapers och vCores extremt hyllad pop-up club, i regi av mikrokorparna TaiKawaii Styleista och Angel Ball Creative. Den hölls senast i Xuyi Crayfishs gamla lokaler, men lär dyka upp på ett nytt ställe i zonen om några veckor. "
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    114.195172,
                    22.2690241,
                    0
                ]
            },
            "properties": {
                "name": "Drönarattack",
                "styleUrl": "#icon-1564-DB4436",
                "styleHash": "3c8b8efa",
                "styleMapHash": {
                    "normal": "#icon-1564-DB4436-normal",
                    "highlight": "#icon-1564-DB4436-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
                "description": "Linden Height ligger i ruiner efter en drönarattack. Många i media spekulerar att det rör sig om nån form av hämndaktion från copyright-lobbyn, riktad mot zonens ägare, Ag4 Khan, som har avskaffat copyright och låter peeps printa vad helst de vill utan konsekvenser. "
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    114.1773019,
                    22.2737451,
                    0
                ]
            },
            "properties": {
                "name": "AI Building",
                "styleUrl": "#icon-1641-DB4436",
                "styleHash": "1e36bb7a",
                "styleMapHash": {
                    "normal": "#icon-1641-DB4436-normal",
                    "highlight": "#icon-1641-DB4436-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
                "description": "Ett väldigt datacenter i en gammal företagsskrapa. Härifrån launchar zonens anattas sina bisarra propagandafeeds, och diverse hot om att \"unleash tranquility\" över Pärldeltat. "
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    114.2266224,
                    22.2823309,
                    0
                ]
            },
            "properties": {
                "name": "Fu Lin Suicide Consultants HQ",
                "styleUrl": "#icon-1547-DB4436",
                "styleHash": "-7f2bffab",
                "styleMapHash": {
                    "normal": "#icon-1547-DB4436-normal",
                    "highlight": "#icon-1547-DB4436-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
                "description": "Den lokala mikrokorpens HQ i Pärldeltat. I en slags ironi, eller kanske ett tecken på att de helt enkelt är dåliga på sitt jobb, begick CEOn Luna Aruño självmord förra månaden genom att hoppa från toppen av Trust Tower. Eller...var det kanske Kichigai som ville bli av med henom av någon anledning?"
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    114.1525626,
                    22.3493647,
                    0
                ]
            },
            "properties": {
                "name": "Locavore",
                "styleUrl": "#icon-1577-DB4436",
                "styleHash": "-92d5fe8",
                "styleMapHash": {
                    "normal": "#icon-1577-DB4436-normal",
                    "highlight": "#icon-1577-DB4436-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
                "description": "En vegansk eko-etno-hippie-myspys-restaurang som på sistone fått viss renomé även i creddiga magasin som Illustra Zanzibar. Dess gamla sunkiga klientel av Piper's Hills loteks och rewilders är nu lite bittra för att moris och norms börjat gentrifiera stället, och priserna har dubblats på ett år. "
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    114.1822494,
                    22.3413997,
                    0
                ]
            },
            "properties": {
                "name": "Twilight Court (Queen Nataxa HQ)",
                "styleUrl": "#icon-1556-DB4436",
                "styleHash": "-170b6909",
                "styleMapHash": {
                    "normal": "#icon-1556-DB4436-normal",
                    "highlight": "#icon-1556-DB4436-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
                "description": "Basen för Painfotainment-zonens daiban Nataxas tyranniska styre. Om du inte när en stark önskan att dö långsamt och plågsamt i förtid avråders starkt från alla former av brott i zonen. Observera att det i den här zonen är brottsligt att ha under 5000 följare på sociala Lager. "
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    114.1324837,
                    22.447397,
                    0
                ]
            },
            "properties": {
                "name": "Lung A Pai Biodome",
                "styleUrl": "#icon-1582-DB4436",
                "styleHash": "cccbc3e",
                "styleMapHash": {
                    "normal": "#icon-1582-DB4436-normal",
                    "highlight": "#icon-1582-DB4436-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
                "description": "En enorm klimatkupol som användes för odling i Tusen-dalen. När den byggdes 2025 såg många den som ett underverk inom eko-arkitektur. Den har sedermera blivit grundligt utplundrad av Gaia Liberation Front, miljö-terrorrörelsen/dödskulten som nu behärskar Tsuen Valley. "
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    113.8997801,
                    22.3119838,
                    0
                ]
            },
            "properties": {
                "name": "Spaceport Asia",
                "styleUrl": "#icon-1856-DB4436",
                "styleHash": "32c9ef94",
                "styleMapHash": {
                    "normal": "#icon-1856-DB4436-normal",
                    "highlight": "#icon-1856-DB4436-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
                "description": "Östasiens viktigaste rymdhamn. "
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    113.2614956,
                    23.1383777,
                    0
                ]
            },
            "properties": {
                "name": "Museum of the Mausoleum of the Nanyue King",
                "styleUrl": "#icon-1636-DB4436",
                "styleHash": "83c9f54",
                "styleMapHash": {
                    "normal": "#icon-1636-DB4436-normal",
                    "highlight": "#icon-1636-DB4436-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
                "description": "Pärldeltats creddigaste museum. Området har hittils förskonats från stridigheterna mellan Canaan Liberation Group och Baiyun-veteranerna, förmodligen eftersom barer nära museet är ett tillför för Röda Wa, en extremt brutal maffia som bägge fraktionerna vill ha på sin sida. "
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    114.2132805,
                    22.2861116,
                    0
                ]
            },
            "properties": {
                "name": "One Island East (IDCF HQ) ",
                "styleUrl": "#icon-1547-DB4436",
                "styleHash": "-7f2bffab",
                "styleMapHash": {
                    "normal": "#icon-1547-DB4436-normal",
                    "highlight": "#icon-1547-DB4436-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
                "description": "En glassig skyskrapa som hunnit få 70 år på nacken. Är HQ för IDC-Fujiyama i Pärldeltat. Ligger vid Taikoo Place, en lokal företagshubb, där även de klassiska HK-mikrokorparna Basic Bombs & Beverages, Gammon Construction och Outblaze har sina huvudkontor. "
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    114.1707166,
                    22.5176935,
                    0
                ]
            },
            "properties": {
                "name": "Shau Tau Kok Secondary Refugee Processing Center",
                "styleUrl": "#icon-1628-DB4436",
                "styleHash": "-4b5a58af",
                "styleMapHash": {
                    "normal": "#icon-1628-DB4436-normal",
                    "highlight": "#icon-1628-DB4436-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
                "description": "Ett stort flyktingläger som upprättades av FN medan de fortfarande kontrollerade zonen. Dessvärre har den tagits över av terrorgruppen Asian Dawn, med fullt stöd från majoriteten av flyktingarna i lägret, som, med viss rätta, känner att de behandlats illa av FN-administrationen i Pärldeltat. "
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    114.1786592,
                    22.3241544,
                    0
                ]
            },
            "properties": {
                "name": "Freemind Kowloon",
                "styleUrl": "#icon-1639-DB4436",
                "styleHash": "45d97b31",
                "styleMapHash": {
                    "normal": "#icon-1639-DB4436-normal",
                    "highlight": "#icon-1639-DB4436-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
                "description": "En bas för den skumma argentinska NGOn Freemind. De erbjuder gratis Obert-Wu-behandlingar för minnesradering om du bara kan övertyga dem att ditt trauma är tillräckligt stort, och avgränsat, för att det ska vara motiverat. "
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    114.1876383,
                    22.3330577,
                    0
                ]
            },
            "properties": {
                "name": "Bliss Body Building (Free Tibet HQ)",
                "styleUrl": "#icon-1639-DB4436",
                "styleHash": "45d97b31",
                "styleMapHash": {
                    "normal": "#icon-1639-DB4436-normal",
                    "highlight": "#icon-1639-DB4436-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
                "description": "En skyskrapa full av cengbas och butiker för religiöst krimskrams, samt ett stort centra för transcendental fitness. Alltsammans sponstrat av Free Tibet-rörelsen, som numera är dominerad av anattas, och har sitt HQ här. "
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    113.9104105,
                    22.7345179,
                    0
                ]
            },
            "properties": {
                "name": "Jiazi Ruins",
                "styleUrl": "#icon-1546-DB4436",
                "styleHash": "6c4a61b6",
                "styleMapHash": {
                    "normal": "#icon-1546-DB4436-normal",
                    "highlight": "#icon-1546-DB4436-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
                "description": "På 20-talet uppfördes några spektakulära fabriksanläggningar och bostadskomplex här, som numera är lika spektakulära ruiner. Laputa Line-dancing & Parkour Classes håller lektioner i parkour här på tisdagarna, för de som saknar dödsrädsla och lyckas ta sig till denna obskyra del av deltat. "
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    114.1569776,
                    22.3312885,
                    0
                ]
            },
            "properties": {
                "name": "Green Garden-secos",
                "styleUrl": "#icon-1588-DB4436",
                "styleHash": "-77f98c08",
                "styleMapHash": {
                    "normal": "#icon-1588-DB4436-normal",
                    "highlight": "#icon-1588-DB4436-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
                "description": "Green garden har posterat secos från Emperor Security samt sina egna företagspoliser på flera platser i Platinum Zone, och annorstädes i Shamshuipo. Syftet är oklart än så länge, men många är oroliga för vad denna mobilisering kan innebära. Kan det måhända handla om vad som sker bakom kulisserna i Platinum Zone, där Syndikatet plötsligt fått massor av inflytande? "
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    114.1869631,
                    22.3008318,
                    0
                ]
            },
            "properties": {
                "name": "Horizons TechHeaven ",
                "styleUrl": "#icon-1647-DB4436",
                "styleHash": "-668f8ccc",
                "styleMapHash": {
                    "normal": "#icon-1647-DB4436-normal",
                    "highlight": "#icon-1647-DB4436-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
                "description": "En lyxig butik för HiQ-eqip, ett slags paladin-alternativ till techmarknaden i Shamshuipo. Fördelen är att Yumacom och Griemann Okker ibland erbjuder förköp och prototyper här, och givetvis första rangens service, samt en stående 5% rabatt på alla deras produkter. Horizons TechHeaven har även filialer i Salisbury och andra lyxade commerce-zoner. "
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    114.2124769,
                    22.2845588,
                    0
                ]
            },
            "properties": {
                "name": "Monster Mansion",
                "styleUrl": "#icon-1547-DB4436",
                "styleHash": "-7f2bffab",
                "styleMapHash": {
                    "normal": "#icon-1547-DB4436-normal",
                    "highlight": "#icon-1547-DB4436-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
                "description": "Pärldeltats absolut koolaste byggnad, åtminstone om man frågar peeps med bra smak, dvs peeps som gillar bisarrt ärkegigantiska och nedgångna hyreshus från medeltiden. De boende här har i mer än ett århundrade behövt stå ut med dryga influencers som försöker ta selfies inne på komplexets klaustrofobiska och extremt fotogeniska courtyard, och nu tröttnat till den grad att de börjat skjuta sådana fridstörare i benen med MADE AR2 och andra billiga men pricksäckra puffror.  "
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    114.2684141,
                    22.2846267,
                    0
                ]
            },
            "properties": {
                "name": "Slightly Sustainable Solutions HQ",
                "styleUrl": "#icon-1547-DB4436",
                "styleHash": "-7f2bffab",
                "styleMapHash": {
                    "normal": "#icon-1547-DB4436-normal",
                    "highlight": "#icon-1547-DB4436-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
                "description": "HQ för mikrokorpen SSS, som försöker implementera diverse biotekniska lösningar för att fixa Pärldeltats katastrofala miljöproblem. "
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    113.3164332,
                    23.1004477,
                    0
                ]
            },
            "properties": {
                "name": "Assemblée Nationale de Djibouti",
                "styleUrl": "#icon-1548-DB4436",
                "styleHash": "-6aa2610c",
                "styleMapHash": {
                    "normal": "#icon-1548-DB4436-normal",
                    "highlight": "#icon-1548-DB4436-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
                "description": "Djiboutis exilregering (kraftigt decimerad och nedbandtad) håller hov i denna pompösa, brutalistiska gamla byggnad. Området har ett par hotell för att hysa besökande diplomater, men de flesta med någon budget föredrar att ta vektorjeten till Mandarin Oriental i Canaan Market. Spontana manifestationer mot afrikanska tyranner, i synnerhet Eritreas strongman Jumba, hålls i kvarteret med ojämna mellanrum. "
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    113.6352814,
                    22.910329,
                    0
                ]
            },
            "properties": {
                "name": "Linshi Sunken Temple",
                "styleUrl": "#icon-1668-DB4436",
                "styleHash": "-58b183ab",
                "styleMapHash": {
                    "normal": "#icon-1668-DB4436-normal",
                    "highlight": "#icon-1668-DB4436-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
                "description": "En helig plats bland båtfolket i Pärldeltat. Av det en gång magnifika templet syns nu bara delar ovanför vattenytan, och taket har fallit sönder. Men under kinesiska högtider åker folk ut hit i småbåtar eller på pråmar, och lämnar ljuslyktor brinnande bland de översvämmade ruinerna. "
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    113.5289089,
                    23.1358711,
                    0
                ]
            },
            "properties": {
                "name": "Drönarattack",
                "styleUrl": "#icon-1564-DB4436",
                "styleHash": "3c8b8efa",
                "styleMapHash": {
                    "normal": "#icon-1564-DB4436-normal",
                    "highlight": "#icon-1564-DB4436-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
                "description": "Rapporter om flera explosioner, och en oidentifierad military grade-drönare i luftrummet. Troligen ett unilateralt angrepp från någon av alla de oräkneliga individer/fraktioner som är upprörda på zonens daiban, Laoyao. Obekräftade uppgifter på Lagren hävdar att flera byggnader rasat, och 100+ peeps omkommit, däribland några av Laoyaos löjtnanter och barn. "
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    113.7218607,
                    23.1403809,
                    0
                ]
            },
            "properties": {
                "name": "Baihe Crossing",
                "styleUrl": "#icon-1657-DB4436",
                "styleHash": "161aa875",
                "styleMapHash": {
                    "normal": "#icon-1657-DB4436-normal",
                    "highlight": "#icon-1657-DB4436-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
                "description": "Den enda farbara vägen mellan Dongguan och Laoyao's Dominion. Eftersom zonernas daibans hatar varandra passionerat ser den väldigt lite trafik, och ön i mitten tros vara seedad med smarta minor, spionkameror och sentry guns. Pansarvagnar patrullerar ofta på bägge sidor. Detta till trots går det att få tillstånd att passera mellan zonerna, bara man har tillräckligt med guanxi hos båda fraktionerna - eller gott om € att muta med. "
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    114.108572,
                    22.2199203,
                    0
                ]
            },
            "properties": {
                "name": "Lamma Nuclear Plant",
                "styleUrl": "#icon-1642-DB4436",
                "styleHash": "32c05a19",
                "styleMapHash": {
                    "normal": "#icon-1642-DB4436-normal",
                    "highlight": "#icon-1642-DB4436-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
                "description": "Hongkongs torium-baserade kärnreaktorer. Hårt bevakade av Gränskommandot. "
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    114.0915107,
                    22.3428063,
                    0
                ]
            },
            "properties": {
                "name": "Sai Tso Wan Harbor",
                "styleUrl": "#icon-1569-DB4436",
                "styleHash": "-5cc457eb",
                "styleMapHash": {
                    "normal": "#icon-1569-DB4436-normal",
                    "highlight": "#icon-1569-DB4436-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
                "description": "Containerhamn administreras av Xifang<br>Gongye. Okänt vilket slags gods de mottar<br>här, men anländande skepp har bland<br>annat varit registrerade i Kongo och<br>Indonesien. Logistikarbetare och några<br>byråkrater bor och jobbar i zonen men<br>alla som vet något fattar att det myllrar av<br>DDW-agenter från Chongqing-falangen<br>här."
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    114.2053813,
                    22.340697,
                    0
                ]
            },
            "properties": {
                "name": "Chi Lin Nunnery ",
                "styleUrl": "#icon-1668-DB4436",
                "styleHash": "-58b183ab",
                "styleMapHash": {
                    "normal": "#icon-1668-DB4436-normal",
                    "highlight": "#icon-1668-DB4436-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
                "description": "Nunnorna i detta stora buddhistkloster vill hoppa av den fallfärdiga och korrupta Diamond Hill-zonen, vars daibans tyvärr inte tycks vilja ge upp klostret utan en fajt. Nunnorna har sökt hjälp hos Pure Land of the Goddess Tin Hau, och fått det - en stor grupp troende buddhister patrullerar nu området, och slänger bryskt ut representater från både korparna och vrakarna som dominerar i Diamond Hill. "
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    114.178616,
                    22.274773,
                    0
                ]
            },
            "properties": {
                "name": "Khalsa Diwan Sikh Temple ",
                "styleUrl": "#icon-1678-DB4436",
                "styleHash": "23f8b196",
                "styleMapHash": {
                    "normal": "#icon-1678-DB4436-normal",
                    "highlight": "#icon-1678-DB4436-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
                "description": "Den klassiska gamla gurdwaran i Hong Kong. Ghazi-gänget Khalsa Kowloon har förgäves försökt ta kontroll över det, och ryktas ha någon form av kupp på gång mot anatta-gänget Sukhāvatī Siblings, som för tillfället kontrollerar zonen. Av religiösa skäl står KK nämligen inte ut med den AI, Madālasā, som Sukhāvatī Siblings,låter sköta mycket av styret i Morrison-zonen, då sikherna anser att deras egen digitaliserade AI-guru, AI Granth, är den enda AI vars ord bör åtlydas. Kaoset i närbelägna South Wan Chai tycks erbjuda KK möjligheter... <br><br>Gurdwaran erbjuder, sikhernas vana trogen, gratis boende (i 15 dagar) och gratis mat till alla behövande, och området är därför ofta frekventerat av shackers och desperata - men även turister och andra nyfikna. "
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    113.111203,
                    23.027054,
                    0
                ]
            },
            "properties": {
                "name": "Foshan Ancestral Temple",
                "styleUrl": "#icon-1668-DB4436",
                "styleHash": "-58b183ab",
                "styleMapHash": {
                    "normal": "#icon-1668-DB4436-normal",
                    "highlight": "#icon-1668-DB4436-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
                "description": "Ett av de viktigaste templen i Pärldeltat. Hur mycket Foshans krigsherrar än strider mot varandra är de inte tillräckligt radikala kommunister för att skända denna plats - den har förblivit en lugn och sakral oas i Foshans betongdjungel. "
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    114.1808244,
                    22.2797968,
                    0
                ]
            },
            "properties": {
                "name": "Canal Road Flyover",
                "styleUrl": "#icon-1676-DB4436",
                "styleHash": "-51a8ba8",
                "styleMapHash": {
                    "normal": "#icon-1676-DB4436-normal",
                    "highlight": "#icon-1676-DB4436-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
                "description": "Här sitter på vissa av månadens dagar gamla analoger som erbjuder mystiska tjänster, i synnerhet den egendomliga ritualen \"villain hitting\", som gör sig bäst på skuggiga platser under vägbroar som denna - har du en fiende eller bitterhet på någon som du behöver få utlopp för? Stick hit under jingzhe-perioden (du kan behöva oogla det begreppet) och betala en tant med ett magi-kit ett par €. "
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    114.173124,
                    22.2748837,
                    0
                ]
            },
            "properties": {
                "name": "Cactus Mansion",
                "styleUrl": "#icon-1547-DB4436",
                "styleHash": "-7f2bffab",
                "styleMapHash": {
                    "normal": "#icon-1547-DB4436-normal",
                    "highlight": "#icon-1547-DB4436-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
                "description": "Ett bizarro höghus från medeltiden, vars udda form och färg gett upphov till dess namn. Invånarna är en mix av åldrade mexikanska taktikals och unga nomos, som kunnat flytta in för att hyrorna i stora delar av Wan Chai, med Hong Kong-mått mätt, blivit relativt låga tack vare allt OC-våld i området.  Cactus Mansion är känt för sina hängande trädgårdar, som producerar Pärldeltats bästa DIY-chili. "
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    114.2033618,
                    22.2716613,
                    0
                ]
            },
            "properties": {
                "name": "Vapentester",
                "styleUrl": "#icon-1541-DB4436",
                "styleHash": "59a489b",
                "styleMapHash": {
                    "normal": "#icon-1541-DB4436-normal",
                    "highlight": "#icon-1541-DB4436-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
                "description": "Ultech använder bergssluttningen för provskjutningar av vapen, och ibland även granater och sprängladdningar. Området är därför farligt att beträda utanför markerade leder, och man måste alltid vara uppmärksam på rasrisk. "
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    113.2008603,
                    23.1775699,
                    0
                ]
            },
            "properties": {
                "name": "Guangzhou Unconventional Drugs & Therapies Emporium",
                "styleUrl": "#icon-1646-DB4436",
                "styleHash": "-7b192b6b",
                "styleMapHash": {
                    "normal": "#icon-1646-DB4436-normal",
                    "highlight": "#icon-1646-DB4436-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
                "description": "En gigantisk butik/klinik/experimentanläggning i UMBs regi. Här erbjuder megakorpen \"alternativa\" eller \"experimentiella\" behandlingar, mediciner och droger, till exempel icke-peer-reviewed budget-varianter av minnesraderande Oberg-Wu-behandlingar, \"hälsokurer\" med mikrodoser av opium-2, Ariadne och Ψ-DOM,  nya former av elchock-terapier, med mera. Turistguider beskriver det omväxlande som ett mardrömslikt och ett fascinerande ställe, men avtrubbade amoks och psykonauter och traumaoffer som prövat allt annat söker sig ofta hit - för UMB erbjuder generösa rabatter om man går med på att delta i diverse experiment. "
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    113.2111151,
                    23.1220293,
                    0
                ]
            },
            "properties": {
                "name": "Risk för minor!",
                "styleUrl": "#icon-1541-DB4436",
                "styleHash": "59a489b",
                "styleMapHash": {
                    "normal": "#icon-1541-DB4436-normal",
                    "highlight": "#icon-1541-DB4436-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
                "description": "UMB tros ha placerat ut en typ av madagaskiska sjöminor i vattnen runt UMB Island. Ta dig framåt med enorm försiktighet om du har för avsikt att färdas med båt bortom denna punkt. "
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    113.120636,
                    23.020794,
                    0
                ]
            },
            "properties": {
                "name": "Great Hall of The People",
                "styleUrl": "#icon-1547-DB4436",
                "styleHash": "-7f2bffab",
                "styleMapHash": {
                    "normal": "#icon-1547-DB4436-normal",
                    "highlight": "#icon-1547-DB4436-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
                "description": "Foshans partipampar sammanträder här (när de inte skjuter på varandra). "
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    114.1805057,
                    22.3073685,
                    0
                ]
            },
            "properties": {
                "name": "Hung Hom Climbers",
                "styleUrl": "#icon-1772-DB4436",
                "styleHash": "-3ea49345",
                "styleMapHash": {
                    "normal": "#icon-1772-DB4436-normal",
                    "highlight": "#icon-1772-DB4436-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
                "description": "Hong Kongs mest kända klättrar-by, som till och med har ett hängande torg. Föremål för ett bråk mellan Interworld och Requiem TV. "
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    114.1612975,
                    22.3235681,
                    0
                ]
            },
            "properties": {
                "name": "Varnande exempel",
                "styleUrl": "#icon-1796-DB4436",
                "styleHash": "cd651b9",
                "styleMapHash": {
                    "normal": "#icon-1796-DB4436-normal",
                    "highlight": "#icon-1796-DB4436-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
                "description": "Fyra av hyresgästerna i Marvel Court har hittats i mindre levande skick, upphängda högt på byggnadens slitna fasad, där delar av dem fritt kunde rinna ned på förbipasserande. Den rådande teorin bland flikar och mediaformare är att det är nån form av hämndaktion mot Xinyu DiDemocratic Movement från gäng affilierade med Chi Sin-triaden, sannolikt Amihan's Posse, som är kända för sina utstuderade gankningar av mer eller mindre oskyldiga. "
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    113.8741832,
                    22.743181,
                    0
                ]
            },
            "properties": {
                "name": "New Tankograd MBT Cache ",
                "styleUrl": "#icon-1562-DB4436",
                "styleHash": "137851bc",
                "styleMapHash": {
                    "normal": "#icon-1562-DB4436-normal",
                    "highlight": "#icon-1562-DB4436-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
                "description": "2061 köpte sibirien 68 ethiopiska Lion of Judah-tanks, som efter en lång serie byråkratiska fuckups lastades av i Shekou. Gränskommandot kunde inte ha 68 main battle tanks som stod och tog plats i hamnen, och transporterade därför egenmäktigt allihop till en lämplig dumpningsplats i norra Shenzhen. Där har de stått sedan dess, ihop med ett ton av gammalt kinesiskt krom som zonens kommunistiska milis samlat ihop. Sibirien har försökt få till en deal med både Gränskommandot, Interworld och Xifang Gongye för att kunna åka och hämta tanksen, men alla inblandade tar chansen att försöka utpressa sovjetrepubliken, och hittils har ingen hittat en lösning alla är tillfreds med. <br><br>Entusiastiska amoks och andra antisos som vill köra destructo-slalom försöker ibland stjäla tanks här, men få har lyckats på grund av New Tankograds välbeväpnade milis, som gjort det till sin heliga uppgift för Komintern att skydda och serva allt krom tills Sibirien kan hämta upp det. "
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    113.874824,
                    22.729363,
                    0
                ]
            },
            "properties": {
                "name": "New Tankograd Airstrip",
                "styleUrl": "#icon-1750-DB4436",
                "styleHash": "-610c3b05",
                "styleMapHash": {
                    "normal": "#icon-1750-DB4436-normal",
                    "highlight": "#icon-1750-DB4436-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
                "description": "Enligt drönarfoto verkar New Tankograds fanatiska, cargo-kult-artade milis hålla på att bygga en airstrip här, förmodligen i hopp om att sibirska flygvapnet ska airlifta ut dem allihop, och allt sibiriskt krom som ligger och samlar damm i zonen. "
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    114.1495431,
                    22.4941676,
                    0
                ]
            },
            "properties": {
                "name": "Stanley Lowton Mortuary Area",
                "styleUrl": "#icon-1796-DB4436",
                "styleHash": "cd651b9",
                "styleMapHash": {
                    "normal": "#icon-1796-DB4436-normal",
                    "highlight": "#icon-1796-DB4436-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
                "description": "Ett nästan bortglömt gravkomplex, inramat av murar i metall och korrugerad plåt, övervuxet av lummig växtlighet. Vids på 99chan och Cryptids.su visar en stor, hundliknande varelse med röda ögon som länge påståtts hemsöka denna plats om nätterna. Varför alla dessa klipp är i gryning 72 dpi tycks ingen på Lagren kunna förklara. "
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    113.1197334,
                    22.6020491,
                    0
                ]
            },
            "properties": {
                "name": "Aliko's Last Step",
                "styleUrl": "#icon-1599-DB4436",
                "styleHash": "193a47d8",
                "styleMapHash": {
                    "normal": "#icon-1599-DB4436-normal",
                    "highlight": "#icon-1599-DB4436-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
                "description": "Här dog den tidiga anon-legenden Aliko när hans amfibiebil gick på en UXO. Den spruckna betong som sticker upp ur det grunda vattnet är täckt med tjocka lager av tibetanska bönevimplar, gravgåvor och diverse fabber-memorabilia. "
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    112.808576,
                    23.1139415,
                    0
                ]
            },
            "properties": {
                "name": "Makou Car Graveyard",
                "styleUrl": "#icon-1741-DB4436",
                "styleHash": "36d32e59",
                "styleMapHash": {
                    "normal": "#icon-1741-DB4436-normal",
                    "highlight": "#icon-1741-DB4436-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
                "description": "En enorm ansamling mer eller mindre rostiga biler från före Kollapsen, staplade i högar stora som Gasherbrum. En del nomader, zonfeis och allmänt desperata kommer hit i hopp om att hitta något som fortfarande går att köra efter lite silvertejp, men det är inte rekommenderat eftersom bilbergen är instabila, och Gränskommandot gillar att flinga granater över floden. "
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    113.2807629,
                    21.9048234,
                    0
                ]
            },
            "properties": {
                "name": "The Blob",
                "styleUrl": "#icon-1894-DB4436",
                "styleHash": "-3a078a6",
                "styleMapHash": {
                    "normal": "#icon-1894-DB4436-normal",
                    "highlight": "#icon-1894-DB4436-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
                "description": "På den här stranden ligger ett enormt objekt och ruttnar sedan vintern -69. Somliga påstår att det är en val eller en kolossbläckfisk, andra ett uppsköljt experiment från en hemlig Genetic-bas under havet. "
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    113.0977515,
                    22.9501228,
                    0
                ]
            },
            "properties": {
                "name": "Lecong Flow Space",
                "styleUrl": "#icon-1773-DB4436",
                "styleHash": "-2a1af4a6",
                "styleMapHash": {
                    "normal": "#icon-1773-DB4436-normal",
                    "highlight": "#icon-1773-DB4436-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
                "description": "I denna gamla park står partytälten och utomhusscenerna permanent uppställda för några av PRDs fetaste musikevents. Besökarna är framför allt nomader och nomo som gillar överhypade singer-songwriters och tuvansk electro-khoomei, och står ut med spöklika ruiner och konstanta översvämningar. Risken är inte obefintlig att man får en roofie och blir shanghajjad till något av Foshans medborgargarden. "
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    113.2986393,
                    23.0224055,
                    0
                ]
            },
            "properties": {
                "name": "Dashi 2nd Crossing",
                "styleUrl": "#icon-1588-DB4436",
                "styleHash": "-77f98c08",
                "styleMapHash": {
                    "normal": "#icon-1588-DB4436-normal",
                    "highlight": "#icon-1588-DB4436-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
                "description": "Bevakas av Nanpu-sekten och deras drönare. Du rekommenderas att ta en annan väg, om du inte är svingrym på bibel-trivia författad på manchuiska. "
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    113.2709804,
                    23.0262361,
                    0
                ]
            },
            "properties": {
                "name": "Dashi 1st Crossing",
                "styleUrl": "#icon-1588-DB4436",
                "styleHash": "-77f98c08",
                "styleMapHash": {
                    "normal": "#icon-1588-DB4436-normal",
                    "highlight": "#icon-1588-DB4436-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
                "description": "Bevakas av Nanpu-sekten och deras drönare. Du rekommenderas att ta en annan väg, om du inte är svingrym på bibel-trivia författad på manchuiska. "
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    113.2574045,
                    23.0465922,
                    0
                ]
            },
            "properties": {
                "name": "Shenyong Crossing",
                "styleUrl": "#icon-1541-DB4436",
                "styleHash": "59a489b",
                "styleMapHash": {
                    "normal": "#icon-1541-DB4436-normal",
                    "highlight": "#icon-1541-DB4436-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
                "description": "Den värsta vägen in i Nanpu Heavenly Kingdom; kommunisterna i Foshan och teosarna i Nanpu hatar varandra passionerat, och bron är enligt uppgifter försåtsminerad. "
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    113.5634236,
                    22.1428818,
                    0
                ]
            },
            "properties": {
                "name": "Cotai Strip",
                "styleUrl": "#icon-1540-DB4436",
                "styleHash": "-eef5604",
                "styleMapHash": {
                    "normal": "#icon-1540-DB4436-normal",
                    "highlight": "#icon-1540-DB4436-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
                "description": "Hjärtat i Macao, och platsen för The Thirteen Orphans, dess berömda megacasinon. "
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    113.4477535,
                    23.141618,
                    0
                ]
            },
            "properties": {
                "name": "The Wall",
                "styleUrl": "#icon-1541-DB4436",
                "styleHash": "59a489b",
                "styleMapHash": {
                    "normal": "#icon-1541-DB4436-normal",
                    "highlight": "#icon-1541-DB4436-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
                "description": "En gräns fortifierad av en betongmur med glaskross och taggtråd, och frekventa vakt- och drönarpatruller som gärna skjuter skarpt. Det är oklart om Laoyao byggt muren från att hindra drägg från Jishanxiang att ta sig in i hans zon, eller för att hindra folk han redan har där från att ta sig ut och rymma. Kanske lite av båda. Är #3 på 99Chans berömda lista \"Top 1000 Ways to Die in the PRD\". Lämna det här stället utanför dina urbex-planer, hett tips.  "
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    113.4485637,
                    23.1293687,
                    0
                ]
            },
            "properties": {
                "name": "Jishanxiang Helipad",
                "styleUrl": "#icon-1593-DB4436",
                "styleHash": "-61ff6fe2",
                "styleMapHash": {
                    "normal": "#icon-1593-DB4436-normal",
                    "highlight": "#icon-1593-DB4436-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
                "description": "Eftersom det av zon-fuckery isolerade Jishanxiang kan vara groteskt svårt att ta sig till utan rätt cert, fordon eller kontakter, har Canaan Liberation Group upprättat denna  hub för vektortaxis, som ett komplement till den suboptimalt designade och stundtals terrorhotade tunnelbanan. 75% av Jishanxiangs arbetande befolkning arbetar i Canaan Market, så behovet av kommunikationer är stort.  "
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    113.5983614,
                    23.1625535,
                    0
                ]
            },
            "properties": {
                "name": "Laoyao's Palace",
                "styleUrl": "#icon-1548-DB4436",
                "styleHash": "-6aa2610c",
                "styleMapHash": {
                    "normal": "#icon-1548-DB4436-normal",
                    "highlight": "#icon-1548-DB4436-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
                "description": "En av Pärldeltats mest spektakulära byggnader, åtminstone om man är ett fan av old-schoolig brutalism med rococo-inslag. Ett gigantiskt komplex som huserar krigsherrens familj och slavar, hundratals vakter, paranoida säkerhetssystem och mytomspunna konstskatter, stulna under Kinas kollaps.  Hur Laoyao blev så rik är omdiskuterat, men förmodligen rör det sig om djupa, djupa lager av korruptionspengar förvärvade under ett långt liv. "
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    113.2918404,
                    23.0512242,
                    0
                ]
            },
            "properties": {
                "name": "Cod Piece",
                "styleUrl": "#icon-1573-DB4436",
                "styleHash": "-5b53da64",
                "styleMapHash": {
                    "normal": "#icon-1573-DB4436-normal",
                    "highlight": "#icon-1573-DB4436-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
                "description": "Denna lilla kiosk ska enligt \"Amok's Guide to Chop\", en legendarisk 99chan-post, ha Pärldeltats bästa fish and chips. Många har bekräftat denna utsago på diverse kanaler, medan andra ifrågasätter trovärdigheten i deras påståenden, eftersom alla vet att nästan ingen får tillträde till Luoxi Industrial-zonen. "
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            113.7627411,
                            22.6792823,
                            0
                        ],
                        [
                            113.7975139,
                            22.6219277,
                            0
                        ],
                        [
                            113.8235092,
                            22.5813635,
                            0
                        ],
                        [
                            113.8393807,
                            22.6041002,
                            0
                        ],
                        [
                            113.8554292,
                            22.6271527,
                            0
                        ],
                        [
                            113.8245366,
                            22.6779401,
                            0
                        ],
                        [
                            113.8430786,
                            22.6780152,
                            0
                        ],
                        [
                            113.846515,
                            22.6827658,
                            0
                        ],
                        [
                            113.8484001,
                            22.6935365,
                            0
                        ],
                        [
                            113.8562107,
                            22.7233467,
                            0
                        ],
                        [
                            113.8535287,
                            22.7239698,
                            0
                        ],
                        [
                            113.8532177,
                            22.7248355,
                            0
                        ],
                        [
                            113.8540063,
                            22.7262976,
                            0
                        ],
                        [
                            113.8528986,
                            22.7311056,
                            0
                        ],
                        [
                            113.8538508,
                            22.731243,
                            0
                        ],
                        [
                            113.8500099,
                            22.74002,
                            0
                        ],
                        [
                            113.8465118,
                            22.7489553,
                            0
                        ],
                        [
                            113.8272858,
                            22.7628857,
                            0
                        ],
                        [
                            113.819046,
                            22.7692172,
                            0
                        ],
                        [
                            113.8080597,
                            22.7692172,
                            0
                        ],
                        [
                            113.7936401,
                            22.7666846,
                            0
                        ],
                        [
                            113.7888336,
                            22.7521214,
                            0
                        ],
                        [
                            113.7448883,
                            22.7356569,
                            0
                        ],
                        [
                            113.7627411,
                            22.6792823,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Bao'an zone",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Flygplats och företagsstäder co-administrerat av Interworld och Xifang gongye (Werinco).<br><br>Prefekten som styr över zonen är också<br>en aktivistisk socialdarwinist som betalar<br>1000 € för sperma eller ägg från peeps<br>med mycket bra gener (slå ditt högsta att-<br>ribut utan edge mot 24 för att kvalificera).<br>Hen betalar lika mycket till alla andra för<br>att de skall sterilisera sig permanent. Inget<br>krav, bara ett vänligt förslag.",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            113.3067432,
                            23.0487752,
                            0
                        ],
                        [
                            113.3060136,
                            23.0410745,
                            0
                        ],
                        [
                            113.3090606,
                            23.0393369,
                            0
                        ],
                        [
                            113.305799,
                            23.0346767,
                            0
                        ],
                        [
                            113.3086086,
                            23.0331052,
                            0
                        ],
                        [
                            113.3580049,
                            23.0281773,
                            0
                        ],
                        [
                            113.3629194,
                            23.0305386,
                            0
                        ],
                        [
                            113.3648832,
                            23.0319167,
                            0
                        ],
                        [
                            113.3665466,
                            23.0336107,
                            0
                        ],
                        [
                            113.361127,
                            23.0344471,
                            0
                        ],
                        [
                            113.354187,
                            23.0355064,
                            0
                        ],
                        [
                            113.3483505,
                            23.0413513,
                            0
                        ],
                        [
                            113.3235439,
                            23.0506089,
                            0
                        ],
                        [
                            113.3067432,
                            23.0487752,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "区139 Social Housing",
                "styleUrl": "#poly-000000-1200-76-nodesc",
                "styleHash": "-265172d4",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-nodesc-normal",
                    "highlight": "#poly-000000-1200-76-nodesc-highlight"
                },
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            113.9466279,
                            22.4320223,
                            0
                        ],
                        [
                            113.9146989,
                            22.4220256,
                            0
                        ],
                        [
                            113.9023393,
                            22.4066324,
                            0
                        ],
                        [
                            113.9203637,
                            22.387587,
                            0
                        ],
                        [
                            113.9134973,
                            22.3756823,
                            0
                        ],
                        [
                            113.9346116,
                            22.3591727,
                            0
                        ],
                        [
                            113.9536661,
                            22.3559975,
                            0
                        ],
                        [
                            113.9731909,
                            22.3702845,
                            0
                        ],
                        [
                            113.9664752,
                            22.3812372,
                            0
                        ],
                        [
                            113.9701424,
                            22.3865902,
                            0
                        ],
                        [
                            113.9729749,
                            22.3856974,
                            0
                        ],
                        [
                            113.9752509,
                            22.3857692,
                            0
                        ],
                        [
                            113.9785109,
                            22.3863918,
                            0
                        ],
                        [
                            113.9814752,
                            22.4042519,
                            0
                        ],
                        [
                            113.9770383,
                            22.405495,
                            0
                        ],
                        [
                            113.9749784,
                            22.4055744,
                            0
                        ],
                        [
                            113.9744526,
                            22.4078359,
                            0
                        ],
                        [
                            113.9714057,
                            22.4069035,
                            0
                        ],
                        [
                            113.9712125,
                            22.4073003,
                            0
                        ],
                        [
                            113.9693994,
                            22.4082723,
                            0
                        ],
                        [
                            113.9686805,
                            22.4115355,
                            0
                        ],
                        [
                            113.9633032,
                            22.418705,
                            0
                        ],
                        [
                            113.9466279,
                            22.4320223,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Bepron Energy Zone",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "En zon med hamnar och förvar av petroleum, gas och återvunnet material. Även om handeln med petroleum krymper drastiskt varje år och är hårt reglerad så har Bepron tagit tillvara på området, eftersom det inte finns någon reell reglering i PRD (på pappret tar dock Gränskommandot miljön på stort allvar). Zonen innehåller även förstaden Tuen Mun.",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            113.3510971,
                            23.1337301,
                            0
                        ],
                        [
                            113.3516121,
                            23.1106808,
                            0
                        ],
                        [
                            113.3512376,
                            23.1097677,
                            0
                        ],
                        [
                            113.3608294,
                            23.1058795,
                            0
                        ],
                        [
                            113.3656679,
                            23.106067,
                            0
                        ],
                        [
                            113.4012276,
                            23.1046659,
                            0
                        ],
                        [
                            113.4075791,
                            23.1206121,
                            0
                        ],
                        [
                            113.4067208,
                            23.1313471,
                            0
                        ],
                        [
                            113.3596802,
                            23.144464,
                            0
                        ],
                        [
                            113.3510971,
                            23.1337301,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "New Town Utility Zone",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Administreras av APT369, en ursprungligen rysk anon-cell som numera även har starkt stöd från sibiriska orbitals. <br><br>New Town Utility Zone styrs enligt en techno-utilitaristisk filosofi som skapades av APT369-ikonen Alina, där löner och coins avskaffats, och resurser istället delas ut baserat på hur mycket utility man anses producera åt andra enligt en avancerad algoritm. Det tycks funka märkligt bra, för New Town Utility Zone är anmärkningsvärt glassig och högfungerande, full av snygga och välklädda anon och sputniks. Det enda orosmolnet är marodörer och amoks som tar sig in från angränsande zoner för att jävlas med anons utopi. <br><br>Särskilda bestämmelser:<br><br>Alla i zonen måste installera ett chip som kopplar dem till utility-algoritmen. <br><br>Inga varor eller tjänster får köpas i zonen. Dessa förtjänas istället på utility-basis. ",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            113.2252693,
                            23.1204694,
                            0
                        ],
                        [
                            113.2331657,
                            23.1072073,
                            0
                        ],
                        [
                            113.245182,
                            23.0988389,
                            0
                        ],
                        [
                            113.2496452,
                            23.0920491,
                            0
                        ],
                        [
                            113.2582283,
                            23.0963125,
                            0
                        ],
                        [
                            113.269558,
                            23.10026,
                            0
                        ],
                        [
                            113.2755661,
                            23.1056284,
                            0
                        ],
                        [
                            113.2788277,
                            23.1108387,
                            0
                        ],
                        [
                            113.2829475,
                            23.1114703,
                            0
                        ],
                        [
                            113.2934189,
                            23.1064179,
                            0
                        ],
                        [
                            113.3047485,
                            23.1051547,
                            0
                        ],
                        [
                            113.3170028,
                            23.1047995,
                            0
                        ],
                        [
                            113.3185508,
                            23.10364,
                            0
                        ],
                        [
                            113.3205239,
                            23.1029147,
                            0
                        ],
                        [
                            113.3249421,
                            23.102451,
                            0
                        ],
                        [
                            113.3293097,
                            23.1016911,
                            0
                        ],
                        [
                            113.332957,
                            23.101728,
                            0
                        ],
                        [
                            113.3321372,
                            23.1049623,
                            0
                        ],
                        [
                            113.3317228,
                            23.1087468,
                            0
                        ],
                        [
                            113.3497238,
                            23.1078389,
                            0
                        ],
                        [
                            113.3512376,
                            23.1097677,
                            0
                        ],
                        [
                            113.3516121,
                            23.1106808,
                            0
                        ],
                        [
                            113.3510971,
                            23.1337301,
                            0
                        ],
                        [
                            113.3596802,
                            23.144464,
                            0
                        ],
                        [
                            113.3414841,
                            23.1462003,
                            0
                        ],
                        [
                            113.322773,
                            23.1537766,
                            0
                        ],
                        [
                            113.3222473,
                            23.1566472,
                            0
                        ],
                        [
                            113.3229233,
                            23.1574659,
                            0
                        ],
                        [
                            113.3215285,
                            23.1584721,
                            0
                        ],
                        [
                            113.3159923,
                            23.162694,
                            0
                        ],
                        [
                            113.3041906,
                            23.1591035,
                            0
                        ],
                        [
                            113.2995128,
                            23.1582748,
                            0
                        ],
                        [
                            113.2944488,
                            23.159064,
                            0
                        ],
                        [
                            113.2910586,
                            23.1585904,
                            0
                        ],
                        [
                            113.2916761,
                            23.1532326,
                            0
                        ],
                        [
                            113.2879644,
                            23.1500186,
                            0
                        ],
                        [
                            113.2864151,
                            23.1487466,
                            0
                        ],
                        [
                            113.2858099,
                            23.1479875,
                            0
                        ],
                        [
                            113.2824152,
                            23.1456105,
                            0
                        ],
                        [
                            113.2798704,
                            23.1415843,
                            0
                        ],
                        [
                            113.2776324,
                            23.1405775,
                            0
                        ],
                        [
                            113.2755661,
                            23.1394129,
                            0
                        ],
                        [
                            113.268099,
                            23.1345195,
                            0
                        ],
                        [
                            113.2604599,
                            23.1351508,
                            0
                        ],
                        [
                            113.2541085,
                            23.1330987,
                            0
                        ],
                        [
                            113.2451395,
                            23.1350716,
                            0
                        ],
                        [
                            113.2421142,
                            23.1422535,
                            0
                        ],
                        [
                            113.2364705,
                            23.1504218,
                            0
                        ],
                        [
                            113.2296469,
                            23.1529474,
                            0
                        ],
                        [
                            113.2272006,
                            23.1555322,
                            0
                        ],
                        [
                            113.2275009,
                            23.1574067,
                            0
                        ],
                        [
                            113.2292175,
                            23.1602476,
                            0
                        ],
                        [
                            113.2305694,
                            23.1619442,
                            0
                        ],
                        [
                            113.2303763,
                            23.164351,
                            0
                        ],
                        [
                            113.2280382,
                            23.1662056,
                            0
                        ],
                        [
                            113.2250993,
                            23.1677445,
                            0
                        ],
                        [
                            113.2222661,
                            23.1675471,
                            0
                        ],
                        [
                            113.2194328,
                            23.1673497,
                            0
                        ],
                        [
                            113.2190687,
                            23.1664612,
                            0
                        ],
                        [
                            113.2186394,
                            23.165514,
                            0
                        ],
                        [
                            113.2163429,
                            23.1570122,
                            0
                        ],
                        [
                            113.2197761,
                            23.1444641,
                            0
                        ],
                        [
                            113.2250976,
                            23.1402022,
                            0
                        ],
                        [
                            113.2257843,
                            23.1395313,
                            0
                        ],
                        [
                            113.2273293,
                            23.1383079,
                            0
                        ],
                        [
                            113.2271576,
                            23.1372029,
                            0
                        ],
                        [
                            113.2268143,
                            23.1351508,
                            0
                        ],
                        [
                            113.2257841,
                            23.129704,
                            0
                        ],
                        [
                            113.2252693,
                            23.1204694,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Canaan Market\n",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Oklar administration.<br><br>Den brokiga kantones-afrikanska själv-<br>ständighetsrörelsen Canaan Liberation Group försöker styra Canaan Market men har även gjort utfall mot andra zoner. Det är oklart exakt vem som styr zonen inom CLG, om någon. Deras<br>pickups och flodbåtar patrullerar flitigt.<br>Det sägs att de letar efter en expert på<br>radiakskador.<br><br>För tillfället råder förbud mot att äta utomhus i hela Canaan Market, efter alltför många spyor på marken som ingen fått för sig att städa upp. ",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.2106343,
                            22.3111942,
                            0
                        ],
                        [
                            114.2132279,
                            22.3154284,
                            0
                        ],
                        [
                            114.2045069,
                            22.321728,
                            0
                        ],
                        [
                            114.2040683,
                            22.3269949,
                            0
                        ],
                        [
                            114.1984034,
                            22.3317983,
                            0
                        ],
                        [
                            114.1913224,
                            22.3271934,
                            0
                        ],
                        [
                            114.189992,
                            22.3244343,
                            0
                        ],
                        [
                            114.1945839,
                            22.3223105,
                            0
                        ],
                        [
                            114.1936827,
                            22.3204446,
                            0
                        ],
                        [
                            114.1954825,
                            22.3195063,
                            0
                        ],
                        [
                            114.1958821,
                            22.3208118,
                            0
                        ],
                        [
                            114.2090893,
                            22.3093678,
                            0
                        ],
                        [
                            114.2106343,
                            22.3111942,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "CBD2",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Adm. av CBD2 Joint Venture Inc.<br><br>Bestämmelser: <br>Näringsidkare betalar zonskatt endast om<br>kunden begär ett kvitto. De mutar ofta<br>peeps för att inte begära kvitto. Förbud mot automatvapen.<br><br>POI: <br>Superskrapa (80–200 våningar)<br>Laboratorium<br>Charlie Chop<br>Laser Purifier Ltd.<br><br>Beskrivning: Området på Kai Tak, flygplatsen från medeltiden, lanserades för ett par decen-<br>nier sedan som det nya centrumet för hela<br>Hongkong. Det har inte riktigt blivit så, då<br>Victoria-zonen kom tillbaka och tog över<br>titeln. Men zonen är packad med moderna<br>skyskrapor och plattformar som utökat<br>landytan ovanpå det förgiftade vattnet.",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            113.917408,
                            22.293782,
                            0
                        ],
                        [
                            113.9203262,
                            22.2919554,
                            0
                        ],
                        [
                            113.9319992,
                            22.2886993,
                            0
                        ],
                        [
                            113.9416122,
                            22.2975144,
                            0
                        ],
                        [
                            113.9426422,
                            22.3052173,
                            0
                        ],
                        [
                            113.9475346,
                            22.3178426,
                            0
                        ],
                        [
                            113.9524269,
                            22.3300699,
                            0
                        ],
                        [
                            113.9200687,
                            22.322289,
                            0
                        ],
                        [
                            113.9070225,
                            22.3194306,
                            0
                        ],
                        [
                            113.8868523,
                            22.3105375,
                            0
                        ],
                        [
                            113.8899422,
                            22.2932261,
                            0
                        ],
                        [
                            113.9001971,
                            22.2922425,
                            0
                        ],
                        [
                            113.917408,
                            22.293782,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Chek Lap Kok (Worldport)",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Administrerad av Interworld. Denna zon, flygplatsen, är centrat i de många transportytor som ägs av InterWorld. Secos här letar febrilt efter något, eller någon. Exakt vad vill de inte säga. De har satt upp vägspärrar och scanners vid ut- och ingång till metros medan drönare<br>sveper över de större folkmassorna. All<br>trafik ut och in genom zonen tar ytterliga-<br>re 4T10 minuter.",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.1722071,
                            22.2969288,
                            0
                        ],
                        [
                            114.1723144,
                            22.2955192,
                            0
                        ],
                        [
                            114.1724861,
                            22.295539,
                            0
                        ],
                        [
                            114.1737306,
                            22.2956234,
                            0
                        ],
                        [
                            114.1737092,
                            22.2960701,
                            0
                        ],
                        [
                            114.1736341,
                            22.2971074,
                            0
                        ],
                        [
                            114.1729957,
                            22.2970553,
                            0
                        ],
                        [
                            114.1728505,
                            22.2987125,
                            0
                        ],
                        [
                            114.1720615,
                            22.2985433,
                            0
                        ],
                        [
                            114.1717162,
                            22.3031875,
                            0
                        ],
                        [
                            114.1681596,
                            22.3030139,
                            0
                        ],
                        [
                            114.167944,
                            22.3007664,
                            0
                        ],
                        [
                            114.1690338,
                            22.2997005,
                            0
                        ],
                        [
                            114.1698661,
                            22.298039,
                            0
                        ],
                        [
                            114.1720885,
                            22.2980911,
                            0
                        ],
                        [
                            114.1722071,
                            22.2969288,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Chungking-Mirador Mansions",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "I praktiken en zon, administrerad av Khalsa Kowloon. Inkluderar hela Kowloon Park som och moskén, som ockuperades efter Kollapsen.",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.2650223,
                            22.3507109,
                            0
                        ],
                        [
                            114.2597866,
                            22.3462654,
                            0
                        ],
                        [
                            114.2578983,
                            22.3457097,
                            0
                        ],
                        [
                            114.256525,
                            22.3430105,
                            0
                        ],
                        [
                            114.2587566,
                            22.3391999,
                            0
                        ],
                        [
                            114.2576408,
                            22.3372152,
                            0
                        ],
                        [
                            114.2576408,
                            22.3349128,
                            0
                        ],
                        [
                            114.2583275,
                            22.3292759,
                            0
                        ],
                        [
                            114.2608166,
                            22.3280056,
                            0
                        ],
                        [
                            114.263134,
                            22.3245122,
                            0
                        ],
                        [
                            114.264679,
                            22.323877,
                            0
                        ],
                        [
                            114.2662239,
                            22.3190336,
                            0
                        ],
                        [
                            114.2700863,
                            22.3166516,
                            0
                        ],
                        [
                            114.2657948,
                            22.311808,
                            0
                        ],
                        [
                            114.2696571,
                            22.3053761,
                            0
                        ],
                        [
                            114.2720604,
                            22.2979115,
                            0
                        ],
                        [
                            114.2738628,
                            22.2923525,
                            0
                        ],
                        [
                            114.2777252,
                            22.2916378,
                            0
                        ],
                        [
                            114.2810726,
                            22.2906053,
                            0
                        ],
                        [
                            114.2831326,
                            22.2925907,
                            0
                        ],
                        [
                            114.2865658,
                            22.2874286,
                            0
                        ],
                        [
                            114.290514,
                            22.2854432,
                            0
                        ],
                        [
                            114.2974663,
                            22.2845695,
                            0
                        ],
                        [
                            114.3131733,
                            22.2893347,
                            0
                        ],
                        [
                            114.3161774,
                            22.303232,
                            0
                        ],
                        [
                            114.3470747,
                            22.3071243,
                            0
                        ],
                        [
                            114.3625259,
                            22.3243534,
                            0
                        ],
                        [
                            114.3336869,
                            22.3442807,
                            0
                        ],
                        [
                            114.3287102,
                            22.3623207,
                            0
                        ],
                        [
                            114.3203845,
                            22.3712614,
                            0
                        ],
                        [
                            114.3178948,
                            22.3822654,
                            0
                        ],
                        [
                            114.2945454,
                            22.3826816,
                            0
                        ],
                        [
                            114.3008104,
                            22.3708784,
                            0
                        ],
                        [
                            114.3007668,
                            22.3494986,
                            0
                        ],
                        [
                            114.2775509,
                            22.3476456,
                            0
                        ],
                        [
                            114.2650223,
                            22.3507109,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Clear Water Bay (Oasis)\n",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Oasis dominerar varje aspekt av zonen, och dess trademark syns förstås: konstinstallationer, fontäner och oaser med rent och gott vatten. Ett av Oasis universitet ligger i zonen tillsammans med många yao bostäder. Oasis ger gratis cert och basinkomst till alla zonens invånare men<br>för att komma in krävs bra genuppsättning<br>och mycket lång kötid.",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.1919736,
                            22.2796559,
                            0
                        ],
                        [
                            114.1909007,
                            22.2786632,
                            0
                        ],
                        [
                            114.1905474,
                            22.2786209,
                            0
                        ],
                        [
                            114.1899841,
                            22.2786655,
                            0
                        ],
                        [
                            114.1909282,
                            22.2780699,
                            0
                        ],
                        [
                            114.1931143,
                            22.2771713,
                            0
                        ],
                        [
                            114.1934736,
                            22.2774915,
                            0
                        ],
                        [
                            114.1932188,
                            22.2779457,
                            0
                        ],
                        [
                            114.1929238,
                            22.2783776,
                            0
                        ],
                        [
                            114.1925214,
                            22.2788839,
                            0
                        ],
                        [
                            114.1919736,
                            22.2796559,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Close Quarters",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Adm. av Azteca Majores.<br><br>Soulja-gänget AM styr zonen och har<br>gjort den till en bas för sin försäljning av<br>bergskokain, kläder och parafernalier.<br>Babel-lagren får gå på högvarv för att<br>förstå den lokala blandningen av spanska<br>och basic, detta gäller särskilt infiltratörer<br>från andra OC:s som är här för att ta reda<br>på hur och var AM tar in sina varor. Många anar kopplingar mellan Azteca Majores och kartellerna i Shek Tong Tsui, som än så länge är rätt svaga i HK, och hade kunnat behöva luta sig på ett simpelt gatugäng som Azteca Majores. ",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            113.3624911,
                            23.1702298,
                            0
                        ],
                        [
                            113.3607423,
                            23.169135,
                            0
                        ],
                        [
                            113.3611768,
                            23.1686369,
                            0
                        ],
                        [
                            113.361838,
                            23.1678071,
                            0
                        ],
                        [
                            113.362821,
                            23.1679045,
                            0
                        ],
                        [
                            113.3631992,
                            23.1671129,
                            0
                        ],
                        [
                            113.3640039,
                            23.1664965,
                            0
                        ],
                        [
                            113.36483,
                            23.166097,
                            0
                        ],
                        [
                            113.3647656,
                            23.1654164,
                            0
                        ],
                        [
                            113.3645726,
                            23.1632463,
                            0
                        ],
                        [
                            113.3656883,
                            23.1628123,
                            0
                        ],
                        [
                            113.366375,
                            23.1625756,
                            0
                        ],
                        [
                            113.367877,
                            23.1619048,
                            0
                        ],
                        [
                            113.369894,
                            23.1608987,
                            0
                        ],
                        [
                            113.3724045,
                            23.1586102,
                            0
                        ],
                        [
                            113.3731127,
                            23.1605042,
                            0
                        ],
                        [
                            113.3739281,
                            23.1621021,
                            0
                        ],
                        [
                            113.3723831,
                            23.1640355,
                            0
                        ],
                        [
                            113.3700228,
                            23.1663732,
                            0
                        ],
                        [
                            113.3684993,
                            23.1688094,
                            0
                        ],
                        [
                            113.3682257,
                            23.1690856,
                            0
                        ],
                        [
                            113.3672655,
                            23.1691251,
                            0
                        ],
                        [
                            113.3659029,
                            23.1686319,
                            0
                        ],
                        [
                            113.3644438,
                            23.1708118,
                            0
                        ],
                        [
                            113.3624911,
                            23.1702298,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "East Side 8",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Oklar adm, kontrolleras av anon-cellen People's Proxy. <br><br>Zonens verkar till synes inte styras av nå-<br>gon men auto turrets på de större gatorna<br>sätter stopp för krigsherrar, banditer och<br>andra som försöker ta över zonen. Vem<br>som styr dem är oklart, men ryktena säger<br>att det är the People’s Proxy, en anon-cell,<br>som vakar över zonen och upprätthåller<br>freden.",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.1552166,
                            22.2987565,
                            0
                        ],
                        [
                            114.1580919,
                            22.2985977,
                            0
                        ],
                        [
                            114.1591648,
                            22.2999874,
                            0
                        ],
                        [
                            114.1668466,
                            22.3008609,
                            0
                        ],
                        [
                            114.1592077,
                            22.3183699,
                            0
                        ],
                        [
                            114.1548929,
                            22.3263021,
                            0
                        ],
                        [
                            114.1526344,
                            22.3229972,
                            0
                        ],
                        [
                            114.1545758,
                            22.3215715,
                            0
                        ],
                        [
                            114.1550099,
                            22.3037811,
                            0
                        ],
                        [
                            114.1543283,
                            22.3023485,
                            0
                        ],
                        [
                            114.1543383,
                            22.2998012,
                            0
                        ],
                        [
                            114.1552166,
                            22.2987565,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Elements Zone",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Zonen ägs av Sun Hung Kai, en markägare med långa anor. De har tjänat stora pengar på den<br>alltmer desperata kampen för bostad och<br>kontorsyta i deltat, och hyrorna i denna<br>zon hjälper förstås till. Företaget har<br>ibland beskyllts för att iscensätta katastro-<br>fer i zoner för att sedan kunna köpa upp<br>dem billigt. <br><br>Dominerad av affärsskrapor, gallerior och dyra lägenheter. ",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            112.8377403,
                            23.0184479,
                            0
                        ],
                        [
                            112.8995404,
                            22.9324693,
                            0
                        ],
                        [
                            112.9201404,
                            22.9008457,
                            0
                        ],
                        [
                            112.9139604,
                            22.8704802,
                            0
                        ],
                        [
                            112.9599672,
                            22.8344123,
                            0
                        ],
                        [
                            113.0121539,
                            22.8103617,
                            0
                        ],
                        [
                            113.0588473,
                            22.8097288,
                            0
                        ],
                        [
                            113.0780741,
                            22.7717454,
                            0
                        ],
                        [
                            113.1240807,
                            22.756549,
                            0
                        ],
                        [
                            113.140219,
                            22.7388181,
                            0
                        ],
                        [
                            113.1645942,
                            22.7280514,
                            0
                        ],
                        [
                            113.1851962,
                            22.7093673,
                            0
                        ],
                        [
                            113.2085408,
                            22.7033485,
                            0
                        ],
                        [
                            113.2236476,
                            22.722351,
                            0
                        ],
                        [
                            113.237381,
                            22.7521164,
                            0
                        ],
                        [
                            113.2696544,
                            22.7299513,
                            0
                        ],
                        [
                            113.2820144,
                            22.7407176,
                            0
                        ],
                        [
                            113.2984943,
                            22.7413509,
                            0
                        ],
                        [
                            113.3410678,
                            22.7489501,
                            0
                        ],
                        [
                            113.3602945,
                            22.7761772,
                            0
                        ],
                        [
                            113.3520546,
                            22.8027658,
                            0
                        ],
                        [
                            113.3314545,
                            22.8249191,
                            0
                        ],
                        [
                            113.3101678,
                            22.8331465,
                            0
                        ],
                        [
                            113.2998678,
                            22.8825006,
                            0
                        ],
                        [
                            113.3163478,
                            22.8888267,
                            0
                        ],
                        [
                            113.2875078,
                            22.9052734,
                            0
                        ],
                        [
                            113.2751479,
                            22.9394254,
                            0
                        ],
                        [
                            113.2518011,
                            22.9760975,
                            0
                        ],
                        [
                            113.251243,
                            22.9886619,
                            0
                        ],
                        [
                            113.2584366,
                            22.9953878,
                            0
                        ],
                        [
                            113.2564894,
                            22.998321,
                            0
                        ],
                        [
                            113.2509641,
                            22.9988939,
                            0
                        ],
                        [
                            113.2494408,
                            23.002884,
                            0
                        ],
                        [
                            113.2509427,
                            23.0107058,
                            0
                        ],
                        [
                            113.2573282,
                            23.0126723,
                            0
                        ],
                        [
                            113.262255,
                            23.0198523,
                            0
                        ],
                        [
                            113.2697059,
                            23.0248111,
                            0
                        ],
                        [
                            113.2655776,
                            23.028441,
                            0
                        ],
                        [
                            113.2627021,
                            23.0329635,
                            0
                        ],
                        [
                            113.2549339,
                            23.036301,
                            0
                        ],
                        [
                            113.2547083,
                            23.0413953,
                            0
                        ],
                        [
                            113.2567145,
                            23.0468056,
                            0
                        ],
                        [
                            113.2736555,
                            23.0534795,
                            0
                        ],
                        [
                            113.2741442,
                            23.0564214,
                            0
                        ],
                        [
                            113.271028,
                            23.0593633,
                            0
                        ],
                        [
                            113.2577237,
                            23.0692352,
                            0
                        ],
                        [
                            113.2509525,
                            23.0628189,
                            0
                        ],
                        [
                            113.2386887,
                            23.0606664,
                            0
                        ],
                        [
                            113.229231,
                            23.0560167,
                            0
                        ],
                        [
                            113.2389137,
                            23.0460755,
                            0
                        ],
                        [
                            113.2375083,
                            23.043568,
                            0
                        ],
                        [
                            113.2243343,
                            23.0419087,
                            0
                        ],
                        [
                            113.212297,
                            23.0433116,
                            0
                        ],
                        [
                            113.2024908,
                            23.0512692,
                            0
                        ],
                        [
                            113.184422,
                            23.0600742,
                            0
                        ],
                        [
                            113.1769978,
                            23.0692743,
                            0
                        ],
                        [
                            113.1707744,
                            23.0753155,
                            0
                        ],
                        [
                            113.1536076,
                            23.0835277,
                            0
                        ],
                        [
                            113.1233942,
                            23.0778423,
                            0
                        ],
                        [
                            113.1220208,
                            23.1144769,
                            0
                        ],
                        [
                            113.0698342,
                            23.1353162,
                            0
                        ],
                        [
                            113.0128407,
                            23.1365791,
                            0
                        ],
                        [
                            112.8480403,
                            23.2684851,
                            0
                        ],
                        [
                            112.8288136,
                            23.2577607,
                            0
                        ],
                        [
                            112.8123312,
                            23.2176904,
                            0
                        ],
                        [
                            112.8044375,
                            23.1888159,
                            0
                        ],
                        [
                            112.8240069,
                            23.1618344,
                            0
                        ],
                        [
                            112.8027202,
                            23.1498387,
                            0
                        ],
                        [
                            112.7958535,
                            23.1365791,
                            0
                        ],
                        [
                            112.8377403,
                            23.0184479,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Foshan",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Adm. av Foshans folkkommun.<br><br>Foshan styrs av neo-kommunistiska folk-<br>kommuner. På pappret är de enade men<br>i realiteten pågår en ständig inre konflikt<br>mellan delarna av zonen.<br><br>Alla besökare i zonen ges ett nytt per-<br>sonnamn med lämpliga socialistiska associationer. Den administrativa<br>avgiften för detta är 6 € och byråkratin<br>tar 1T10 minuter. Att använda sitt gamla<br>namn är olagligt.",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            113.3483505,
                            23.0413513,
                            0
                        ],
                        [
                            113.354187,
                            23.0355064,
                            0
                        ],
                        [
                            113.3665466,
                            23.0336107,
                            0
                        ],
                        [
                            113.3806229,
                            23.0313991,
                            0
                        ],
                        [
                            113.3943558,
                            23.0265016,
                            0
                        ],
                        [
                            113.4092903,
                            23.0337687,
                            0
                        ],
                        [
                            113.4194183,
                            23.0505131,
                            0
                        ],
                        [
                            113.4266731,
                            23.0610949,
                            0
                        ],
                        [
                            113.4461975,
                            23.0754678,
                            0
                        ],
                        [
                            113.441906,
                            23.0805214,
                            0
                        ],
                        [
                            113.4321213,
                            23.0903121,
                            0
                        ],
                        [
                            113.4022522,
                            23.0805214,
                            0
                        ],
                        [
                            113.3902359,
                            23.0770471,
                            0
                        ],
                        [
                            113.3732414,
                            23.0719934,
                            0
                        ],
                        [
                            113.366375,
                            23.0666236,
                            0
                        ],
                        [
                            113.36586,
                            23.060306,
                            0
                        ],
                        [
                            113.3698082,
                            23.0539881,
                            0
                        ],
                        [
                            113.366375,
                            23.0467221,
                            0
                        ],
                        [
                            113.3483505,
                            23.0413513,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "GHEMC-zonen",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Ägd av Ouyang Tangrong, adm. av Taihei Ginkou och Afrocorp. Zonen domineras helt av miss Ouyangs projekt att bygga GHEMC, Asiens största megauniversitet, och flera mindre campus är redan up and running på Xiaoguwei island. Men ön har en mörk historia av övergrepp och död som skedde under Kollapsen, i en galen warlords regi. Den konstiga stämningen går nästan att ta på, och spökhistorier och makabra fantasier florerar bland studenterna, ivrigt påhejade av meltade afrikanska campuskulter som snabbt vinner nya rekryter.",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            113.5643005,
                            22.871114,
                            0
                        ],
                        [
                            113.6247253,
                            22.7419896,
                            0
                        ],
                        [
                            113.5814649,
                            22.7350252,
                            0
                        ],
                        [
                            113.5848999,
                            22.7128565,
                            0
                        ],
                        [
                            113.6590576,
                            22.6317576,
                            0
                        ],
                        [
                            113.6727905,
                            22.5632932,
                            0
                        ],
                        [
                            113.6260986,
                            22.49987,
                            0
                        ],
                        [
                            113.6013794,
                            22.3932534,
                            0
                        ],
                        [
                            113.7606812,
                            22.3729362,
                            0
                        ],
                        [
                            113.889771,
                            22.4326096,
                            0
                        ],
                        [
                            113.8149239,
                            22.5699365,
                            0
                        ],
                        [
                            113.8252258,
                            22.5791445,
                            0
                        ],
                        [
                            113.8067005,
                            22.6067757,
                            0
                        ],
                        [
                            113.7730575,
                            22.6625649,
                            0
                        ],
                        [
                            113.7661921,
                            22.6727216,
                            0
                        ],
                        [
                            113.7627411,
                            22.6792823,
                            0
                        ],
                        [
                            113.7527874,
                            22.710953,
                            0
                        ],
                        [
                            113.7448883,
                            22.7356569,
                            0
                        ],
                        [
                            113.7393951,
                            22.7426229,
                            0
                        ],
                        [
                            113.6700439,
                            22.750855,
                            0
                        ],
                        [
                            113.6247486,
                            22.8109932,
                            0
                        ],
                        [
                            113.5643005,
                            22.871114,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Haicheng",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Kallad båtstaden. I praktiken laglöst; domineras av gangsterbossarna Triaytno, Ip-man Fung och Yael London. Indonesiska<br>pirater besöker ofta zonen för att fira ett<br>byte, shanghaia nya rekryter eller handla<br>reservdelar och vapen.",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.2152878,
                            22.3190809,
                            0
                        ],
                        [
                            114.2171761,
                            22.3178105,
                            0
                        ],
                        [
                            114.2226452,
                            22.3176591,
                            0
                        ],
                        [
                            114.2236323,
                            22.3205572,
                            0
                        ],
                        [
                            114.2199845,
                            22.3248845,
                            0
                        ],
                        [
                            114.2185683,
                            22.3308788,
                            0
                        ],
                        [
                            114.2163796,
                            22.3311567,
                            0
                        ],
                        [
                            114.2128605,
                            22.3288543,
                            0
                        ],
                        [
                            114.2142767,
                            22.3264724,
                            0
                        ],
                        [
                            114.2152878,
                            22.3190809,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Jordan Valley",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Adm. av Chi Sin-triaden<br><br>Bestämmelser: Förbud mot osedligt beteende, exempel-vis offentliga kyssar, avslöjande kläder och vulgära displayväv-tryck.Inga drönare tillåtna.<br><br>POI: <br>MTR-tunnel<br>Gatumarknad<br><br>Korpar: <br>Princess Estelle Asset Management Ltd<br>Florinda VR Hotel Ltd<br><br>Beskrivning: <br>En zon fylld av åldrande miljonprojekt där<br>diverse gatugäng härjar. Mäktigast är den<br>egensinniga och oberäkneliga Chi Sin-tri-<br>aden. De tar inte särskilt väl hand om<br>zonen och det finns ett gryende missnöje<br>bland peeps, som kan flamma upp om<br>förhållandena är rätt.<br><br>Domän: Tortyr.",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.0482139,
                            22.4442813,
                            0
                        ],
                        [
                            114.0537437,
                            22.4407961,
                            0
                        ],
                        [
                            114.0614684,
                            22.4340528,
                            0
                        ],
                        [
                            114.0643867,
                            22.4274679,
                            0
                        ],
                        [
                            114.0636142,
                            22.4181849,
                            0
                        ],
                        [
                            114.0618116,
                            22.412829,
                            0
                        ],
                        [
                            114.0595372,
                            22.4084053,
                            0
                        ],
                        [
                            114.061726,
                            22.4035055,
                            0
                        ],
                        [
                            114.0756735,
                            22.4006494,
                            0
                        ],
                        [
                            114.0868529,
                            22.4035853,
                            0
                        ],
                        [
                            114.0933116,
                            22.3988236,
                            0
                        ],
                        [
                            114.0989112,
                            22.4058068,
                            0
                        ],
                        [
                            114.105713,
                            22.4117582,
                            0
                        ],
                        [
                            114.1145753,
                            22.4121947,
                            0
                        ],
                        [
                            114.1194786,
                            22.4173322,
                            0
                        ],
                        [
                            114.1297038,
                            22.4227868,
                            0
                        ],
                        [
                            114.1130543,
                            22.434172,
                            0
                        ],
                        [
                            114.1048129,
                            22.459636,
                            0
                        ],
                        [
                            114.1062056,
                            22.4632654,
                            0
                        ],
                        [
                            114.1071546,
                            22.4649362,
                            0
                        ],
                        [
                            114.1081466,
                            22.4677571,
                            0
                        ],
                        [
                            114.1044227,
                            22.4689572,
                            0
                        ],
                        [
                            114.0536494,
                            22.4607856,
                            0
                        ],
                        [
                            114.0518436,
                            22.4566188,
                            0
                        ],
                        [
                            114.0493512,
                            22.4530865,
                            0
                        ],
                        [
                            114.0487932,
                            22.4503499,
                            0
                        ],
                        [
                            114.0503811,
                            22.4480494,
                            0
                        ],
                        [
                            114.0482139,
                            22.4442813,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Kam Tin District",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Sun Hung Kai var en av Hongkongs största<br>markägare innan Kollapsen. De har tjänat<br>stora € på den alltmer desperata kampen<br>för bostads- och kontorsyta i deltat, och<br>hyrorna i denna zon hjälper förstås till.<br>Företaget har ibland beskyllts för att<br>iscensätta katastrofer för att sedan kunna<br>köpa upp marken billigt, vilket anses vara<br>vad som skett i Kam Tin - området var<br>länge utdömd på grund av kraftigt föro-<br>renad mark sedan Kollapsen, men nu har<br>allt sanerats och helt nya bostadskomplex byggts. Många klimatflyktingar från Sydasien och Indokina har bosatt sig i Kam Tin, men stora delar av zonen är fortfarande grönområden, om än fulla med gammalt plastskräp och sjuklig vegetation. ",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.1815961,
                            22.7103223,
                            0
                        ],
                        [
                            114.1747284,
                            22.6840338,
                            0
                        ],
                        [
                            114.1685486,
                            22.660908,
                            0
                        ],
                        [
                            114.166489,
                            22.6552044,
                            0
                        ],
                        [
                            114.1930956,
                            22.6456201,
                            0
                        ],
                        [
                            114.2181556,
                            22.6324685,
                            0
                        ],
                        [
                            114.2370415,
                            22.6432442,
                            0
                        ],
                        [
                            114.2544651,
                            22.6538586,
                            0
                        ],
                        [
                            114.2669105,
                            22.6621753,
                            0
                        ],
                        [
                            114.295063,
                            22.657423,
                            0
                        ],
                        [
                            114.3347168,
                            22.6490269,
                            0
                        ],
                        [
                            114.3772888,
                            22.6399964,
                            0
                        ],
                        [
                            114.3924729,
                            22.6886222,
                            0
                        ],
                        [
                            114.4281005,
                            22.7255238,
                            0
                        ],
                        [
                            114.316864,
                            22.7343903,
                            0
                        ],
                        [
                            114.2584991,
                            22.7635188,
                            0
                        ],
                        [
                            114.2174639,
                            22.7366085,
                            0
                        ],
                        [
                            114.1815961,
                            22.7103223,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Longgang",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Formellt del av Shenzhen, i praktiken kontrollerat av gerillan Lónggǎng Dĭkàng Yùndòng.<br><br>Krigsherren Bai Zhiyuan styr zonen med<br>hjälp av sin gerilla Lónggǎng Dĭkàng<br>Yùndòng. Zonen ligger i en ogästvänlig<br>del av Shenzhen där fallfärdiga ruiner,<br>träskmark och kamoflerade autoturrets<br>tävlar om att ta livet av ovälkomna gäster.<br>Zhiyan letar efter / tvångsrekryterar<br>för närvarande peeps med utbildning i<br>ekonomi.",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.2227551,
                            22.3055425,
                            0
                        ],
                        [
                            114.2299219,
                            22.310426,
                            0
                        ],
                        [
                            114.2264887,
                            22.3174929,
                            0
                        ],
                        [
                            114.2171761,
                            22.3178105,
                            0
                        ],
                        [
                            114.2152878,
                            22.3190809,
                            0
                        ],
                        [
                            114.2132279,
                            22.3154284,
                            0
                        ],
                        [
                            114.2227551,
                            22.3055425,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "New Kwun Tong",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "En av Interworlds administrativa zoner.<br>Transportföretaget kontrollerar stora delar<br>av transportsektorn i PRD och här finns<br>kontor, bostäder, gallerior och underhåll-<br>ningskomplex för deras anställda.<br><br>Domän: Logistik.",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.3387,
                            22.2663503,
                            0
                        ],
                        [
                            114.334151,
                            22.2536409,
                            0
                        ],
                        [
                            114.3472831,
                            22.2468091,
                            0
                        ],
                        [
                            114.3565528,
                            22.2538792,
                            0
                        ],
                        [
                            114.3679683,
                            22.2513372,
                            0
                        ],
                        [
                            114.3744056,
                            22.2689715,
                            0
                        ],
                        [
                            114.3496864,
                            22.2766759,
                            0
                        ],
                        [
                            114.3387,
                            22.2663503,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Ninepin Islands (PLGTH)",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Claimed as a holy site by the Pure Land of the Goddess Tin Hau. ",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.2430496,
                            22.1648322,
                            0
                        ],
                        [
                            114.24614,
                            22.1541804,
                            0
                        ],
                        [
                            114.2595291,
                            22.1486157,
                            0
                        ],
                        [
                            114.284935,
                            22.1626065,
                            0
                        ],
                        [
                            114.3148048,
                            22.1885164,
                            0
                        ],
                        [
                            114.2749787,
                            22.2010741,
                            0
                        ],
                        [
                            114.238756,
                            22.1940804,
                            0
                        ],
                        [
                            114.2390148,
                            22.1791384,
                            0
                        ],
                        [
                            114.2430496,
                            22.1648322,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Po Toi-zone",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Ökänt nomo-fäste, styrt av Ayesha X och 𝕵𝖔𝖇𝖊𝖑𝖑𝖊.",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.2144746,
                            22.2904923,
                            0
                        ],
                        [
                            114.2081004,
                            22.2885826,
                            0
                        ],
                        [
                            114.2075425,
                            22.2866766,
                            0
                        ],
                        [
                            114.2100114,
                            22.2796515,
                            0
                        ],
                        [
                            114.2104835,
                            22.2782219,
                            0
                        ],
                        [
                            114.2160625,
                            22.2794926,
                            0
                        ],
                        [
                            114.2164916,
                            22.2817562,
                            0
                        ],
                        [
                            114.2220277,
                            22.2856081,
                            0
                        ],
                        [
                            114.221899,
                            22.2888642,
                            0
                        ],
                        [
                            114.2153758,
                            22.2907305,
                            0
                        ],
                        [
                            114.2144746,
                            22.2904923,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Quarry Bay",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Adm. Av IDC-Fujiyama<br><br>IDCF mutade in sina kontorskomplex här under Kollapsen, och fick områdets gytter av enorma, extremt tätt packade bostadskomplex på köpet. På pappret är Quarry Bay en teknologiskt woke zon, där robotar och androider anses ha samma rättigheter som djur, och alltså inte får behandlas hur som helst, och områdena närmast kusten har åtskilliga iögonfallande företagsskrapor. På slutet av 50-talet genomförde IDCF flera reformer för att förbättra livet för zonens invånare genom bättre virr-noder, automatiserade lägenheter, vaktrobotar och annat, men mycket av projekten har runnit ut i sanden efterhand som IDCF blivit allt mer pressade av sina konkurrenter, och istället valt att fokusera på sina rena industriprotektorat annorstädes i deltat. Stora delar av Quarry Bay har därför förblivit nedgångna och smutsiga, endast patrullerade av buggiga vaktrobotar som får alldeles för lite service. IDCF är dock måna om att skydda alla tech-hemligheter som ryms i deras HQ från industrispionage, så zonens mer lyxade delar är fulla av japanska secos, och det kan ofta vara frustrerande svårt att få cert till Quarry Bay, med lriktlinjer som tycks ändras dagligen. <br><br><br>t.",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.1668466,
                            22.3008609,
                            0
                        ],
                        [
                            114.1649437,
                            22.2989637,
                            0
                        ],
                        [
                            114.1649222,
                            22.2944074,
                            0
                        ],
                        [
                            114.1677975,
                            22.2937522,
                            0
                        ],
                        [
                            114.1685164,
                            22.2931727,
                            0
                        ],
                        [
                            114.1695195,
                            22.2930002,
                            0
                        ],
                        [
                            114.1719576,
                            22.2931255,
                            0
                        ],
                        [
                            114.1733457,
                            22.2932453,
                            0
                        ],
                        [
                            114.1733249,
                            22.2933659,
                            0
                        ],
                        [
                            114.173296,
                            22.2937795,
                            0
                        ],
                        [
                            114.1734026,
                            22.2937807,
                            0
                        ],
                        [
                            114.1733752,
                            22.2944371,
                            0
                        ],
                        [
                            114.1733658,
                            22.2947449,
                            0
                        ],
                        [
                            114.1732967,
                            22.2947883,
                            0
                        ],
                        [
                            114.1718678,
                            22.2946903,
                            0
                        ],
                        [
                            114.17133,
                            22.2946705,
                            0
                        ],
                        [
                            114.1712295,
                            22.295508,
                            0
                        ],
                        [
                            114.1723144,
                            22.2955192,
                            0
                        ],
                        [
                            114.1722175,
                            22.2967903,
                            0
                        ],
                        [
                            114.1720885,
                            22.2980911,
                            0
                        ],
                        [
                            114.1698661,
                            22.298039,
                            0
                        ],
                        [
                            114.1690338,
                            22.2997005,
                            0
                        ],
                        [
                            114.167944,
                            22.3007664,
                            0
                        ],
                        [
                            114.1668466,
                            22.3008609,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Salisbury Commercial Zone",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Sammanslutning av en mängd butiker, administrerad av Chow Tai Fook. <br><br>Hongkong har alltid varit Chow Tai Fooks<br>hemstad. Zonen är fylld av lyxbordeller,<br>smyckesaffärer, Michelin-restauranger och<br>boutiques dit paladiner med för mycket €<br>skickar sina peepmins. Halva priset på din<br>nästa plastikoperation om du handlar för<br>mer än 20 000 €!<br><br>Man får inte vara gravid eller föda barn i<br>zonen utan särskild licens!",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.1856485,
                            22.4621806,
                            0
                        ],
                        [
                            114.1839105,
                            22.4607528,
                            0
                        ],
                        [
                            114.1829663,
                            22.4594441,
                            0
                        ],
                        [
                            114.1818076,
                            22.4590871,
                            0
                        ],
                        [
                            114.1789323,
                            22.4592854,
                            0
                        ],
                        [
                            114.1777306,
                            22.4585319,
                            0
                        ],
                        [
                            114.1773444,
                            22.4568265,
                            0
                        ],
                        [
                            114.1779023,
                            22.4538519,
                            0
                        ],
                        [
                            114.1772586,
                            22.4519877,
                            0
                        ],
                        [
                            114.1768723,
                            22.4491717,
                            0
                        ],
                        [
                            114.178589,
                            22.4485371,
                            0
                        ],
                        [
                            114.1817003,
                            22.4483982,
                            0
                        ],
                        [
                            114.1893713,
                            22.4491421,
                            0
                        ],
                        [
                            114.1941081,
                            22.4497519,
                            0
                        ],
                        [
                            114.1954976,
                            22.4510359,
                            0
                        ],
                        [
                            114.1966134,
                            22.4599795,
                            0
                        ],
                        [
                            114.1926866,
                            22.4614271,
                            0
                        ],
                        [
                            114.1904121,
                            22.4622004,
                            0
                        ],
                        [
                            114.1856485,
                            22.4621806,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Tai Po Industrial Zone",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Här domineras gatubilden av Wo Hop<br>To-triaden. Patrullerande vans fyllda<br>med dailos visar lojt och övertydligt sina<br>PMC-smiggar och slasher-klor. Lokala<br>lingdaon Billion Yip har mycket stora<br>ambitioner och förbereder zonen för<br>ett begynnande krig – hon har bara inte<br>bestämt om det är mot indoneserna, Chi<br>Sin eller Sentry.",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            113.9203262,
                            22.2919554,
                            0
                        ],
                        [
                            113.9170647,
                            22.2851255,
                            0
                        ],
                        [
                            113.9242744,
                            22.2829017,
                            0
                        ],
                        [
                            113.9244461,
                            22.2668577,
                            0
                        ],
                        [
                            113.9299393,
                            22.2652691,
                            0
                        ],
                        [
                            113.9332008,
                            22.2652691,
                            0
                        ],
                        [
                            113.9361191,
                            22.2628862,
                            0
                        ],
                        [
                            113.9390373,
                            22.2633628,
                            0
                        ],
                        [
                            113.9361191,
                            22.2700349,
                            0
                        ],
                        [
                            113.9366341,
                            22.274324,
                            0
                        ],
                        [
                            113.9428139,
                            22.2749594,
                            0
                        ],
                        [
                            113.9477921,
                            22.2693995,
                            0
                        ],
                        [
                            113.9519119,
                            22.2728943,
                            0
                        ],
                        [
                            113.9436722,
                            22.2805191,
                            0
                        ],
                        [
                            113.9465904,
                            22.285602,
                            0
                        ],
                        [
                            113.9565468,
                            22.2919554,
                            0
                        ],
                        [
                            113.9551735,
                            22.2976733,
                            0
                        ],
                        [
                            113.9416122,
                            22.2975144,
                            0
                        ],
                        [
                            113.9319992,
                            22.2886993,
                            0
                        ],
                        [
                            113.9203262,
                            22.2919554,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Tung Chung (Worldport)\n",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Adm. av Interworld och Chow Tai Fook. En i mångas ögon ganska tråkig \"transit\"-zon som innehåller en hel förstad av hotell, nöjesinrättningar och kontor för att serva peeps som av någon outgrundlig anledning älskar<br>flygplatser för mycket för att ta maglevtåget in till centrum. Zonens \"attraktioner\" inkluderar NeoExpo Asia, Tung Chung Pleasure Gardens, SkyPier III och InterWorld Air Museum, för att inte tala om Ancient Kiln Park, en svastikaformad uppsättning pråliga men banala Chow Tai Fook-hotell. Under stora internationella mässor lever Tung Chung dock upp rejält och kan tillfälligt bli ett ganska koolu ställe. ",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.1930194,
                            22.3381426,
                            0
                        ],
                        [
                            114.1920196,
                            22.3368183,
                            0
                        ],
                        [
                            114.1912363,
                            22.334635,
                            0
                        ],
                        [
                            114.1908501,
                            22.3333747,
                            0
                        ],
                        [
                            114.1912149,
                            22.3331067,
                            0
                        ],
                        [
                            114.1916655,
                            22.3320945,
                            0
                        ],
                        [
                            114.1924594,
                            22.33137,
                            0
                        ],
                        [
                            114.1930495,
                            22.3319456,
                            0
                        ],
                        [
                            114.1936182,
                            22.3315089,
                            0
                        ],
                        [
                            114.1938649,
                            22.3316578,
                            0
                        ],
                        [
                            114.1943692,
                            22.3311318,
                            0
                        ],
                        [
                            114.1953798,
                            22.3319104,
                            0
                        ],
                        [
                            114.1950257,
                            22.3322924,
                            0
                        ],
                        [
                            114.194669,
                            22.3326422,
                            0
                        ],
                        [
                            114.1942318,
                            22.3334287,
                            0
                        ],
                        [
                            114.1939582,
                            22.3343418,
                            0
                        ],
                        [
                            114.1939314,
                            22.3351059,
                            0
                        ],
                        [
                            114.194036,
                            22.3352895,
                            0
                        ],
                        [
                            114.1941969,
                            22.3358192,
                            0
                        ],
                        [
                            114.1944571,
                            22.3363365,
                            0
                        ],
                        [
                            114.1949399,
                            22.3369319,
                            0
                        ],
                        [
                            114.1930194,
                            22.3381426,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Tung Tau Russian Town",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Ett litet \"Russian Town\" av glåmiga high rises, proppfullt med skins, amoks och primals med slaviska och sibiriska attribut. Zonen har dock även en påtaglig kontingent av kinesiska analoger, som traskar runt med té-termosar och munskydd. ",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.1924248,
                            22.3100177,
                            0
                        ],
                        [
                            114.1918768,
                            22.3098832,
                            0
                        ],
                        [
                            114.1913671,
                            22.3094311,
                            0
                        ],
                        [
                            114.1906993,
                            22.3088184,
                            0
                        ],
                        [
                            114.1903147,
                            22.3088426,
                            0
                        ],
                        [
                            114.1901709,
                            22.3086921,
                            0
                        ],
                        [
                            114.1900636,
                            22.307243,
                            0
                        ],
                        [
                            114.1890551,
                            22.3058335,
                            0
                        ],
                        [
                            114.1874458,
                            22.3035902,
                            0
                        ],
                        [
                            114.1919948,
                            22.3014858,
                            0
                        ],
                        [
                            114.1938831,
                            22.3025182,
                            0
                        ],
                        [
                            114.1933466,
                            22.3041262,
                            0
                        ],
                        [
                            114.1944195,
                            22.3084142,
                            0
                        ],
                        [
                            114.1924248,
                            22.3100177,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Whampoa Commons",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Adm. av Khalsa Kowloon.<br><br>Zonen styrs av ghazi-gänget Khalsa<br>Kowloon, vars medlemmar, som framför<br>allt är sikher, ser till att hålla en fredlig<br>och god stämning i zonen. Här kostar nya<br>identiteter och piratad eqip 10 % mindre<br>om man beter sig respektfullt. KK:s mål<br>är att samla in pengar för att bygga ut sin<br>gurdwara.<br><br>Förbud mot att äta mat utomhus.<br><br>Förbud mot osedligt beteende, exempel-<br>vis offentliga kyssar, avslöjande kläder och<br>vulgära displayväv-tryck.",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.0100837,
                            22.4487236,
                            0
                        ],
                        [
                            114.0061354,
                            22.4456298,
                            0
                        ],
                        [
                            114.0047621,
                            22.441584,
                            0
                        ],
                        [
                            114.0037321,
                            22.4342854,
                            0
                        ],
                        [
                            114.0090536,
                            22.4371414,
                            0
                        ],
                        [
                            114.015405,
                            22.4379348,
                            0
                        ],
                        [
                            114.0178941,
                            22.4442812,
                            0
                        ],
                        [
                            114.0376354,
                            22.4442019,
                            0
                        ],
                        [
                            114.0387725,
                            22.4410485,
                            0
                        ],
                        [
                            114.0400386,
                            22.4405527,
                            0
                        ],
                        [
                            114.0395236,
                            22.4442019,
                            0
                        ],
                        [
                            114.0401888,
                            22.4470974,
                            0
                        ],
                        [
                            114.0426564,
                            22.4468991,
                            0
                        ],
                        [
                            114.0425062,
                            22.4441921,
                            0
                        ],
                        [
                            114.0454994,
                            22.4437905,
                            0
                        ],
                        [
                            114.0482139,
                            22.4442813,
                            0
                        ],
                        [
                            114.050424,
                            22.4480494,
                            0
                        ],
                        [
                            114.0488361,
                            22.4503499,
                            0
                        ],
                        [
                            114.0493941,
                            22.4530865,
                            0
                        ],
                        [
                            114.0462183,
                            22.4564181,
                            0
                        ],
                        [
                            114.0426135,
                            22.4556645,
                            0
                        ],
                        [
                            114.0395879,
                            22.4557581,
                            0
                        ],
                        [
                            114.037013,
                            22.4557162,
                            0
                        ],
                        [
                            114.035173,
                            22.4556655,
                            0
                        ],
                        [
                            114.0332471,
                            22.4556545,
                            0
                        ],
                        [
                            114.0337108,
                            22.4549074,
                            0
                        ],
                        [
                            114.0340219,
                            22.4545307,
                            0
                        ],
                        [
                            114.0318857,
                            22.4515631,
                            0
                        ],
                        [
                            114.0319042,
                            22.450236,
                            0
                        ],
                        [
                            114.0294169,
                            22.4475238,
                            0
                        ],
                        [
                            114.0284433,
                            22.4475981,
                            0
                        ],
                        [
                            114.0274804,
                            22.4476824,
                            0
                        ],
                        [
                            114.0256189,
                            22.4483665,
                            0
                        ],
                        [
                            114.026724,
                            22.4504538,
                            0
                        ],
                        [
                            114.0287409,
                            22.4528385,
                            0
                        ],
                        [
                            114.0306829,
                            22.4545539,
                            0
                        ],
                        [
                            114.033172,
                            22.4582425,
                            0
                        ],
                        [
                            114.030597,
                            22.4622085,
                            0
                        ],
                        [
                            114.0281938,
                            22.464826,
                            0
                        ],
                        [
                            114.0281938,
                            22.4685539,
                            0
                        ],
                        [
                            114.025104,
                            22.4668882,
                            0
                        ],
                        [
                            114.0174649,
                            22.4694263,
                            0
                        ],
                        [
                            114.0136884,
                            22.4668089,
                            0
                        ],
                        [
                            114.0112851,
                            22.4656986,
                            0
                        ],
                        [
                            114.0080237,
                            22.4641915,
                            0
                        ],
                        [
                            114.0087962,
                            22.4580045,
                            0
                        ],
                        [
                            114.0083242,
                            22.4532451,
                            0
                        ],
                        [
                            114.0100837,
                            22.4487236,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Yuen Long North\n",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Som de flesta 88K-zoner är denna<br>mestadels välordnad, med lekande barn,<br>äldre som gymnastiserar och leende<br>affärsidkare, allt under löftet att den som<br>stör ordningen får handen instoppad i<br>en nudelkvarn. Triaddraken i denna zon<br>är dock försvunnen och hens löjtnanter<br>börjar snart göra upp om vem som blir<br>nästa ledare.",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.0035728,
                            22.434152,
                            0
                        ],
                        [
                            114.0072636,
                            22.4264563,
                            0
                        ],
                        [
                            114.01413,
                            22.4282018,
                            0
                        ],
                        [
                            114.0147308,
                            22.4261389,
                            0
                        ],
                        [
                            114.0124992,
                            22.4199503,
                            0
                        ],
                        [
                            114.0127659,
                            22.4132503,
                            0
                        ],
                        [
                            114.0156841,
                            22.4103144,
                            0
                        ],
                        [
                            114.0214348,
                            22.4126948,
                            0
                        ],
                        [
                            114.0252972,
                            22.4183284,
                            0
                        ],
                        [
                            114.0280437,
                            22.4221368,
                            0
                        ],
                        [
                            114.0315628,
                            22.4229302,
                            0
                        ],
                        [
                            114.031992,
                            22.4247551,
                            0
                        ],
                        [
                            114.0285587,
                            22.4291981,
                            0
                        ],
                        [
                            114.0286446,
                            22.4338789,
                            0
                        ],
                        [
                            114.0341377,
                            22.4353069,
                            0
                        ],
                        [
                            114.036026,
                            22.4367349,
                            0
                        ],
                        [
                            114.0386009,
                            22.4388769,
                            0
                        ],
                        [
                            114.0390301,
                            22.4403842,
                            0
                        ],
                        [
                            114.0376354,
                            22.4442019,
                            0
                        ],
                        [
                            114.0178941,
                            22.4442812,
                            0
                        ],
                        [
                            114.015405,
                            22.4379348,
                            0
                        ],
                        [
                            114.0090536,
                            22.4371414,
                            0
                        ],
                        [
                            114.0035728,
                            22.434152,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Yuen Long South\n",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Södra delen av Yuen Long innehas av den<br>lilla men ettriga Yuen Long-triaden, som i<br>flera decennier stått emot sina 88K-gran-<br>nar. Det syns inte direkt, men hela zonen<br>är förberedd för gatustrider, med barri-<br>kader som kan rullas fram på nolltid och<br>gömda förvar av vapen och bambukäppar.",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.0426564,
                            22.4468991,
                            0
                        ],
                        [
                            114.0413797,
                            22.4470082,
                            0
                        ],
                        [
                            114.0401888,
                            22.4470974,
                            0
                        ],
                        [
                            114.0394592,
                            22.4440334,
                            0
                        ],
                        [
                            114.0400386,
                            22.4405527,
                            0
                        ],
                        [
                            114.0403926,
                            22.4415617,
                            0
                        ],
                        [
                            114.0409827,
                            22.4424517,
                            0
                        ],
                        [
                            114.0421092,
                            22.4434607,
                            0
                        ],
                        [
                            114.0425062,
                            22.4441921,
                            0
                        ],
                        [
                            114.0425598,
                            22.4451489,
                            0
                        ],
                        [
                            114.0426564,
                            22.4468991,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Pok Oi (Ikeda)\n",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Företagsenklav, adm. av Ikeda. <br><br>Vård- och läkemedelsjätten Ikeda äger<br>denna och många liknande zoner, via<br>något av sina många dotterbolag. Ett<br>sjukhus finns förstås här, men den främ-<br>sta inkomstkällan är äldrevården med<br>bostadskomplex patrullerade av vänliga<br>japanska robotar. Traditionen att ta hand<br>om sina äldre har sakta tunnats ur, det är<br>lättare att betala lite kudi åt Ikeda för slut-<br>förvaring, det ser ju fint ut på reklamen!<br>Ikeda vill absolut inte att denna image ska<br>förstöras ...",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            113.3140182,
                            23.3380003,
                            0
                        ],
                        [
                            113.321743,
                            23.3387883,
                            0
                        ],
                        [
                            113.3256912,
                            23.3432015,
                            0
                        ],
                        [
                            113.3299828,
                            23.3488754,
                            0
                        ],
                        [
                            113.334446,
                            23.3528154,
                            0
                        ],
                        [
                            113.3361626,
                            23.3603799,
                            0
                        ],
                        [
                            113.3390808,
                            23.3654227,
                            0
                        ],
                        [
                            113.3442307,
                            23.3665258,
                            0
                        ],
                        [
                            113.3490372,
                            23.3657379,
                            0
                        ],
                        [
                            113.354702,
                            23.3707805,
                            0
                        ],
                        [
                            113.355217,
                            23.3788167,
                            0
                        ],
                        [
                            113.3498955,
                            23.3822832,
                            0
                        ],
                        [
                            113.3423424,
                            23.3833861,
                            0
                        ],
                        [
                            113.3373642,
                            23.3865373,
                            0
                        ],
                        [
                            113.3390808,
                            23.3936273,
                            0
                        ],
                        [
                            113.3409691,
                            23.3978811,
                            0
                        ],
                        [
                            113.3464622,
                            23.3996141,
                            0
                        ],
                        [
                            113.3545303,
                            23.4022923,
                            0
                        ],
                        [
                            113.3583069,
                            23.4063883,
                            0
                        ],
                        [
                            113.3600235,
                            23.412217,
                            0
                        ],
                        [
                            113.3559036,
                            23.41521,
                            0
                        ],
                        [
                            113.3540154,
                            23.4172578,
                            0
                        ],
                        [
                            113.3517838,
                            23.4210383,
                            0
                        ],
                        [
                            113.3469772,
                            23.4240311,
                            0
                        ],
                        [
                            113.3493805,
                            23.4287564,
                            0
                        ],
                        [
                            113.3493805,
                            23.4342691,
                            0
                        ],
                        [
                            113.3449173,
                            23.4374191,
                            0
                        ],
                        [
                            113.3392525,
                            23.4416715,
                            0
                        ],
                        [
                            113.3308411,
                            23.4443489,
                            0
                        ],
                        [
                            113.3253479,
                            23.4463962,
                            0
                        ],
                        [
                            113.3167648,
                            23.4474986,
                            0
                        ],
                        [
                            113.3112717,
                            23.4470262,
                            0
                        ],
                        [
                            113.3085251,
                            23.4443489,
                            0
                        ],
                        [
                            113.3047485,
                            23.4445063,
                            0
                        ],
                        [
                            113.2944489,
                            23.4423015,
                            0
                        ],
                        [
                            113.2831192,
                            23.4437189,
                            0
                        ],
                        [
                            113.2777977,
                            23.4369466,
                            0
                        ],
                        [
                            113.278656,
                            23.4227709,
                            0
                        ],
                        [
                            113.2743645,
                            23.4183604,
                            0
                        ],
                        [
                            113.268013,
                            23.4078061,
                            0
                        ],
                        [
                            113.2633781,
                            23.4041828,
                            0
                        ],
                        [
                            113.2596016,
                            23.3942575,
                            0
                        ],
                        [
                            113.2565117,
                            23.3936273,
                            0
                        ],
                        [
                            113.2534218,
                            23.388428,
                            0
                        ],
                        [
                            113.2621765,
                            23.3843315,
                            0
                        ],
                        [
                            113.2681847,
                            23.3827558,
                            0
                        ],
                        [
                            113.2769394,
                            23.3846466,
                            0
                        ],
                        [
                            113.2860374,
                            23.3873251,
                            0
                        ],
                        [
                            113.2841492,
                            23.383071,
                            0
                        ],
                        [
                            113.2814026,
                            23.3791318,
                            0
                        ],
                        [
                            113.2762527,
                            23.3709381,
                            0
                        ],
                        [
                            113.2699013,
                            23.3657379,
                            0
                        ],
                        [
                            113.2741928,
                            23.349033,
                            0
                        ],
                        [
                            113.2800293,
                            23.3465113,
                            0
                        ],
                        [
                            113.2959938,
                            23.3370545,
                            0
                        ],
                        [
                            113.3032036,
                            23.3290158,
                            0
                        ],
                        [
                            113.3054352,
                            23.3392612,
                            0
                        ],
                        [
                            113.3086967,
                            23.3398916,
                            0
                        ],
                        [
                            113.3140182,
                            23.3380003,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Baiyun Airport (Border Command)\n",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Adm. av Gränskommandot. En exklav av Pärldeltat, omgiven på alla sidor av Mainland. Extremt få internationella flygningar; stora delar av zonen blockerade av bråte och tankhinder. Ett antal militära vektorjet ständigt stationerade. Stadsbilden domineras i övrigt av ett antal enorma, depressiva hotellkomplex. Alla invånare<br>och gäster behöver arbeta ett par timmar<br>om dagen med gatuvård och skräpsamling<br>för att få vistas i zonen.",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            113.5097122,
                            23.0527245,
                            0
                        ],
                        [
                            113.5049057,
                            23.0484596,
                            0
                        ],
                        [
                            113.5198402,
                            23.0372441,
                            0
                        ],
                        [
                            113.5313416,
                            23.0490915,
                            0
                        ],
                        [
                            113.5404396,
                            23.0547778,
                            0
                        ],
                        [
                            113.5464478,
                            23.0686768,
                            0
                        ],
                        [
                            113.5479927,
                            23.0783105,
                            0
                        ],
                        [
                            113.5461044,
                            23.0794159,
                            0
                        ],
                        [
                            113.5395813,
                            23.0762574,
                            0
                        ],
                        [
                            113.5378647,
                            23.0737306,
                            0
                        ],
                        [
                            113.5330582,
                            23.0700982,
                            0
                        ],
                        [
                            113.5296249,
                            23.0708879,
                            0
                        ],
                        [
                            113.52705,
                            23.0677292,
                            0
                        ],
                        [
                            113.5184669,
                            23.0685189,
                            0
                        ],
                        [
                            113.5152054,
                            23.0689927,
                            0
                        ],
                        [
                            113.5109138,
                            23.0667816,
                            0
                        ],
                        [
                            113.5150337,
                            23.0604639,
                            0
                        ],
                        [
                            113.5097122,
                            23.0527245,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Huangpu New Port \n",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Containerhamn, flodbåtar, minröjare. <br><br>Zonen ägs av megakorpen Xifang Gongye<br>och är en enorm containerhamn för varor<br>som skeppas till och från mainland-Kina. Barriärer har skyddat zonen från det värsta av översvämningarna, och den reser sig som en ö av containrar och lyftkranar ur vattnet. Men fukt tränger ändå igenom, och zonen som helhet är ett eländigt, smutsigt och vattensjukt ställe. <br>Logistikarbetare och några byråkrater bor<br>och jobbar i zonen men alla som vet något<br>fattar att det myllrar av DDW-agenter från<br>Chongqing-falangen här.",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            113.1735349,
                            23.1712951,
                            0
                        ],
                        [
                            113.1731915,
                            23.1690265,
                            0
                        ],
                        [
                            113.1727624,
                            23.1670932,
                            0
                        ],
                        [
                            113.1730843,
                            23.1653375,
                            0
                        ],
                        [
                            113.1753373,
                            23.1630293,
                            0
                        ],
                        [
                            113.1765604,
                            23.1607606,
                            0
                        ],
                        [
                            113.1775045,
                            23.1599715,
                            0
                        ],
                        [
                            113.1802082,
                            23.1619837,
                            0
                        ],
                        [
                            113.1825793,
                            23.1642328,
                            0
                        ],
                        [
                            113.1819892,
                            23.167113,
                            0
                        ],
                        [
                            113.1845427,
                            23.1679119,
                            0
                        ],
                        [
                            113.1849504,
                            23.1694999,
                            0
                        ],
                        [
                            113.1813669,
                            23.1707427,
                            0
                        ],
                        [
                            113.1794786,
                            23.1713345,
                            0
                        ],
                        [
                            113.1780195,
                            23.1726562,
                            0
                        ],
                        [
                            113.1735349,
                            23.1712951,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Shuikoucun",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Adm. av Röda svastikan; ryktas vara bas för penningtvätt och smuggling. Inkluderar en mindre flodhamn. Röda svastikan, Röda korsets asiatiska gren har fyllt zonen med improviserade<br>sjukhus, vaccinationskliniker och flykting-<br>mottagningar, och sprider irriterande<br>informationskampanjer om syntit och<br>ebola-2 på Lagren. Grannzonen, som<br>aktivt försökt driva ut sina tiggare och<br>shackers genom diverse hatkampanjer och<br>pogromer, är nära att förklara krig efter-<br>som Röda svastikan unilateralt airdroppar<br>matpaket och vapen till dem.",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            113.5348606,
                            22.2075108,
                            0
                        ],
                        [
                            113.5352898,
                            22.2034581,
                            0
                        ],
                        [
                            113.5351181,
                            22.1995642,
                            0
                        ],
                        [
                            113.5350323,
                            22.1963854,
                            0
                        ],
                        [
                            113.534174,
                            22.194796,
                            0
                        ],
                        [
                            113.5318565,
                            22.1953523,
                            0
                        ],
                        [
                            113.5282516,
                            22.1888355,
                            0
                        ],
                        [
                            113.5269642,
                            22.182398,
                            0
                        ],
                        [
                            113.5356331,
                            22.1672963,
                            0
                        ],
                        [
                            113.5386372,
                            22.158314,
                            0
                        ],
                        [
                            113.5487652,
                            22.1462308,
                            0
                        ],
                        [
                            113.5490227,
                            22.1381217,
                            0
                        ],
                        [
                            113.5485935,
                            22.1203914,
                            0
                        ],
                        [
                            113.5489368,
                            22.1051241,
                            0
                        ],
                        [
                            113.575716,
                            22.1055217,
                            0
                        ],
                        [
                            113.6083317,
                            22.1331129,
                            0
                        ],
                        [
                            113.6024094,
                            22.1638784,
                            0
                        ],
                        [
                            113.5805225,
                            22.1722244,
                            0
                        ],
                        [
                            113.5700512,
                            22.1788214,
                            0
                        ],
                        [
                            113.5719397,
                            22.1954308,
                            0
                        ],
                        [
                            113.5686779,
                            22.2099741,
                            0
                        ],
                        [
                            113.5580349,
                            22.2132321,
                            0
                        ],
                        [
                            113.5540867,
                            22.2145034,
                            0
                        ],
                        [
                            113.5507393,
                            22.2156159,
                            0
                        ],
                        [
                            113.5512543,
                            22.2171256,
                            0
                        ],
                        [
                            113.5494518,
                            22.2165694,
                            0
                        ],
                        [
                            113.5441303,
                            22.2167283,
                            0
                        ],
                        [
                            113.5433578,
                            22.2157748,
                            0
                        ],
                        [
                            113.5412979,
                            22.2129937,
                            0
                        ],
                        [
                            113.5377789,
                            22.2136294,
                            0
                        ],
                        [
                            113.5351181,
                            22.213391,
                            0
                        ],
                        [
                            113.533144,
                            22.212358,
                            0
                        ],
                        [
                            113.5348606,
                            22.2075108,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Macao ",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Ett centrum för europeiskt inspirerad dekadens i regi av Wong Lo-Kat, en ökänd spelkung som vann zonen under ett mahjongparti. Macao är världskänt för sina glassiga, lyxade casinohotell, restauranger och boutiquer, och sin skamligt koloniala stämning. Massor av tvivelaktigheter sker givetvis i skuggan av glamouren, men Macaos tretton casinobossar ser till att två sina händer genom att överlåta det verkliga skitgörat åt P11-maffian, som här har sin kanske främsta bastion utanför Europa. ",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.267211,
                            22.2840335,
                            0
                        ],
                        [
                            114.2662454,
                            22.2844107,
                            0
                        ],
                        [
                            114.2661166,
                            22.2833187,
                            0
                        ],
                        [
                            114.2660952,
                            22.2825245,
                            0
                        ],
                        [
                            114.2673182,
                            22.2817898,
                            0
                        ],
                        [
                            114.2690349,
                            22.2818891,
                            0
                        ],
                        [
                            114.2696142,
                            22.2830606,
                            0
                        ],
                        [
                            114.267211,
                            22.2840335,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Gammon Corporate Zone (Typhoon)\n",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Ett industriprotektorat kontrollerat av<br>flygbolaget Typhoon. Har en mindre hamn<br>för kryssningsfartyg, vektor-plattformar<br>och träningsanläggningar samt kontor.<br>Har flera gånger utsatts för sabotage av<br>InterWorld.",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.272629,
                            22.2847582,
                            0
                        ],
                        [
                            114.2719746,
                            22.283408,
                            0
                        ],
                        [
                            114.2716098,
                            22.2825344,
                            0
                        ],
                        [
                            114.2730904,
                            22.2819189,
                            0
                        ],
                        [
                            114.2740989,
                            22.2842022,
                            0
                        ],
                        [
                            114.272629,
                            22.2847582,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Xu Wan Memorial Facility (UMB)\n",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Experimentiellt \"controlled environment\"- företagskomplex, ägt av UMB. Tros inrymma hundratals anställda, men ingen besökare ser någonsin dessa.",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.2697859,
                            22.2885603,
                            0
                        ],
                        [
                            114.2662454,
                            22.2844107,
                            0
                        ],
                        [
                            114.2696142,
                            22.2830606,
                            0
                        ],
                        [
                            114.27176,
                            22.2877463,
                            0
                        ],
                        [
                            114.2697859,
                            22.2885603,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Kwan O Technology Complex\n",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Företagszon, ägs av IDC-Fujiyama. <br><br>Zonen ägs av IDC-F vars positioner i<br>PRD ansätts både av konkurrenter och<br>Chongqing-falangen. Denna zon är deras<br>enda produktionscenter i PRD och mycket<br>eqip till den lokala marknaden printas<br>här. Dessutom pågår R&D i zonen som är<br>bevakad av japanska mercs och robotar.<br>Cert är nästan omöjligt att få tag på.",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.1897488,
                            22.3847221,
                            0
                        ],
                        [
                            114.1918516,
                            22.3830753,
                            0
                        ],
                        [
                            114.1941261,
                            22.3854562,
                            0
                        ],
                        [
                            114.1913366,
                            22.3873609,
                            0
                        ],
                        [
                            114.1894054,
                            22.3849602,
                            0
                        ],
                        [
                            114.1897488,
                            22.3847221,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Lek Yuen Estate\n",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Zonen är under kontroll av den gigantiska<br>possen TH-4, och invånarna är således<br>vana vid ett tyranniskt styre och en<br>uppfuckad tendens till övervåld. Den här<br>zonen chefas över av Roscoe Lee själv.<br><br>Intim kroppsvisitering på alla besökare<br>(just den här veckan >_<).",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.2542934,
                            22.3621415,
                            0
                        ],
                        [
                            114.2592716,
                            22.3584108,
                            0
                        ],
                        [
                            114.262619,
                            22.3557913,
                            0
                        ],
                        [
                            114.2682838,
                            22.3528542,
                            0
                        ],
                        [
                            114.2698288,
                            22.3494407,
                            0
                        ],
                        [
                            114.2775509,
                            22.3476456,
                            0
                        ],
                        [
                            114.3007668,
                            22.3494986,
                            0
                        ],
                        [
                            114.3008104,
                            22.3708784,
                            0
                        ],
                        [
                            114.2945454,
                            22.3826816,
                            0
                        ],
                        [
                            114.3173369,
                            22.3823249,
                            0
                        ],
                        [
                            114.3131137,
                            22.3838314,
                            0
                        ],
                        [
                            114.3134395,
                            22.3863696,
                            0
                        ],
                        [
                            114.3162799,
                            22.388827,
                            0
                        ],
                        [
                            114.3166956,
                            22.3896278,
                            0
                        ],
                        [
                            114.3164676,
                            22.3928491,
                            0
                        ],
                        [
                            114.318774,
                            22.3952984,
                            0
                        ],
                        [
                            114.3208552,
                            22.4036687,
                            0
                        ],
                        [
                            114.3165416,
                            22.4101155,
                            0
                        ],
                        [
                            114.32815,
                            22.4132496,
                            0
                        ],
                        [
                            114.3321628,
                            22.4205495,
                            0
                        ],
                        [
                            114.3354034,
                            22.4281667,
                            0
                        ],
                        [
                            114.3518829,
                            22.4621193,
                            0
                        ],
                        [
                            114.3405533,
                            22.4852785,
                            0
                        ],
                        [
                            114.3151474,
                            22.4821062,
                            0
                        ],
                        [
                            114.2712021,
                            22.44562,
                            0
                        ],
                        [
                            114.2756653,
                            22.4103937,
                            0
                        ],
                        [
                            114.2763519,
                            22.3970625,
                            0
                        ],
                        [
                            114.2689705,
                            22.3961103,
                            0
                        ],
                        [
                            114.263649,
                            22.3954754,
                            0
                        ],
                        [
                            114.2591858,
                            22.3907139,
                            0
                        ],
                        [
                            114.2581558,
                            22.3815079,
                            0
                        ],
                        [
                            114.2523193,
                            22.3767459,
                            0
                        ],
                        [
                            114.2478561,
                            22.3688089,
                            0
                        ],
                        [
                            114.2475128,
                            22.363094,
                            0
                        ],
                        [
                            114.2542934,
                            22.3621415,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Sai Kung",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Kontrolleras av Sai Kungs medborgar-<br>garde. Deras kommitté tycks hitta på nya<br>bestämmelser varje vecka. Många brott<br>bestraffas både drakoniskt och fanatiskt,<br>men deras verkliga problem är att brotts-<br>ligheten nu är rekordlåg – och utan brott,<br>varför behövs gardet?",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.3190527,
                            22.3967848,
                            0
                        ],
                        [
                            114.3186023,
                            22.3952984,
                            0
                        ],
                        [
                            114.3162959,
                            22.3928491,
                            0
                        ],
                        [
                            114.3165239,
                            22.3896278,
                            0
                        ],
                        [
                            114.3163919,
                            22.3890672,
                            0
                        ],
                        [
                            114.316744,
                            22.389425,
                            0
                        ],
                        [
                            114.3171175,
                            22.389148,
                            0
                        ],
                        [
                            114.3172979,
                            22.3890296,
                            0
                        ],
                        [
                            114.3174565,
                            22.3885723,
                            0
                        ],
                        [
                            114.3188595,
                            22.388095,
                            0
                        ],
                        [
                            114.3195891,
                            22.3879363,
                            0
                        ],
                        [
                            114.3195891,
                            22.3881149,
                            0
                        ],
                        [
                            114.3202972,
                            22.3887299,
                            0
                        ],
                        [
                            114.3198251,
                            22.3893648,
                            0
                        ],
                        [
                            114.3202328,
                            22.3897418,
                            0
                        ],
                        [
                            114.3212736,
                            22.3904857,
                            0
                        ],
                        [
                            114.3217778,
                            22.3906147,
                            0
                        ],
                        [
                            114.3219441,
                            22.3906395,
                            0
                        ],
                        [
                            114.3220138,
                            22.3907338,
                            0
                        ],
                        [
                            114.3223571,
                            22.3912893,
                            0
                        ],
                        [
                            114.3229794,
                            22.3917654,
                            0
                        ],
                        [
                            114.3225932,
                            22.3919638,
                            0
                        ],
                        [
                            114.322443,
                            22.3926979,
                            0
                        ],
                        [
                            114.3228721,
                            22.3934518,
                            0
                        ],
                        [
                            114.3229365,
                            22.3948207,
                            0
                        ],
                        [
                            114.3242454,
                            22.3955746,
                            0
                        ],
                        [
                            114.3227434,
                            22.3974197,
                            0
                        ],
                        [
                            114.3213701,
                            22.39861,
                            0
                        ],
                        [
                            114.3190527,
                            22.3967848,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Sheung Yiu",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Ägs 87% av Green Garden, 13% Sun Hung<br>Kai Properties. Livsmedelsjätten Green<br>Garden har här en stor hub för försäljning<br>av livsmedel och utkörning och zonen är<br>extra tät på restauranger. Den är dock<br>känd för att skyddas av Emperor Security,<br>som satt många av sina psykbrutna vets<br>från Filippinerna att vakta.",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.31916,
                            22.3880157,
                            0
                        ],
                        [
                            114.3174565,
                            22.3885723,
                            0
                        ],
                        [
                            114.3172979,
                            22.3890296,
                            0
                        ],
                        [
                            114.316744,
                            22.389425,
                            0
                        ],
                        [
                            114.3164563,
                            22.3889283,
                            0
                        ],
                        [
                            114.3134395,
                            22.3863696,
                            0
                        ],
                        [
                            114.3131137,
                            22.3838314,
                            0
                        ],
                        [
                            114.3173369,
                            22.3823249,
                            0
                        ],
                        [
                            114.31916,
                            22.3880157,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Tsam Chuk Wan\n",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Kontrolleras egentligen av Taihei Ginkou,<br>men efter en framgångsrik crowdfunder-<br>kampanj har sociala arenan Baby Kingdom<br>skaffat ett lease på 33 år. Här byggs för<br>fullt på Montessori-dagis, fosterfrysar och<br>yoga-studios, allt under inhyrd bevakning<br>från Scheffka Security. <br><br>Endast bpop får spelas i zonen!",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.3165416,
                            22.4101155,
                            0
                        ],
                        [
                            114.3208552,
                            22.4036687,
                            0
                        ],
                        [
                            114.3281078,
                            22.4056327,
                            0
                        ],
                        [
                            114.32815,
                            22.4132496,
                            0
                        ],
                        [
                            114.3165416,
                            22.4101155,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Maclehose Quarantine Area\n",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Leasat av Sai Kungs medborgargarde, till Ikeda 70%, Röda svastikan 30%<br><br>Leasat av Sai Kungs medborgargarde<br>till Ikeda. Vård- och läkemedelsjätten<br>använder denna bergiga, avskilda zon som<br>en karantän, där olika zoner som drabbats<br>av sjukdomar kan skicka de drabbade. Det<br><br>finns här även en särskild uttrycknings-<br>styrka som mot betalning kan dyka upp i<br>zoner med klass-3 skyddsutrustning och<br>sätta upp karantän, ta med sig patienter<br>eller desinficera.",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.1833973,
                            22.3886902,
                            0
                        ],
                        [
                            114.1829681,
                            22.3866268,
                            0
                        ],
                        [
                            114.1842985,
                            22.3850594,
                            0
                        ],
                        [
                            114.1865516,
                            22.3852082,
                            0
                        ],
                        [
                            114.1866589,
                            22.3863887,
                            0
                        ],
                        [
                            114.1854572,
                            22.3886902,
                            0
                        ],
                        [
                            114.1833973,
                            22.3886902,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Ten Thousand Buddhas Monastery (PLGH)",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Zonen styrs av munkar och nunnor från<br>det lokala buddhistiska templet. Templen<br>är fredade i frihamnsavtalet och deras<br>inflytande och övertalningsförmåga<br>har gjort dem till de facto lingdaos. Här<br>välkomnas de flesta, så länge de har vett<br>och hyfs, respekterar naturen och de äldre<br>samt donerar frikostigt till templen. De<br>som failar får några brutna ben och depor-<br>teras av väktarna.",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.3208552,
                            22.4036687,
                            0
                        ],
                        [
                            114.3190527,
                            22.3967848,
                            0
                        ],
                        [
                            114.3213701,
                            22.39861,
                            0
                        ],
                        [
                            114.3242454,
                            22.3955746,
                            0
                        ],
                        [
                            114.3229365,
                            22.3948207,
                            0
                        ],
                        [
                            114.322443,
                            22.3926979,
                            0
                        ],
                        [
                            114.3229794,
                            22.3917654,
                            0
                        ],
                        [
                            114.3198251,
                            22.3893648,
                            0
                        ],
                        [
                            114.3202972,
                            22.3887299,
                            0
                        ],
                        [
                            114.3195891,
                            22.3881149,
                            0
                        ],
                        [
                            114.3195891,
                            22.3879363,
                            0
                        ],
                        [
                            114.3188595,
                            22.388095,
                            0
                        ],
                        [
                            114.3173369,
                            22.3823249,
                            0
                        ],
                        [
                            114.3203845,
                            22.3712614,
                            0
                        ],
                        [
                            114.3287102,
                            22.3623207,
                            0
                        ],
                        [
                            114.3336869,
                            22.3442807,
                            0
                        ],
                        [
                            114.3625259,
                            22.3243534,
                            0
                        ],
                        [
                            114.3745422,
                            22.3234006,
                            0
                        ],
                        [
                            114.3844986,
                            22.3282438,
                            0
                        ],
                        [
                            114.3961716,
                            22.361189,
                            0
                        ],
                        [
                            114.4123077,
                            22.4127742,
                            0
                        ],
                        [
                            114.4049263,
                            22.445144,
                            0
                        ],
                        [
                            114.3915367,
                            22.4613261,
                            0
                        ],
                        [
                            114.371624,
                            22.4925744,
                            0
                        ],
                        [
                            114.3559595,
                            22.4890065,
                            0
                        ],
                        [
                            114.3405533,
                            22.4852785,
                            0
                        ],
                        [
                            114.3518829,
                            22.4621193,
                            0
                        ],
                        [
                            114.32815,
                            22.4132496,
                            0
                        ],
                        [
                            114.3281078,
                            22.4056327,
                            0
                        ],
                        [
                            114.3208552,
                            22.4036687,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Sai Kung East\n",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Leasat till Oasis (i 60 år) av Sai Kungs medborgargarde. Innehåller en av de största färskvatten-reservoarerna i Hongkong som Oasis gör bra business av.  Dess<br>trademark syns förstås väl: konstinstalla-<br>tioner, fontäner och oaser med rent och<br>gott vatten. Oasis ger gratis cert och basin-<br>komst till alla zonens invånare men för att<br>komma in krävs bra genuppsättning och<br>mycket lång kötid.",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.0250397,
                            22.7831455,
                            0
                        ],
                        [
                            114.0521706,
                            22.7787109,
                            0
                        ],
                        [
                            114.0930176,
                            22.7369234,
                            0
                        ],
                        [
                            114.1637421,
                            22.7806132,
                            0
                        ],
                        [
                            114.1822815,
                            22.8103648,
                            0
                        ],
                        [
                            114.2035679,
                            22.8201731,
                            0
                        ],
                        [
                            114.1781616,
                            22.8578274,
                            0
                        ],
                        [
                            114.1060638,
                            22.8590929,
                            0
                        ],
                        [
                            114.0731049,
                            22.8439068,
                            0
                        ],
                        [
                            114.0250397,
                            22.7831455,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Tangxiazhen",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Ett naturskönt område med många gamla glassiga hotell och turistanläggningar, mixade med brutalistiska bostadskomplex. Det mesta är i ruiner sedan Kollapsen, och zonen bebos mest av åldrade analoger som vägrat lämna sina hem. Flera lokala miliser har i omgångar squattat i de välbevarade, lyxiga hotellen, och fått spridda granatskurar flingade i sin riktning av Gränskommandot. Situationen eskalerade dock när gerillan Lónggǎng Dĭkàng Yùndòng svepte in. De har sedan 2066 seedat zonen med assymetriska försvarssystem och tokpreppat för en motattack som aldrig riktigt verkar materialisera sig. ",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.3065636,
                            22.5677316,
                            0
                        ],
                        [
                            114.3196099,
                            22.5975281,
                            0
                        ],
                        [
                            114.3489609,
                            22.5864367,
                            0
                        ],
                        [
                            114.3841517,
                            22.6089384,
                            0
                        ],
                        [
                            114.4314457,
                            22.5995899,
                            0
                        ],
                        [
                            114.4439359,
                            22.6218532,
                            0
                        ],
                        [
                            114.4475952,
                            22.6285699,
                            0
                        ],
                        [
                            114.4473202,
                            22.6290863,
                            0
                        ],
                        [
                            114.4459727,
                            22.6317602,
                            0
                        ],
                        [
                            114.4456414,
                            22.6325839,
                            0
                        ],
                        [
                            114.4445279,
                            22.6339225,
                            0
                        ],
                        [
                            114.4423142,
                            22.6366771,
                            0
                        ],
                        [
                            114.4382336,
                            22.638996,
                            0
                        ],
                        [
                            114.4326024,
                            22.6424001,
                            0
                        ],
                        [
                            114.4285796,
                            22.6447436,
                            0
                        ],
                        [
                            114.4161752,
                            22.6436301,
                            0
                        ],
                        [
                            114.4127056,
                            22.6432344,
                            0
                        ],
                        [
                            114.4083351,
                            22.6428384,
                            0
                        ],
                        [
                            114.4057661,
                            22.6426222,
                            0
                        ],
                        [
                            114.4013059,
                            22.6422368,
                            0
                        ],
                        [
                            114.3913096,
                            22.6412571,
                            0
                        ],
                        [
                            114.3798208,
                            22.6402341,
                            0
                        ],
                        [
                            114.3776751,
                            22.6400362,
                            0
                        ],
                        [
                            114.3601228,
                            22.6444324,
                            0
                        ],
                        [
                            114.295063,
                            22.657423,
                            0
                        ],
                        [
                            114.2669105,
                            22.6621753,
                            0
                        ],
                        [
                            114.2186735,
                            22.6323908,
                            0
                        ],
                        [
                            114.1980743,
                            22.6431644,
                            0
                        ],
                        [
                            114.183655,
                            22.6495014,
                            0
                        ],
                        [
                            114.166489,
                            22.6552044,
                            0
                        ],
                        [
                            114.1815961,
                            22.7103223,
                            0
                        ],
                        [
                            114.2110356,
                            22.7325684,
                            0
                        ],
                        [
                            114.2591864,
                            22.7628853,
                            0
                        ],
                        [
                            114.1829691,
                            22.8097313,
                            0
                        ],
                        [
                            114.1728401,
                            22.7946976,
                            0
                        ],
                        [
                            114.1637421,
                            22.7806132,
                            0
                        ],
                        [
                            114.1285525,
                            22.758463,
                            0
                        ],
                        [
                            114.0930176,
                            22.7369234,
                            0
                        ],
                        [
                            114.0804922,
                            22.7492669,
                            0
                        ],
                        [
                            114.0521706,
                            22.7787109,
                            0
                        ],
                        [
                            114.0250397,
                            22.7831455,
                            0
                        ],
                        [
                            113.9769763,
                            22.7932733,
                            0
                        ],
                        [
                            113.9546562,
                            22.7929957,
                            0
                        ],
                        [
                            113.9543809,
                            22.7846977,
                            0
                        ],
                        [
                            113.9569559,
                            22.7728273,
                            0
                        ],
                        [
                            113.946955,
                            22.7512116,
                            0
                        ],
                        [
                            113.9476203,
                            22.7508553,
                            0
                        ],
                        [
                            113.9494234,
                            22.7502223,
                            0
                        ],
                        [
                            113.9513545,
                            22.7457499,
                            0
                        ],
                        [
                            113.9527703,
                            22.7377151,
                            0
                        ],
                        [
                            113.9537142,
                            22.7319361,
                            0
                        ],
                        [
                            113.9489937,
                            22.7322529,
                            0
                        ],
                        [
                            113.9459037,
                            22.7288487,
                            0
                        ],
                        [
                            113.9422989,
                            22.7263154,
                            0
                        ],
                        [
                            113.9333985,
                            22.7251079,
                            0
                        ],
                        [
                            113.923171,
                            22.7240808,
                            0
                        ],
                        [
                            113.9248741,
                            22.7156117,
                            0
                        ],
                        [
                            113.9267083,
                            22.706478,
                            0
                        ],
                        [
                            113.928951,
                            22.6934415,
                            0
                        ],
                        [
                            113.9280928,
                            22.6879775,
                            0
                        ],
                        [
                            113.9256037,
                            22.6741979,
                            0
                        ],
                        [
                            113.9229853,
                            22.6737468,
                            0
                        ],
                        [
                            113.9168827,
                            22.6721678,
                            0
                        ],
                        [
                            113.9047468,
                            22.6705548,
                            0
                        ],
                        [
                            113.8937605,
                            22.6715052,
                            0
                        ],
                        [
                            113.8973818,
                            22.6615655,
                            0
                        ],
                        [
                            113.87887,
                            22.6649499,
                            0
                        ],
                        [
                            113.8573274,
                            22.676909,
                            0
                        ],
                        [
                            113.846515,
                            22.6827658,
                            0
                        ],
                        [
                            113.8430786,
                            22.6780152,
                            0
                        ],
                        [
                            113.8245366,
                            22.6779401,
                            0
                        ],
                        [
                            113.8554292,
                            22.6271527,
                            0
                        ],
                        [
                            113.8156132,
                            22.5693031,
                            0
                        ],
                        [
                            113.8904599,
                            22.4319762,
                            0
                        ],
                        [
                            114.008562,
                            22.5233405,
                            0
                        ],
                        [
                            114.0380875,
                            22.5068485,
                            0
                        ],
                        [
                            114.0552534,
                            22.4992362,
                            0
                        ],
                        [
                            114.0648663,
                            22.5074829,
                            0
                        ],
                        [
                            114.0765393,
                            22.5169976,
                            0
                        ],
                        [
                            114.0909586,
                            22.5309514,
                            0
                        ],
                        [
                            114.1204842,
                            22.5315856,
                            0
                        ],
                        [
                            114.1472631,
                            22.5391961,
                            0
                        ],
                        [
                            114.1561893,
                            22.560757,
                            0
                        ],
                        [
                            114.1870882,
                            22.5575865,
                            0
                        ],
                        [
                            114.20898,
                            22.5580471,
                            0
                        ],
                        [
                            114.2086465,
                            22.5573883,
                            0
                        ],
                        [
                            114.2093428,
                            22.5570465,
                            0
                        ],
                        [
                            114.2105854,
                            22.5561449,
                            0
                        ],
                        [
                            114.2113969,
                            22.556244,
                            0
                        ],
                        [
                            114.2135992,
                            22.5549363,
                            0
                        ],
                        [
                            114.2154581,
                            22.5550554,
                            0
                        ],
                        [
                            114.2161583,
                            22.5554122,
                            0
                        ],
                        [
                            114.2166493,
                            22.5552538,
                            0
                        ],
                        [
                            114.2169807,
                            22.5558682,
                            0
                        ],
                        [
                            114.2175695,
                            22.5561655,
                            0
                        ],
                        [
                            114.218715,
                            22.5556108,
                            0
                        ],
                        [
                            114.2192596,
                            22.5549372,
                            0
                        ],
                        [
                            114.2200549,
                            22.5537495,
                            0
                        ],
                        [
                            114.2206585,
                            22.5535538,
                            0
                        ],
                        [
                            114.2212218,
                            22.5519338,
                            0
                        ],
                        [
                            114.2234346,
                            22.5511238,
                            0
                        ],
                        [
                            114.2243694,
                            22.5509764,
                            0
                        ],
                        [
                            114.2246175,
                            22.550393,
                            0
                        ],
                        [
                            114.22701,
                            22.5479259,
                            0
                        ],
                        [
                            114.2264252,
                            22.5465337,
                            0
                        ],
                        [
                            114.225532,
                            22.5457782,
                            0
                        ],
                        [
                            114.2256004,
                            22.545123,
                            0
                        ],
                        [
                            114.228467,
                            22.5402174,
                            0
                        ],
                        [
                            114.2332647,
                            22.5436354,
                            0
                        ],
                        [
                            114.2438184,
                            22.5517243,
                            0
                        ],
                        [
                            114.292527,
                            22.5609992,
                            0
                        ],
                        [
                            114.3065636,
                            22.5677316,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Shenzhen (Border Command)\n",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "En av de största zonerna i PRD, helt kontrollerat av kinesiska gränskommandot, som hållit den i järngrepp sedan Kollapsen. Även om stora mängder människor fortfarande bor här är också mycket raserat eller övertaget av Gränskommandots trupper.",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            113.5198402,
                            23.0372441,
                            0
                        ],
                        [
                            113.5299683,
                            22.993795,
                            0
                        ],
                        [
                            113.5588074,
                            22.9267769,
                            0
                        ],
                        [
                            113.5643005,
                            22.871114,
                            0
                        ],
                        [
                            113.6082458,
                            22.8268204,
                            0
                        ],
                        [
                            113.6384583,
                            22.8344146,
                            0
                        ],
                        [
                            113.7098694,
                            22.8533983,
                            0
                        ],
                        [
                            113.7744141,
                            22.8394772,
                            0
                        ],
                        [
                            113.7826538,
                            22.8521328,
                            0
                        ],
                        [
                            113.830719,
                            22.867318,
                            0
                        ],
                        [
                            113.8719177,
                            22.8597256,
                            0
                        ],
                        [
                            113.9268494,
                            22.886297,
                            0
                        ],
                        [
                            114.01474,
                            22.9356302,
                            0
                        ],
                        [
                            114.046563,
                            22.9650014,
                            0
                        ],
                        [
                            114.0526727,
                            22.961312,
                            0
                        ],
                        [
                            114.0542877,
                            22.9602597,
                            0
                        ],
                        [
                            114.060615,
                            22.9575161,
                            0
                        ],
                        [
                            114.0688144,
                            22.9540359,
                            0
                        ],
                        [
                            114.0671969,
                            22.956961,
                            0
                        ],
                        [
                            114.0706677,
                            22.9642228,
                            0
                        ],
                        [
                            114.0740045,
                            22.9710005,
                            0
                        ],
                        [
                            114.0772452,
                            22.9752305,
                            0
                        ],
                        [
                            114.07995,
                            22.9768943,
                            0
                        ],
                        [
                            114.0824411,
                            22.9767456,
                            0
                        ],
                        [
                            114.0860499,
                            22.9800829,
                            0
                        ],
                        [
                            114.0891479,
                            22.9856509,
                            0
                        ],
                        [
                            114.0915675,
                            22.9871464,
                            0
                        ],
                        [
                            114.0989816,
                            22.9887147,
                            0
                        ],
                        [
                            114.1078028,
                            22.9976973,
                            0
                        ],
                        [
                            114.1139455,
                            23.0072864,
                            0
                        ],
                        [
                            114.1160119,
                            23.0101203,
                            0
                        ],
                        [
                            114.1171342,
                            23.0138232,
                            0
                        ],
                        [
                            114.1165253,
                            23.0160347,
                            0
                        ],
                        [
                            114.1190493,
                            23.0183648,
                            0
                        ],
                        [
                            114.1285133,
                            23.0255048,
                            0
                        ],
                        [
                            114.1347158,
                            23.0259301,
                            0
                        ],
                        [
                            114.1382804,
                            23.0312833,
                            0
                        ],
                        [
                            114.1325765,
                            23.0305537,
                            0
                        ],
                        [
                            114.1325375,
                            23.0323518,
                            0
                        ],
                        [
                            114.1343479,
                            23.0336573,
                            0
                        ],
                        [
                            114.1346133,
                            23.0355946,
                            0
                        ],
                        [
                            114.1303535,
                            23.0368676,
                            0
                        ],
                        [
                            114.1271553,
                            23.0405201,
                            0
                        ],
                        [
                            114.1260792,
                            23.0439563,
                            0
                        ],
                        [
                            114.1259765,
                            23.0454584,
                            0
                        ],
                        [
                            114.1246891,
                            23.0486176,
                            0
                        ],
                        [
                            114.1140465,
                            23.053475,
                            0
                        ],
                        [
                            114.1012573,
                            23.0595163,
                            0
                        ],
                        [
                            114.0792847,
                            23.0969441,
                            0
                        ],
                        [
                            114.0634919,
                            23.1029447,
                            0
                        ],
                        [
                            114.0518188,
                            23.0999442,
                            0
                        ],
                        [
                            114.0401017,
                            23.09205,
                            0
                        ],
                        [
                            114.0136242,
                            23.0824164,
                            0
                        ],
                        [
                            113.9749146,
                            23.1138386,
                            0
                        ],
                        [
                            113.9337158,
                            23.1113124,
                            0
                        ],
                        [
                            113.9057342,
                            23.1149487,
                            0
                        ],
                        [
                            113.8939746,
                            23.1113174,
                            0
                        ],
                        [
                            113.8815295,
                            23.1007382,
                            0
                        ],
                        [
                            113.8657376,
                            23.1021178,
                            0
                        ],
                        [
                            113.862133,
                            23.1109177,
                            0
                        ],
                        [
                            113.8614479,
                            23.1141951,
                            0
                        ],
                        [
                            113.8597315,
                            23.1149605,
                            0
                        ],
                        [
                            113.8447972,
                            23.1152518,
                            0
                        ],
                        [
                            113.8343259,
                            23.1183598,
                            0
                        ],
                        [
                            113.8154411,
                            23.1272578,
                            0
                        ],
                        [
                            113.8071156,
                            23.1291572,
                            0
                        ],
                        [
                            113.7773323,
                            23.1271077,
                            0
                        ],
                        [
                            113.7536442,
                            23.1312862,
                            0
                        ],
                        [
                            113.7347603,
                            23.1416228,
                            0
                        ],
                        [
                            113.7256813,
                            23.1387839,
                            0
                        ],
                        [
                            113.7150577,
                            23.1376809,
                            0
                        ],
                        [
                            113.6967306,
                            23.1253704,
                            0
                        ],
                        [
                            113.6329651,
                            23.10626,
                            0
                        ],
                        [
                            113.5574341,
                            23.0759416,
                            0
                        ],
                        [
                            113.5198402,
                            23.0372441,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Dongguan",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Zonen administreras av Dongguan-poli-<br>sen som kämpar för att hålla ordning på<br>den kaotiska och mycket stora zonen.<br>Många delar av området är laglöst medan<br>vissa delar är nogo för secos då de blir<br>omedelbart beskjutna. En okänd sjukdom<br>som orsakar talfel, ofrivilliga upprepning-<br>ar, minnesluckor samt interagerar med de<br>drabbades cybernetik sägs dra igenom<br>zonen. Sjukdomen antas vara artificiell till<br>sitt ursprung, men ingen vet egentligen.",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            113.6082458,
                            22.8268204,
                            0
                        ],
                        [
                            113.6700439,
                            22.750855,
                            0
                        ],
                        [
                            113.7393951,
                            22.7426229,
                            0
                        ],
                        [
                            113.7448883,
                            22.7356569,
                            0
                        ],
                        [
                            113.7888336,
                            22.7521214,
                            0
                        ],
                        [
                            113.7936401,
                            22.7666846,
                            0
                        ],
                        [
                            113.7918375,
                            22.7691379,
                            0
                        ],
                        [
                            113.7993049,
                            22.7798218,
                            0
                        ],
                        [
                            113.8029095,
                            22.7866274,
                            0
                        ],
                        [
                            113.8074586,
                            22.7821168,
                            0
                        ],
                        [
                            113.8131663,
                            22.784926,
                            0
                        ],
                        [
                            113.8150978,
                            22.792483,
                            0
                        ],
                        [
                            113.8279724,
                            22.7926412,
                            0
                        ],
                        [
                            113.8272858,
                            22.8166941,
                            0
                        ],
                        [
                            113.8128662,
                            22.8198587,
                            0
                        ],
                        [
                            113.7963867,
                            22.8268204,
                            0
                        ],
                        [
                            113.7778473,
                            22.8249218,
                            0
                        ],
                        [
                            113.7744141,
                            22.8394772,
                            0
                        ],
                        [
                            113.7098694,
                            22.8533983,
                            0
                        ],
                        [
                            113.6082458,
                            22.8268204,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "G4 Security Zone\n",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Adm. av Nimrod på uppdrag av Hongkongs Styrelse. I praktiken endast en fortifierad motorväg omgiven av ruiner, soptippar och illegala ankringsplatser. Plågat av konstanta översvämningar; ryktet går att ingenjörer från Xifang gongye (Werinco) undersöker området i syfte att städa upp och bygga barriärer.",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            113.487661,
                            22.5708758,
                            0
                        ],
                        [
                            113.4444023,
                            22.5784842,
                            0
                        ],
                        [
                            113.4183098,
                            22.5677055,
                            0
                        ],
                        [
                            113.3953072,
                            22.5515358,
                            0
                        ],
                        [
                            113.3719612,
                            22.5426576,
                            0
                        ],
                        [
                            113.3654381,
                            22.5321932,
                            0
                        ],
                        [
                            113.3506752,
                            22.5309247,
                            0
                        ],
                        [
                            113.3414055,
                            22.5299733,
                            0
                        ],
                        [
                            113.3441521,
                            22.5084075,
                            0
                        ],
                        [
                            113.3173729,
                            22.4798593,
                            0
                        ],
                        [
                            113.3551384,
                            22.4633621,
                            0
                        ],
                        [
                            113.3822609,
                            22.4817627,
                            0
                        ],
                        [
                            113.4234596,
                            22.4954029,
                            0
                        ],
                        [
                            113.454702,
                            22.503967,
                            0
                        ],
                        [
                            113.4739281,
                            22.5239479,
                            0
                        ],
                        [
                            113.4938408,
                            22.5366327,
                            0
                        ],
                        [
                            113.487661,
                            22.5708758,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Zhongshan Free State",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "En isolerad urbaniserad zon i norra hörnet av en ö som bildats av vattenhöjningen. Skådeplats för ett socialt experiment av den amerikanske miljardären Hussein Moddelmog, som här försökt skapa en libertariansk utopi som är extrem till och med med PRD-mått mätt. Diverse excentriska och tungt beväpnade amerikaner har slått sig ned och rustat upp de fallfärdiga gamla huskomplexen, men enligt uppgift hålls zonens ekonomi endast igång av de rika innevånarnas förmögenheter. ",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            113.5444122,
                            22.8284841,
                            0
                        ],
                        [
                            113.5392624,
                            22.8136111,
                            0
                        ],
                        [
                            113.530336,
                            22.7962044,
                            0
                        ],
                        [
                            113.5244995,
                            22.7863924,
                            0
                        ],
                        [
                            113.5275894,
                            22.772781,
                            0
                        ],
                        [
                            113.5848999,
                            22.7128565,
                            0
                        ],
                        [
                            113.5814649,
                            22.7350252,
                            0
                        ],
                        [
                            113.624897,
                            22.7419896,
                            0
                        ],
                        [
                            113.5909324,
                            22.8142439,
                            0
                        ],
                        [
                            113.5444122,
                            22.8284841,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Nansha Island Reclamation Zone",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Flera mikrokorpar använde Nansha-ön för att testa nya tekniker för landfills, barriärer, vattenrening och tyfonskydd. En hel grupp ingenjörer och andra anställda försvann spårlöst 2063, möjligen mördade eller tillfångatagna av Haichengs krims. Korparna valde då att överge ön snarare än lägga orimliga mängder € på att skaffa sig beskydd. Sedan dess har en del squatters från Haicheng bosatt sig här, men många gamla fabriker och reclamation-projekt står tysta och övergivna bakom höga stängsel. ",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.0846041,
                            22.3483602,
                            0
                        ],
                        [
                            114.0840462,
                            22.3441924,
                            0
                        ],
                        [
                            114.0857199,
                            22.3418902,
                            0
                        ],
                        [
                            114.0913847,
                            22.3348246,
                            0
                        ],
                        [
                            114.0929297,
                            22.3366902,
                            0
                        ],
                        [
                            114.0950754,
                            22.3384368,
                            0
                        ],
                        [
                            114.0960625,
                            22.3415727,
                            0
                        ],
                        [
                            114.0912989,
                            22.3448672,
                            0
                        ],
                        [
                            114.088209,
                            22.3460183,
                            0
                        ],
                        [
                            114.0868357,
                            22.3482808,
                            0
                        ],
                        [
                            114.0846041,
                            22.3483602,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Sai Tso Wan",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Adm.  av Xifang Gongye (Werinco).<br><br>En dyster containerhamn, noga övervakad av tusentals slitna kameror. Enorma mängder gods lastas och lossas dagligen av logistikarbetare, för Xifang gongye är inte så inne på det här med automatisering. Det bor dessutom en del lågt rankade Chongqing-byråkrater i zonen, och förmodligen myllrar stället av DDW-agenter. <br><br> ",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.0771028,
                            22.3546924,
                            0
                        ],
                        [
                            114.0838834,
                            22.3484609,
                            0
                        ],
                        [
                            114.0868357,
                            22.3482808,
                            0
                        ],
                        [
                            114.088209,
                            22.3460183,
                            0
                        ],
                        [
                            114.0912989,
                            22.3448672,
                            0
                        ],
                        [
                            114.0960625,
                            22.3415727,
                            0
                        ],
                        [
                            114.0950754,
                            22.3384368,
                            0
                        ],
                        [
                            114.0929297,
                            22.3366902,
                            0
                        ],
                        [
                            114.0913847,
                            22.3348246,
                            0
                        ],
                        [
                            114.0893766,
                            22.3355604,
                            0
                        ],
                        [
                            114.0883681,
                            22.3252908,
                            0
                        ],
                        [
                            114.1159197,
                            22.323544,
                            0
                        ],
                        [
                            114.1220137,
                            22.3310072,
                            0
                        ],
                        [
                            114.1073796,
                            22.3646661,
                            0
                        ],
                        [
                            114.0780684,
                            22.3618086,
                            0
                        ],
                        [
                            114.0771028,
                            22.3546924,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Tsing Yi",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Kontrollerad av MTWGU, Motor Transport Workers General Union, som förbjuder AI-styrda fordon i zonen. Självkörande bilar har en 50%<br>chans att bli beskjutna utan förvarning. Tungt beväpnade, tros stöttade av Chongqing-Kina. ",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.1763084,
                            22.3116431,
                            0
                        ],
                        [
                            114.1761046,
                            22.3110476,
                            0
                        ],
                        [
                            114.1760617,
                            22.3108987,
                            0
                        ],
                        [
                            114.1759651,
                            22.3104719,
                            0
                        ],
                        [
                            114.1760724,
                            22.3095885,
                            0
                        ],
                        [
                            114.1763835,
                            22.3086853,
                            0
                        ],
                        [
                            114.1765659,
                            22.307782,
                            0
                        ],
                        [
                            114.1770272,
                            22.3062336,
                            0
                        ],
                        [
                            114.1785615,
                            22.3058068,
                            0
                        ],
                        [
                            114.1791516,
                            22.3079309,
                            0
                        ],
                        [
                            114.1790443,
                            22.3088739,
                            0
                        ],
                        [
                            114.1781484,
                            22.3102883,
                            0
                        ],
                        [
                            114.1776978,
                            22.3110005,
                            0
                        ],
                        [
                            114.1770849,
                            22.311668,
                            0
                        ],
                        [
                            114.1767684,
                            22.3118627,
                            0
                        ],
                        [
                            114.1763701,
                            22.3121766,
                            0
                        ],
                        [
                            114.1762279,
                            22.3121344,
                            0
                        ],
                        [
                            114.1763084,
                            22.3116431,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Wylie South",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Joint Administration; 43%  Sun Hung Kai Properties, 25% Gr, 19% Interworld, 13% Coca Cola. Notervärd för sin enorma coca-cola-reklamskylt, som får dramatiska, närmast surrealistiska effekter sedd genom virrglas.<br><br>Zonen är dock än mer notervärd för sitt läge, då den kontrollerar delar av West Kowloon Corridor och Princess Margaret Road, och av oklara skäl förbjuder AI-styrd trafik på dessa sträckor. Många misstänker att någon aktör, dold i zonens extremt invecklade ägarstruktur, försöker sabotera för Interworld, eller bara på något sätt uppfylla löften givna till MTWGU, Hongkongs chaufförers mäktiga fackförening. Interworld har förgäves försökt ta över zonen genom uppköp.",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.1785128,
                            22.3056381,
                            0
                        ],
                        [
                            114.1791566,
                            22.3056158,
                            0
                        ],
                        [
                            114.1794637,
                            22.3056604,
                            0
                        ],
                        [
                            114.1797574,
                            22.3057274,
                            0
                        ],
                        [
                            114.1801356,
                            22.3060029,
                            0
                        ],
                        [
                            114.1803247,
                            22.3061257,
                            0
                        ],
                        [
                            114.1805352,
                            22.306189,
                            0
                        ],
                        [
                            114.1806989,
                            22.306251,
                            0
                        ],
                        [
                            114.181069,
                            22.3061343,
                            0
                        ],
                        [
                            114.1812621,
                            22.3058986,
                            0
                        ],
                        [
                            114.1813935,
                            22.3056381,
                            0
                        ],
                        [
                            114.1813023,
                            22.3052051,
                            0
                        ],
                        [
                            114.1811038,
                            22.3047373,
                            0
                        ],
                        [
                            114.1807284,
                            22.3038414,
                            0
                        ],
                        [
                            114.1805191,
                            22.3033854,
                            0
                        ],
                        [
                            114.1803099,
                            22.3029592,
                            0
                        ],
                        [
                            114.1802147,
                            22.3028002,
                            0
                        ],
                        [
                            114.1801195,
                            22.3025619,
                            0
                        ],
                        [
                            114.1800792,
                            22.3024027,
                            0
                        ],
                        [
                            114.1800202,
                            22.3020497,
                            0
                        ],
                        [
                            114.1801111,
                            22.3014442,
                            0
                        ],
                        [
                            114.180277,
                            22.3008585,
                            0
                        ],
                        [
                            114.1805878,
                            22.3010074,
                            0
                        ],
                        [
                            114.1809498,
                            22.3011787,
                            0
                        ],
                        [
                            114.1809523,
                            22.3014045,
                            0
                        ],
                        [
                            114.181018,
                            22.3019864,
                            0
                        ],
                        [
                            114.1830458,
                            22.301881,
                            0
                        ],
                        [
                            114.1846444,
                            22.3018612,
                            0
                        ],
                        [
                            114.1855765,
                            22.3018487,
                            0
                        ],
                        [
                            114.1854363,
                            22.3027706,
                            0
                        ],
                        [
                            114.1850494,
                            22.3035387,
                            0
                        ],
                        [
                            114.1847396,
                            22.3040666,
                            0
                        ],
                        [
                            114.184392,
                            22.303875,
                            0
                        ],
                        [
                            114.1842823,
                            22.3042112,
                            0
                        ],
                        [
                            114.1839524,
                            22.3047088,
                            0
                        ],
                        [
                            114.1837109,
                            22.3048911,
                            0
                        ],
                        [
                            114.1838786,
                            22.3052863,
                            0
                        ],
                        [
                            114.1851808,
                            22.3069223,
                            0
                        ],
                        [
                            114.1841965,
                            22.3074744,
                            0
                        ],
                        [
                            114.183365,
                            22.3065668,
                            0
                        ],
                        [
                            114.1829365,
                            22.3068367,
                            0
                        ],
                        [
                            114.1832175,
                            22.3073404,
                            0
                        ],
                        [
                            114.1835393,
                            22.3077771,
                            0
                        ],
                        [
                            114.1822948,
                            22.3084521,
                            0
                        ],
                        [
                            114.1810288,
                            22.3092263,
                            0
                        ],
                        [
                            114.1793203,
                            22.3109236,
                            0
                        ],
                        [
                            114.1776385,
                            22.3126407,
                            0
                        ],
                        [
                            114.1772684,
                            22.3125836,
                            0
                        ],
                        [
                            114.1769498,
                            22.3127313,
                            0
                        ],
                        [
                            114.1765495,
                            22.3127921,
                            0
                        ],
                        [
                            114.1763701,
                            22.3121766,
                            0
                        ],
                        [
                            114.1767369,
                            22.31189,
                            0
                        ],
                        [
                            114.1771037,
                            22.3116382,
                            0
                        ],
                        [
                            114.1776978,
                            22.3110005,
                            0
                        ],
                        [
                            114.1784514,
                            22.309828,
                            0
                        ],
                        [
                            114.1788134,
                            22.3092368,
                            0
                        ],
                        [
                            114.1789984,
                            22.3089387,
                            0
                        ],
                        [
                            114.1790547,
                            22.3087649,
                            0
                        ],
                        [
                            114.1790654,
                            22.308596,
                            0
                        ],
                        [
                            114.1791516,
                            22.3079309,
                            0
                        ],
                        [
                            114.1788027,
                            22.3067696,
                            0
                        ],
                        [
                            114.1785128,
                            22.3056381,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Hong Hum West",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Viktigaste maglev-tunnelstationen och<br>vägkorsningen i Hongkong, där tågen<br>till PRD och resten av mainland avgår.<br>Administreras av Interworld men det<br>finns starka intressen från Yakuzan också.",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.1832175,
                            22.3073404,
                            0
                        ],
                        [
                            114.1829365,
                            22.3068367,
                            0
                        ],
                        [
                            114.183365,
                            22.3065668,
                            0
                        ],
                        [
                            114.183768,
                            22.3070107,
                            0
                        ],
                        [
                            114.1832175,
                            22.3073404,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Lo Lung Hang Flower Market",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Adm. av Hung Hom Seniors' Association, en posse analoger som verkar beskedliga, men gång på gång vägrat sälja zonen trots generösa bud från Interworld och Green Garden. ",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.1832175,
                            22.3073404,
                            0
                        ],
                        [
                            114.183768,
                            22.3070107,
                            0
                        ],
                        [
                            114.1841965,
                            22.3074744,
                            0
                        ],
                        [
                            114.1845056,
                            22.307306,
                            0
                        ],
                        [
                            114.1846532,
                            22.3074971,
                            0
                        ],
                        [
                            114.183768,
                            22.3080455,
                            0
                        ],
                        [
                            114.1836116,
                            22.3078798,
                            0
                        ],
                        [
                            114.183482,
                            22.3076992,
                            0
                        ],
                        [
                            114.1832175,
                            22.3073404,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Sang On Mansions ",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Bostadskomplex och butiker. Adm. av Green Garden, som pressat priserna i zonen till stundtals orimliga nivåer för att konkurrera ut butiker i rivalen Interworlds omgivande zoner. ",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.1837109,
                            22.3048911,
                            0
                        ],
                        [
                            114.1839524,
                            22.3047088,
                            0
                        ],
                        [
                            114.1842823,
                            22.3042112,
                            0
                        ],
                        [
                            114.184392,
                            22.303875,
                            0
                        ],
                        [
                            114.1857063,
                            22.304612,
                            0
                        ],
                        [
                            114.185776,
                            22.3048056,
                            0
                        ],
                        [
                            114.1857894,
                            22.3049197,
                            0
                        ],
                        [
                            114.1851054,
                            22.3052969,
                            0
                        ],
                        [
                            114.184231,
                            22.3057237,
                            0
                        ],
                        [
                            114.1838786,
                            22.3052863,
                            0
                        ],
                        [
                            114.1837109,
                            22.3048911,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "The Royal Peninsula Block",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Ett gigantiskt byggnadskomplex, något slitet, om än inte i nivå med det fallfärdiga Chungking-Mirador Mansions, men känt för sin dystopiska look. Ägs via klantiga östeuropeiska bulvaner av den brittiska megakoncernen Five Circles. Lediga lägenheter blir ofta bostad åt lågt rankade brittiska diplomater. Glåmiga budget-eskorter hänger 24/7 i The Five Roses, komplexets enda bar. Övriga inrättningar består främst av massage parlors, spritbutiker och skumma ryska fraktbolag. ",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.1761616,
                            22.3112327,
                            0
                        ],
                        [
                            114.1753837,
                            22.3112724,
                            0
                        ],
                        [
                            114.1748956,
                            22.3112526,
                            0
                        ],
                        [
                            114.1747239,
                            22.3112377,
                            0
                        ],
                        [
                            114.1742733,
                            22.3114461,
                            0
                        ],
                        [
                            114.1739943,
                            22.3114164,
                            0
                        ],
                        [
                            114.1737154,
                            22.3113022,
                            0
                        ],
                        [
                            114.1731682,
                            22.3108704,
                            0
                        ],
                        [
                            114.1734096,
                            22.3098977,
                            0
                        ],
                        [
                            114.1729805,
                            22.3097935,
                            0
                        ],
                        [
                            114.1731146,
                            22.3094213,
                            0
                        ],
                        [
                            114.173238,
                            22.3087314,
                            0
                        ],
                        [
                            114.173297,
                            22.3081061,
                            0
                        ],
                        [
                            114.1732541,
                            22.3078183,
                            0
                        ],
                        [
                            114.1732218,
                            22.3076173,
                            0
                        ],
                        [
                            114.1735672,
                            22.307464,
                            0
                        ],
                        [
                            114.173934,
                            22.3073579,
                            0
                        ],
                        [
                            114.1746703,
                            22.3070986,
                            0
                        ],
                        [
                            114.1770272,
                            22.3062336,
                            0
                        ],
                        [
                            114.1763835,
                            22.3086853,
                            0
                        ],
                        [
                            114.1760724,
                            22.3095885,
                            0
                        ],
                        [
                            114.1759651,
                            22.3104719,
                            0
                        ],
                        [
                            114.1761616,
                            22.3112327,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Sima Ziyi Memorial Hospital",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Uppkallat efter en hjältemodig läkare under Kollapsen, som försvann spårlöst i dess slutskede. Många tror att hon mördades av general Zhong på Xiaoguwei Island. Numera kontrollerat av Ikeda. Ett komplex i flera nivåer som utgör Hongkongs största (men inte tvunget bästa) sjukhus. ",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.1712295,
                            22.295508,
                            0
                        ],
                        [
                            114.17133,
                            22.2946705,
                            0
                        ],
                        [
                            114.1718678,
                            22.2946903,
                            0
                        ],
                        [
                            114.1724057,
                            22.2947287,
                            0
                        ],
                        [
                            114.1723144,
                            22.2955192,
                            0
                        ],
                        [
                            114.1712295,
                            22.295508,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "The Peninsula",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Hongkongs dyraste hotell. Ägs av Hongkong and Shanghai Hotels Limited (HSH). Lyxiga Rolls Royce-vektorjets går i skytteltrafik till landningsplattformen på taket. ",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.1136941,
                            22.3531355,
                            0
                        ],
                        [
                            114.112541,
                            22.3524678,
                            0
                        ],
                        [
                            114.1140804,
                            22.3492061,
                            0
                        ],
                        [
                            114.1220137,
                            22.3310072,
                            0
                        ],
                        [
                            114.1159197,
                            22.323544,
                            0
                        ],
                        [
                            114.12644,
                            22.3184019,
                            0
                        ],
                        [
                            114.1399579,
                            22.3226896,
                            0
                        ],
                        [
                            114.1432411,
                            22.3251111,
                            0
                        ],
                        [
                            114.1453548,
                            22.3283068,
                            0
                        ],
                        [
                            114.1526344,
                            22.3229972,
                            0
                        ],
                        [
                            114.1548929,
                            22.3263021,
                            0
                        ],
                        [
                            114.1468894,
                            22.3306095,
                            0
                        ],
                        [
                            114.138113,
                            22.3336461,
                            0
                        ],
                        [
                            114.1334781,
                            22.3369011,
                            0
                        ],
                        [
                            114.1336498,
                            22.3392828,
                            0
                        ],
                        [
                            114.1305599,
                            22.3431728,
                            0
                        ],
                        [
                            114.1255817,
                            22.3456338,
                            0
                        ],
                        [
                            114.1257963,
                            22.3503175,
                            0
                        ],
                        [
                            114.126397,
                            22.3528974,
                            0
                        ],
                        [
                            114.1136941,
                            22.3531355,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Worldport Container Terminals",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Viktig containerhamn, administreras av<br>InterWorld. Innehåller en del i Tsuen Wan,<br>en del i Kwai Ching, en del i Lai Chi Kok.<br>Det mesta är automatiserat men det finns<br>många bortglömda vinklar och vrår med<br>både analoga fordon och människor.",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            113.8434015,
                            22.8626989,
                            0
                        ],
                        [
                            113.8443456,
                            22.8646761,
                            0
                        ],
                        [
                            113.830719,
                            22.867318,
                            0
                        ],
                        [
                            113.7826538,
                            22.8521328,
                            0
                        ],
                        [
                            113.7744141,
                            22.8394772,
                            0
                        ],
                        [
                            113.7778473,
                            22.8249218,
                            0
                        ],
                        [
                            113.7963867,
                            22.8268204,
                            0
                        ],
                        [
                            113.8128662,
                            22.8198587,
                            0
                        ],
                        [
                            113.8255487,
                            22.8170577,
                            0
                        ],
                        [
                            113.8270936,
                            22.8216462,
                            0
                        ],
                        [
                            113.8252054,
                            22.8275003,
                            0
                        ],
                        [
                            113.8236604,
                            22.8316139,
                            0
                        ],
                        [
                            113.8240037,
                            22.8350154,
                            0
                        ],
                        [
                            113.8318143,
                            22.8410272,
                            0
                        ],
                        [
                            113.8434015,
                            22.8626989,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Lianhua Mountain/The Lotus Villa Corporate Zone ",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Oklara ägarförhållanden. Leasades på tvivelaktiga grunder av Samsundai från den kinesiska mikrokorpen Baoneng Group, men Baoneng Group gick i konkurs 2062 och obekräftade uppgifter menar att leasen löpte ut 2068. Likafullt finns rapporter om räder med vektorfordon från Samsundais secos, riktade mot shackers och squatters som försökt slå ned sina bopålar i zonen. I praktiken ett övergivet, övervuxet ingenmansland med ökande djurliv. ",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            113.8443243,
                            22.8645971,
                            0
                        ],
                        [
                            113.831793,
                            22.8409482,
                            0
                        ],
                        [
                            113.8239824,
                            22.8349364,
                            0
                        ],
                        [
                            113.8236391,
                            22.8315349,
                            0
                        ],
                        [
                            113.8270723,
                            22.8215672,
                            0
                        ],
                        [
                            113.8255274,
                            22.8169787,
                            0
                        ],
                        [
                            113.8272645,
                            22.8166151,
                            0
                        ],
                        [
                            113.8278665,
                            22.7949847,
                            0
                        ],
                        [
                            113.8531007,
                            22.8068532,
                            0
                        ],
                        [
                            113.9477683,
                            22.7928771,
                            0
                        ],
                        [
                            113.9769549,
                            22.7931943,
                            0
                        ],
                        [
                            114.0250183,
                            22.7830665,
                            0
                        ],
                        [
                            114.0730834,
                            22.8438278,
                            0
                        ],
                        [
                            114.1060423,
                            22.8590139,
                            0
                        ],
                        [
                            114.1239821,
                            22.8587461,
                            0
                        ],
                        [
                            114.1214932,
                            22.8632541,
                            0
                        ],
                        [
                            114.1200555,
                            22.8658343,
                            0
                        ],
                        [
                            114.1197873,
                            22.8669168,
                            0
                        ],
                        [
                            114.1183817,
                            22.8677818,
                            0
                        ],
                        [
                            114.1158229,
                            22.8674383,
                            0
                        ],
                        [
                            114.1134143,
                            22.8670157,
                            0
                        ],
                        [
                            114.1125613,
                            22.8672455,
                            0
                        ],
                        [
                            114.1119444,
                            22.8679894,
                            0
                        ],
                        [
                            114.110775,
                            22.8702828,
                            0
                        ],
                        [
                            114.1088761,
                            22.8729024,
                            0
                        ],
                        [
                            114.1071059,
                            22.875522,
                            0
                        ],
                        [
                            114.1053249,
                            22.8781218,
                            0
                        ],
                        [
                            114.1015053,
                            22.8785765,
                            0
                        ],
                        [
                            114.0988123,
                            22.8779734,
                            0
                        ],
                        [
                            114.0956583,
                            22.8808401,
                            0
                        ],
                        [
                            114.0925309,
                            22.8818138,
                            0
                        ],
                        [
                            114.0901867,
                            22.8833903,
                            0
                        ],
                        [
                            114.0876922,
                            22.8849966,
                            0
                        ],
                        [
                            114.0841248,
                            22.8858812,
                            0
                        ],
                        [
                            114.0809622,
                            22.8891404,
                            0
                        ],
                        [
                            114.0815616,
                            22.8914792,
                            0
                        ],
                        [
                            114.0855514,
                            22.8927109,
                            0
                        ],
                        [
                            114.0867423,
                            22.8948655,
                            0
                        ],
                        [
                            114.0879762,
                            22.8973167,
                            0
                        ],
                        [
                            114.0879871,
                            22.8981122,
                            0
                        ],
                        [
                            114.08582,
                            22.900499,
                            0
                        ],
                        [
                            114.086319,
                            22.9036912,
                            0
                        ],
                        [
                            114.0870244,
                            22.9045411,
                            0
                        ],
                        [
                            114.0869574,
                            22.9061619,
                            0
                        ],
                        [
                            114.0854044,
                            22.9084573,
                            0
                        ],
                        [
                            114.0831809,
                            22.909206,
                            0
                        ],
                        [
                            114.0779075,
                            22.9117691,
                            0
                        ],
                        [
                            114.0752196,
                            22.9159359,
                            0
                        ],
                        [
                            114.072818,
                            22.9166741,
                            0
                        ],
                        [
                            114.0706309,
                            22.9170763,
                            0
                        ],
                        [
                            114.0704328,
                            22.9175943,
                            0
                        ],
                        [
                            114.0707496,
                            22.91831,
                            0
                        ],
                        [
                            114.0738295,
                            22.9224687,
                            0
                        ],
                        [
                            114.0756649,
                            22.9237224,
                            0
                        ],
                        [
                            114.0766792,
                            22.9263255,
                            0
                        ],
                        [
                            114.0743861,
                            22.930967,
                            0
                        ],
                        [
                            114.0728533,
                            22.9335445,
                            0
                        ],
                        [
                            114.0708483,
                            22.9355885,
                            0
                        ],
                        [
                            114.0691127,
                            22.9377281,
                            0
                        ],
                        [
                            114.0671827,
                            22.9460301,
                            0
                        ],
                        [
                            114.0676231,
                            22.9501219,
                            0
                        ],
                        [
                            114.0671995,
                            22.9521085,
                            0
                        ],
                        [
                            114.0688144,
                            22.9540359,
                            0
                        ],
                        [
                            114.0542877,
                            22.9602597,
                            0
                        ],
                        [
                            114.046563,
                            22.9650014,
                            0
                        ],
                        [
                            114.0366664,
                            22.9564201,
                            0
                        ],
                        [
                            114.0269427,
                            22.947206,
                            0
                        ],
                        [
                            114.0175637,
                            22.9384655,
                            0
                        ],
                        [
                            114.0124455,
                            22.9344111,
                            0
                        ],
                        [
                            114.0062976,
                            22.9309889,
                            0
                        ],
                        [
                            113.954381,
                            22.9013898,
                            0
                        ],
                        [
                            113.9224661,
                            22.8840184,
                            0
                        ],
                        [
                            113.9034027,
                            22.8748786,
                            0
                        ],
                        [
                            113.8937002,
                            22.8701495,
                            0
                        ],
                        [
                            113.8884201,
                            22.8675475,
                            0
                        ],
                        [
                            113.8852008,
                            22.8660883,
                            0
                        ],
                        [
                            113.8837306,
                            22.8653587,
                            0
                        ],
                        [
                            113.88299,
                            22.864985,
                            0
                        ],
                        [
                            113.8818956,
                            22.8645106,
                            0
                        ],
                        [
                            113.8781619,
                            22.8626916,
                            0
                        ],
                        [
                            113.8718964,
                            22.8596466,
                            0
                        ],
                        [
                            113.8443243,
                            22.8645971,
                            0
                        ]
                    ],
                    [
                        [
                            113.884138,
                            22.8647775,
                            0
                        ],
                        [
                            113.8830758,
                            22.8649554,
                            0
                        ],
                        [
                            113.8767461,
                            22.8563638,
                            0
                        ],
                        [
                            113.8701371,
                            22.8458043,
                            0
                        ],
                        [
                            113.8742998,
                            22.8373804,
                            0
                        ],
                        [
                            113.8770463,
                            22.8299057,
                            0
                        ],
                        [
                            113.8930714,
                            22.8336637,
                            0
                        ],
                        [
                            113.8964494,
                            22.8392996,
                            0
                        ],
                        [
                            113.9050204,
                            22.8404661,
                            0
                        ],
                        [
                            113.9119924,
                            22.8495217,
                            0
                        ],
                        [
                            113.9213688,
                            22.8588932,
                            0
                        ],
                        [
                            113.88299,
                            22.864985,
                            0
                        ],
                        [
                            113.884138,
                            22.8647775,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Huangjiangzhen Buffer Zone ",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Sedan krigsherren Ma  Xiùyīngs död 2048 formellt annekterad av Kina, men gränsen är knappt bevakad förrän öster om Shima New River. I princip obefolkad, utom en klunga baracker från kinesiska megakorpen Guangxi Gongye, som verkar utföra stora grävprojekt, i ett oklart syfte. Enstaka jordbrukare, somliga av dem Ma Xiùyīngs gamla manskap, bebor fallfärdiga betongkomplex i zonens västra delar, och tycks lämnas ostörda av Gränskommandot. Stora arealer har minerats av Lónggǎng-gerillan, som regelbundet tycks göra intrång i zonen. ",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            113.883752,
                            22.8654378,
                            0
                        ],
                        [
                            113.8830114,
                            22.8650641,
                            0
                        ],
                        [
                            113.8701584,
                            22.8458834,
                            0
                        ],
                        [
                            113.8743211,
                            22.8374594,
                            0
                        ],
                        [
                            113.8770676,
                            22.8299847,
                            0
                        ],
                        [
                            113.8930927,
                            22.8337428,
                            0
                        ],
                        [
                            113.8964707,
                            22.8393787,
                            0
                        ],
                        [
                            113.9050417,
                            22.8405451,
                            0
                        ],
                        [
                            113.9127862,
                            22.8500753,
                            0
                        ],
                        [
                            113.9213901,
                            22.8589723,
                            0
                        ],
                        [
                            113.883752,
                            22.8654378,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Luotian Reservoir ",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Adm. av Everbright Water, en kinesisk mikrokorp som aldrig förstatligades, men likafullt har suspekta kopplingar till Chongqing-falangen. Deras enda verksamhet är vattenrening och vattenhantering i Pärldeltat. ",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            113.3650134,
                            23.085293,
                            0
                        ],
                        [
                            113.3653138,
                            23.0950833,
                            0
                        ],
                        [
                            113.3656679,
                            23.106067,
                            0
                        ],
                        [
                            113.3608294,
                            23.1058795,
                            0
                        ],
                        [
                            113.3512376,
                            23.1097677,
                            0
                        ],
                        [
                            113.3497238,
                            23.1078389,
                            0
                        ],
                        [
                            113.3317228,
                            23.1087468,
                            0
                        ],
                        [
                            113.332061,
                            23.105119,
                            0
                        ],
                        [
                            113.332957,
                            23.101728,
                            0
                        ],
                        [
                            113.3345006,
                            23.0987152,
                            0
                        ],
                        [
                            113.3507226,
                            23.0860826,
                            0
                        ],
                        [
                            113.3650134,
                            23.085293,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Pazhou Minefield",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "The Haizhou Hounds, en grupp aggressiva<br>reclos känd för sina mångtaliga robot-<br>vakthundar, styr över området som var<br>ett övergivet minfält som blivit över efter<br>Kollapsen. I mitten av minfältet finns<br>deras bas och den stora marknaden där<br>handel sker på dagarna. Ibland fraggas<br>random peeps när de inte följer de utsatta<br>virrmärkena för säker passage genom fäl-<br>tet. Old man Fu tar alltid bets på när nästa<br>sprängning sker.",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.1696612,
                            22.3116839,
                            0
                        ],
                        [
                            114.1697336,
                            22.3112397,
                            0
                        ],
                        [
                            114.1698325,
                            22.3107311,
                            0
                        ],
                        [
                            114.1702358,
                            22.3108029,
                            0
                        ],
                        [
                            114.1705409,
                            22.3108575,
                            0
                        ],
                        [
                            114.1709378,
                            22.310927,
                            0
                        ],
                        [
                            114.1708681,
                            22.3114779,
                            0
                        ],
                        [
                            114.1707662,
                            22.311798,
                            0
                        ],
                        [
                            114.1704228,
                            22.3117838,
                            0
                        ],
                        [
                            114.1703501,
                            22.3117766,
                            0
                        ],
                        [
                            114.1701292,
                            22.3117633,
                            0
                        ],
                        [
                            114.1696612,
                            22.3116839,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Hing Kee",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "En klassisk problemzon; ägs av mikrokorpen Hing Kee, som driver flera restauranger, butiker och hotell på området, däribland en av mondes mest berömda restauranger, Echo's, som gourméer flyger från andra sidan jorden för att besöka. Hing Kees excentriska ägare är superparanoida mot kriminalitet eftersom deras zon drar massor av turister, och har därför infört Kowloons mest svårtillgängliga cert, som måste bokas minst 1 månad i förväg. Dessutom sker konstanta GenID-kontroller. Resultatet är att Hing Kee-zonen utgör en permanent roadblock på centrala Kowloon som frustrerat både fordon och fotgängare i årtionden. ",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.1830075,
                            22.2823771,
                            0
                        ],
                        [
                            114.1860942,
                            22.2827534,
                            0
                        ],
                        [
                            114.1880254,
                            22.279656,
                            0
                        ],
                        [
                            114.1898708,
                            22.2790206,
                            0
                        ],
                        [
                            114.1899841,
                            22.2786655,
                            0
                        ],
                        [
                            114.1905474,
                            22.2786209,
                            0
                        ],
                        [
                            114.1909007,
                            22.2786632,
                            0
                        ],
                        [
                            114.1925744,
                            22.2801722,
                            0
                        ],
                        [
                            114.1920594,
                            22.282396,
                            0
                        ],
                        [
                            114.1886691,
                            22.2879951,
                            0
                        ],
                        [
                            114.1833508,
                            22.286507,
                            0
                        ],
                        [
                            114.1830075,
                            22.2823771,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Victoria Corporate Zone",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Det kommersiella centrat i Hongkong och<br>kanske hela Pärldeltat. Zonen etablerades<br>genom att den före detta Victoria-parken<br>helt byggdes över och att delar av hamnen<br>fylldes igen. Här står några av de största<br>skraporna i plexet. Zonen ägs av ett nät-<br>verk av digitala volocorps som sålt andelar<br>till miljontals ägare över hela världen.<br>Zonen bevakas nitiskt av Nimrod.",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.12644,
                            22.3184019,
                            0
                        ],
                        [
                            114.1331769,
                            22.3171956,
                            0
                        ],
                        [
                            114.1338206,
                            22.3134637,
                            0
                        ],
                        [
                            114.1424895,
                            22.3149723,
                            0
                        ],
                        [
                            114.1479398,
                            22.3219993,
                            0
                        ],
                        [
                            114.1432411,
                            22.3251111,
                            0
                        ],
                        [
                            114.1399579,
                            22.3226896,
                            0
                        ],
                        [
                            114.12644,
                            22.3184019,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Paramil Protectorate",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Området var tidigare tillhåll för kinesiska<br>flottan men ParaMil tog över området<br>efter Kollapsen, och är en av få säkerhetsbolag som därmed kan erbjuda säkerhet<br>och eskort i Hongkongs farvatten. Zonen<br>är extremt bevakad och innehåller också<br>ParaMils träningsanläggningar och arsenaler.",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.1203475,
                            22.2173713,
                            0
                        ],
                        [
                            114.1206909,
                            22.1921808,
                            0
                        ],
                        [
                            114.1138244,
                            22.1878892,
                            0
                        ],
                        [
                            114.1139961,
                            22.1823258,
                            0
                        ],
                        [
                            114.1249824,
                            22.1797825,
                            0
                        ],
                        [
                            114.1316772,
                            22.1832796,
                            0
                        ],
                        [
                            114.1380287,
                            22.1775571,
                            0
                        ],
                        [
                            114.1351104,
                            22.1910682,
                            0
                        ],
                        [
                            114.1491866,
                            22.1823258,
                            0
                        ],
                        [
                            114.1399169,
                            22.1983,
                            0
                        ],
                        [
                            114.142921,
                            22.1983,
                            0
                        ],
                        [
                            114.1521049,
                            22.2061672,
                            0
                        ],
                        [
                            114.1547656,
                            22.2037833,
                            0
                        ],
                        [
                            114.1573406,
                            22.2041011,
                            0
                        ],
                        [
                            114.1602588,
                            22.2072003,
                            0
                        ],
                        [
                            114.1589713,
                            22.2099815,
                            0
                        ],
                        [
                            114.1533065,
                            22.2093458,
                            0
                        ],
                        [
                            114.1508174,
                            22.2120475,
                            0
                        ],
                        [
                            114.1446376,
                            22.207836,
                            0
                        ],
                        [
                            114.1398311,
                            22.2093458,
                            0
                        ],
                        [
                            114.1314197,
                            22.2061672,
                            0
                        ],
                        [
                            114.1300464,
                            22.2021145,
                            0
                        ],
                        [
                            114.1271282,
                            22.2049753,
                            0
                        ],
                        [
                            114.127214,
                            22.2092663,
                            0
                        ],
                        [
                            114.1369129,
                            22.2156232,
                            0
                        ],
                        [
                            114.1369987,
                            22.2183248,
                            0
                        ],
                        [
                            114.1333938,
                            22.2212647,
                            0
                        ],
                        [
                            114.1301322,
                            22.2177686,
                            0
                        ],
                        [
                            114.1263557,
                            22.2219798,
                            0
                        ],
                        [
                            114.1203475,
                            22.2173713,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Sau-sektens zon",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Ett ödsligt område som utgör merparten av Lamma Island. Nästan helt övergivet på grund av miljö-fuckup och Gränskommando-basen på öns norra delar, som gör att man löper en påtaglig risk att äta AK-ammo om man trampar en meter fel över en osynlig, invecklad zongräns. Lamma islands halvt övergivna fiskebyar och hamnar är befolkade av medlemmarna i Sau-sekten, ett bisarrt sällskap som tror att jorden har blivit Sanjiva, en av buddhismens dunkla helvetesvärldar. För att få presumptiva besökare på bättre tankar tycks de ha klätt in öns stränder med makabra dockor upphängda på pålar - vissa rykten hävdar att det inte bara är dockor, utan också resterna av deras offer...  ",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.1263557,
                            22.2219798,
                            0
                        ],
                        [
                            114.1260553,
                            22.2262306,
                            0
                        ],
                        [
                            114.1205192,
                            22.2333415,
                            0
                        ],
                        [
                            114.1237807,
                            22.2358044,
                            0
                        ],
                        [
                            114.1232658,
                            22.2385851,
                            0
                        ],
                        [
                            114.1122793,
                            22.239777,
                            0
                        ],
                        [
                            114.1057563,
                            22.2342949,
                            0
                        ],
                        [
                            114.1082454,
                            22.2261114,
                            0
                        ],
                        [
                            114.1024947,
                            22.2193577,
                            0
                        ],
                        [
                            114.1033531,
                            22.2107761,
                            0
                        ],
                        [
                            114.1076446,
                            22.2107761,
                            0
                        ],
                        [
                            114.1144251,
                            22.2150669,
                            0
                        ],
                        [
                            114.1139961,
                            22.2199934,
                            0
                        ],
                        [
                            114.1203475,
                            22.2173713,
                            0
                        ],
                        [
                            114.1263557,
                            22.2219798,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Protektorat 14",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Huserar Hongkongs toriumreaktorer, och anses därför av största vikt. Kontrolleras av Gränskommandot. Överstelöjtnant Mo passar på att få utlopp för sin filosofi att Hårt Arbete Lönar Sig och tvingar alla invånare att jobba minst 4 timmar om dagen med städning, byggnation och äldrevård.",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.1535432,
                            22.3422558,
                            0
                        ],
                        [
                            114.1540583,
                            22.3417696,
                            0
                        ],
                        [
                            114.1624106,
                            22.3352249,
                            0
                        ],
                        [
                            114.1622444,
                            22.3360238,
                            0
                        ],
                        [
                            114.1619547,
                            22.337175,
                            0
                        ],
                        [
                            114.1627701,
                            22.3382765,
                            0
                        ],
                        [
                            114.1616852,
                            22.3403108,
                            0
                        ],
                        [
                            114.1612439,
                            22.3407177,
                            0
                        ],
                        [
                            114.1608336,
                            22.3408169,
                            0
                        ],
                        [
                            114.1602274,
                            22.341333,
                            0
                        ],
                        [
                            114.1596587,
                            22.3420871,
                            0
                        ],
                        [
                            114.1587575,
                            22.3426032,
                            0
                        ],
                        [
                            114.1575344,
                            22.3428016,
                            0
                        ],
                        [
                            114.154702,
                            22.3426726,
                            0
                        ],
                        [
                            114.1535432,
                            22.3422558,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "So Uk Estate Zone",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Adm. av Chinese Trade Institute (i teorin); So Uk Tong (i praktiken).<br><br>Zonen ägs på pappret av Chinese Trade Institute, men i praktiken har en hemlandsorganisation som kallar sig So Uk Tong makten på gatan. Det spelar föga roll, för båda är i någon mån köpta av Chongqing-falangen. Chongqing försöker uppmuntra So Uk Tongs maffialiknande beteende, för alla konflikter med triaderna passar Kina bra - ju mer man kan klaga på kriminalitet och vanskötsel i deltat, desto mer kan man en vacker dag kräva att få administrera fler områden. <br><br>Chinese Trade Institute ligger däremot lågt, och  på sin höjd nöjer de sig med att sprida lite propaganda och träna upp lojalister. ",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.1489575,
                            22.2593377,
                            0
                        ],
                        [
                            114.1668961,
                            22.2635476,
                            0
                        ],
                        [
                            114.1704152,
                            22.2692666,
                            0
                        ],
                        [
                            114.1660121,
                            22.2758315,
                            0
                        ],
                        [
                            114.1661462,
                            22.275901,
                            0
                        ],
                        [
                            114.1657009,
                            22.2760102,
                            0
                        ],
                        [
                            114.1654488,
                            22.2762584,
                            0
                        ],
                        [
                            114.1652681,
                            22.2762947,
                            0
                        ],
                        [
                            114.16467,
                            22.2765069,
                            0
                        ],
                        [
                            114.1643212,
                            22.2767228,
                            0
                        ],
                        [
                            114.1635278,
                            22.2772948,
                            0
                        ],
                        [
                            114.1630992,
                            22.2778171,
                            0
                        ],
                        [
                            114.1621948,
                            22.2779203,
                            0
                        ],
                        [
                            114.1614416,
                            22.2784625,
                            0
                        ],
                        [
                            114.1606118,
                            22.2779465,
                            0
                        ],
                        [
                            114.1601477,
                            22.2776859,
                            0
                        ],
                        [
                            114.1532491,
                            22.2795126,
                            0
                        ],
                        [
                            114.1450951,
                            22.2847544,
                            0
                        ],
                        [
                            114.1350959,
                            22.2838212,
                            0
                        ],
                        [
                            114.133747,
                            22.2826087,
                            0
                        ],
                        [
                            114.1339172,
                            22.2781815,
                            0
                        ],
                        [
                            114.1307146,
                            22.2736004,
                            0
                        ],
                        [
                            114.1326926,
                            22.2701006,
                            0
                        ],
                        [
                            114.1334332,
                            22.2680355,
                            0
                        ],
                        [
                            114.1401817,
                            22.2599335,
                            0
                        ],
                        [
                            114.1489575,
                            22.2593377,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "The Peak Eco-living Zone",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "En zon som innehåller de flesta dyra<br>bostäder i Hongkong, där man gått ihop<br>för att skapa en zon med mycket rigorösa<br>miljö- och uppföranderegler.  Hela zonen utgörs av bergssidor och toppar, med Victoria Peak<br>com den främsta. Här mår växtligheten<br>mestadels bra och man kan se familjer<br>vandra och campa.",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.2106343,
                            22.3111942,
                            0
                        ],
                        [
                            114.2090893,
                            22.3093678,
                            0
                        ],
                        [
                            114.2156071,
                            22.303247,
                            0
                        ],
                        [
                            114.2173237,
                            22.3047558,
                            0
                        ],
                        [
                            114.2133755,
                            22.3096791,
                            0
                        ],
                        [
                            114.2106343,
                            22.3111942,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "KTICH",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Kai Tak International Cruise Harbour, a.k.a ”Kitchi” byggdes när folk fortfarande trodde framtiden innebar glassiga kryssningsfartyg på turkosblå, stilla havsbäddar. Numer anländer det kanske ett skepp per kvartal (antingen illa åtgånget eller med trippel-skrov och sentryguns), och hela området hade lagts ned om det inte vore för en smart pivot av syftet – KTICH blev Kitchi – Kowloons främsta partyområde<br><br>Sägs ägas genom bulvaner av brottsorga-<br>nisationen Syndikatet.",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.0805999,
                            22.2882628,
                            0
                        ],
                        [
                            114.0431778,
                            22.2931866,
                            0
                        ],
                        [
                            114.0303031,
                            22.2893747,
                            0
                        ],
                        [
                            114.0304745,
                            22.2726951,
                            0
                        ],
                        [
                            114.0254966,
                            22.2560154,
                            0
                        ],
                        [
                            114.0373418,
                            22.2496604,
                            0
                        ],
                        [
                            114.0436927,
                            22.240445,
                            0
                        ],
                        [
                            114.0587989,
                            22.2623702,
                            0
                        ],
                        [
                            114.0802566,
                            22.2817505,
                            0
                        ],
                        [
                            114.0805999,
                            22.2882628,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Vision Island Zone",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "En rekordstor reclamation av vatten som<br>sammanförde ett antal öar öster om<br>Lantau till en helt ny ö. Detta såldes som<br>en slutgiltig lösning för trångboddheten,<br>en ö som skulle rymma mer än en miljon<br>invånare. Dessvärre höjdes havsnivån. Vi-<br>sion Island är fortfarande ett av de bättre<br>ställena i Hongkong, en stad i staden med<br>en modern infrastruktur och ren luft. Stora<br>delar av ön är dessvärre vattensjuka och<br>försvinner under vatten vid större tyfoner.",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.1558635,
                            22.2891762,
                            0
                        ],
                        [
                            114.1537178,
                            22.2872503,
                            0
                        ],
                        [
                            114.1502416,
                            22.2813732,
                            0
                        ],
                        [
                            114.1532491,
                            22.2795126,
                            0
                        ],
                        [
                            114.1601477,
                            22.2776859,
                            0
                        ],
                        [
                            114.160218,
                            22.2777168,
                            0
                        ],
                        [
                            114.1624662,
                            22.2791177,
                            0
                        ],
                        [
                            114.1646549,
                            22.2781249,
                            0
                        ],
                        [
                            114.1669723,
                            22.2778271,
                            0
                        ],
                        [
                            114.1674936,
                            22.2780374,
                            0
                        ],
                        [
                            114.1687381,
                            22.2828425,
                            0
                        ],
                        [
                            114.1631162,
                            22.28455,
                            0
                        ],
                        [
                            114.1626871,
                            22.2869326,
                            0
                        ],
                        [
                            114.1558635,
                            22.2891762,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Central Zone",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Adm. av EDA Group (69%), Jardine Matheson (15%), Hopewell Group (11%), частный Labs (5%). <br><br>Det gamla centrumet och kolonins födel-<br>seplats har gått på dekis jämfört med de<br>nyare affärsdistrikten i Victoria och Tsim<br>Sha Tsui. Skyskraporna som står här är<br>inte längre vare sig särskilt höga eller im-<br>ponerande och de innehåller mestadels lo-<br>kala mikrokorpar och agenturer. I gengäld<br>har Central blivit ett allt mer gentrifierat<br>tillhåll, med glitchande fabbare, lyxade<br>och nedkopplade konstgallerier och hand-<br>strimlade nudlar.",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.1984034,
                            22.3317983,
                            0
                        ],
                        [
                            114.2040683,
                            22.3269949,
                            0
                        ],
                        [
                            114.204194,
                            22.325759,
                            0
                        ],
                        [
                            114.2051596,
                            22.3283394,
                            0
                        ],
                        [
                            114.2047948,
                            22.3344329,
                            0
                        ],
                        [
                            114.2031211,
                            22.3350481,
                            0
                        ],
                        [
                            114.1995592,
                            22.3339764,
                            0
                        ],
                        [
                            114.1962976,
                            22.3325274,
                            0
                        ],
                        [
                            114.197349,
                            22.3310984,
                            0
                        ],
                        [
                            114.1984034,
                            22.3317983,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "ReSector",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Sentrys incidentbas, bostäder och trä-<br>ningslokaler. Problemet för Sentry är att<br>en felberäknad zongräns har resulterat<br>i att man byggt på mark som tillhör Lun<br>Wo Don-triaden, som är beredda att ta till<br>rejäla motmedel. Ett dilemma ...",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.2132279,
                            22.3154284,
                            0
                        ],
                        [
                            114.2152878,
                            22.3190809,
                            0
                        ],
                        [
                            114.2142767,
                            22.3264724,
                            0
                        ],
                        [
                            114.2128605,
                            22.3288543,
                            0
                        ],
                        [
                            114.2074127,
                            22.3324679,
                            0
                        ],
                        [
                            114.2050094,
                            22.331138,
                            0
                        ],
                        [
                            114.2051596,
                            22.3283394,
                            0
                        ],
                        [
                            114.204194,
                            22.325759,
                            0
                        ],
                        [
                            114.2045069,
                            22.321728,
                            0
                        ],
                        [
                            114.2132279,
                            22.3154284,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Bay Bridal Zone",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "En rik och tätbebyggd zon som på oklara<br>grunder ägs av en sammanslutning av<br>bröllopsagenturer. Det var eventuellt nå-<br>gon mixup efter Kollapsen, eller så är den<br>branschen verkligen så lukrativ. Zonen<br>bevakas drakoniskt av Emperor Security.",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [
                    113.333521,
                    23.134807,
                    0
                ]
            },
            "properties": {
                "name": "The Mandarin Oriental Abyssal Pool",
                "styleUrl": "#icon-1701-DB4436",
                "styleHash": "442a5955",
                "styleMapHash": {
                    "normal": "#icon-1701-DB4436-normal",
                    "highlight": "#icon-1701-DB4436-highlight"
                },
                "icon": "https://www.gstatic.com/mapspro/images/stock/503-wht-blank_maps.png",
                "description": "En 47 meter djup pool för fridykning, med många undervattenskammare och en nästan labyrintisk design, som byggdes när Mandarin Oriental renoverades på 20-talet. På grund av en byråkratisk fuckup (involverande finstilta klausuler om utrymmen under vatten) ingick poolen inte i något av avtalen när Canaan Market-zonen bildades, och betraktas allmänt som en autonom, självständig zon, trots att den bara kan acessas via lyxhotellet Mandarin Oriental, som tronar ovanpå den.  Zonen ägs sedan ett par år av Rodney Rong, en casinoboss från Macao-zonen, som också äger en andel i Mandarin Oriental. Rong skriver under på Canaan Markets bestämmelser vad gäller resten av Mandarin Oriental - men inte poolen. Den har han istället till avantgardistiska privata förlustelser av en typ som inte hade gått hem varken i Macao eller Canaan Market. <br><br>Rong har låtit fylla poolen med 11000 kubikmeter breathable liquid till en kostnad av åtskilliga miljoner €, och smycka ut den till ett labyrintiskt, överjordiskt lustpalats, komplett med pärlemorstukaturer, genmoddade breathable liquid-fiskar och syntetiska korallrev. Här håller han dekadenta orgier där sadistiska fuerdaier som Luke Wong och P11-maffians Giovanna Garsem är ständiga gäster. Eskorter i avslöjande bikinis, kräm i kapslar, märklig molecular cooking och fuktskyddad simstim är bara några av ingredienserna. Alla gäster lämnar inte alltid djupen levande, och Mandarin Hotels staff säger ofta att hela komplexet luktar av en pikant mix av Peraëro-4 Total Breathable Liquid och blod... <br><br>Anmärkningsvärt nog är Mandarin Oriental Abyssal Pool långt ifrån den minsta zonen i PRD. Icke desto mindre toppar den många listor över Pärldeltats mest bisarra zoner. "
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.2245164,
                            22.2463255,
                            0
                        ],
                        [
                            114.2255464,
                            22.2418767,
                            0
                        ],
                        [
                            114.2329278,
                            22.2406056,
                            0
                        ],
                        [
                            114.235846,
                            22.2221731,
                            0
                        ],
                        [
                            114.2418542,
                            22.2083471,
                            0
                        ],
                        [
                            114.2610803,
                            22.204215,
                            0
                        ],
                        [
                            114.2588487,
                            22.2310718,
                            0
                        ],
                        [
                            114.2512956,
                            22.2485499,
                            0
                        ],
                        [
                            114.2482057,
                            22.252363,
                            0
                        ],
                        [
                            114.2344728,
                            22.2518863,
                            0
                        ],
                        [
                            114.2245164,
                            22.2463255,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Nimrod Training Zone",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Det avskilda området som tidigare hetter<br>Shek O brukade vara ett tillhåll för surfare<br>och bybor. De finns fortfarande kvar på<br>sina håll, men Nimrod använder stora<br>delar av området som träningsanlägg-<br>ning. Man har byggt en mindre hamn och<br>landningsbana för att snabbt kunna ta in<br>förstärkningar.",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            113.4461975,
                            23.0754678,
                            0
                        ],
                        [
                            113.4266731,
                            23.0610949,
                            0
                        ],
                        [
                            113.4177233,
                            23.04759,
                            0
                        ],
                        [
                            113.4092903,
                            23.0337687,
                            0
                        ],
                        [
                            113.3943558,
                            23.0265016,
                            0
                        ],
                        [
                            113.3806229,
                            23.0313991,
                            0
                        ],
                        [
                            113.3916542,
                            22.9871571,
                            0
                        ],
                        [
                            113.4699317,
                            23.0282387,
                            0
                        ],
                        [
                            113.5015174,
                            23.0427723,
                            0
                        ],
                        [
                            113.4814139,
                            23.0635449,
                            0
                        ],
                        [
                            113.4461975,
                            23.0754678,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Mirror Lake-zonen",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Ägs av den sibiriska mikrokorpen Kazatomprom, som via oklara infosocialistiska anti-ägarförhållanden tycks vara del av både EBD Group och Laminar-koncernen. Stora skyltar på kyrilliska med svåruttydda budskap som kanske och kanske inte är sibirisk propaganda dominerar zonens skyline. De västra delarna är stundtals översvämmade, och landskapet en opittoresk mix av träskmark och vattensjuka betongmurar till förseglade industrikomplex. Den östra delen, som i folkmun kallas Hualongzhen, är uppmuddrad och beskyddad av InterWorld i syfte att säkra farvattnen, och hem åt en båtkoloni av västeuropeiska Jesus-freaks, The Maritime Co-Prosperity of Our Saviour of the Seven Seas, som InterWorld inte har legal rätt att avhysa. ",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.2038516,
                            22.3372902,
                            0
                        ],
                        [
                            114.2037661,
                            22.3371922,
                            0
                        ],
                        [
                            114.205,
                            22.3345524,
                            0
                        ],
                        [
                            114.2053647,
                            22.3343936,
                            0
                        ],
                        [
                            114.2058017,
                            22.333944,
                            0
                        ],
                        [
                            114.2062387,
                            22.3336731,
                            0
                        ],
                        [
                            114.2065606,
                            22.3331273,
                            0
                        ],
                        [
                            114.2070809,
                            22.3328742,
                            0
                        ],
                        [
                            114.2075369,
                            22.3331868,
                            0
                        ],
                        [
                            114.2078213,
                            22.333539,
                            0
                        ],
                        [
                            114.208229,
                            22.3340551,
                            0
                        ],
                        [
                            114.2080251,
                            22.3347994,
                            0
                        ],
                        [
                            114.2076711,
                            22.3356528,
                            0
                        ],
                        [
                            114.2071561,
                            22.3365261,
                            0
                        ],
                        [
                            114.2061046,
                            22.3372208,
                            0
                        ],
                        [
                            114.2051605,
                            22.3376177,
                            0
                        ],
                        [
                            114.2038516,
                            22.3372902,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Choi Hung Terrestrial Zone",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Zonen ägs till 40% av HSTC, 14% EBD Group och 46% Ares Mining, och är mest känd för Luk Ching Building, en skyskrapa som huserar lägenheter åt Ares anställda, varav somliga inte sällan är på jord-permission från arbeten på månen och i asteroidbältet. Zonen domineras av orbitals, med logee-anpassade gym och flera caféer, loggbarer, hotell och dagis med rymdtema. HSTC försöker köpa upp mer av zonen, men har hittils blivit blockade av EBD. ",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.4375527,
                            22.5517999,
                            0
                        ],
                        [
                            114.4205582,
                            22.5511658,
                            0
                        ],
                        [
                            114.4353211,
                            22.5251635,
                            0
                        ],
                        [
                            114.4522384,
                            22.5341664,
                            0
                        ],
                        [
                            114.4388999,
                            22.5518321,
                            0
                        ],
                        [
                            114.4375527,
                            22.5517999,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Tung Ping Chau Restoration Zone",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Adm. av Tai Tong Villages medborgargarde, som välkomnar turister och daytrippers med ett endags-cert för det generösa priset av 30€. Ön  var en gång för länge sedan full av fiskare och bönder, men övergavs på 1970-talet, och har många ödsliga, folktomma byar. Det är oklart hur Tai Tongs medborgargarde, som består av nyinflyttade ättlingar till öborna, tänkt restaurera sin zon, men det ryktas givetvis om smuggling av krom, kräm och tech till mainland. ",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.1630563,
                            22.2788695,
                            0
                        ],
                        [
                            114.1624662,
                            22.2791177,
                            0
                        ],
                        [
                            114.1618976,
                            22.2787702,
                            0
                        ],
                        [
                            114.1614416,
                            22.2784625,
                            0
                        ],
                        [
                            114.1621948,
                            22.2779203,
                            0
                        ],
                        [
                            114.1630992,
                            22.2778171,
                            0
                        ],
                        [
                            114.1635069,
                            22.2773406,
                            0
                        ],
                        [
                            114.1635278,
                            22.2772948,
                            0
                        ],
                        [
                            114.1644939,
                            22.2765761,
                            0
                        ],
                        [
                            114.1652681,
                            22.2762947,
                            0
                        ],
                        [
                            114.1654488,
                            22.2762584,
                            0
                        ],
                        [
                            114.1657009,
                            22.2760102,
                            0
                        ],
                        [
                            114.1661462,
                            22.275901,
                            0
                        ],
                        [
                            114.1673478,
                            22.2766456,
                            0
                        ],
                        [
                            114.1681256,
                            22.2772761,
                            0
                        ],
                        [
                            114.1679272,
                            22.27742,
                            0
                        ],
                        [
                            114.167882,
                            22.2780067,
                            0
                        ],
                        [
                            114.1675902,
                            22.278087,
                            0
                        ],
                        [
                            114.1669723,
                            22.2778271,
                            0
                        ],
                        [
                            114.1646549,
                            22.2781249,
                            0
                        ],
                        [
                            114.1630563,
                            22.2788695,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "N.A.F.F.I.",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Det syns inte på utsidan, där tronar stora Superdome-loggor på Lagren, men alla vet att det i själva verket är Samsundais PR-avdelning som bossar över zonen. Den är fylld med KTV-kaféer, koreanska BBQ:er och astroturfers från Samsundai som alla vill vara »din vän«. Domän: KTV.",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            113.5314997,
                            22.2120588,
                            0
                        ],
                        [
                            113.5351181,
                            22.213391,
                            0
                        ],
                        [
                            113.5412979,
                            22.2129937,
                            0
                        ],
                        [
                            113.5441303,
                            22.2167283,
                            0
                        ],
                        [
                            113.5494518,
                            22.2165694,
                            0
                        ],
                        [
                            113.5512543,
                            22.2171256,
                            0
                        ],
                        [
                            113.551173,
                            22.2166103,
                            0
                        ],
                        [
                            113.5507393,
                            22.2156159,
                            0
                        ],
                        [
                            113.5529799,
                            22.2149826,
                            0
                        ],
                        [
                            113.5568084,
                            22.2136342,
                            0
                        ],
                        [
                            113.5587576,
                            22.2168967,
                            0
                        ],
                        [
                            113.5625704,
                            22.2187334,
                            0
                        ],
                        [
                            113.5764742,
                            22.2296196,
                            0
                        ],
                        [
                            113.5875466,
                            22.2253687,
                            0
                        ],
                        [
                            113.5982759,
                            22.2325583,
                            0
                        ],
                        [
                            113.6005075,
                            22.2403442,
                            0
                        ],
                        [
                            113.598276,
                            22.2484473,
                            0
                        ],
                        [
                            113.6011942,
                            22.2555968,
                            0
                        ],
                        [
                            113.5867747,
                            22.269577,
                            0
                        ],
                        [
                            113.5833845,
                            22.2784324,
                            0
                        ],
                        [
                            113.5811957,
                            22.2855403,
                            0
                        ],
                        [
                            113.5783633,
                            22.2948331,
                            0
                        ],
                        [
                            113.5747583,
                            22.297533,
                            0
                        ],
                        [
                            113.5702952,
                            22.2870501,
                            0
                        ],
                        [
                            113.5072954,
                            22.2718009,
                            0
                        ],
                        [
                            113.509206,
                            22.2618708,
                            0
                        ],
                        [
                            113.5045078,
                            22.25202,
                            0
                        ],
                        [
                            113.5165677,
                            22.2401031,
                            0
                        ],
                        [
                            113.5283687,
                            22.2234988,
                            0
                        ],
                        [
                            113.5306209,
                            22.2175404,
                            0
                        ],
                        [
                            113.5314997,
                            22.2120588,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Zhuhai",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "En märklig framgångssaga, kontrollerad av den öppet AI-styrda mikrokorpen Gree som låtit fördela sina aktier \"rättvist\" mellan zonens invånare. Zhuhai är väl skyddat bakom översvämmningsbarriärer, vallar, övervakningssystem och minfält, och har lyckats motverka sin isolation tack vare HKMZ-bron som går direkt till Chek Lap Kok och vidare till Hongkongs centrum. Zhuhai har extremt stränga cert-krav, men försvarar sig med att det krävs för att upprätthålla zonens stabila infrastruktur och utmärkta sociala skyddsnät. Gree-AIn fixar ständigt nya algodlingar, serviceanläggningar, lagernoder, konstinstallationer, fritidsbåtar och andra till synes random livsbonusar åt sina ~550 000 bortskämda invånare. Om cert-fifflare och dissidenter måste avhysas brutalt av zonpolisen, eller spärras in utan rättegång, är det ett litet pris att betala för zonens trygghet. Men många utomstående frågar sig hur i helvete Gree lyckats finansiera hela kalaset. ",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.1783408,
                            22.3050117,
                            0
                        ],
                        [
                            114.1780082,
                            22.3038306,
                            0
                        ],
                        [
                            114.1772089,
                            22.3028479,
                            0
                        ],
                        [
                            114.1800251,
                            22.3021828,
                            0
                        ],
                        [
                            114.181304,
                            22.3052233,
                            0
                        ],
                        [
                            114.1813952,
                            22.3056563,
                            0
                        ],
                        [
                            114.1812678,
                            22.3058864,
                            0
                        ],
                        [
                            114.1810707,
                            22.3061525,
                            0
                        ],
                        [
                            114.1807006,
                            22.3062692,
                            0
                        ],
                        [
                            114.1803264,
                            22.3061439,
                            0
                        ],
                        [
                            114.1796694,
                            22.3057056,
                            0
                        ],
                        [
                            114.179116,
                            22.3056252,
                            0
                        ],
                        [
                            114.1788821,
                            22.3056396,
                            0
                        ],
                        [
                            114.1785146,
                            22.3056563,
                            0
                        ],
                        [
                            114.1783408,
                            22.3050117,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Polytechnic",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Hong Kong Polytechnic University är en mikrokorp med kopplingar till både Ütopya- och Freedom-keiretsun; hela fakulteten är köpta av olika aktörer, och zonen proppfull av reklamkampanjer och rivaliserande endorsements. Av dessa har Naija OC, den nigerianska maffian, börjat få övertaget,, än så länge i skuggorna. Deras maktbas bygger på att driva bordeller och gatukök för de västafrikanska och sydamerikanska studenter som flockats till universitets billiga virr-kurser, och tillbringar en del av sin utbildning on-site. Zonen är småtrendig och Frica-style med<br>sköna gatukök och atta bostadskuber,<br>med pockande lager som pekar besökare<br>till Naija OCs bordeller.  Allt du behöver göra för att få ett cert är att ge access till din lagerprofil. Vad peeps inte inser är att Naija OC håller på att göra hela zonen till en fabrik för stulna ID:n, och de andra makthavarna på Polytechnic tycks oförmögna att stoppa dem. ",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.029032,
                            22.4530752,
                            0
                        ],
                        [
                            114.0287409,
                            22.4528385,
                            0
                        ],
                        [
                            114.0279537,
                            22.451935,
                            0
                        ],
                        [
                            114.0270579,
                            22.4508046,
                            0
                        ],
                        [
                            114.026724,
                            22.4504538,
                            0
                        ],
                        [
                            114.0262902,
                            22.4495905,
                            0
                        ],
                        [
                            114.0256405,
                            22.4483567,
                            0
                        ],
                        [
                            114.0275019,
                            22.4476726,
                            0
                        ],
                        [
                            114.0294385,
                            22.4475139,
                            0
                        ],
                        [
                            114.0300798,
                            22.4482313,
                            0
                        ],
                        [
                            114.0307909,
                            22.4489758,
                            0
                        ],
                        [
                            114.0319258,
                            22.4502261,
                            0
                        ],
                        [
                            114.0319824,
                            22.4503435,
                            0
                        ],
                        [
                            114.0319073,
                            22.4515532,
                            0
                        ],
                        [
                            114.0315211,
                            22.4517713,
                            0
                        ],
                        [
                            114.0307379,
                            22.4521779,
                            0
                        ],
                        [
                            114.0294397,
                            22.4528423,
                            0
                        ],
                        [
                            114.029032,
                            22.4530752,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Tung Tau Playground",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Adm. av 88K-triaden.<br><br>Mika Wong, en av höjdarna inom 88K-triaden, har länge haft ett starkt inflytande bland basarna i den här zonen, och gjort goda förtjänster på Tiandihui Stadium och Golden Sun Casino Complex. Hennes planer att bli ny ledare för hela 88K och integrera zonen med resten av Yuen Long har dock kraftigt försvårats av det plötsliga virusutbrottet, och den karantän zonen försatts i av Bureau for Disease Control. ",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.0337324,
                            22.4548976,
                            0
                        ],
                        [
                            114.0333918,
                            22.4554032,
                            0
                        ],
                        [
                            114.0330726,
                            22.4560477,
                            0
                        ],
                        [
                            114.03252,
                            22.4572574,
                            0
                        ],
                        [
                            114.0307045,
                            22.4545441,
                            0
                        ],
                        [
                            114.029032,
                            22.4530752,
                            0
                        ],
                        [
                            114.0319073,
                            22.4515532,
                            0
                        ],
                        [
                            114.0340435,
                            22.4545208,
                            0
                        ],
                        [
                            114.0337324,
                            22.4548976,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Po Wai Flood Control Area",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Adm. av Gränskommandot, varvid 88K-triaden funnit för gott att hålla sig borta. Zonen är på pappret tillägnad ansträngningar att kontrollera översvämningarna som plågar området, men i praktiken tycks mycket lite sådan aktivitet ske, och Gränskommandots närvaro består mest av att de stundtals sträjfar de squatters och shackers som bosatt sig i zonens fallfärdiga byggnadskomplex. ",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            113.9810559,
                            22.199984,
                            0
                        ],
                        [
                            113.9805839,
                            22.1927125,
                            0
                        ],
                        [
                            113.9838456,
                            22.1892158,
                            0
                        ],
                        [
                            113.9893816,
                            22.1872689,
                            0
                        ],
                        [
                            113.9955185,
                            22.1895733,
                            0
                        ],
                        [
                            113.9960765,
                            22.1969044,
                            0
                        ],
                        [
                            113.9907977,
                            22.2037587,
                            0
                        ],
                        [
                            113.9856264,
                            22.2025469,
                            0
                        ],
                        [
                            113.9810559,
                            22.199984,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Shek Kwu Chau Rehabilitation Area",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "En pittoresk, rofylld ö. Administreras av Society for the Aid and Rehabilitation of Drug Abusers, som har ett stort rehabiliteringscenter här. Shek Kwu Chaus cert är notoriskt svårtillgängligt,, och få utomstående beviljas tillträde till ön. ",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.3630038,
                            22.5107923,
                            0
                        ],
                        [
                            114.3484127,
                            22.5008408,
                            0
                        ],
                        [
                            114.3556224,
                            22.4889062,
                            0
                        ],
                        [
                            114.3639264,
                            22.49077,
                            0
                        ],
                        [
                            114.371624,
                            22.4925744,
                            0
                        ],
                        [
                            114.372059,
                            22.4928316,
                            0
                        ],
                        [
                            114.3692912,
                            22.5023671,
                            0
                        ],
                        [
                            114.3630038,
                            22.5107923,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Port Island",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "En obebodd ö under oklar administration; anspråk finns från Gränskommandot, Oasis, Yan Luo Biotech, Sai Kungs medborgargarde, och Tung Ping Chaus medborgarde, men i praktiken är det ingen som bryr sig tillräckligt mycket för att pusha sitt anspråk. Populär hiking spot, särskilt bland anon, eftersom buggiga kinesiska spiondrönare och Gränskommandots robot-miniubåtar tycks programmerade att nödlanda/surfacea på Port Island så fort de fått ont om batteri eller blivit lindrigt skadade. Att försöka få tag i dessa drönare är inte alltid hälsosamt. ",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.0549248,
                            22.3239876,
                            0
                        ],
                        [
                            114.0356125,
                            22.3263309,
                            0
                        ],
                        [
                            114.0146273,
                            22.3174371,
                            0
                        ],
                        [
                            114.0080821,
                            22.3124942,
                            0
                        ],
                        [
                            114.0051422,
                            22.3067571,
                            0
                        ],
                        [
                            114.0083826,
                            22.3045933,
                            0
                        ],
                        [
                            113.9948862,
                            22.2955206,
                            0
                        ],
                        [
                            113.9915228,
                            22.29359,
                            0
                        ],
                        [
                            113.9819446,
                            22.2916716,
                            0
                        ],
                        [
                            113.9850695,
                            22.2903885,
                            0
                        ],
                        [
                            113.9907882,
                            22.2878771,
                            0
                        ],
                        [
                            114.0121705,
                            22.2806003,
                            0
                        ],
                        [
                            114.0192192,
                            22.2860303,
                            0
                        ],
                        [
                            114.0295618,
                            22.2891673,
                            0
                        ],
                        [
                            114.0431778,
                            22.2931866,
                            0
                        ],
                        [
                            114.0572851,
                            22.311601,
                            0
                        ],
                        [
                            114.0585941,
                            22.3182707,
                            0
                        ],
                        [
                            114.0549248,
                            22.3239876,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Worldview Resorts Zone",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Hela zonen är en lekplats för Pärldeltats<br>medel- och överklass. Här finns en enorm<br>nöjespark, golfbanor, dyra condos och fej-<br>kade italienska caféer, allt vackert inramat<br>av Lantaus berg.",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.2124899,
                            22.3834697,
                            0
                        ],
                        [
                            114.2116692,
                            22.383311,
                            0
                        ],
                        [
                            114.2107841,
                            22.3833903,
                            0
                        ],
                        [
                            114.2112561,
                            22.3823784,
                            0
                        ],
                        [
                            114.2109449,
                            22.381684,
                            0
                        ],
                        [
                            114.2112239,
                            22.3806125,
                            0
                        ],
                        [
                            114.2116638,
                            22.380186,
                            0
                        ],
                        [
                            114.2131337,
                            22.379819,
                            0
                        ],
                        [
                            114.2168673,
                            22.379938,
                            0
                        ],
                        [
                            114.2234119,
                            22.3803745,
                            0
                        ],
                        [
                            114.2225536,
                            22.3831721,
                            0
                        ],
                        [
                            114.2175754,
                            22.3846204,
                            0
                        ],
                        [
                            114.2124899,
                            22.3834697,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Sam Hang Topo",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Detta bostadskomplex kontrolleras av<br>Falungong, qigong-gymnastiken som<br>blev motståndsrörelse och bidragande till<br>Kollapsen. Det märks inte ofta att zonen<br>styrs av dem, mer än på den välbesökta<br>morgongymnastiken i zonens parker. Rö-<br>relsen är numer stormrik och elaka rykten<br>säger att den följer Chongqings ledband,<br>eller åtminstone smugglar något fram och<br>tillbaka över gränsen.",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            113.8930732,
                            22.2850949,
                            0
                        ],
                        [
                            113.872387,
                            22.2719912,
                            0
                        ],
                        [
                            113.8462954,
                            22.2776293,
                            0
                        ],
                        [
                            113.8258677,
                            22.2482393,
                            0
                        ],
                        [
                            113.8358241,
                            22.1940502,
                            0
                        ],
                        [
                            113.8531619,
                            22.1907123,
                            0
                        ],
                        [
                            113.883715,
                            22.210586,
                            0
                        ],
                        [
                            113.9302379,
                            22.1978648,
                            0
                        ],
                        [
                            113.9472323,
                            22.2437905,
                            0
                        ],
                        [
                            113.9405375,
                            22.2504636,
                            0
                        ],
                        [
                            113.9400226,
                            22.2557065,
                            0
                        ],
                        [
                            113.9361191,
                            22.2628862,
                            0
                        ],
                        [
                            113.9332008,
                            22.2652691,
                            0
                        ],
                        [
                            113.9299393,
                            22.2652691,
                            0
                        ],
                        [
                            113.9244461,
                            22.2668577,
                            0
                        ],
                        [
                            113.9242744,
                            22.2829017,
                            0
                        ],
                        [
                            113.9170647,
                            22.2851255,
                            0
                        ],
                        [
                            113.9203262,
                            22.2919554,
                            0
                        ],
                        [
                            113.917408,
                            22.293782,
                            0
                        ],
                        [
                            113.9001971,
                            22.2922425,
                            0
                        ],
                        [
                            113.8930732,
                            22.2850949,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "West Lantau",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Västra delen av Lantau styrs av munkar<br>och nunnor från de lokala buddhistiska<br>och daoistiska templen. De äger inte<br>formellt hela zonen men ägarna låter dem<br>ha kontrollen och templen är fredade i<br>frihamnsavtalet. Deras inflytande och<br>övertalningsförmåga har gjort dem till de<br>facto lingdaos. Här välkomnas de flesta, så<br>länge de har vett och hyfs, respekterar na-<br>turen och de äldre samt donerar frikostigt<br>till templen. De som failar får några brutna<br>ben och deporteras av väktarna.",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.2638635,
                            22.2738075,
                            0
                        ],
                        [
                            114.2654835,
                            22.273043,
                            0
                        ],
                        [
                            114.27132,
                            22.2614066,
                            0
                        ],
                        [
                            114.2787014,
                            22.2598973,
                            0
                        ],
                        [
                            114.2866408,
                            22.2693099,
                            0
                        ],
                        [
                            114.2877995,
                            22.2770937,
                            0
                        ],
                        [
                            114.2844736,
                            22.2785432,
                            0
                        ],
                        [
                            114.2836045,
                            22.2811642,
                            0
                        ],
                        [
                            114.2769419,
                            22.2827129,
                            0
                        ],
                        [
                            114.2751824,
                            22.281879,
                            0
                        ],
                        [
                            114.2746245,
                            22.2797941,
                            0
                        ],
                        [
                            114.274367,
                            22.2775504,
                            0
                        ],
                        [
                            114.274882,
                            22.2758627,
                            0
                        ],
                        [
                            114.2681872,
                            22.2757834,
                            0
                        ],
                        [
                            114.2638635,
                            22.2738075,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Fat Tong O Landfill",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Den något avlägsna udden på Fat<br>Tong-halvön har varit en dumpnings-<br>plats för skräp, skrot och ägarlös eqip i<br>över hundra år. Platsen övergavs efter<br>Kollapsen men har sedan dess blivit hem<br>för otaliga reclos som byggt kåkstäder i<br>flera våningar, marknader och verkstäder<br>runt de enorma sopbergen. De betalar för<br>beskydd av Triyatnos och hans pirater som<br>ofta använder Fat Tong för att reparera<br>trasiga båtar och ersätta eqip.",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            113.9859397,
                            22.4750463,
                            0
                        ],
                        [
                            113.9626336,
                            22.4555347,
                            0
                        ],
                        [
                            113.9551343,
                            22.4509339,
                            0
                        ],
                        [
                            113.9502104,
                            22.4452223,
                            0
                        ],
                        [
                            113.9439136,
                            22.4382411,
                            0
                        ],
                        [
                            113.942767,
                            22.4348295,
                            0
                        ],
                        [
                            113.9633032,
                            22.418705,
                            0
                        ],
                        [
                            113.9739235,
                            22.4247934,
                            0
                        ],
                        [
                            113.989046,
                            22.4163799,
                            0
                        ],
                        [
                            113.9917282,
                            22.4182049,
                            0
                        ],
                        [
                            114.0072636,
                            22.4264563,
                            0
                        ],
                        [
                            114.0037321,
                            22.4341268,
                            0
                        ],
                        [
                            114.0047621,
                            22.4414254,
                            0
                        ],
                        [
                            114.0061354,
                            22.4454712,
                            0
                        ],
                        [
                            114.0100837,
                            22.4487236,
                            0
                        ],
                        [
                            114.0084273,
                            22.4528373,
                            0
                        ],
                        [
                            114.0087962,
                            22.4578459,
                            0
                        ],
                        [
                            114.0080237,
                            22.4640329,
                            0
                        ],
                        [
                            114.0068523,
                            22.4660102,
                            0
                        ],
                        [
                            114.0039642,
                            22.467115,
                            0
                        ],
                        [
                            114.0025909,
                            22.4707634,
                            0
                        ],
                        [
                            114.0006167,
                            22.4727066,
                            0
                        ],
                        [
                            113.9859397,
                            22.4750463,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Tin Shui Wai",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Zonen har köpts upp av en bulvan för<br>Chinese Trade Institute, vilket i sin tur är<br>sponsrat av Chongqing-falangen. Målet<br>är att sakta men säkert köpa tillbaka PRD<br>och förbereda för Införlivandet. Under ti-<br>den ligger man lågt, på sin höjd nöjer man<br>sig med att sprida lite propaganda och<br>träna upp lojalister. Skulle man få trubbel<br>kommer alltid Gränskommandot snabbt ...<br>Domän: Mainland-politik.",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.2225668,
                            22.426693,
                            0
                        ],
                        [
                            114.2174169,
                            22.4086025,
                            0
                        ],
                        [
                            114.2192087,
                            22.4068469,
                            0
                        ],
                        [
                            114.2202977,
                            22.4058897,
                            0
                        ],
                        [
                            114.2215583,
                            22.4050119,
                            0
                        ],
                        [
                            114.2274162,
                            22.3959854,
                            0
                        ],
                        [
                            114.2291758,
                            22.3944776,
                            0
                        ],
                        [
                            114.2301627,
                            22.3944379,
                            0
                        ],
                        [
                            114.2314563,
                            22.3941239,
                            0
                        ],
                        [
                            114.2333262,
                            22.393788,
                            0
                        ],
                        [
                            114.2591858,
                            22.3907139,
                            0
                        ],
                        [
                            114.263649,
                            22.3954754,
                            0
                        ],
                        [
                            114.2763519,
                            22.3970625,
                            0
                        ],
                        [
                            114.2756653,
                            22.4103937,
                            0
                        ],
                        [
                            114.2712021,
                            22.44562,
                            0
                        ],
                        [
                            114.2409346,
                            22.4398627,
                            0
                        ],
                        [
                            114.2225668,
                            22.426693,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Tolo Harbour",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Adm. av EuroProducts.<br><br>Den stora afrikansk-europeiska korpen<br>EuroProducts har lager eller försäljning i<br>zonen men också en mängd agenter som<br>försöker snappa upp de senaste trenderna<br>och inom en timme se till att fabrikerna<br>i Sudan börjar producera det. Domän:<br>Förbrukningsvaror.",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.0962332,
                            22.37882,
                            0
                        ],
                        [
                            114.0881652,
                            22.3725499,
                            0
                        ],
                        [
                            114.0867918,
                            22.3673907,
                            0
                        ],
                        [
                            114.0881487,
                            22.3625363,
                            0
                        ],
                        [
                            114.1073581,
                            22.3646661,
                            0
                        ],
                        [
                            114.112541,
                            22.3524678,
                            0
                        ],
                        [
                            114.1136726,
                            22.3531355,
                            0
                        ],
                        [
                            114.1263755,
                            22.3528974,
                            0
                        ],
                        [
                            114.1257748,
                            22.3503175,
                            0
                        ],
                        [
                            114.1255602,
                            22.3456338,
                            0
                        ],
                        [
                            114.1305384,
                            22.3431728,
                            0
                        ],
                        [
                            114.1322993,
                            22.3409467,
                            0
                        ],
                        [
                            114.1336283,
                            22.3392828,
                            0
                        ],
                        [
                            114.1335834,
                            22.3388122,
                            0
                        ],
                        [
                            114.1335532,
                            22.338092,
                            0
                        ],
                        [
                            114.1370469,
                            22.3388179,
                            0
                        ],
                        [
                            114.1375656,
                            22.338939,
                            0
                        ],
                        [
                            114.1387817,
                            22.3399531,
                            0
                        ],
                        [
                            114.1450565,
                            22.3446985,
                            0
                        ],
                        [
                            114.1474045,
                            22.3465164,
                            0
                        ],
                        [
                            114.1471133,
                            22.3467516,
                            0
                        ],
                        [
                            114.1469829,
                            22.3477112,
                            0
                        ],
                        [
                            114.1464663,
                            22.3485416,
                            0
                        ],
                        [
                            114.1458671,
                            22.3491996,
                            0
                        ],
                        [
                            114.1443133,
                            22.3500444,
                            0
                        ],
                        [
                            114.1398782,
                            22.3595722,
                            0
                        ],
                        [
                            114.1451566,
                            22.3677876,
                            0
                        ],
                        [
                            114.1442125,
                            22.3842168,
                            0
                        ],
                        [
                            114.1397709,
                            22.386836,
                            0
                        ],
                        [
                            114.1361875,
                            22.3885026,
                            0
                        ],
                        [
                            114.1274542,
                            22.3870344,
                            0
                        ],
                        [
                            114.123184,
                            22.3894547,
                            0
                        ],
                        [
                            114.1149872,
                            22.3831063,
                            0
                        ],
                        [
                            114.105417,
                            22.3857247,
                            0
                        ],
                        [
                            114.0962332,
                            22.37882,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Tsuen Wan",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Adm. av Ka Shing Estate.<br><br>Zonen ägs av stiftelsen som förvaltar den<br>legendariska sekelskiftesmiljardären Li Ka<br>Shings tillgångar. Som bekant är alla ätt-<br>lingar döda; de som inte tagit livet av sig<br>eller överdoserat strök med i Kollapsen.<br>Men stiftelsen, fortfarande styrd enligt Ka<br>Shings invecklade instruktioner, fortsätter<br>att köpa, sälja och expandera. Det får en<br>att undra om den där brända kroppen<br>tillhör släkten som det ryktats, och vem<br>som begick mordet?",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.0619266,
                            22.505005,
                            0
                        ],
                        [
                            114.0741242,
                            22.4941883,
                            0
                        ],
                        [
                            114.0868799,
                            22.5033374,
                            0
                        ],
                        [
                            114.0837158,
                            22.5114945,
                            0
                        ],
                        [
                            114.1024267,
                            22.5211681,
                            0
                        ],
                        [
                            114.1119112,
                            22.5150626,
                            0
                        ],
                        [
                            114.1148722,
                            22.5167677,
                            0
                        ],
                        [
                            114.1216101,
                            22.5203748,
                            0
                        ],
                        [
                            114.1204842,
                            22.5315856,
                            0
                        ],
                        [
                            114.0909586,
                            22.5309514,
                            0
                        ],
                        [
                            114.0775359,
                            22.5178401,
                            0
                        ],
                        [
                            114.0619266,
                            22.505005,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Protektorat 10",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Adm av Gränskommandot.<br><br>Zonen är ockuperad av Chongqing,<br>sannolikt för att den korsas av en viktig<br>genomfartsled. Överstelöjtnant Mo passar<br>på att få utlopp för sin filosofi att Hårt<br>Arbete Lönar Sig och tvingar alla invånare<br>att jobba minst 4 timmar om dagen med<br>städning, byggnation och äldrevård.",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.119224,
                            22.5191726,
                            0
                        ],
                        [
                            114.1119112,
                            22.5150626,
                            0
                        ],
                        [
                            114.1024267,
                            22.5211681,
                            0
                        ],
                        [
                            114.0837158,
                            22.5114945,
                            0
                        ],
                        [
                            114.0868799,
                            22.5033374,
                            0
                        ],
                        [
                            114.0888012,
                            22.4992634,
                            0
                        ],
                        [
                            114.1081519,
                            22.4899129,
                            0
                        ],
                        [
                            114.1268844,
                            22.4856901,
                            0
                        ],
                        [
                            114.1272491,
                            22.4857495,
                            0
                        ],
                        [
                            114.1285796,
                            22.4858287,
                            0
                        ],
                        [
                            114.1317553,
                            22.4868201,
                            0
                        ],
                        [
                            114.1339868,
                            22.4874545,
                            0
                        ],
                        [
                            114.1357465,
                            22.4893975,
                            0
                        ],
                        [
                            114.1463035,
                            22.4904186,
                            0
                        ],
                        [
                            114.1478054,
                            22.4919946,
                            0
                        ],
                        [
                            114.1457186,
                            22.4953277,
                            0
                        ],
                        [
                            114.1459708,
                            22.4958258,
                            0
                        ],
                        [
                            114.1474835,
                            22.496822,
                            0
                        ],
                        [
                            114.1476767,
                            22.4975852,
                            0
                        ],
                        [
                            114.1477197,
                            22.4994289,
                            0
                        ],
                        [
                            114.1490984,
                            22.5005886,
                            0
                        ],
                        [
                            114.1491467,
                            22.5009554,
                            0
                        ],
                        [
                            114.1487498,
                            22.5024026,
                            0
                        ],
                        [
                            114.1468023,
                            22.5037268,
                            0
                        ],
                        [
                            114.1466534,
                            22.5058569,
                            0
                        ],
                        [
                            114.1455604,
                            22.5074715,
                            0
                        ],
                        [
                            114.1449621,
                            22.5075687,
                            0
                        ],
                        [
                            114.1417751,
                            22.5071445,
                            0
                        ],
                        [
                            114.1390488,
                            22.5060187,
                            0
                        ],
                        [
                            114.1355704,
                            22.5070178,
                            0
                        ],
                        [
                            114.1341492,
                            22.5098476,
                            0
                        ],
                        [
                            114.1323361,
                            22.5137627,
                            0
                        ],
                        [
                            114.1300079,
                            22.5153781,
                            0
                        ],
                        [
                            114.1276796,
                            22.516042,
                            0
                        ],
                        [
                            114.1237952,
                            22.5153873,
                            0
                        ],
                        [
                            114.119224,
                            22.5191726,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Kwu Tung Free Trade Zone",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Zonen behärskas av den sleeka singapo-<br>reanska Ghee Hin-triaden, som försöker<br>upprätthålla en atmosfär av corporate och<br>elegans medan de tvingar alla att vörda<br>deras förfadersandar och diverse obskyra<br>daoist-gudar. I själva verket har de dock<br>tagit över zonen för att röka ut sina<br>ärkefiender Bollywood-maffian, och deras<br>fasad håller på att krackelera i en orgie av<br>våld och mord.<br><br>Utegångsförbud 23.00–06.00 är infört av Gränskommandot på grund av \"säkerhetsläget\". ",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            113.476653,
                            22.1474629,
                            0
                        ],
                        [
                            113.4728765,
                            22.1460319,
                            0
                        ],
                        [
                            113.5000938,
                            22.1328683,
                            0
                        ],
                        [
                            113.522104,
                            22.1357538,
                            0
                        ],
                        [
                            113.5267757,
                            22.1246502,
                            0
                        ],
                        [
                            113.470748,
                            22.1098748,
                            0
                        ],
                        [
                            113.4711599,
                            22.1088216,
                            0
                        ],
                        [
                            113.4687566,
                            22.0542606,
                            0
                        ],
                        [
                            113.4905576,
                            22.0547379,
                            0
                        ],
                        [
                            113.5133886,
                            22.0603063,
                            0
                        ],
                        [
                            113.5260915,
                            22.0660336,
                            0
                        ],
                        [
                            113.5315844,
                            22.0732729,
                            0
                        ],
                        [
                            113.54712,
                            22.0720402,
                            0
                        ],
                        [
                            113.5548877,
                            22.0711052,
                            0
                        ],
                        [
                            113.5609388,
                            22.0727152,
                            0
                        ],
                        [
                            113.5674619,
                            22.0889403,
                            0
                        ],
                        [
                            113.5535573,
                            22.0976885,
                            0
                        ],
                        [
                            113.5489368,
                            22.1051241,
                            0
                        ],
                        [
                            113.5489368,
                            22.122338,
                            0
                        ],
                        [
                            113.5490656,
                            22.1371068,
                            0
                        ],
                        [
                            113.5487652,
                            22.1462308,
                            0
                        ],
                        [
                            113.5386372,
                            22.158314,
                            0
                        ],
                        [
                            113.5356331,
                            22.1672963,
                            0
                        ],
                        [
                            113.5082387,
                            22.1557304,
                            0
                        ],
                        [
                            113.476653,
                            22.1474629,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "West Taipa",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Adm. av vrakare.<br><br>Zonen är delvis översvämmad och har<br>intagits av båtburna vrakare som tog<br>skydd från en tyfon. De försörjer sig på<br>att reparera båtar och motorer samt sälja<br>scrap, men vad de framförallt vill är att<br>kunna fylla på lagret av sjukvårdseqip<br>och medicin i sin flytande klinik.",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            113.5625704,
                            22.2187334,
                            0
                        ],
                        [
                            113.5587576,
                            22.2168967,
                            0
                        ],
                        [
                            113.5568084,
                            22.2136342,
                            0
                        ],
                        [
                            113.5686779,
                            22.2099741,
                            0
                        ],
                        [
                            113.5719397,
                            22.1954308,
                            0
                        ],
                        [
                            113.5850188,
                            22.1959104,
                            0
                        ],
                        [
                            113.5870788,
                            22.2102144,
                            0
                        ],
                        [
                            113.6614082,
                            22.245809,
                            0
                        ],
                        [
                            113.6808059,
                            22.2697984,
                            0
                        ],
                        [
                            113.6924789,
                            22.2817122,
                            0
                        ],
                        [
                            113.721318,
                            22.2818711,
                            0
                        ],
                        [
                            113.8462954,
                            22.2776293,
                            0
                        ],
                        [
                            113.8595559,
                            22.2747309,
                            0
                        ],
                        [
                            113.872387,
                            22.2719912,
                            0
                        ],
                        [
                            113.8922963,
                            22.2844366,
                            0
                        ],
                        [
                            113.9001971,
                            22.2922425,
                            0
                        ],
                        [
                            113.8899422,
                            22.2932261,
                            0
                        ],
                        [
                            113.8694617,
                            22.2809974,
                            0
                        ],
                        [
                            113.8395927,
                            22.2862392,
                            0
                        ],
                        [
                            113.6892173,
                            22.2881452,
                            0
                        ],
                        [
                            113.6541984,
                            22.2500193,
                            0
                        ],
                        [
                            113.5834739,
                            22.2156971,
                            0
                        ],
                        [
                            113.5625704,
                            22.2187334,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "HMZ-Interworld Joint Venture",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Adm. av Interworld.<br><br>HZM-bron är ålderstigen men fortfarande<br>ett magnifikt byggnadsverk. Hela bron<br>räknas som en zon som ägs av InterWorld,<br>med några tillhörande öar för lukrativa<br>vägtullar och butiker. Zonen är en magnet<br>för terrorattentat och bevakas hänsynslöst<br>av både InterWorlds säkerhetsstyrkor och<br>patrullerande kinesiska sjöstridskrafter.",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            113.487661,
                            22.5708758,
                            0
                        ],
                        [
                            113.4938408,
                            22.5366327,
                            0
                        ],
                        [
                            113.4739281,
                            22.5239479,
                            0
                        ],
                        [
                            113.4576202,
                            22.5070595,
                            0
                        ],
                        [
                            113.4809716,
                            22.4857909,
                            0
                        ],
                        [
                            113.5523699,
                            22.4520557,
                            0
                        ],
                        [
                            113.5731001,
                            22.4836425,
                            0
                        ],
                        [
                            113.5703543,
                            22.533128,
                            0
                        ],
                        [
                            113.487661,
                            22.5708758,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Nanlang-MFF Economic Zone",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Adm. av Mitsubishi-Ford-Fiat.<br><br>Hela zonen domineras av en nästan helt<br>automatiserad fabrik som producerar el-<br>bilar och autoner för PRD och angränsan-<br>de regioner. Andra delar av zonen består<br>av ett 53 våningar högt parkeringstorn.<br>För att blidka familjerna till sparkade fa-<br>briksarbetare byggde MFF för länge sedan<br>ett lägenhetsområde, som nu är nedgång-<br>et. Företaget har funnit det bäst att fort-<br>sätta betala de sparkades ättlingar, hellre<br>än att dra på sig protester och triader.",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            113.2753896,
                            23.1589737,
                            0
                        ],
                        [
                            113.2807159,
                            23.1594585,
                            0
                        ],
                        [
                            113.2893849,
                            23.1585115,
                            0
                        ],
                        [
                            113.2944488,
                            23.159064,
                            0
                        ],
                        [
                            113.2995128,
                            23.1582748,
                            0
                        ],
                        [
                            113.303542,
                            23.1589737,
                            0
                        ],
                        [
                            113.3159923,
                            23.162694,
                            0
                        ],
                        [
                            113.3248281,
                            23.1894312,
                            0
                        ],
                        [
                            113.3268022,
                            23.1981884,
                            0
                        ],
                        [
                            113.3191632,
                            23.2082861,
                            0
                        ],
                        [
                            113.3147,
                            23.21507,
                            0
                        ],
                        [
                            113.3107518,
                            23.2172786,
                            0
                        ],
                        [
                            113.3042715,
                            23.2207888,
                            0
                        ],
                        [
                            113.3006451,
                            23.2180083,
                            0
                        ],
                        [
                            113.2977055,
                            23.2134924,
                            0
                        ],
                        [
                            113.2884358,
                            23.2018173,
                            0
                        ],
                        [
                            113.2840585,
                            23.192587,
                            0
                        ],
                        [
                            113.2799386,
                            23.183356,
                            0
                        ],
                        [
                            113.2769345,
                            23.1708892,
                            0
                        ],
                        [
                            113.275647,
                            23.1627616,
                            0
                        ],
                        [
                            113.2753896,
                            23.1589737,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "The First Peak",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Adm. av Zhiyang Yan.<br><br>Detta område är på pappret kinesisk mark<br>men styrs av krigsherren Zhiyang Yan med tillhörande trupp, en övervintrad rebell från<br>Kollapsen. Chongqing-regimen har aldrig<br>lyckats ta kontrollen över det bergiga<br>området, som i bevakas med alla tricks i<br>gerilla-handboken. Yan letar efter<br>tvångsrekryter eller peeps med kunskap<br>om hur man balanserar en budget (han är<br>inte direkt en erfaren statsman).",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.2256045,
                            22.4746462,
                            0
                        ],
                        [
                            114.2419123,
                            22.4479949,
                            0
                        ],
                        [
                            114.2712021,
                            22.44562,
                            0
                        ],
                        [
                            114.3151474,
                            22.4821062,
                            0
                        ],
                        [
                            114.3405533,
                            22.4852785,
                            0
                        ],
                        [
                            114.3556224,
                            22.4889062,
                            0
                        ],
                        [
                            114.3390726,
                            22.5160407,
                            0
                        ],
                        [
                            114.304397,
                            22.5039884,
                            0
                        ],
                        [
                            114.2983889,
                            22.5036712,
                            0
                        ],
                        [
                            114.2843127,
                            22.5160407,
                            0
                        ],
                        [
                            114.2946123,
                            22.5223836,
                            0
                        ],
                        [
                            114.2796778,
                            22.5374468,
                            0
                        ],
                        [
                            114.262855,
                            22.5525084,
                            0
                        ],
                        [
                            114.2598598,
                            22.5551288,
                            0
                        ],
                        [
                            114.2438184,
                            22.5517243,
                            0
                        ],
                        [
                            114.230647,
                            22.5417277,
                            0
                        ],
                        [
                            114.2027735,
                            22.5234936,
                            0
                        ],
                        [
                            114.2163347,
                            22.5133449,
                            0
                        ],
                        [
                            114.2329009,
                            22.499706,
                            0
                        ],
                        [
                            114.2388224,
                            22.4851149,
                            0
                        ],
                        [
                            114.2256045,
                            22.4746462,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Green Peninsula",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Adm. av Momo's Rewilders.<br><br>Sannolikt var zonen tidigare övergiven<br>men den har fått nytt liv och ett täcke av<br>växtlighet tack vare att den styrs av ett<br>gäng sympatiska rewilders. Deras mål<br>är självförsörjning och de städar bort<br>föroreningar, sprider frön samt släpper ut<br>husdjur (och en och annan zoo-rymling).<br>Deras problem nu är att någon form av pa-<br>rasit angriper grödorna och de misstänker<br>någon av plexets biotech-bolag.",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.1822211,
                            22.2366407,
                            0
                        ],
                        [
                            114.1811589,
                            22.2359654,
                            0
                        ],
                        [
                            114.1813413,
                            22.2334429,
                            0
                        ],
                        [
                            114.1831008,
                            22.2314765,
                            0
                        ],
                        [
                            114.1859654,
                            22.2320625,
                            0
                        ],
                        [
                            114.1880897,
                            22.2308806,
                            0
                        ],
                        [
                            114.1928855,
                            22.2319135,
                            0
                        ],
                        [
                            114.1922096,
                            22.2346148,
                            0
                        ],
                        [
                            114.1886798,
                            22.2365414,
                            0
                        ],
                        [
                            114.1822211,
                            22.2366407,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Tusitan Orb Zone",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Adm. av Ma-familjen. <br><br>Den ultrarika daibanen Molly Ma och hennes  incestuösa familj av augs styr zonen som mestadels utgörs av en undervattensarkologi. Lagar är godtyckliga och zonens cert kostar<br>2000 € om man inte själv är en aug med<br>intressant genuppsättning. Festerna är<br>fantastiska då gästerna är både overkligt<br>snygga och utrustade med diverse extra<br>biologi. Det sägs att en del besökare får<br>underliga ägg planterade i sig men det är<br>nog bara rykten.<br><br>Man får inte vara gravid eller föda barn i<br>zonen utan särskild licens.",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.1889481,
                            22.2364029,
                            0
                        ],
                        [
                            114.1922096,
                            22.2346148,
                            0
                        ],
                        [
                            114.1931538,
                            22.2306031,
                            0
                        ],
                        [
                            114.190729,
                            22.2276237,
                            0
                        ],
                        [
                            114.1898064,
                            22.2245647,
                            0
                        ],
                        [
                            114.1942266,
                            22.220433,
                            0
                        ],
                        [
                            114.2050413,
                            22.2233332,
                            0
                        ],
                        [
                            114.2080026,
                            22.2272362,
                            0
                        ],
                        [
                            114.206329,
                            22.2320528,
                            0
                        ],
                        [
                            114.204827,
                            22.235797,
                            0
                        ],
                        [
                            114.2036467,
                            22.2377882,
                            0
                        ],
                        [
                            114.1980032,
                            22.2395013,
                            0
                        ],
                        [
                            114.1957501,
                            22.2401766,
                            0
                        ],
                        [
                            114.1947094,
                            22.2400972,
                            0
                        ],
                        [
                            114.1878323,
                            22.2380713,
                            0
                        ],
                        [
                            114.1889481,
                            22.2364029,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Repulse Bay",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Adm. av Farring International.<br><br>Denna paladintäta zon ägs av finansjät-<br>ten Farring International och är en viktig<br>synaps i världsekonomins neurala nätverk.<br>Här rör sig rika paladiner, säljare, korp-<br>klättrare och allt möjligt annat i kostym.<br>Zonen domineras av en av FI:s filialer i<br>PRD.<br><br>Endast media från ONN släpps in i zonen. Misstänkt mediautrustning riskerar att beslagtas. Tiggare blir omedelbart arresterade. ",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.1183979,
                            22.2721707,
                            0
                        ],
                        [
                            114.1248358,
                            22.2626587,
                            0
                        ],
                        [
                            114.131058,
                            22.2508825,
                            0
                        ],
                        [
                            114.1345234,
                            22.2490157,
                            0
                        ],
                        [
                            114.1382034,
                            22.2479035,
                            0
                        ],
                        [
                            114.1439325,
                            22.2493136,
                            0
                        ],
                        [
                            114.1434605,
                            22.2515975,
                            0
                        ],
                        [
                            114.1400058,
                            22.2603455,
                            0
                        ],
                        [
                            114.1336761,
                            22.2677226,
                            0
                        ],
                        [
                            114.1307146,
                            22.2736004,
                            0
                        ],
                        [
                            114.1269165,
                            22.2743947,
                            0
                        ],
                        [
                            114.1183979,
                            22.2721707,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Cyberport",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Adm. av The Sandy Bay Neopets Convention.<br><br>Zonen domineras av virrhusdjur. Affärsinnehavarna, invånarna och de flesta<br>besökarna här är neopets och köphysterin<br>verkar vara konstant, då det alltid finns<br>något nytt virrkoppel, fur-shine, pet-klä-<br>der att inhandla. Alla som inte är aegyo<br>måste betala en bad boy-skatt på 100 €<br>för att få cert till zonen.",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.1746703,
                            22.3070986,
                            0
                        ],
                        [
                            114.1737333,
                            22.3066379,
                            0
                        ],
                        [
                            114.1730145,
                            22.3060373,
                            0
                        ],
                        [
                            114.1734222,
                            22.3051639,
                            0
                        ],
                        [
                            114.1736206,
                            22.3033672,
                            0
                        ],
                        [
                            114.1758362,
                            22.303402,
                            0
                        ],
                        [
                            114.1764155,
                            22.303263,
                            0
                        ],
                        [
                            114.1772089,
                            22.3028479,
                            0
                        ],
                        [
                            114.1780082,
                            22.3038306,
                            0
                        ],
                        [
                            114.1785615,
                            22.3058068,
                            0
                        ],
                        [
                            114.1770272,
                            22.3062336,
                            0
                        ],
                        [
                            114.1746703,
                            22.3070986,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Revolution Barracks",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Adm. av Remb.<br><br>Jammer-gänget Remb har kontroll över<br>zonen och drar runt på gatorna i röda<br>masker och går ultra på saker i grupp.<br><br>Alla besökare måste bidra med krom eller<br>ammo värt minst 20 € till zonmilisens<br>vapencache. Utegångsförbud inom semi-random intervaller, i regel på natten. Zonen har ett dedikerat lager för att hjälpa peeps hålla<br>koll.",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.1608628,
                            22.3175517,
                            0
                        ],
                        [
                            114.1608628,
                            22.3172738,
                            0
                        ],
                        [
                            114.1624292,
                            22.3131647,
                            0
                        ],
                        [
                            114.1629871,
                            22.3122515,
                            0
                        ],
                        [
                            114.1644033,
                            22.3118744,
                            0
                        ],
                        [
                            114.1669782,
                            22.3123111,
                            0
                        ],
                        [
                            114.1669353,
                            22.3130257,
                            0
                        ],
                        [
                            114.1663345,
                            22.3170753,
                            0
                        ],
                        [
                            114.1652724,
                            22.3182365,
                            0
                        ],
                        [
                            114.164017,
                            22.3185045,
                            0
                        ],
                        [
                            114.1608628,
                            22.3175517,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Olympian Max",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "De företagsamma Lun Wo Don-triaderna<br>äger zonen och många av mikrokorparna i den. LWD håller en diskret profil och ser mest till att saker fungerar, under ytan. Men de tolererar inte för en stund konkurrens och planerar just nu en räd mot en posse nyanlända mercs.<br><br>Zonen har utvecklat en lite speciell företagskultur, där endat vegetarisk mat är tillåten. Näringsidkare betalar zonskatt endast om kunden begär ett kvitto. De mutar ofta<br>peeps för att inte begära kvitto.",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.1693287,
                            22.3192687,
                            0
                        ],
                        [
                            114.1717212,
                            22.3197749,
                            0
                        ],
                        [
                            114.1763614,
                            22.3207276,
                            0
                        ],
                        [
                            114.1780406,
                            22.3215068,
                            0
                        ],
                        [
                            114.1779869,
                            22.3216606,
                            0
                        ],
                        [
                            114.1779547,
                            22.3219087,
                            0
                        ],
                        [
                            114.1780834,
                            22.322395,
                            0
                        ],
                        [
                            114.1782444,
                            22.3228119,
                            0
                        ],
                        [
                            114.1783222,
                            22.3244668,
                            0
                        ],
                        [
                            114.1783289,
                            22.3253389,
                            0
                        ],
                        [
                            114.1782068,
                            22.3256354,
                            0
                        ],
                        [
                            114.1685455,
                            22.3239532,
                            0
                        ],
                        [
                            114.1693287,
                            22.3192687,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Mong Kok East",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Adm. av Wo Hop To-triaden.<br><br>Här domineras gatubilden av WHT.<br>Patrullerande vans fyllda med dailos visar<br>lojt och övertydligt sina PMC-smiggar<br>och slasher-klor. Lokala lingdaon Billion<br>Yip har mycket stora ambitioner och<br>förbereder zonen för ett begynnande krig<br>– hon har bara inte bestämt om det är mot<br>indoneserna, Chi Sin eller Sentry.",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.2354246,
                            22.2879722,
                            0
                        ],
                        [
                            114.2430206,
                            22.2843983,
                            0
                        ],
                        [
                            114.2573114,
                            22.2996462,
                            0
                        ],
                        [
                            114.2426343,
                            22.3045299,
                            0
                        ],
                        [
                            114.2425807,
                            22.3015917,
                            0
                        ],
                        [
                            114.2443725,
                            22.2991697,
                            0
                        ],
                        [
                            114.2397374,
                            22.2931739,
                            0
                        ],
                        [
                            114.2354246,
                            22.2879722,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Junk Bay Permanent Cemetary",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Adm. av: Reclo-grupper.<br><br>En av de otaliga dumpningsplatserna för<br>skräp och skrot i deltat. Zonen är ägarlös<br>efter att en rymdkapsel kraschade i ett av<br>sopbergen och dess ägare Ares avrätta-<br>de alla som envisades med att peta på<br>den. Efter månader av grävande lämnade<br>Ares plötsligt zonen. Nu har åtskilliga<br>reclo-grupper krigar med varandra om<br>rätten till zonen, övertygade om att det<br>fortfarande finns Ares-tech gömt i sop-<br>bergen.",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            113.8150978,
                            22.792483,
                            0
                        ],
                        [
                            113.8131663,
                            22.784926,
                            0
                        ],
                        [
                            113.8074586,
                            22.7821168,
                            0
                        ],
                        [
                            113.8029095,
                            22.7866274,
                            0
                        ],
                        [
                            113.7918375,
                            22.7691379,
                            0
                        ],
                        [
                            113.7936401,
                            22.7666846,
                            0
                        ],
                        [
                            113.8080597,
                            22.7692172,
                            0
                        ],
                        [
                            113.819046,
                            22.7692172,
                            0
                        ],
                        [
                            113.8465118,
                            22.7489553,
                            0
                        ],
                        [
                            113.8478851,
                            22.7446809,
                            0
                        ],
                        [
                            113.8671112,
                            22.744206,
                            0
                        ],
                        [
                            113.8733767,
                            22.7663679,
                            0
                        ],
                        [
                            113.8580132,
                            22.7809297,
                            0
                        ],
                        [
                            113.8510615,
                            22.7950156,
                            0
                        ],
                        [
                            113.8150978,
                            22.792483,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Xinyu",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Adm. av The Xinyu DiDemocratic Movement.<br><br>En grupp moris, The Xinyu DiDemocratic Movement, har instiftat en direktdemokrati i zonen där alla invånare, inklusive temporära besökare, uppmanas rösta i Lagren på 1T10 olika motioner varje dag. 10 € i böter utfärdas per motion som går obesvarad. Målet är att organisera områdets kontraktsslavar och giggare för bättre villkor.<br><br>Det är ett stort mysterium vilken sorts deal Songgang gjort med Gränskommandot för att kunna upprätthålla sin zon, särskilt då den ryktas innehålla celler av Longgang-gerillan. Gränskommandot har dock unilateralt rullat in i zonen nyligen i syfte att \"ta itu med terrorister\". ",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.000492,
                            22.2747565,
                            0
                        ],
                        [
                            113.9907501,
                            22.2742802,
                            0
                        ],
                        [
                            113.9856003,
                            22.2720164,
                            0
                        ],
                        [
                            113.9834546,
                            22.2685214,
                            0
                        ],
                        [
                            113.9867163,
                            22.2591482,
                            0
                        ],
                        [
                            113.9912652,
                            22.2560504,
                            0
                        ],
                        [
                            113.9922307,
                            22.2560505,
                            0
                        ],
                        [
                            114.0011786,
                            22.2571229,
                            0
                        ],
                        [
                            114.0087744,
                            22.262008,
                            0
                        ],
                        [
                            114.0089463,
                            22.2687994,
                            0
                        ],
                        [
                            114.005084,
                            22.2720163,
                            0
                        ],
                        [
                            114.000492,
                            22.2747565,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "MPDA Protectorate",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Adm. av: MPDA<br><br>Madagaskiska Plant och Drog-administra-<br>tionen har med hjälp av den madagaskiska<br>marinen tagit över denna perifera zon.<br>Det är ytterst oklart varför, och om de<br>har tillåtelse från Gränskommandot, men<br>trubbel lär det bli.",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.1438987,
                            22.3316545,
                            0
                        ],
                        [
                            114.1443064,
                            22.3339767,
                            0
                        ],
                        [
                            114.1445853,
                            22.3374104,
                            0
                        ],
                        [
                            114.1441991,
                            22.3387402,
                            0
                        ],
                        [
                            114.1433086,
                            22.3404372,
                            0
                        ],
                        [
                            114.141195,
                            22.3395539,
                            0
                        ],
                        [
                            114.1401329,
                            22.3398814,
                            0
                        ],
                        [
                            114.1393926,
                            22.3403875,
                            0
                        ],
                        [
                            114.1375656,
                            22.338939,
                            0
                        ],
                        [
                            114.1335532,
                            22.338092,
                            0
                        ],
                        [
                            114.1334781,
                            22.3369011,
                            0
                        ],
                        [
                            114.138113,
                            22.3336461,
                            0
                        ],
                        [
                            114.1438987,
                            22.3316545,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Lai Chi Kok",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Adm. av: Kichigai.<br><br>Synkargänget Kichigai bossar över zonen<br>och håller andra oogies i schack. Gator<br>stängs rutinmässigt av för extravaganta<br>streetraces som får imponerande stor<br>publik både på lagren och IRL. Deras<br>stora problem är att någon stulit ledaren<br>Burberry Fungs jetpack ...",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.1625834,
                            22.3452642,
                            0
                        ],
                        [
                            114.1620899,
                            22.3425452,
                            0
                        ],
                        [
                            114.1631199,
                            22.3420987,
                            0
                        ],
                        [
                            114.1645146,
                            22.3422227,
                            0
                        ],
                        [
                            114.1650296,
                            22.342962,
                            0
                        ],
                        [
                            114.1650511,
                            22.3435673,
                            0
                        ],
                        [
                            114.164815,
                            22.3446291,
                            0
                        ],
                        [
                            114.1638923,
                            22.3455222,
                            0
                        ],
                        [
                            114.1625834,
                            22.3452642,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Tai Woh Ping",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Adm. av Looksee Analytics.<br><br>Looksee Analytics äger Tai Woh Ping-tornen och bedriver utåt marknadsanalys<br>och AI-genererar reklam för sina kun-<br>der. Men alla inom som vet något om<br>hackingvärlden förstår att hackergruppen<br>die Kaos-Kontroll-Kabale har infiltrerat<br>hela zonen, eller kanske styr den helt<br>och hållet? Den lokala ledaren Kluger-<br>Hase drar i trådarna men vad hens sanna<br>identitet är förblir oklart. Hen kan vara<br>en av tornens många städare, CTO:n eller<br>någon menlös analytiker som gömmer sig<br>på 12:e våningen.",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.1813217,
                            22.333984,
                            0
                        ],
                        [
                            114.181665,
                            22.3390451,
                            0
                        ],
                        [
                            114.1792725,
                            22.3391443,
                            0
                        ],
                        [
                            114.1789506,
                            22.3340832,
                            0
                        ],
                        [
                            114.1813217,
                            22.333984,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Kowloon East Barracks",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Adm. av: Europeiska federationen.<br><br>Zonen, officiellt kallad EF-Hong Kong<br>Friend Zone, är ett officiellt protektorat<br>under Europeiska Federationen, givetvis<br>med Excos och Chongqing-falangens<br>godkännande. Här ryms beväpnade miljö-<br>inspektörer.",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.1984391,
                            22.3416655,
                            0
                        ],
                        [
                            114.2051768,
                            22.3378945,
                            0
                        ],
                        [
                            114.2085028,
                            22.3409312,
                            0
                        ],
                        [
                            114.207623,
                            22.3437097,
                            0
                        ],
                        [
                            114.2060458,
                            22.3477286,
                            0
                        ],
                        [
                            114.2036694,
                            22.3484827,
                            0
                        ],
                        [
                            114.202205,
                            22.3488003,
                            0
                        ],
                        [
                            114.2017141,
                            22.3486886,
                            0
                        ],
                        [
                            114.2010838,
                            22.3487854,
                            0
                        ],
                        [
                            114.1999734,
                            22.349088,
                            0
                        ],
                        [
                            114.1983426,
                            22.3490037,
                            0
                        ],
                        [
                            114.1981816,
                            22.3489045,
                            0
                        ],
                        [
                            114.1981119,
                            22.3488647,
                            0
                        ],
                        [
                            114.1980287,
                            22.3487358,
                            0
                        ],
                        [
                            114.1979295,
                            22.3484579,
                            0
                        ],
                        [
                            114.1975299,
                            22.3462178,
                            0
                        ],
                        [
                            114.1974628,
                            22.3439677,
                            0
                        ],
                        [
                            114.1988897,
                            22.3437792,
                            0
                        ],
                        [
                            114.1984391,
                            22.3416655,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Diamond Hill",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Adm. av: Vrakare<br><br>Diamond Hill var tidigare uppdelat i flera<br>zoner men markplanet är nedgånget och sedan länge övergivet av korparna<br>som äger skraporna i zonen. De kommer<br>ned ibland för att putsa upp sina autotur-<br>rets och laga söndermöglad betong men<br>håller sig annars borta. Markplanet här<br>och de första tre våningarna i skraporna<br>har blivit sin egna zon och styrs av tre oli-<br>ka klaner av vrakare som i samråd sköter<br>zonen, dess cert och dess invånare.",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.2031349,
                            22.3910389,
                            0
                        ],
                        [
                            114.195496,
                            22.3838765,
                            0
                        ],
                        [
                            114.1903462,
                            22.37844,
                            0
                        ],
                        [
                            114.1871705,
                            22.3766939,
                            0
                        ],
                        [
                            114.1923632,
                            22.3712571,
                            0
                        ],
                        [
                            114.2001521,
                            22.3748686,
                            0
                        ],
                        [
                            114.207684,
                            22.3786384,
                            0
                        ],
                        [
                            114.2107149,
                            22.3812079,
                            0
                        ],
                        [
                            114.2112561,
                            22.3823784,
                            0
                        ],
                        [
                            114.2107453,
                            22.3834523,
                            0
                        ],
                        [
                            114.2098482,
                            22.3841889,
                            0
                        ],
                        [
                            114.207024,
                            22.3867335,
                            0
                        ],
                        [
                            114.2031349,
                            22.3910389,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Sha Tin Reclamation Zone",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Adm av: Retake the Mainland.<br><br>Denna gräsrotsorganisation som kämpar<br>mot Chongqing-falangen har helt infes-<br>terat zonen och åtnjuter stort folkligt<br>stöd. De finns sannolikt i zonen för att<br>kunna stoppa viktiga transporter till/från<br>mainland eller för att bygga opinion allt<br>närmare gränsen. De är ganska harmlösa<br>och söker mest sprida budskapet på virren<br>eller med sina karaktäristiska propaganda-<br>paraplyn av displayväv.",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.1982974,
                            22.4388178,
                            0
                        ],
                        [
                            114.1937484,
                            22.4329471,
                            0
                        ],
                        [
                            114.197525,
                            22.408708,
                            0
                        ],
                        [
                            114.2050352,
                            22.4034311,
                            0
                        ],
                        [
                            114.213983,
                            22.4109694,
                            0
                        ],
                        [
                            114.2165793,
                            22.4273935,
                            0
                        ],
                        [
                            114.1982974,
                            22.4388178,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Independent University",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Adm. av Guillermo Tsai-Cooper.<br><br>En rik och välmenande men något social-<br>darwinistisk daiban och markägare bossar<br>över zonen. Hen betalar 1000 € för sper-<br>ma eller ägg från peeps med mycket bra<br>gener (slå ditt högsta attribut utan edge<br>mot 24). Hen betalar lika mycket till alla<br>andra för att de skall sterilisera sig perma-<br>nent. Inget krav, bara ett vänligt förslag.<br><br>Alla vapen är förbjudna i zonen! ",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.182121,
                            22.2697275,
                            0
                        ],
                        [
                            114.1822282,
                            22.2666099,
                            0
                        ],
                        [
                            114.1844598,
                            22.266173,
                            0
                        ],
                        [
                            114.185404,
                            22.2637504,
                            0
                        ],
                        [
                            114.1876785,
                            22.2628766,
                            0
                        ],
                        [
                            114.1893147,
                            22.2631149,
                            0
                        ],
                        [
                            114.1901488,
                            22.2631794,
                            0
                        ],
                        [
                            114.1904466,
                            22.2633929,
                            0
                        ],
                        [
                            114.1903661,
                            22.265607,
                            0
                        ],
                        [
                            114.1900173,
                            22.268119,
                            0
                        ],
                        [
                            114.190425,
                            22.2689108,
                            0
                        ],
                        [
                            114.1910044,
                            22.2694246,
                            0
                        ],
                        [
                            114.1911975,
                            22.2705714,
                            0
                        ],
                        [
                            114.1918841,
                            22.2714401,
                            0
                        ],
                        [
                            114.1920129,
                            22.2726067,
                            0
                        ],
                        [
                            114.19197,
                            22.2747513,
                            0
                        ],
                        [
                            114.1914872,
                            22.2762405,
                            0
                        ],
                        [
                            114.1895775,
                            22.2771638,
                            0
                        ],
                        [
                            114.1883008,
                            22.278385,
                            0
                        ],
                        [
                            114.1873352,
                            22.2785041,
                            0
                        ],
                        [
                            114.1855757,
                            22.2784247,
                            0
                        ],
                        [
                            114.1829763,
                            22.2773223,
                            0
                        ],
                        [
                            114.1807202,
                            22.2762992,
                            0
                        ],
                        [
                            114.1794816,
                            22.2740761,
                            0
                        ],
                        [
                            114.182121,
                            22.2697275,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Happy Valley",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Adm. av Happy Valley Common Venture.<br><br>Westover, Dragon Industries, Worldview,<br>88K-triaden och Yamamoto-yakuzan höll<br>länge föredömligt sams i egenskap av<br>Happy Valleys dominerande aktörer, men<br>sprickor har börjat uppstå. Dels är halva<br>undre världen i öppet krig mot Wo Hop<br>To-triaden och bratvan och dels har en<br>last med ovärderliga kapplöpningshästar,<br>däribland Westover-bossen Lord Goodwins egen Twisted Tango Foxtrot, nyligen försvunnit. Relationerna mellan Happy Valleys megakorpar har surnat till nivån att det talas om företagskrig.<br><br>Inga drönare tillåtna i zonen!",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.1679272,
                            22.2774101,
                            0
                        ],
                        [
                            114.1719279,
                            22.2750234,
                            0
                        ],
                        [
                            114.1724644,
                            22.2759567,
                            0
                        ],
                        [
                            114.172797,
                            22.2765325,
                            0
                        ],
                        [
                            114.1748998,
                            22.2766914,
                            0
                        ],
                        [
                            114.175978,
                            22.2774062,
                            0
                        ],
                        [
                            114.1772387,
                            22.2779721,
                            0
                        ],
                        [
                            114.1794274,
                            22.2786472,
                            0
                        ],
                        [
                            114.1806397,
                            22.2762645,
                            0
                        ],
                        [
                            114.1817234,
                            22.2767311,
                            0
                        ],
                        [
                            114.1836868,
                            22.2776296,
                            0
                        ],
                        [
                            114.1820667,
                            22.2810696,
                            0
                        ],
                        [
                            114.1787837,
                            22.2835019,
                            0
                        ],
                        [
                            114.1771315,
                            22.2840082,
                            0
                        ],
                        [
                            114.1755007,
                            22.2846535,
                            0
                        ],
                        [
                            114.1741381,
                            22.2855768,
                            0
                        ],
                        [
                            114.1714613,
                            22.2851648,
                            0
                        ],
                        [
                            114.1699861,
                            22.283482,
                            0
                        ],
                        [
                            114.1687381,
                            22.2828326,
                            0
                        ],
                        [
                            114.1674936,
                            22.2780275,
                            0
                        ],
                        [
                            114.167882,
                            22.2779968,
                            0
                        ],
                        [
                            114.1679272,
                            22.2774101,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Wan Chai",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Adm. av Sumitomo Group.<br><br>Zonen ägs indirekt av den japanska finans-<br>giganten Sumitomo, och huserar skrapor åt många japanska företag. I praktiken är den infesterad av triaderna och yakuzan, som dagligen battlar bland Wan Chais nedgångna skysrapor, murriga temabarer och skumma bordeller. <br><br>Wanchai har en extra 5 % skatt på alla varor<br>och tjänster. Pengarna går till att sponsra<br>högervridna tankesmedjor.<br>Alla besökare i zonen måste betala 1T10<br>× 10 € till yakuzan för att inte riskera att<br>råka ut för en »olycka«.",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.2657948,
                            22.311808,
                            0
                        ],
                        [
                            114.2621047,
                            22.3000001,
                            0
                        ],
                        [
                            114.2655809,
                            22.2955133,
                            0
                        ],
                        [
                            114.2662675,
                            22.2913043,
                            0
                        ],
                        [
                            114.2672009,
                            22.2910859,
                            0
                        ],
                        [
                            114.2690134,
                            22.2906351,
                            0
                        ],
                        [
                            114.2722535,
                            22.2901586,
                            0
                        ],
                        [
                            114.2740244,
                            22.2897159,
                            0
                        ],
                        [
                            114.2736539,
                            22.2922105,
                            0
                        ],
                        [
                            114.2738628,
                            22.2923525,
                            0
                        ],
                        [
                            114.2696571,
                            22.3053761,
                            0
                        ],
                        [
                            114.2657948,
                            22.311808,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Lohas park",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Adm. av Progenex.<br><br>En av de första megakorparna att bevaka<br>sina intressen i deltat. Progenex-zoner kan<br>lätt ses på avstånd, då de regelmässigt har<br>murar, taggtråd, checkpoints och mycket<br>annat. Därinne får boende och certifiera-<br>de besökare tillgång till premiumsjukvård,<br>dietist, bostad och mycket annat.<br><br>Utegångsförbud 23.00–06.00.<br>Alla besökare måste skriva under ett<br>non-disclosure agreement gällande allt de<br>ser i zonen.",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.2434787,
                            22.2744129,
                            0
                        ],
                        [
                            114.2420625,
                            22.2723776,
                            0
                        ],
                        [
                            114.2404532,
                            22.2701635,
                            0
                        ],
                        [
                            114.2331791,
                            22.2711762,
                            0
                        ],
                        [
                            114.2300892,
                            22.2649608,
                            0
                        ],
                        [
                            114.2292523,
                            22.2634517,
                            0
                        ],
                        [
                            114.2289948,
                            22.2627963,
                            0
                        ],
                        [
                            114.2293167,
                            22.2624786,
                            0
                        ],
                        [
                            114.2323207,
                            22.2570771,
                            0
                        ],
                        [
                            114.2344728,
                            22.2518863,
                            0
                        ],
                        [
                            114.2482057,
                            22.252363,
                            0
                        ],
                        [
                            114.2512956,
                            22.2485499,
                            0
                        ],
                        [
                            114.2547686,
                            22.2479082,
                            0
                        ],
                        [
                            114.25704,
                            22.2525889,
                            0
                        ],
                        [
                            114.2583703,
                            22.2608502,
                            0
                        ],
                        [
                            114.257555,
                            22.2631538,
                            0
                        ],
                        [
                            114.2513751,
                            22.2694288,
                            0
                        ],
                        [
                            114.2454099,
                            22.2748298,
                            0
                        ],
                        [
                            114.2434787,
                            22.2744129,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Chai Wan",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Adm. av Chai Wans medborgargarde.<br><br>Efter en tids brottslighet som secokorpar-<br>na inte sett någon vinning i att stoppa har<br>en grupp förbannade och bittra med-<br>borgargardister tagit över zonen. Chai Wans medborgargarde domineras av stockkonservativa analoger och \"\"humyn born humyn\"-nomos, och har nyligen kört ut alla augs ur zonen - trots protester från EXCO och Andorras ambassad.<br><br>Augs är inte längre välkomna i zonen. Misstänkta augs kan gripas och tvingas genomgå mer eller mindre förnedrande och tvivelaktiga undersökningar.",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.1428218,
                            22.2447694,
                            0
                        ],
                        [
                            114.1439376,
                            22.2420683,
                            0
                        ],
                        [
                            114.1527782,
                            22.2332497,
                            0
                        ],
                        [
                            114.1585289,
                            22.2276881,
                            0
                        ],
                        [
                            114.1634212,
                            22.2274498,
                            0
                        ],
                        [
                            114.1628204,
                            22.2342825,
                            0
                        ],
                        [
                            114.1641078,
                            22.2367454,
                            0
                        ],
                        [
                            114.1618762,
                            22.2370632,
                            0
                        ],
                        [
                            114.1619621,
                            22.2392083,
                            0
                        ],
                        [
                            114.160503,
                            22.2426244,
                            0
                        ],
                        [
                            114.1605888,
                            22.2451666,
                            0
                        ],
                        [
                            114.1574989,
                            22.2464377,
                            0
                        ],
                        [
                            114.1546665,
                            22.2455638,
                            0
                        ],
                        [
                            114.1502891,
                            22.2463582,
                            0
                        ],
                        [
                            114.1466617,
                            22.2456278,
                            0
                        ],
                        [
                            114.1428218,
                            22.2447694,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Freedom Island",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Adm. av The Anti-Freak Legion of Hong Kong. <br><br>AFLHK har deklarerat området som ett<br>av sina safe spaces mot freaksen. Vid<br>zongränserna hänger kapade kroppsdelar<br>från påstådda freaks. Alla peeps med<br>moddar som är #Bizarro eller på annat sätt<br>inte ser tillräckligt trad ut nekas inträde i<br>zonen eller blir förvandlade till eftermid-<br>dagens target practice.<br><br>AFLHK har även instiftat utegångsförbud inom semi-random intervaller, i regel på natten, då de går på freaks-jakt. Zonen har ett dedikerat lager för att hjälpa peeps hålla koll.",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.1693287,
                            22.3192687,
                            0
                        ],
                        [
                            114.1706596,
                            22.312941,
                            0
                        ],
                        [
                            114.1715823,
                            22.3131593,
                            0
                        ],
                        [
                            114.1740284,
                            22.3164546,
                            0
                        ],
                        [
                            114.1726766,
                            22.3199681,
                            0
                        ],
                        [
                            114.1693287,
                            22.3192687,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Mong Kok Commercial Association",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Adm. av Yoshi Sotomayor. <br><br>På pappret styrs zonen av Mong Kok Commercial Association, men i själva verket drar entreprenören och mori-fanatikern Yoshi Sotomayor i spakarna. Han har extremt kort tålamod och låter gladeligen inhyrda secos gå medeltida på peeps vid minsta provokation. Fantasifulla tortyravrättningar av påstådda omegas sänds på publika lager, vilket oväntat blivit en hit som reality-stream i amerikanska och europeiska TV-soffor. Den lokala befolk-<br>ningen är inte lika road.",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.2248186,
                            22.2387613,
                            0
                        ],
                        [
                            114.2227586,
                            22.2362985,
                            0
                        ],
                        [
                            114.223574,
                            22.2324253,
                            0
                        ],
                        [
                            114.2259987,
                            22.2292671,
                            0
                        ],
                        [
                            114.2297753,
                            22.2291082,
                            0
                        ],
                        [
                            114.2313417,
                            22.2319685,
                            0
                        ],
                        [
                            114.2287453,
                            22.2388208,
                            0
                        ],
                        [
                            114.225355,
                            22.239079,
                            0
                        ],
                        [
                            114.2248186,
                            22.2387613,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Poly Research Zone",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Adm. av Poly Synapse Labs.<br><br>Zonen domineras av detta topphemliga<br>R&D-labb som utför jobb åt Genetica.<br>Tillträde är strikt förbjudet och labbet är<br>omringat av elstängsel, sentrytorn, aggro<br>secos samt ett par ton landminor.",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.2396157,
                            22.280235,
                            0
                        ],
                        [
                            114.2368476,
                            22.2786267,
                            0
                        ],
                        [
                            114.2360752,
                            22.2776538,
                            0
                        ],
                        [
                            114.2362897,
                            22.2767602,
                            0
                        ],
                        [
                            114.2381995,
                            22.2743179,
                            0
                        ],
                        [
                            114.2387359,
                            22.2724315,
                            0
                        ],
                        [
                            114.2379849,
                            22.2704855,
                            0
                        ],
                        [
                            114.2404532,
                            22.2701635,
                            0
                        ],
                        [
                            114.2434787,
                            22.2744129,
                            0
                        ],
                        [
                            114.2418687,
                            22.2791429,
                            0
                        ],
                        [
                            114.2398946,
                            22.2804336,
                            0
                        ],
                        [
                            114.2396157,
                            22.280235,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Interworld Depots",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Adm. av Interworld.<br><br>En ganska färglös zon fylld av fabriker och InterWorld-depåer. Har dock även en spektakulär klättrar-koloni på insidan av en helautomatisk HSTC-fabrik som tillverkar maskindelar. Autonerna är inte programmerade att reagera på klättrarna som rör sig bland takbjälkarna högt ovan, och låter dem därför hållas.",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.230466,
                            22.2752206,
                            0
                        ],
                        [
                            114.2360752,
                            22.2776538,
                            0
                        ],
                        [
                            114.2368476,
                            22.2786267,
                            0
                        ],
                        [
                            114.2398946,
                            22.2804336,
                            0
                        ],
                        [
                            114.234779,
                            22.2848506,
                            0
                        ],
                        [
                            114.2337061,
                            22.2843741,
                            0
                        ],
                        [
                            114.2328907,
                            22.2838181,
                            0
                        ],
                        [
                            114.2278696,
                            22.283421,
                            0
                        ],
                        [
                            114.2281486,
                            22.2795889,
                            0
                        ],
                        [
                            114.2282344,
                            22.2766105,
                            0
                        ],
                        [
                            114.230466,
                            22.2752206,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Shau Kei Wan",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Adm. av Nanhai-triaden.<br><br>Den mäktiga taiwanesiska Nanhai-triaden<br>kontrollerar zonen via sina proxys Esquel Group och Chun Kee Noodle Factory, som tillsammans på pappret äger 86% av Shau Kei Wan. Journalister tror att Nanhai använder zonen som en vital länk i sitt nätverk för att smuggla slavar över Stilla havet, men ingen utomstående tycks våga nysta i saken, eller för den delen ingripa. Den enda aktör som står emot Nanhai är Sin Ka Ju Printing Svcs Ltd, men de äger bara 14% av zonen, så vad kan de göra? <br><br>.",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.2287233,
                            22.2975726,
                            0
                        ],
                        [
                            114.2369738,
                            22.2898396,
                            0
                        ],
                        [
                            114.2376068,
                            22.2905841,
                            0
                        ],
                        [
                            114.2410217,
                            22.2948074,
                            0
                        ],
                        [
                            114.2443725,
                            22.2991697,
                            0
                        ],
                        [
                            114.2425807,
                            22.3015917,
                            0
                        ],
                        [
                            114.2405658,
                            22.3030762,
                            0
                        ],
                        [
                            114.2394189,
                            22.3041758,
                            0
                        ],
                        [
                            114.2386153,
                            22.3046401,
                            0
                        ],
                        [
                            114.2359758,
                            22.3019204,
                            0
                        ],
                        [
                            114.2287233,
                            22.2975726,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Yau Tong Residential Zone",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Adm. av Zau Man-Yuk (återuppväckt).<br><br>Zonen har styrts av samma älskade<br>lingdao sedan kollapsen, men för något<br>år sedan avled hon i sviterna av en förgiftad durian-glass. Nu styrs zonen av<br>en AI-version av lingdaon. Med hjälp av<br>teknik från Nexus baseras dess beteende<br>på de strömmar och vids som Zau Man-Yuk<br>lämnade efter sig. Zonens nej-sägare menar att AI:n saknar äkta intelligens och medvetande,<br>men majoriteten av befolkningen älskar fortfarande sin ledare, till den grad att AI:n är uppvaktad av ett antal unga peeps som vill gifta sig med den.",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.2225365,
                            22.3054151,
                            0
                        ],
                        [
                            114.2287233,
                            22.2975726,
                            0
                        ],
                        [
                            114.2359758,
                            22.3019204,
                            0
                        ],
                        [
                            114.2366342,
                            22.3026854,
                            0
                        ],
                        [
                            114.2299219,
                            22.310426,
                            0
                        ],
                        [
                            114.2225365,
                            22.3054151,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Kowloon Commerce Zone",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Adm. av EuroProducts (55%), KenSir 24h Tuition Centre (25%), Daewoo Dojos (11%), EuroFitness (6%), Candid Cantonese Tailors (3%). <br><br>Zonen tycks användas som en testbädd av EuroProducts, EuroFitness och Candid Cantonese Tailors-agenter, då nya kläder konstant är på rea och astroturfers, brand managers och försäljare ständigt försöker ta pulsen på vad som trendar, och köra det mot Consorcio-keiretsuns allsmäktiga statistik-algoritmer. ",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.0548282,
                            22.35531,
                            0
                        ],
                        [
                            114.0525108,
                            22.3479275,
                            0
                        ],
                        [
                            114.0578323,
                            22.3411003,
                            0
                        ],
                        [
                            114.061523,
                            22.3414972,
                            0
                        ],
                        [
                            114.0677028,
                            22.3457047,
                            0
                        ],
                        [
                            114.0642696,
                            22.3513409,
                            0
                        ],
                        [
                            114.0684753,
                            22.3542781,
                            0
                        ],
                        [
                            114.0628963,
                            22.3574532,
                            0
                        ],
                        [
                            114.0553432,
                            22.3565007,
                            0
                        ],
                        [
                            114.0548282,
                            22.35531,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Park Island Beauty Zone",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Adm. av alter-gänget Fēicháng Míngrén.<br><br>Park Island domineras av motorvägsbron<br>som passerar över den. Zonen byggdes<br>som ett billigare alternativ till andra<br>strandnära expatparker, men är nu svårt<br>nedgånget. Tillräde till zonen går bara<br>genom båt eller den enda välbevakade<br>motorvägsavfarten. Zonens fysiska och<br>virtuella rum är flitigt utsmyckade med<br>hyllningar till vackra celebs, som zonens idoldyrkande plexizens ständigt uppdaterar. Fula peeps riskerar att avvisas om de uppehåller sig i zonen, eller, om de har tur, utsättas för en obligatorisk makeover från en legion av entusiastiska Azam, Z.Y, 可爱-chan och Azadeh-lookalikes. ",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.1942266,
                            22.220433,
                            0
                        ],
                        [
                            114.196241,
                            22.2163097,
                            0
                        ],
                        [
                            114.1949535,
                            22.2107475,
                            0
                        ],
                        [
                            114.1995025,
                            22.2091185,
                            0
                        ],
                        [
                            114.2024637,
                            22.2113037,
                            0
                        ],
                        [
                            114.2071844,
                            22.2124162,
                            0
                        ],
                        [
                            114.2110039,
                            22.2173824,
                            0
                        ],
                        [
                            114.2050413,
                            22.2233332,
                            0
                        ],
                        [
                            114.1942266,
                            22.220433,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Nordic Light Exclave",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Adm. av Nordic Light Exclaves.<br><br>Miljögruppen NLE har tagit kontrollen<br>över zonen, sponsrad av skandinaviska<br>staten. Målet är att bygga upp zonen efter<br>den skandinaviska modellen och sprida<br>denna till angränsande zoner med hjälp<br>av aktivism. Nedskräpning är ett brott<br>och enklaven har byggt ett imponerande<br>system av dammar och pumpar för att<br>motverka översvämningar. Hurtigheten<br>förefaller obligatorisk, medan den militära<br>närvaron är minimal.",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            113.2184124,
                            23.1489341,
                            0
                        ],
                        [
                            113.2158375,
                            23.1492497,
                            0
                        ],
                        [
                            113.2112884,
                            23.1277818,
                            0
                        ],
                        [
                            113.2116318,
                            23.1220986,
                            0
                        ],
                        [
                            113.2157516,
                            23.1176781,
                            0
                        ],
                        [
                            113.2245922,
                            23.1124681,
                            0
                        ],
                        [
                            113.2295704,
                            23.1134548,
                            0
                        ],
                        [
                            113.2252693,
                            23.1204694,
                            0
                        ],
                        [
                            113.2256222,
                            23.128729,
                            0
                        ],
                        [
                            113.2273293,
                            23.1383079,
                            0
                        ],
                        [
                            113.2197761,
                            23.1444641,
                            0
                        ],
                        [
                            113.2184124,
                            23.1489341,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Datansha",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Adm av: Canaan Liberation Group (i teorin), Whatever Wu och hans nihilo-hackers (i praktiken). <br><br>Ingen tycks orka göra anspråk på Datansha,<br>kanske för att det finns ingenting av<br>intresse här. Zonen blev mer eller mindre jämnad med marken av klusterbomber under Kollapsen, och väldigt lite har byggts. De som inte lyckats flytta har gett upp för länge sedan och den glåmiga nihilo-subkulturen har tagit över helt. De undermåliga hus som byggdes i något välgörenhetsprojekt på 2050-talet håller redan på att förfalla, men ingen verkar bry<br>sig. Vissa är snarare belåtna över att deras<br>nihilism/cynism visat sig befogad. En liten cirkel av hacking-kompetenta nihilos, ledda av analogen Whatever Wu, har i praktiken tagit makten genom att kontrollera allas Lager-access. Inte för att alla orkar bry sig... ",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            113.1791834,
                            23.1275622,
                            0
                        ],
                        [
                            113.1784967,
                            23.1268519,
                            0
                        ],
                        [
                            113.1759217,
                            23.1165902,
                            0
                        ],
                        [
                            113.1736043,
                            23.1109853,
                            0
                        ],
                        [
                            113.1736043,
                            23.1074329,
                            0
                        ],
                        [
                            113.1744626,
                            23.1037224,
                            0
                        ],
                        [
                            113.1750635,
                            23.1027751,
                            0
                        ],
                        [
                            113.1900839,
                            23.1030909,
                            0
                        ],
                        [
                            113.1995252,
                            23.1025382,
                            0
                        ],
                        [
                            113.1975511,
                            23.1105117,
                            0
                        ],
                        [
                            113.1977228,
                            23.1210107,
                            0
                        ],
                        [
                            113.1924871,
                            23.1210896,
                            0
                        ],
                        [
                            113.1868223,
                            23.125431,
                            0
                        ],
                        [
                            113.1791834,
                            23.1275622,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Huangqi Industrial Zone",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Zonen har länge varit en industriell zon<br>och har till största delen fredats i de olika<br>konflikterna i området. Det sägs bero på<br>att zonen, som ägs av Yumacom i joint<br>venture med ett flertal lokala korpar,<br>betalar strida strömmar mutor både till<br>Chongqing och till Canaan Market. <br><br>Trots att zonen är vida beryktad som astråkig, och mestadels befolkad av industrirobotar och deras servicetekniker, finns en mystisk och extremt koolu pop-up club som hålls i gamla industrilokaler här en gång i kvartalet, i regi av mikrokorparna TaiKawaii Styleista och Angel Ball Creative. Den drar då många shapers och vCores till zonen. ",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.2583074,
                            22.3150324,
                            0
                        ],
                        [
                            114.2486085,
                            22.3113402,
                            0
                        ],
                        [
                            114.2426343,
                            22.3045299,
                            0
                        ],
                        [
                            114.2573114,
                            22.2996462,
                            0
                        ],
                        [
                            114.2621047,
                            22.3000001,
                            0
                        ],
                        [
                            114.2644215,
                            22.3074804,
                            0
                        ],
                        [
                            114.2625662,
                            22.311177,
                            0
                        ],
                        [
                            114.2602652,
                            22.3135017,
                            0
                        ],
                        [
                            114.2583074,
                            22.3150324,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Tseung Kwan O",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Adm. av Ka Shing Estate (93%), CO2 Inspection Consultants (5%), MediPlant 48h Clinic (2%). <br><br>Zonen ägs av stiftelsen som förvaltar den<br>legendariska sekelskiftesmiljardären Li Ka<br>Shings tillgångar. Som bekant är alla ätt-<br>lingar döda; de som inte tagit livet av sig<br>eller överdoserat strök med i Kollapsen.<br>Men stiftelsen, fortfarande styrd enligt Ka<br>Shings invecklade instruktioner, fortsätter<br>att köpa, sälja och expandera. Det får en<br>att undra om den där brända kroppen<br>tillhör släkten som det ryktats, och vem<br>som begick mordet?",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.0266753,
                            22.3348017,
                            0
                        ],
                        [
                            114.0330267,
                            22.3251156,
                            0
                        ],
                        [
                            114.0356125,
                            22.3263309,
                            0
                        ],
                        [
                            114.0549248,
                            22.3239876,
                            0
                        ],
                        [
                            114.0614367,
                            22.3313084,
                            0
                        ],
                        [
                            114.0578323,
                            22.3411003,
                            0
                        ],
                        [
                            114.0519953,
                            22.3484564,
                            0
                        ],
                        [
                            114.0418673,
                            22.3486945,
                            0
                        ],
                        [
                            114.0266753,
                            22.3348017,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Lantau Interchange",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Adm. av Lantau Motorways (på pappret) EuroCore Black Ops (i praktiken).<br><br>Ett team av elita kommandosoldater<br>från Eurocore har diskret tagit hela zonen i temporär besittning, från den i princip defuncta mikrokorpen Lantau Motorways. Varför är oklart, liksom huruvida Gränskommandot planerar att ingripa, men en teori är att det är på grund av Madagaskars flottmanövrar utanför södra Lantau. Kommandosoldaterna klär sig i Sentry-uniformer men har fan så mycket mer ultra eqip. Är du i vägen lär du hamna i en liksäck, men de tar också hjälp av lokala européer för vissa sysslor. ",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.1309279,
                            22.2896596,
                            0
                        ],
                        [
                            114.1331595,
                            22.2871579,
                            0
                        ],
                        [
                            114.1356486,
                            22.2866417,
                            0
                        ],
                        [
                            114.1404551,
                            22.2877535,
                            0
                        ],
                        [
                            114.1423005,
                            22.2876741,
                            0
                        ],
                        [
                            114.1412705,
                            22.291367,
                            0
                        ],
                        [
                            114.1340822,
                            22.2901956,
                            0
                        ],
                        [
                            114.1309279,
                            22.2896596,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Shek Tong Tsui",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Adm. av Cadwalader-Wickersham-Taft & Zhou (50%), Blue Apsara Beauty (50%), Los Cárteles (i praktiken). <br><br>Det stenhårda dödläget i den långvariga maktkampen mellan zonens två korpar tycks ha gett kartellerna, samlingsnamnet för sydame-<br>rikanska OC:s, den möjlighet att etablera sig i HK som de länge suktat efter. La Unión-kartellens fotsoldater tar ut hänsynslösa beskyddaravgifter, och syns plötsligt överallt. Journalister på HK OC Review-Journal tror att de på rekordtid infiltrerat bägge företagen. <br> Kanske de förbereder och säkrar upp för en stor leverans av kräm till Chongqing.",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.1889281,
                            22.3247736,
                            0
                        ],
                        [
                            114.1878553,
                            22.3227886,
                            0
                        ],
                        [
                            114.1918357,
                            22.3210022,
                            0
                        ],
                        [
                            114.1935094,
                            22.3203422,
                            0
                        ],
                        [
                            114.1936381,
                            22.3203372,
                            0
                        ],
                        [
                            114.1945839,
                            22.3223105,
                            0
                        ],
                        [
                            114.189992,
                            22.3244343,
                            0
                        ],
                        [
                            114.1889281,
                            22.3247736,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Westover Kowloon Properties",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Adm. av Westover<br><br>Zonen kontrolleras av bostads- och<br>försäkringsbolaget Westover. Det är en<br>ren och välskött zon för kommersiella<br>ändamål, där norms äter söndagsbrunch<br>eller går i boutiquer. Dock är luften dålig, och alla i zonen måste använda Chang-Hwas<br>artificiella luftvägsmembran eller någon<br>annan typ av certifierat andningsfilter.<br>Byråkratin kring detta tar typiskt 2T10 minuter, om ens andningsfilter är i ordning.<br><br>Zonen förbjuder även förarstyrda fordon, så blockers göre sig icke besvär!",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.2023443,
                            22.4590598,
                            0
                        ],
                        [
                            114.2070007,
                            22.4530015,
                            0
                        ],
                        [
                            114.2079019,
                            22.4486386,
                            0
                        ],
                        [
                            114.2153263,
                            22.4462192,
                            0
                        ],
                        [
                            114.2213344,
                            22.4469728,
                            0
                        ],
                        [
                            114.2221069,
                            22.4499872,
                            0
                        ],
                        [
                            114.2217206,
                            22.4546673,
                            0
                        ],
                        [
                            114.2192316,
                            22.4576816,
                            0
                        ],
                        [
                            114.2088675,
                            22.4613006,
                            0
                        ],
                        [
                            114.2061209,
                            22.4608545,
                            0
                        ],
                        [
                            114.2023443,
                            22.4590598,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Chemcorp Environmental Zone",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "ChemCorp har en CHOO-fabrik i zonen,<br>där de raffinerar genetiskt modifierade<br>alger till bränslet som driver mondes flyg-<br>och sjöfart. Zonen är ett industrikomplex<br>med en konstant ström av självkörande<br>lastbilar, algstank, kemister och lågavlö-<br>nade logistikarbetare. Många av områdets<br>fiskare extraknäcker med att odla och<br>samla in alger till ChemCorp.<br><br>I linje med företagets europeiska profil är endast #Eurostyle-kläder tillåtna i zonen. ",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.0203234,
                            22.3757846,
                            0
                        ],
                        [
                            114.0174481,
                            22.3726098,
                            0
                        ],
                        [
                            114.0238426,
                            22.3709629,
                            0
                        ],
                        [
                            114.0268037,
                            22.3707446,
                            0
                        ],
                        [
                            114.0272758,
                            22.3732051,
                            0
                        ],
                        [
                            114.0269325,
                            22.3734036,
                            0
                        ],
                        [
                            114.0256879,
                            22.374237,
                            0
                        ],
                        [
                            114.0236708,
                            22.3749116,
                            0
                        ],
                        [
                            114.020023,
                            22.375864,
                            0
                        ],
                        [
                            114.0203234,
                            22.3757846,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Tai Lam Free Zone",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Adm. av Röd gryning.<br><br>Den ökända sibiriskstödda upprorsrörelsen/terrororganisationen Röd gryning opererar i zonen, att döma av<br>virrpamfletterna. Gränskommandot eller<br>DDW lär vara på ingång, för sibirisk infosocialism har man inget till övers för. Röd gryning har det övergripande målet att hämnas på kapitalister, revisionister och femtekolonnare.",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            113.4710904,
                            22.287058,
                            0
                        ],
                        [
                            113.4601041,
                            22.2783217,
                            0
                        ],
                        [
                            113.451521,
                            22.2878522,
                            0
                        ],
                        [
                            113.4449979,
                            22.2795925,
                            0
                        ],
                        [
                            113.4420796,
                            22.2657722,
                            0
                        ],
                        [
                            113.462679,
                            22.2424176,
                            0
                        ],
                        [
                            113.4760686,
                            22.2363797,
                            0
                        ],
                        [
                            113.4831067,
                            22.2471842,
                            0
                        ],
                        [
                            113.4973546,
                            22.258941,
                            0
                        ],
                        [
                            113.4710904,
                            22.287058,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Tanzhou",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Adm. av Tanzhous folkkommun.<br><br>En av Pärldeltats märkligaste zoner. Nästan alltid översvämmad, och består mest av gyttja och vattensjuka ruiner. Icke desto mindre har en kommunistisk folkkommun klamrat sig fast vid övervåningarna på sina sönderfallande hus, och på pråmar och flatbottnade båtar i den kletiga mixen av lera, vatten och industriavfall. Den<br>neo-kommunistisk rörelsen är entusiastisk och välorganiserad, och byledaren var nyligen på kongress i Vladivostok, men, föga förvånande, lider de av invånarbrist i sin zon, som alla mentalt sunda peeps helst flyttar ifrån så fort de får möjlighet. Därför har de lanserat stora, mycket iögonfallande och väldigt ambitiösa reklamkampanjer längst den närbelägna expressvägen, för att få nyanlända att vilja slå ned sina<br>bopålar i gyttjan.",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            113.3154971,
                            23.0956279,
                            0
                        ],
                        [
                            113.3161837,
                            23.0833898,
                            0
                        ],
                        [
                            113.3148104,
                            23.0776257,
                            0
                        ],
                        [
                            113.3317191,
                            23.0781785,
                            0
                        ],
                        [
                            113.3322341,
                            23.0867061,
                            0
                        ],
                        [
                            113.3330065,
                            23.090338,
                            0
                        ],
                        [
                            113.3324057,
                            23.0934962,
                            0
                        ],
                        [
                            113.3336074,
                            23.0960226,
                            0
                        ],
                        [
                            113.3154971,
                            23.0956279,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Red Hill",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Adm. av Emperor Security.<br><br>Denna zon, nära Canaan Market, används<br>som Emperor Security’s träningsläger och<br>kontorsområde. Området har flera tempel<br>och historiska byggnader, vilka Emperor<br>Security bara förstärkt i sin arkitektur och<br>formspråk. Det gäller att känna historiens<br>vingslag när man tränar peeps att stå i<br>vaktkurer!",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.1903147,
                            22.3088426,
                            0
                        ],
                        [
                            114.1875896,
                            22.3088228,
                            0
                        ],
                        [
                            114.1874394,
                            22.3079691,
                            0
                        ],
                        [
                            114.1866455,
                            22.3067582,
                            0
                        ],
                        [
                            114.1888771,
                            22.3055472,
                            0
                        ],
                        [
                            114.1900636,
                            22.307243,
                            0
                        ],
                        [
                            114.1901709,
                            22.3086921,
                            0
                        ],
                        [
                            114.1903147,
                            22.3088426,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Tai Wan",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Adm. av Kevin Koo (57%), Pearl Bridge Partners Ltd (22%), Millenia Surveillance Electronics Inc (21%).<br><br>Zonen är ett vanligt tillhåll för clubber-gänget Kevin’s Posse som brukar anordna vilda, extravaganta och krämfyllda fester. På senare tid har stämningen dämpats då allt fler paralyserats av Droll-beroende. Inte lätt att anordna fester, än mindre hitta försvunne Kevin,<br>under sådana omständigheter.<br><br>För att uppmuntra en konstant partystämning har Tai Wan väldigt märkliga och dryga bestämmelser. Dessa inkluderar:<br><br>Peeps som inte är solklart macho eller<br>femme får inte vistas i zonen.<br><br>Analoger får inte vistas i zonen (medborg-<br>are som hunnit bli 60 deporteras).",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.1715823,
                            22.3131593,
                            0
                        ],
                        [
                            114.1733471,
                            22.3110187,
                            0
                        ],
                        [
                            114.1737154,
                            22.3113022,
                            0
                        ],
                        [
                            114.1739943,
                            22.3114164,
                            0
                        ],
                        [
                            114.1742733,
                            22.3114461,
                            0
                        ],
                        [
                            114.1747239,
                            22.3112377,
                            0
                        ],
                        [
                            114.1753837,
                            22.3112724,
                            0
                        ],
                        [
                            114.1761616,
                            22.3112327,
                            0
                        ],
                        [
                            114.1763084,
                            22.3116431,
                            0
                        ],
                        [
                            114.1762279,
                            22.3121344,
                            0
                        ],
                        [
                            114.1760186,
                            22.3125771,
                            0
                        ],
                        [
                            114.1754714,
                            22.3135498,
                            0
                        ],
                        [
                            114.1750101,
                            22.3145919,
                            0
                        ],
                        [
                            114.1746238,
                            22.3153959,
                            0
                        ],
                        [
                            114.1740284,
                            22.3164546,
                            0
                        ],
                        [
                            114.1715823,
                            22.3131593,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Psykotown",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Adm. av Elchk Lutheran Secondary School(på pappret). Anarki (de facto). <br><br>Lärarkåren på Elchk Lutheran Secondary School tröttnade nån gång på 50-talet på sina skrikiga elever, och lättade på trycket genom att tulla på diverse substanser i kemi-salen. Det har gått utför sedan dess. <br>Ett gäng psykonauter och andra kreativa<br>kakor »styr« zonen numera. Egentligen råder en<br>slags kvasiutopisk anarki där folk är för<br>utzonkade för att ägna sig åt brott eller<br>andra dasai saker, som att underhålla<br>infrastrukturen. Alla besökare får 1 dos<br>#LoQ #Trippies gratis.",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.1676516,
                            22.3189747,
                            0
                        ],
                        [
                            114.1678608,
                            22.3180566,
                            0
                        ],
                        [
                            114.1674048,
                            22.3179722,
                            0
                        ],
                        [
                            114.1675925,
                            22.3171187,
                            0
                        ],
                        [
                            114.1680217,
                            22.317208,
                            0
                        ],
                        [
                            114.1682416,
                            22.3162651,
                            0
                        ],
                        [
                            114.1699154,
                            22.3165728,
                            0
                        ],
                        [
                            114.1693287,
                            22.3192687,
                            0
                        ],
                        [
                            114.1676516,
                            22.3189747,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Langham Place",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Adm. av Bangalore Investments. <br><br>I Langham Palaces extrema shopping-kaos, stimulerat av zonens avsaknad av copyright-lagar, verkar ingen ha märkt att anon-gruppen SSP Runners fethackat Bangalore Investments, och sedan ett par månader i praktiken kontrollerar zonen med företaget som sina bulvaner. Förmodligen vill de koppla ihop två strategiska undernets som passerar zonen, men ingen vet säkert.<br><br>Alla besökare i zonen måste betala 1T10<br>× 10 € till SSP Runners för att inte riskera<br>att råka ut för en »olycka«.<br>Drönarattacker från okända aktörer har skett fyra gånger sedan SSP Runners tog över.",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.1506502,
                            22.2903313,
                            0
                        ],
                        [
                            114.1476354,
                            22.2866979,
                            0
                        ],
                        [
                            114.1484938,
                            22.2862611,
                            0
                        ],
                        [
                            114.1491482,
                            22.2860229,
                            0
                        ],
                        [
                            114.1496012,
                            22.2858067,
                            0
                        ],
                        [
                            114.1498249,
                            22.2856549,
                            0
                        ],
                        [
                            114.1521728,
                            22.2846295,
                            0
                        ],
                        [
                            114.1537178,
                            22.2872503,
                            0
                        ],
                        [
                            114.1555739,
                            22.288928,
                            0
                        ],
                        [
                            114.1531608,
                            22.2900335,
                            0
                        ],
                        [
                            114.1506502,
                            22.2903313,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Sheung Wan",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Adm. av Ayala 29%, Taihei Ginkou 15%, Gas Europa 15%, Windsor KTV 11%, HKPD 10%, Ubtech Shenzhen 7%, Gammon Construction 7%, Outblaze 6%. <br><br>Röran av företag som kontrollerar Sheung Wan enligt överenskommelser efter Frihamnsavtalet är i praktiken mest en serie fronter för den egensinniga och oberäkneliga Chi Sin-triaden, samt på senare tid element från La Únion-kartellen, en ny aktör i HK. Ingendera tar  särskilt väl hand om zonen och det finns ett gryende missnöje bland peeps, som kan flamma upp om<br>förhållandena är rätt. Under tiden har Chi<br>Sin fullt upp med att bekämpa zetas i<br>området. ",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.183334,
                            22.2862961,
                            0
                        ],
                        [
                            114.1784094,
                            22.2836057,
                            0
                        ],
                        [
                            114.1787837,
                            22.2835118,
                            0
                        ],
                        [
                            114.181789,
                            22.2812926,
                            0
                        ],
                        [
                            114.1829048,
                            22.2817791,
                            0
                        ],
                        [
                            114.1830075,
                            22.2823771,
                            0
                        ],
                        [
                            114.183334,
                            22.2862961,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Tunnel Blockade Village",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Adm. av Motor Transport Workers General Union.<br><br>Denna ettriga organisation har växt till en<br>bred rörelse av blockare – familjer som<br>förlorat jobb eller på annat sätt drabbats<br>av automatiseringen. Deras hemvist är<br>Tsing-Yi-ön men har även lyckats ta över<br>denna i övrigt menlösa zon som består av<br>vägrampar, vägtullar och underhållsgara-<br>ge. De har inte aktivt börjat stoppa trafik<br>än men bygger upp sina barrikader.",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.1797213,
                            22.2745216,
                            0
                        ],
                        [
                            114.1775487,
                            22.2751322,
                            0
                        ],
                        [
                            114.1756819,
                            22.2748145,
                            0
                        ],
                        [
                            114.1751884,
                            22.2743876,
                            0
                        ],
                        [
                            114.1750489,
                            22.273365,
                            0
                        ],
                        [
                            114.1752849,
                            22.2717765,
                            0
                        ],
                        [
                            114.1784499,
                            22.2694929,
                            0
                        ],
                        [
                            114.1816364,
                            22.2704659,
                            0
                        ],
                        [
                            114.1794816,
                            22.2740761,
                            0
                        ],
                        [
                            114.1797213,
                            22.2745216,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Morrison",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Adm. av Sukhāvatī Siblings<br><br>Detta kollektiv av cyberbuddhistiska anattas tycks kontrollera zonen tack vare kudi<br>och sploits från sina virrfärder. Zonens<br>publika lager bjuder in till meditation och<br>medvetandeträning. Kollektivet sägs vara<br>involverat i försöken att infiltrera fastlandet med cyberbuddhism.<br><br>Utegångsförbud 23.00–06.00.<br>Alla aktiviteter som utförs i zonen måste<br>klimatkompenseras. Summan varje peep<br>ska betala beräknas med hjälp av ett kom-<br>plext datasheet som ständigt uppdateras<br>av Madālasā, zonens välvilliga och inte alls irriterande AI (^_^).",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            113.4916876,
                            23.0753884,
                            0
                        ],
                        [
                            113.5225867,
                            23.0831266,
                            0
                        ],
                        [
                            113.5199261,
                            23.09568,
                            0
                        ],
                        [
                            113.5141753,
                            23.1091805,
                            0
                        ],
                        [
                            113.5153769,
                            23.1183376,
                            0
                        ],
                        [
                            113.5126303,
                            23.1347558,
                            0
                        ],
                        [
                            113.5050772,
                            23.1401228,
                            0
                        ],
                        [
                            113.4970091,
                            23.1447004,
                            0
                        ],
                        [
                            113.4939192,
                            23.1454896,
                            0
                        ],
                        [
                            113.4922026,
                            23.1454107,
                            0
                        ],
                        [
                            113.4705733,
                            23.1351504,
                            0
                        ],
                        [
                            113.4563254,
                            23.1368868,
                            0
                        ],
                        [
                            113.4535788,
                            23.1177851,
                            0
                        ],
                        [
                            113.4432791,
                            23.1081542,
                            0
                        ],
                        [
                            113.4407042,
                            23.0978911,
                            0
                        ],
                        [
                            113.4400175,
                            23.0959963,
                            0
                        ],
                        [
                            113.4401892,
                            23.0925224,
                            0
                        ],
                        [
                            113.4437941,
                            23.0909433,
                            0
                        ],
                        [
                            113.4916876,
                            23.0753884,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Red Swastika Relief Zone",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Adm. av Röda Svastikan.<br><br>Lord Goodwin eller någon annan mer eller<br>mindre mysko aktör har fått ett psykbryt<br>och donerat hela zonen till Röda svastikan, Röda korsets asiatiska gren. Dessa har nu fyllt zonen med improviserade sjukhus, gratis vaccinationskliniker och flyktingmottagningar, och sprider irriterande informationskampanjer om syntit, denguefeber och ebola-2 på Lagren.",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.226322,
                            22.2833843,
                            0
                        ],
                        [
                            114.2242835,
                            22.281498,
                            0
                        ],
                        [
                            114.2257212,
                            22.2810612,
                            0
                        ],
                        [
                            114.2268799,
                            22.2799096,
                            0
                        ],
                        [
                            114.2281486,
                            22.2795889,
                            0
                        ],
                        [
                            114.2278696,
                            22.283421,
                            0
                        ],
                        [
                            114.226322,
                            22.2833843,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Splendid Gardens",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Adm. av Splendid Gardens Properties.ltd (i teorin), Kichigai (i praktiken):<br><br>Ett av synkargänget Kichigais haunts, som <br>egentligen ägs av en hunsad bostadsförening. Gator stängs rutinmässigt av för extravaganta streetraces som får imponerande stor publik både på lagren och IRL. Men zonen är väldigt liten, och rykten går att Kichigai aggressivt letar möjligheter att expandera den för att kunna köra ännu fetare races utan att secos lägger sig i. <br><br>Förbud mot automatvapen (som inte tillhör Kichigai).<br>Förbud mot babel (översättningslager).",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            113.9693994,
                            22.4082723,
                            0
                        ],
                        [
                            113.9712125,
                            22.4073003,
                            0
                        ],
                        [
                            113.9714057,
                            22.4069035,
                            0
                        ],
                        [
                            113.9744526,
                            22.4078359,
                            0
                        ],
                        [
                            113.9749784,
                            22.4055744,
                            0
                        ],
                        [
                            113.9770383,
                            22.405495,
                            0
                        ],
                        [
                            113.9782077,
                            22.4091352,
                            0
                        ],
                        [
                            113.9761693,
                            22.4095716,
                            0
                        ],
                        [
                            113.9764482,
                            22.4103007,
                            0
                        ],
                        [
                            113.9765448,
                            22.4110793,
                            0
                        ],
                        [
                            113.976416,
                            22.4115454,
                            0
                        ],
                        [
                            113.9764106,
                            22.4123835,
                            0
                        ],
                        [
                            113.9765528,
                            22.4129762,
                            0
                        ],
                        [
                            113.9765233,
                            22.4134746,
                            0
                        ],
                        [
                            113.9721996,
                            22.4137572,
                            0
                        ],
                        [
                            113.9686805,
                            22.4115355,
                            0
                        ],
                        [
                            113.9693994,
                            22.4082723,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Castle Peak Hospital",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Adm. av HumanaRaytheon.<br><br>Militär- och hälsogiganten HumanaRaytheon äger denna zon. Den innehåller ett<br>stort sjukhuskomplex, en mängd bostäder,<br>patienthotell och laboratorier, men<br>rymmer även ett antal alldeles vanliga<br>bostadskvarter och butiker som visst låg<br>där redan innan företaget köpte in sig.<br>Företaget får enligt avtalet med Kina inte<br>ha några militära resurser här, men frågan<br>är vad de förvarar i det där avstängda<br>underjordiska garaget?",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.1520155,
                            22.3495003,
                            0
                        ],
                        [
                            114.1523373,
                            22.3487065,
                            0
                        ],
                        [
                            114.1518116,
                            22.3482203,
                            0
                        ],
                        [
                            114.1511303,
                            22.3475703,
                            0
                        ],
                        [
                            114.1511035,
                            22.3456998,
                            0
                        ],
                        [
                            114.1509748,
                            22.3445189,
                            0
                        ],
                        [
                            114.151994,
                            22.3436259,
                            0
                        ],
                        [
                            114.1520906,
                            22.3436556,
                            0
                        ],
                        [
                            114.1549122,
                            22.3439037,
                            0
                        ],
                        [
                            114.1552127,
                            22.3444395,
                            0
                        ],
                        [
                            114.1546655,
                            22.3479523,
                            0
                        ],
                        [
                            114.1539681,
                            22.3493812,
                            0
                        ],
                        [
                            114.1520155,
                            22.3495003,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Piper's Hill",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Adm. av SauvageTua.<br><br>Bergsidan har intagits av ett gäng sym-<br>patiska europeiska nomos, SauvageTua. De har byggt diverse skjul och ruckel på Piper's Hill, och hydroponiska odlingar av baljväxter och potatis, men försöker konstant förbättra sin standard.  Folk låter dem för det mesta vara i fred, och accepterar deras krav att besökare i zonen bär #Eko-kläder, men Breakers, ett brutalt booster-gäng som fördrivits från centrala Kowloon av Kichigai, har på sistone kryssat förbi extra långsamt, på ett oroväckande och hotfullt vis. ",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.1786338,
                            22.34307,
                            0
                        ],
                        [
                            114.1791487,
                            22.3409266,
                            0
                        ],
                        [
                            114.1792725,
                            22.3391443,
                            0
                        ],
                        [
                            114.181665,
                            22.3390451,
                            0
                        ],
                        [
                            114.183333,
                            22.3397357,
                            0
                        ],
                        [
                            114.18432,
                            22.3429708,
                            0
                        ],
                        [
                            114.1823459,
                            22.3434868,
                            0
                        ],
                        [
                            114.181037,
                            22.343209,
                            0
                        ],
                        [
                            114.1800929,
                            22.34307,
                            0
                        ],
                        [
                            114.1793204,
                            22.3435067,
                            0
                        ],
                        [
                            114.1786338,
                            22.34307,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Painfotainment Central",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Adm. av drottning Nataxa<br><br>FBC-drottingen Nataxa styr zonen med<br>järnhand. Zonen är en kombinerad tortyr-<br>central och inspelningsstudio där fantasi-<br>fulla avrättningar streamas på virren med<br>överraskande hög produktionskvalitet.<br>Dessa har blivit en hit på både europeiska<br>och amerikanska vidtjänster och reklam-<br>intäkterna har gjort Nataxa till en av PRDs<br>rikaste peeps. Det ryktas dock att brist på<br>kriminella gör att hon importerar fångar<br>från andra zoner och att hon inte är så<br>noggrann med vilka de egentligen är.<br><br>Endast besökare med fler än 5000 följare<br>får tillträde till zonen. Att vistas i zonen med mindre följare än så kan eventuellt landa en på Nataxas tortyrstream. ",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.1113224,
                            22.4484027,
                            0
                        ],
                        [
                            114.1079887,
                            22.4497603,
                            0
                        ],
                        [
                            114.1130543,
                            22.434172,
                            0
                        ],
                        [
                            114.1294055,
                            22.4228896,
                            0
                        ],
                        [
                            114.1520647,
                            22.4525204,
                            0
                        ],
                        [
                            114.1468291,
                            22.4595801,
                            0
                        ],
                        [
                            114.1470866,
                            22.4615631,
                            0
                        ],
                        [
                            114.142795,
                            22.4628322,
                            0
                        ],
                        [
                            114.1316369,
                            22.461127,
                            0
                        ],
                        [
                            114.1254571,
                            22.4546424,
                            0
                        ],
                        [
                            114.1236548,
                            22.4536309,
                            0
                        ],
                        [
                            114.1188912,
                            22.4504182,
                            0
                        ],
                        [
                            114.1173033,
                            22.4447463,
                            0
                        ],
                        [
                            114.1136691,
                            22.4473282,
                            0
                        ],
                        [
                            114.1113224,
                            22.4484027,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Tsuen Valley",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Adm. av Gaia Liberation Front.<br><br>Dödskulten/miljörörelsen som inte tvekar<br>inför något har tagit över zonen med<br>sina blågröna paras, kanske de har fått<br>en sponsrad studieresa. På virren viskas<br>det om ett kommande massjälvmord<br>men andra menar att de håller på att loota en övergiven kinesisk klimatkupol. Under tiden<br>trakasserar de gravida till att begå abort.<br><br>Besökare måste ha anlänt från utvalda<br>allierade grannzoner.<br><br>Alla i zonen måste iaktta eko-religiösa ritualer på föreskriven tid (ses som en incident av klass 3).",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            113.9701424,
                            22.3865902,
                            0
                        ],
                        [
                            113.9664752,
                            22.3812372,
                            0
                        ],
                        [
                            113.968599,
                            22.3777849,
                            0
                        ],
                        [
                            113.9704858,
                            22.3784156,
                            0
                        ],
                        [
                            113.9763223,
                            22.3811339,
                            0
                        ],
                        [
                            113.9754317,
                            22.3823144,
                            0
                        ],
                        [
                            113.9775882,
                            22.3833363,
                            0
                        ],
                        [
                            113.9799915,
                            22.383872,
                            0
                        ],
                        [
                            113.9785109,
                            22.3863918,
                            0
                        ],
                        [
                            113.9761935,
                            22.3859355,
                            0
                        ],
                        [
                            113.97467,
                            22.3857569,
                            0
                        ],
                        [
                            113.9729749,
                            22.3856974,
                            0
                        ],
                        [
                            113.9701424,
                            22.3865902,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Castle Inspection Zone",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Adm. av kinesiska inspektörer.<br><br>Kinas sätt att hålla någorlunda koll på<br>PRD, utöver Gränskommandot, är genom<br>sina inspektörer, som enligt avtal med<br>Exco har rätt att bedriva viss verksamhet i<br>PRD. Här finns kontor och bostäder, nog-<br>grant inrutade och övervakade. Som alla<br>gissat är det dock DDW, Kinas säkerhets-<br>tjänst, som drar i trådarna i zonen.",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.1606514,
                            22.2482847,
                            0
                        ],
                        [
                            114.1605888,
                            22.2451666,
                            0
                        ],
                        [
                            114.160503,
                            22.2426244,
                            0
                        ],
                        [
                            114.1619621,
                            22.2392083,
                            0
                        ],
                        [
                            114.1618762,
                            22.2370632,
                            0
                        ],
                        [
                            114.1641078,
                            22.2367454,
                            0
                        ],
                        [
                            114.1628204,
                            22.2342825,
                            0
                        ],
                        [
                            114.1634212,
                            22.2274498,
                            0
                        ],
                        [
                            114.1741697,
                            22.2290588,
                            0
                        ],
                        [
                            114.1808216,
                            22.2445112,
                            0
                        ],
                        [
                            114.1784183,
                            22.2458618,
                            0
                        ],
                        [
                            114.1763155,
                            22.248245,
                            0
                        ],
                        [
                            114.1746847,
                            22.2500721,
                            0
                        ],
                        [
                            114.1710798,
                            22.2504693,
                            0
                        ],
                        [
                            114.1683761,
                            22.2495955,
                            0
                        ],
                        [
                            114.1654579,
                            22.248245,
                            0
                        ],
                        [
                            114.1620676,
                            22.2485231,
                            0
                        ],
                        [
                            114.1606514,
                            22.2482847,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Ocean Park",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Adm. av Falungong.<br><br>Den bergiga halvön var tidigare en vatten-<br>betonad nöjespark men Falungong-organisationen, med ursprung som gymnastikrörelse som gjorde motstånd i Kollapsen, har gjort om temat till traditionell kinesisk stil. Rörelsen är numer stormrik och elaka rykten säger att den följer Chongqings<br>ledband, eller åtminstone smugglar något<br>fram och tillbaka över gränsen.<br><br>Förbud mot automatvapen.<br>Intim kroppsvisitering på alla besökare<br>(just den här veckan >_<).",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.19197,
                            22.2747513,
                            0
                        ],
                        [
                            114.1918841,
                            22.2714401,
                            0
                        ],
                        [
                            114.1911975,
                            22.2705714,
                            0
                        ],
                        [
                            114.1910044,
                            22.2694246,
                            0
                        ],
                        [
                            114.190425,
                            22.2689108,
                            0
                        ],
                        [
                            114.1900173,
                            22.268119,
                            0
                        ],
                        [
                            114.1903661,
                            22.265607,
                            0
                        ],
                        [
                            114.1904466,
                            22.2633929,
                            0
                        ],
                        [
                            114.1920177,
                            22.2633301,
                            0
                        ],
                        [
                            114.1978541,
                            22.2694264,
                            0
                        ],
                        [
                            114.1968457,
                            22.2729808,
                            0
                        ],
                        [
                            114.19197,
                            22.2747513,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Jardine's Lookout",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Adm. av Aga Khan Development Network.<br><br>Zonen ägs av gruppen som förvaltar den<br>persiska Aga Khan-dynastin (som utgör<br>Nizari-muslimernas ledare). Dynastins<br>nuvarande ledare, super-celeben Ag4<br>Khan VII, har ibland siktats i zonen för att<br>inspektera diverse byggnationer och kasta sponsorpengar på lokala företag, varav AutoChef Parlor Restaurant och Laputa Line-dancing & Parkour Classes är de mest framstående. <br><br>Ag4 Khans ådra trogen gäller inga copyright-lagar i zonen. Drönarattacker från okända aktörer (förmodligen copyright-lobbyn) sker misstänkt ofta.<br><br>Gratis sjukvård gäller för zonens peeps; besökare måste dock teckna sjukförsäkring för 100 €.<br><br>Byråkratin tar 1T100 minuter.",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            113.2753896,
                            23.1589737,
                            0
                        ],
                        [
                            113.2694867,
                            23.1583765,
                            0
                        ],
                        [
                            113.2683045,
                            23.1578185,
                            0
                        ],
                        [
                            113.2664162,
                            23.1562599,
                            0
                        ],
                        [
                            113.2643134,
                            23.1553327,
                            0
                        ],
                        [
                            113.2633693,
                            23.1543462,
                            0
                        ],
                        [
                            113.2616955,
                            23.1520181,
                            0
                        ],
                        [
                            113.2586915,
                            23.1464148,
                            0
                        ],
                        [
                            113.2552476,
                            23.1480031,
                            0
                        ],
                        [
                            113.2530052,
                            23.1477763,
                            0
                        ],
                        [
                            113.2505054,
                            23.1472336,
                            0
                        ],
                        [
                            113.2487781,
                            23.1463753,
                            0
                        ],
                        [
                            113.2454735,
                            23.1443628,
                            0
                        ],
                        [
                            113.2425124,
                            23.142745,
                            0
                        ],
                        [
                            113.2421997,
                            23.1420955,
                            0
                        ],
                        [
                            113.2451395,
                            23.1350716,
                            0
                        ],
                        [
                            113.2541085,
                            23.1330987,
                            0
                        ],
                        [
                            113.2604599,
                            23.1351508,
                            0
                        ],
                        [
                            113.268099,
                            23.1345195,
                            0
                        ],
                        [
                            113.2755661,
                            23.1394129,
                            0
                        ],
                        [
                            113.2772827,
                            23.1404587,
                            0
                        ],
                        [
                            113.2789564,
                            23.1411887,
                            0
                        ],
                        [
                            113.2798704,
                            23.1415843,
                            0
                        ],
                        [
                            113.2824152,
                            23.1456105,
                            0
                        ],
                        [
                            113.2845007,
                            23.1468754,
                            0
                        ],
                        [
                            113.2916761,
                            23.1532326,
                            0
                        ],
                        [
                            113.2910586,
                            23.1585904,
                            0
                        ],
                        [
                            113.2807159,
                            23.1594585,
                            0
                        ],
                        [
                            113.2753896,
                            23.1589737,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Six Banyan Trees Sanctuary",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Adm. av Caanan Liberation Group (i teorin), the Baiyun Veterans (i praktiken).<br><br>Delar av norra Canaan Market, och i synnerhet Dengfeng-subdistriktet, har i praktiken kommit att fungera som en egen zon, sedan en tungt beväpnad grupp kinesiska krigsveteraner invaderade området. De letar efter Gordon Gao, en krigsförbrytare de suktar efter att hämnas på, som ryktas ha gått under jorden i Canaan Market. Istället för att hitta honom har \"Baiyun-veteranerna\", som de kallas, hamnat i bitter konflikt med Canaan Liberation Group, och har utropat sin egen zon, Six Banyan Trees Sanctuary. De tar ut beskyddaravgifter, inför random utegångsförbud, och skickar dödspatruller att ta itu med \"patrasket\" på gatorna, medan de håller sig väl med områdets analoger och präster och andra konservativa element som är lätta att vända emot de hyperliberala grupperna i Canaan market. ",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.186354,
                            22.3780751,
                            0
                        ],
                        [
                            114.1798737,
                            22.3795037,
                            0
                        ],
                        [
                            114.1633943,
                            22.3726384,
                            0
                        ],
                        [
                            114.1618064,
                            22.367757,
                            0
                        ],
                        [
                            114.164038,
                            22.3574381,
                            0
                        ],
                        [
                            114.1727927,
                            22.3556124,
                            0
                        ],
                        [
                            114.1781143,
                            22.3586289,
                            0
                        ],
                        [
                            114.1888431,
                            22.3652964,
                            0
                        ],
                        [
                            114.1923632,
                            22.3712571,
                            0
                        ],
                        [
                            114.1871705,
                            22.3766939,
                            0
                        ],
                        [
                            114.186354,
                            22.3780751,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Tin Sum",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Adm. av Bamboo Union-triaden.<br><br><br>Detta är en av få zoner som fortfaran-<br>de kontrolleras av Bamboo Union, den<br>Taiwan-kopplade och löst sammanslutna<br>befrielseorganisationen som var mest<br>aktiv under Kollapsen. Zonen är välbefäst<br>med spridda vapengömmor och slitna<br>Koumintang-posters. Men lingdaon<br>brottas med intern splittring, en tveksam<br>befolkning och förlorade marknadsandelar<br>till de större triaderna.",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.1608003,
                            22.3316459,
                            0
                        ],
                        [
                            114.1577962,
                            22.3282022,
                            0
                        ],
                        [
                            114.1604784,
                            22.3261776,
                            0
                        ],
                        [
                            114.1637829,
                            22.3297504,
                            0
                        ],
                        [
                            114.1608861,
                            22.3316757,
                            0
                        ],
                        [
                            114.1608003,
                            22.3316459,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Sham Shui Po Zone",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Adm. av The Xinyu DiDemocratic Movement<br><br>I Shamshuipos kaos har The Xinyu DiDemocrats, samma mori-grupp som kontrollerar Xinyu-zonen långt upp i norr, infört ett radikalt eko-direktdemokratiskt folkstyre i centrala Shamshuipo. Det är på många sätt en bättre deal än att slava åt Chi Sin-triaden, som brukade basa här, men somliga grymtar surt över de nya klimatskatterna:<br><br>Alla invånare, inklusive temporära besökare, uppmanas rösta i Lagren på 1T10 olika motioner varje dag.10 € i böter utfärdas per motion som<br>går obesvarad. Målet är att radikalisera områdets avtrubbade padonkis, och göra dem eco-woke. ",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.228467,
                            22.5401778,
                            0
                        ],
                        [
                            114.2256004,
                            22.5450834,
                            0
                        ],
                        [
                            114.225532,
                            22.5457386,
                            0
                        ],
                        [
                            114.2264252,
                            22.5464941,
                            0
                        ],
                        [
                            114.22701,
                            22.5478863,
                            0
                        ],
                        [
                            114.2246175,
                            22.5503534,
                            0
                        ],
                        [
                            114.2243694,
                            22.5509368,
                            0
                        ],
                        [
                            114.2243694,
                            22.5509764,
                            0
                        ],
                        [
                            114.2234346,
                            22.5510842,
                            0
                        ],
                        [
                            114.222693,
                            22.5514694,
                            0
                        ],
                        [
                            114.2212218,
                            22.5519338,
                            0
                        ],
                        [
                            114.2206585,
                            22.5535538,
                            0
                        ],
                        [
                            114.2200549,
                            22.5537495,
                            0
                        ],
                        [
                            114.2195562,
                            22.5544543,
                            0
                        ],
                        [
                            114.218715,
                            22.5556108,
                            0
                        ],
                        [
                            114.2175695,
                            22.5561259,
                            0
                        ],
                        [
                            114.2169807,
                            22.5558682,
                            0
                        ],
                        [
                            114.2166493,
                            22.5552538,
                            0
                        ],
                        [
                            114.2161583,
                            22.5554122,
                            0
                        ],
                        [
                            114.2149919,
                            22.5549692,
                            0
                        ],
                        [
                            114.2135992,
                            22.5549363,
                            0
                        ],
                        [
                            114.2124151,
                            22.5556163,
                            0
                        ],
                        [
                            114.2121226,
                            22.555367,
                            0
                        ],
                        [
                            114.2125047,
                            22.5495565,
                            0
                        ],
                        [
                            114.2055057,
                            22.5428342,
                            0
                        ],
                        [
                            114.2140459,
                            22.5348671,
                            0
                        ],
                        [
                            114.2168783,
                            22.5326473,
                            0
                        ],
                        [
                            114.228467,
                            22.5401778,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Shau Tau Kok UN Administration",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Adm. av FN<br><br>När gatustrider bröt ut under Kollapsen<br>så ville väst ha dit FN, medan Kina helst<br>slapp och såg allt som ett internt problem.<br>Till slut landade det i att FN fick sätta upp<br>enstaka observationsposter. Denna zon<br>administreras sålunda av trötta FN-byråkrater med en symbolisk trupp av blåhjälmar och blådrönare. Deras främsta jobb är att hantera flyktingmassorna som halvpermanent bor här, då zonen enligt FN-regler inte kräver särskilda cert eller handlingar för att beträdas.",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.1653413,
                            22.54591,
                            0
                        ],
                        [
                            114.1663284,
                            22.5440967,
                            0
                        ],
                        [
                            114.172079,
                            22.5412131,
                            0
                        ],
                        [
                            114.1784305,
                            22.5441066,
                            0
                        ],
                        [
                            114.1784305,
                            22.5484269,
                            0
                        ],
                        [
                            114.1738815,
                            22.5518354,
                            0
                        ],
                        [
                            114.1690321,
                            22.5534604,
                            0
                        ],
                        [
                            114.1636247,
                            22.5532622,
                            0
                        ],
                        [
                            114.1604061,
                            22.5513994,
                            0
                        ],
                        [
                            114.1617365,
                            22.5482287,
                            0
                        ],
                        [
                            114.1653413,
                            22.54591,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Northeast Dump Zone",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Adm. av ReCorp<br><br>En extremt gudsförgäten, avfolkad zon, som ReCorp icke desto mindre betraktar som en strategisk tillgång. Återvinningskorporationen<br>arrenderar zonen från den numera irrelevanta holdingkoncernen Jardine Matheson, för att driva sin biologiska refaktorator. Det är en del i deras mycket större ambition att sakta bli en<br>oersättlig del av hela PRD:s kretslopp.",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.1784521,
                            22.5223216,
                            0
                        ],
                        [
                            114.178259,
                            22.5223018,
                            0
                        ],
                        [
                            114.1762634,
                            22.5220738,
                            0
                        ],
                        [
                            114.1754909,
                            22.521172,
                            0
                        ],
                        [
                            114.1740319,
                            22.5206071,
                            0
                        ],
                        [
                            114.1720363,
                            22.5209539,
                            0
                        ],
                        [
                            114.1686888,
                            22.519388,
                            0
                        ],
                        [
                            114.1680023,
                            22.5169499,
                            0
                        ],
                        [
                            114.1696974,
                            22.5148488,
                            0
                        ],
                        [
                            114.1708132,
                            22.5142541,
                            0
                        ],
                        [
                            114.1730877,
                            22.513263,
                            0
                        ],
                        [
                            114.1744181,
                            22.5134018,
                            0
                        ],
                        [
                            114.1747828,
                            22.5142343,
                            0
                        ],
                        [
                            114.1753193,
                            22.5146109,
                            0
                        ],
                        [
                            114.1771646,
                            22.5132828,
                            0
                        ],
                        [
                            114.1776582,
                            22.5136793,
                            0
                        ],
                        [
                            114.1799112,
                            22.5151065,
                            0
                        ],
                        [
                            114.1819712,
                            22.5159588,
                            0
                        ],
                        [
                            114.1825076,
                            22.5167715,
                            0
                        ],
                        [
                            114.1824218,
                            22.5177824,
                            0
                        ],
                        [
                            114.1818639,
                            22.5186942,
                            0
                        ],
                        [
                            114.1784521,
                            22.5223216,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Last Stand",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Adm. av FN (i teorin), Asian Dawn (i praktiken). <br><br>Denna meltade terrorgrupp har kontrollen<br>över zonen. Kan bara beklaga. De kämpar<br>mot allt kinesiskt inflytande i regionen;<br>mainland eller Tigerallians, who cares?<br>Just nu är de i färd med att sabotera infrastrukturen i norra HK. AD hämtar<br>sitt stöd i zonen från ett före detta FN-drivet flyktingläger fullt av av frustrerade  filippiner och indoneser. ",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.1724076,
                            22.4452173,
                            0
                        ],
                        [
                            114.1760769,
                            22.4438687,
                            0
                        ],
                        [
                            114.1771712,
                            22.4434919,
                            0
                        ],
                        [
                            114.1795101,
                            22.4447611,
                            0
                        ],
                        [
                            114.1800466,
                            22.4456139,
                            0
                        ],
                        [
                            114.178589,
                            22.4485371,
                            0
                        ],
                        [
                            114.1768723,
                            22.4491717,
                            0
                        ],
                        [
                            114.1735234,
                            22.4505719,
                            0
                        ],
                        [
                            114.1730514,
                            22.4502744,
                            0
                        ],
                        [
                            114.1724291,
                            22.4494613,
                            0
                        ],
                        [
                            114.1722574,
                            22.448787,
                            0
                        ],
                        [
                            114.172472,
                            22.447498,
                            0
                        ],
                        [
                            114.1724076,
                            22.4452173,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Kwong Fuk Estate",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "adm. av Black Fuk Gang<br><br>Zonen styrs av ett gäng med en brutal,<br>homoerotisk machismo som få i PRD kan<br>överträffa. BFG har en strikt hederskodex<br>och hämnas alltid våldsamt (mycket våld-<br>samt) på alla som förorättar dem. Femmes<br>är förbjudna inträde i zonen. Det enda<br>undantaget är Lo Wai Lolitas, de är okej. Besökare förses dessutom med en leende, lättklädd guide/övervakare.",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.1852117,
                            22.3461847,
                            0
                        ],
                        [
                            114.1858876,
                            22.3441505,
                            0
                        ],
                        [
                            114.1886556,
                            22.3450833,
                            0
                        ],
                        [
                            114.1954148,
                            22.3453413,
                            0
                        ],
                        [
                            114.1957635,
                            22.3455447,
                            0
                        ],
                        [
                            114.195852,
                            22.3460036,
                            0
                        ],
                        [
                            114.1957475,
                            22.3464526,
                            0
                        ],
                        [
                            114.1946102,
                            22.3472863,
                            0
                        ],
                        [
                            114.1899646,
                            22.3465519,
                            0
                        ],
                        [
                            114.18872,
                            22.3469885,
                            0
                        ],
                        [
                            114.186982,
                            22.3477178,
                            0
                        ],
                        [
                            114.1852117,
                            22.3461847,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Chuk Un Estate",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Adm. av Molards<br><br>Zonen kontrolleras av det groteskt<br>kroppsmoddande gänget Molards. De är<br>inte så farliga som de ser ut, de deppar<br>fortfarande efter att deras förra ledare<br>torterades till döds. Men de börjar bli des-<br>perata och den nya ledaren The Original<br>Ramses Kwok börjar samla stöd för ett<br>attentat mot Anti-Freak Legion. Ledaren i Chuk Un Estate, Miriel Maclehose, förväntas ta ställning till The Original Ramses Kwoks ambitioner inom kort. ",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.1782068,
                            22.3256354,
                            0
                        ],
                        [
                            114.1783289,
                            22.3253389,
                            0
                        ],
                        [
                            114.1782444,
                            22.3228119,
                            0
                        ],
                        [
                            114.1779547,
                            22.3219087,
                            0
                        ],
                        [
                            114.1780406,
                            22.3215068,
                            0
                        ],
                        [
                            114.1857295,
                            22.325024,
                            0
                        ],
                        [
                            114.1852575,
                            22.3270288,
                            0
                        ],
                        [
                            114.1782068,
                            22.3256354,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Kowloon Hospital",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Adm. av Progenex<br><br>Progenex har utvecklat en stor paranoia mot att triaderna ska nästla sig in företaget eller dess dotterbolag, eller bara göra gatorna i zonen osäkra. Utöver sin sedvanliga approach med murar och checkpoints har de därför infört utegångsförbud inom semi-random intervaller, och ett krav på att alla som uppehåller sig längre än en timme i zonen måste ha en genID-tatuering från Progenex.",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.1871028,
                            22.3306996,
                            0
                        ],
                        [
                            114.1869097,
                            22.3272062,
                            0
                        ],
                        [
                            114.1913515,
                            22.3279605,
                            0
                        ],
                        [
                            114.194141,
                            22.3305011,
                            0
                        ],
                        [
                            114.1871028,
                            22.3306996,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Kowloon City",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Adm. av SSO-triaden.<br><br>Sio Sam Ong-triaden försöker konsolidera<br>sin makt i zonen och är för tillfället upptagna med att jaga ut diverse misstänkta fiender och förrädare. Den paranoide lingdaon, fett under triadernas tumme, har också börjat skrämma iväg motsträviga näringsidkare genom rivningshot från byggföretag han kontrollerar. Mycket skum cybernetik ryktas cirkulera i zonen, av oklara skäl - är det kanske SSOs smuggelgods på vift? ",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.1908501,
                            22.3333747,
                            0
                        ],
                        [
                            114.1912363,
                            22.334635,
                            0
                        ],
                        [
                            114.1896862,
                            22.3355748,
                            0
                        ],
                        [
                            114.1895428,
                            22.3364648,
                            0
                        ],
                        [
                            114.189514,
                            22.3368353,
                            0
                        ],
                        [
                            114.1894315,
                            22.3370669,
                            0
                        ],
                        [
                            114.1890734,
                            22.3377087,
                            0
                        ],
                        [
                            114.1881575,
                            22.3382711,
                            0
                        ],
                        [
                            114.187428,
                            22.3378345,
                            0
                        ],
                        [
                            114.1866126,
                            22.3374375,
                            0
                        ],
                        [
                            114.1852822,
                            22.3385688,
                            0
                        ],
                        [
                            114.1842308,
                            22.3382711,
                            0
                        ],
                        [
                            114.1851964,
                            22.3354925,
                            0
                        ],
                        [
                            114.1851534,
                            22.3344405,
                            0
                        ],
                        [
                            114.1856255,
                            22.3339642,
                            0
                        ],
                        [
                            114.1854968,
                            22.3318603,
                            0
                        ],
                        [
                            114.1870632,
                            22.3318206,
                            0
                        ],
                        [
                            114.1871028,
                            22.3306996,
                            0
                        ],
                        [
                            114.1913762,
                            22.3305503,
                            0
                        ],
                        [
                            114.1924594,
                            22.33137,
                            0
                        ],
                        [
                            114.1916655,
                            22.3320945,
                            0
                        ],
                        [
                            114.1912149,
                            22.3331067,
                            0
                        ],
                        [
                            114.1908501,
                            22.3333747,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Nirvana Zone",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Adm. av Sukhāvatī Siblings<br><br>Anatta-rörelsens ursprungliga hjärta i Hongkong, även om mycket av credsen på sistone varit delat med Sukhāvatī Siblings andra zon, Morrison. Anattas och cyberbuddhister av alla färger och former frekventerar Nirvana Zones kaféer, loggbarer och butiker, men Sukhāvatī Siblings har kommit att bli ledande tack vare allt sitt €. De har dock respekterat de regler som zonens gamle ledare, buddhist-ikonen Kunsel Lama, instiftade. Detta innebär bland annat att all måste måste ätas med chopsticks, att kött är förbjudet i zonen, och att alla besökare förväntas uppföra sig med värdighet och respekt, och omfamna stillheten i sina hjärtan. Lagerströmmar med blommor, trippiga mönster och soft musik påminner en konstant om detta, bara så ingen glömmer det. Nirvana Zone ska vara en fristad för stillhet och medkänsla i Hongkongs kaos och misär. ",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            113.9804392,
                            22.4137398,
                            0
                        ],
                        [
                            113.9788728,
                            22.4079672,
                            0
                        ],
                        [
                            113.9820056,
                            22.4085623,
                            0
                        ],
                        [
                            113.9852672,
                            22.4076101,
                            0
                        ],
                        [
                            113.9867799,
                            22.4106948,
                            0
                        ],
                        [
                            113.9867907,
                            22.410794,
                            0
                        ],
                        [
                            113.986222,
                            22.4133331,
                            0
                        ],
                        [
                            113.9854388,
                            22.4153069,
                            0
                        ],
                        [
                            113.9823918,
                            22.4148507,
                            0
                        ],
                        [
                            113.9804392,
                            22.4137398,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Lingnan",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Adm. av Adéle Kwok-Valis.<br><br>Familjen Kwow-Valis köpte upp zonen före Kollapsen, och styr den än. De försöker fostra en lojalitet bland zonens invånare, och har bland annat förbjudit kränkningar, men är även fanatiska augs. Peeps utan tillräckligt imponerande genuppsättning måste punga ut med 2000 € för zonens cert (som tur är är den lätt att undvika, då inga viktiga genomfartsleder passerar den). Kwok-Valis-familjens tillgångar har dock slösats bort av en serie ofattbart hedonistiska och dekadenta fuerdaier, men den åldrade matriarken Adéle vet på råd. Hon driver numera Bellarosa Senior Ladies' Club Etiquette Academy, ett universitet för överklassetikett, som skickar ut konsulter till nyrikt drägg över hela Pärldeltat. ",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            113.9754317,
                            22.3823144,
                            0
                        ],
                        [
                            113.9772257,
                            22.3799201,
                            0
                        ],
                        [
                            113.9784231,
                            22.3788718,
                            0
                        ],
                        [
                            113.979127,
                            22.3786568,
                            0
                        ],
                        [
                            113.9799048,
                            22.3784782,
                            0
                        ],
                        [
                            113.9801569,
                            22.3783492,
                            0
                        ],
                        [
                            113.9809079,
                            22.3788353,
                            0
                        ],
                        [
                            113.9778341,
                            22.3833939,
                            0
                        ],
                        [
                            113.9775882,
                            22.3833363,
                            0
                        ],
                        [
                            113.9754317,
                            22.3823144,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Sam Shing",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Adm. Av ReCorp<br><br>Återvinningskorporationen ReCorp<br>äger zonen, och har flera anläggningar här. Av någon anledning upprätthåller de en sträng sekretess: drönare som passerar luftrummet olovligen skjuts ned, och alla som vill uppehålla sig i zonen måste skriva under ett non-disclosure agreement. Suspekta påmar med biologiskt material anländer  från uppströms i Pärldeltat.",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            113.8531007,
                            22.8068532,
                            0
                        ],
                        [
                            113.8278665,
                            22.7949847,
                            0
                        ],
                        [
                            113.8283157,
                            22.793624,
                            0
                        ],
                        [
                            113.8510615,
                            22.7950156,
                            0
                        ],
                        [
                            113.8580132,
                            22.7809297,
                            0
                        ],
                        [
                            113.8733767,
                            22.7663679,
                            0
                        ],
                        [
                            113.8709734,
                            22.7597197,
                            0
                        ],
                        [
                            113.8995553,
                            22.7552875,
                            0
                        ],
                        [
                            113.9166361,
                            22.7579791,
                            0
                        ],
                        [
                            113.946955,
                            22.7512116,
                            0
                        ],
                        [
                            113.9569559,
                            22.7728273,
                            0
                        ],
                        [
                            113.9545198,
                            22.7840569,
                            0
                        ],
                        [
                            113.9546562,
                            22.7929957,
                            0
                        ],
                        [
                            113.9477683,
                            22.7928771,
                            0
                        ],
                        [
                            113.8531007,
                            22.8068532,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Nine Dragons Modern Village",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Adm. av Dragon Industries<br><br>En skum enklav som Dragon förvärvat i någon sorts ohelig deal med Chongqing. Till korpens försvar har de dock gjort ansträngningar att revitalisera zonen, renoverat infrastrukturen och byggt ett kulturhus för att hedra Shenzhens förflutna.<br><br>Zonen innehåller en stor klädfabrik och flera kontor och butiker i Dragons regi. Dragon vet dock att zonen har infiltrerats av fackliga representanter från nomo-styret i Xinyu, så under ytan pågår jakten för fullt för att identifiera dem.",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.2175728,
                            22.2131308,
                            0
                        ],
                        [
                            114.2181522,
                            22.2089094,
                            0
                        ],
                        [
                            114.2208666,
                            22.2091775,
                            0
                        ],
                        [
                            114.2201048,
                            22.2135777,
                            0
                        ],
                        [
                            114.2175728,
                            22.2131308,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Stanley Prison",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Adm. av Germinals<br><br>Det gamla fängelset har tagits över<br>av crispergänget Germinals som styr<br>diktatoriskt över zonen och sprider sina<br>virusvektorer mellan sig för att hitta bättre<br>mutationer. Nyanlända kan stoppas för<br>DNA-sekvensering och om de har en<br>spännande gen så lär de snabbt föras<br>till högkvarteret »Gestation Chambers«.",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.1726766,
                            22.3199681,
                            0
                        ],
                        [
                            114.1740284,
                            22.3164546,
                            0
                        ],
                        [
                            114.1772354,
                            22.3210624,
                            0
                        ],
                        [
                            114.1772461,
                            22.3211418,
                            0
                        ],
                        [
                            114.1763614,
                            22.3207276,
                            0
                        ],
                        [
                            114.1726766,
                            22.3199681,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Victory Triangle",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Adm. av Dojima-gumi.<br><br>Zonen styrs hyfsta osubtilt, utan knussel som hemliga bulvaner, av yakuza-familjen Dojima-gumi som specialiserar sig på beskydd,<br>nyotaimori-restauranger, gambling, lillfing-<br>erkapning, seppuku simulators och svåra<br>ojii-sans som gör traditionella japanska<br>tatueringar på hörnet. Deras kamp mot<br>triaderna är evig och de behöver alla<br>allierade de kan få. Dojima-gumis patriark hatar augs, så misstänkta augs kan gripas och riskerar att utsättas för mer eller mindre förnedrande undersökningar. ",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.1762641,
                            22.3004698,
                            0
                        ],
                        [
                            114.1754916,
                            22.2953676,
                            0
                        ],
                        [
                            114.1771975,
                            22.2960327,
                            0
                        ],
                        [
                            114.1783026,
                            22.2968764,
                            0
                        ],
                        [
                            114.1807702,
                            22.2990404,
                            0
                        ],
                        [
                            114.1801111,
                            22.3014442,
                            0
                        ],
                        [
                            114.1784022,
                            22.3012846,
                            0
                        ],
                        [
                            114.1771653,
                            22.3003308,
                            0
                        ],
                        [
                            114.1762641,
                            22.3004698,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "East Tsim Sha Tsui",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Adm. av Genetica<br><br>Zonen består primärt av butiker och bostadskomplex, det mesta i Geneticas regim. Korpen har experimenterat lite med biobyggnader i området, bland annat en<br>fraktal staty som växer av sig själv och<br>en bakteriehinna på bostadshusen som<br>bryter ned föroreningar.<br><br>Zonen är en problemzon i HK, då den av hävd inte tillåter överflygningar av drönare utan att dessa först registrerats vid East TST Drone Authority. Sedan Genetica köpte upp zonen har folk rest högljudda krav på att megakorpen ska lägga ned East TST Drone Authority, men Genetica vill hålla sig väl med zonens invånare och har hittils inte fuckat med deras omhuldade gamla institution. ",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.1937453,
                            22.2851279,
                            0
                        ],
                        [
                            114.1980153,
                            22.2814745,
                            0
                        ],
                        [
                            114.199732,
                            22.2821893,
                            0
                        ],
                        [
                            114.2003972,
                            22.2828247,
                            0
                        ],
                        [
                            114.2023498,
                            22.28485,
                            0
                        ],
                        [
                            114.2075425,
                            22.2866766,
                            0
                        ],
                        [
                            114.2081004,
                            22.2885826,
                            0
                        ],
                        [
                            114.207564,
                            22.2898533,
                            0
                        ],
                        [
                            114.2053968,
                            22.2904687,
                            0
                        ],
                        [
                            114.2003113,
                            22.2887017,
                            0
                        ],
                        [
                            114.1962987,
                            22.2880862,
                            0
                        ],
                        [
                            114.1937453,
                            22.2851279,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Braemar Park",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Adm. av Bolkiah-huset.<br><br>Denna lyxade zon ägs av Bolkiah-huset,<br>det vill säga Brunei-sultanatets dynasti.<br>Här bor de när de flyger in för att shoppa,<br>festa eller göra deals. Det verkar också<br>som de har någon dialog med Genetica, vars representanter ofta frekventerar zonen.<br>På Lagren ryktas om en crazy plan att återuppliva den ursprungliga sultanen från 1400-talet. <br><br>I linje med Bolkiahs muslimska tro är alla droger förbjudna i zonen. ",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.1678857,
                            22.3264569,
                            0
                        ],
                        [
                            114.1685455,
                            22.3239532,
                            0
                        ],
                        [
                            114.1706538,
                            22.3243132,
                            0
                        ],
                        [
                            114.1702246,
                            22.3267149,
                            0
                        ],
                        [
                            114.1678857,
                            22.3264569,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Black Jade",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Adm. av Wen Yuming, Yu-Yu Adeyemi, och Jada Odhiambo.  <br><br>Zonen styrs av ett litet kotteri av afro-kinesiska Binary Kei-lagerprinsessor beväpnade med daddys bankkonto. De har en seriös (och dyr)<br>säkerhetsstyrka som skyddar dem vart de<br>än går. Styret i zonen är ganska handsoff,<br>men de har VIP-access till alla etablissemang och peeps som styret inte gillar tenderar att bli spöade av secos medan ledningen entusiastiskt recenserar fighten på sina strömmar. <br><br>Peeps som inte är solklart macho eller femme får inte vistas i Black Jade-zonen. ",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.1412705,
                            22.291367,
                            0
                        ],
                        [
                            114.1423005,
                            22.2876741,
                            0
                        ],
                        [
                            114.1422933,
                            22.2869446,
                            0
                        ],
                        [
                            114.142484,
                            22.2869496,
                            0
                        ],
                        [
                            114.1449731,
                            22.2869893,
                            0
                        ],
                        [
                            114.1455525,
                            22.2874658,
                            0
                        ],
                        [
                            114.1462391,
                            22.2870489,
                            0
                        ],
                        [
                            114.1471618,
                            22.2874261,
                            0
                        ],
                        [
                            114.1476354,
                            22.2866979,
                            0
                        ],
                        [
                            114.1490608,
                            22.288389,
                            0
                        ],
                        [
                            114.1467112,
                            22.2890542,
                            0
                        ],
                        [
                            114.1437071,
                            22.2896101,
                            0
                        ],
                        [
                            114.1412705,
                            22.291367,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Connaught Properties Tranche 3",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Adm. av Saturday Warriors.<br><br>Zonen utgör några kvarter andra klassens<br>kontor och lyxlägenheter ägda av en investmentbank. Men VuChain har hackats på<br>något sätt och hackergruppen Saturday<br>Warriors är nu den officiella ägaren av zonen. De har ett behov av att ASAP säkra upp zonen rent fysiskt om inte Connaught Properties ska ta tillbaka den. En åtgärd de infört, om det inte bara är en bugg i Connaughts databaser, är att zonen nu plötsligt kräver  visa-on-arrival, kostnad 50 €, väntetid 1T100 minuter.",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            113.8890613,
                            22.7186994,
                            0
                        ],
                        [
                            113.8923872,
                            22.6905116,
                            0
                        ],
                        [
                            113.8910139,
                            22.6829093,
                            0
                        ],
                        [
                            113.8937605,
                            22.6715052,
                            0
                        ],
                        [
                            113.9047468,
                            22.6705548,
                            0
                        ],
                        [
                            113.9181364,
                            22.6724556,
                            0
                        ],
                        [
                            113.9256037,
                            22.6741979,
                            0
                        ],
                        [
                            113.9280928,
                            22.6879775,
                            0
                        ],
                        [
                            113.928951,
                            22.6934415,
                            0
                        ],
                        [
                            113.923171,
                            22.7240808,
                            0
                        ],
                        [
                            113.9122504,
                            22.7221334,
                            0
                        ],
                        [
                            113.9012565,
                            22.7210105,
                            0
                        ],
                        [
                            113.8890613,
                            22.7186994,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Shiyan Reservoir",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Megakorpen ReCorp har fått i uppdrag att<br>sanera den djupt förorenade Shiyan Reservoir, som sedan Kollapsen haft otjänligt<br>vatten som också förgiftat närliggande<br>grundvatten. Rykten i området gör gällan-<br>de att sjön förgiftades av kinesiska myn-<br>digheter när rebeller började samlas här i<br>inledningen av Kollapsen. Andra menar att<br>det är en av många ställen som förgiftats<br>när man valde att bomba Shenzhen.",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.2407818,
                            22.3204481,
                            0
                        ],
                        [
                            114.2394247,
                            22.3215944,
                            0
                        ],
                        [
                            114.2401087,
                            22.3235818,
                            0
                        ],
                        [
                            114.2401919,
                            22.3237927,
                            0
                        ],
                        [
                            114.2367693,
                            22.3285565,
                            0
                        ],
                        [
                            114.2338724,
                            22.3308392,
                            0
                        ],
                        [
                            114.2312975,
                            22.3305514,
                            0
                        ],
                        [
                            114.2312116,
                            22.3280703,
                            0
                        ],
                        [
                            114.2302032,
                            22.3287254,
                            0
                        ],
                        [
                            114.2305143,
                            22.3268992,
                            0
                        ],
                        [
                            114.2307825,
                            22.32548,
                            0
                        ],
                        [
                            114.2316408,
                            22.3234951,
                            0
                        ],
                        [
                            114.2343445,
                            22.3201603,
                            0
                        ],
                        [
                            114.2357178,
                            22.3189693,
                            0
                        ],
                        [
                            114.2375631,
                            22.3182547,
                            0
                        ],
                        [
                            114.2392583,
                            22.318751,
                            0
                        ],
                        [
                            114.2406852,
                            22.3191678,
                            0
                        ],
                        [
                            114.2407818,
                            22.3204481,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Crest Secured Residential Zone",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Zonen ägs av Ken Sirs konglomerat, och<br>huserar klonkroppar primärt för honom själv<br>men även för släkt, vänner och vissa favorit-anställda. Säkerheten sköts av Nimrod.<br><br>Ken Sirs problem är att han vill expandera<br>zonen, men ingen av grannzonerna tycks beredd att avträda territorium till någon som helst rimlig summa. <br><br>För att klonkropparna inte ska kontamineras råder strängt förbud mot att dö i zonen; skulle<br>så ändå ske måste närstående betala för<br>refaktorering av kroppen + en serviceav-<br>gift på 200 €. Livförsäkringar är null and void.",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.2107881,
                            22.3354387,
                            0
                        ],
                        [
                            114.2133844,
                            22.3325607,
                            0
                        ],
                        [
                            114.2143286,
                            22.3325012,
                            0
                        ],
                        [
                            114.2172039,
                            22.3329577,
                            0
                        ],
                        [
                            114.2192424,
                            22.3333149,
                            0
                        ],
                        [
                            114.2212379,
                            22.3332157,
                            0
                        ],
                        [
                            114.2208732,
                            22.3350417,
                            0
                        ],
                        [
                            114.2184913,
                            22.3362128,
                            0
                        ],
                        [
                            114.217,
                            22.3363815,
                            0
                        ],
                        [
                            114.21538,
                            22.3369471,
                            0
                        ],
                        [
                            114.2136419,
                            22.3384357,
                            0
                        ],
                        [
                            114.2115391,
                            22.3377807,
                            0
                        ],
                        [
                            114.2107881,
                            22.3354387,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Good Hope Controlled Area",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Adm. av Good Hope Militia<br><br>Efter att zonen skakades av fighter mellan triaderna och yakzen tröttnade medborgarna, och bjöd in en avfällig fraktion av Sai Kungs medborgarde att rensa upp. Det gjorde de med råge, men till priset av att bara besökare från utvalda grannzoner med låg \"kriminalitet\" släpps in i zonen numera, och taggtrådsstängsel och vaktposter är the order of the day. ",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.2472436,
                            22.3630562,
                            0
                        ],
                        [
                            114.2347981,
                            22.359643,
                            0
                        ],
                        [
                            114.2402913,
                            22.3520225,
                            0
                        ],
                        [
                            114.242995,
                            22.3481725,
                            0
                        ],
                        [
                            114.256525,
                            22.3430105,
                            0
                        ],
                        [
                            114.2578983,
                            22.3457097,
                            0
                        ],
                        [
                            114.2597866,
                            22.3462654,
                            0
                        ],
                        [
                            114.2650223,
                            22.3507109,
                            0
                        ],
                        [
                            114.2698288,
                            22.3494407,
                            0
                        ],
                        [
                            114.2682838,
                            22.3528542,
                            0
                        ],
                        [
                            114.262619,
                            22.3557913,
                            0
                        ],
                        [
                            114.2542934,
                            22.3621415,
                            0
                        ],
                        [
                            114.2472436,
                            22.3630562,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Clearwater Marina",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Adm. av Disangani-klanen.<br><br>Madagaskars diktator Disangani köpte zonen under utpressningsliknande former 2068, och har sedan dess gjort den till en lekplats för Madagaskars elit och osedvanligt korrupta paladiner. En mindre brigad livvakter och tjänare finns alltid på plats bland lustjakter och cocktails utifall att någon medlem av Disangani-klanen skulle dyka upp med sitt entourage. Annars känns zonen ofta märkligt ödslig, en konstig blandning av lyx, natur och fascist-paranoia. ",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.123184,
                            22.3893753,
                            0
                        ],
                        [
                            114.1236708,
                            22.4051865,
                            0
                        ],
                        [
                            114.1194651,
                            22.4053452,
                            0
                        ],
                        [
                            114.1145753,
                            22.4121947,
                            0
                        ],
                        [
                            114.105713,
                            22.4116788,
                            0
                        ],
                        [
                            114.0989112,
                            22.4057274,
                            0
                        ],
                        [
                            114.0933116,
                            22.3987442,
                            0
                        ],
                        [
                            114.1008828,
                            22.390228,
                            0
                        ],
                        [
                            114.1040585,
                            22.3905454,
                            0
                        ],
                        [
                            114.105417,
                            22.3856453,
                            0
                        ],
                        [
                            114.1149872,
                            22.3830269,
                            0
                        ],
                        [
                            114.123184,
                            22.3893753,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Chuen Lung Villas",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Adm. av Sulin Arya.<br><br>Översteinspektör Sulin Arya har en egen<br>zon, undanskymd och välbefäst, i bergen<br>ovanför de stora containerhamnarna. Utåt<br>sett ser det anspråkslöst ut, men hon sägs<br>ha använt sina många inkommande strömmar av mutpengar att bygga ett underjordiskt komplex, med replikor av romerska skulpturer och hinduiska konstverk. Här hyser hon även ett privat zoo av indisk palmmård för att producera kaffesorten kopi luwak.",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            113.7963126,
                            22.1775723,
                            0
                        ],
                        [
                            113.7942527,
                            22.1661266,
                            0
                        ],
                        [
                            113.7995742,
                            22.1507052,
                            0
                        ],
                        [
                            113.8095309,
                            22.135282,
                            0
                        ],
                        [
                            113.8069556,
                            22.1190623,
                            0
                        ],
                        [
                            113.8268683,
                            22.1107926,
                            0
                        ],
                        [
                            113.8498709,
                            22.1136553,
                            0
                        ],
                        [
                            113.8498709,
                            22.1591316,
                            0
                        ],
                        [
                            113.8145087,
                            22.182818,
                            0
                        ],
                        [
                            113.7963126,
                            22.1775723,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Kuishan Island",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Adm. av Indonesiska pirater.<br><br>Inte ursprungligen en del av Pearl River Delta Special Economic Zone, eller Frihamnsavtalet, men Kuishan Island gled ur Kinas händer efter att Chongqing ignorerade administrationen av ön allt för länge. Den är nu intagen av en än så länge oidentifierad indonesisk piratliga, möjligen en av de många från Sulawesi, som sägs ha utsatt lokalbefolkning för diverse grymheter. Det är populärt i PRD att försöka nå ön med kameradrönare, för att utröna något on den mystiska piratfraktionen och skryta om det på Lagren. Frågan är om piraterna kommer dröja kvar länge dock, för förr eller senare borde Gränskommandot ingripa. Åtminstone när de löst krisen med Madagaskars flotta i deltat...",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            113.8806959,
                            22.7582234,
                            0
                        ],
                        [
                            113.8881632,
                            22.7313103,
                            0
                        ],
                        [
                            113.8857599,
                            22.7192768,
                            0
                        ],
                        [
                            113.8890613,
                            22.7186994,
                            0
                        ],
                        [
                            113.9060159,
                            22.7216519,
                            0
                        ],
                        [
                            113.9142557,
                            22.7222853,
                            0
                        ],
                        [
                            113.923171,
                            22.7240808,
                            0
                        ],
                        [
                            113.9422989,
                            22.7263154,
                            0
                        ],
                        [
                            113.9459037,
                            22.7288487,
                            0
                        ],
                        [
                            113.9482446,
                            22.7317061,
                            0
                        ],
                        [
                            113.9497778,
                            22.7324544,
                            0
                        ],
                        [
                            113.9537142,
                            22.7319361,
                            0
                        ],
                        [
                            113.9513545,
                            22.7457499,
                            0
                        ],
                        [
                            113.9494234,
                            22.7502223,
                            0
                        ],
                        [
                            113.9476203,
                            22.7508553,
                            0
                        ],
                        [
                            113.946955,
                            22.7512116,
                            0
                        ],
                        [
                            113.9432665,
                            22.7518914,
                            0
                        ],
                        [
                            113.9166361,
                            22.7579791,
                            0
                        ],
                        [
                            113.8995553,
                            22.7552875,
                            0
                        ],
                        [
                            113.8806959,
                            22.7582234,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Robotown",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Adm. av HSTC<br><br>Ett rent industriprotektorat, i ett gudsförgätet hörn av deltat. Gamla samhällen ligger fortfarande i ruiner, men HSTCs robot-kontrollerade fabriker matar på för fullt med sina produktionslinjer av plastskräp, budget-tech och maskindelar. Säkerheten är hög, både för att avskräcka Gränskommandot från att kränka zonens autonomi, och eftersom det ryktas att den antikinesiska terrorgruppen Asian Dawn fått tag i säkerhetskoderna till flera HSTC-anläggningar i deltat. ",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.181789,
                            22.2812926,
                            0
                        ],
                        [
                            114.1820667,
                            22.2810795,
                            0
                        ],
                        [
                            114.1836868,
                            22.2776395,
                            0
                        ],
                        [
                            114.1855757,
                            22.2784247,
                            0
                        ],
                        [
                            114.1869921,
                            22.2784655,
                            0
                        ],
                        [
                            114.1874213,
                            22.2791803,
                            0
                        ],
                        [
                            114.1880254,
                            22.279656,
                            0
                        ],
                        [
                            114.1860942,
                            22.2827534,
                            0
                        ],
                        [
                            114.1830075,
                            22.2823771,
                            0
                        ],
                        [
                            114.1829048,
                            22.2817791,
                            0
                        ],
                        [
                            114.181789,
                            22.2812926,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Causeway Bay",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Adm. av Westover <br><br>Zonen kontrolleras av försäkringsbolaget<br>Westover. Det är en ren och välskött zon<br>för kommersiella ändamål, där norms<br>äter söndagsbrunch eller går i boutiquer. De få som har bostäder i zonen får extremt generös basinkomst. Detta har fått priserna på bostäder i zonen att skjuta i höjden även för att vara i Hongkong. ",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.1982682,
                            22.2930285,
                            0
                        ],
                        [
                            114.1993625,
                            22.2885415,
                            0
                        ],
                        [
                            114.2003113,
                            22.2887017,
                            0
                        ],
                        [
                            114.2033536,
                            22.2897526,
                            0
                        ],
                        [
                            114.2033322,
                            22.2907056,
                            0
                        ],
                        [
                            114.2023666,
                            22.2938227,
                            0
                        ],
                        [
                            114.2006285,
                            22.2934852,
                            0
                        ],
                        [
                            114.1982682,
                            22.2930285,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "North Point",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Adm. av 366 Studios.<br><br>En gren av Bollywood-maffian som kallar<br>sig Bada Vasundhara Gang dominerar<br>zonen genom en mjukvaru-mikrokorp<br>de satt upp: 366 Studios. Deras mystery<br>shoppers och skoterburna budbärare rör<br>sig smärtfritt i hela PRD med syfte att<br>hitta de senaste trenderna inom printing,<br>mode, media, shopping och lager. Därefter<br>stjäl, kopierar eller utpressar de sig till<br>designen som kan säljas dyrt för mass-<br>produktion. De som sätter sig emot hittas ofta dränkta eller förgiftade. <br><br>Peeps i tråkiga kläder släpps inte in i North Point, då det inte matchar 366 Studios company profile. ",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.1460609,
                            22.2477926,
                            0
                        ],
                        [
                            114.1466617,
                            22.2456278,
                            0
                        ],
                        [
                            114.1502891,
                            22.2463582,
                            0
                        ],
                        [
                            114.1546665,
                            22.2455638,
                            0
                        ],
                        [
                            114.1574989,
                            22.2464377,
                            0
                        ],
                        [
                            114.1605888,
                            22.2451666,
                            0
                        ],
                        [
                            114.1606514,
                            22.2482847,
                            0
                        ],
                        [
                            114.1590642,
                            22.2524597,
                            0
                        ],
                        [
                            114.1541183,
                            22.251864,
                            0
                        ],
                        [
                            114.1502237,
                            22.2538796,
                            0
                        ],
                        [
                            114.1458892,
                            22.2499673,
                            0
                        ],
                        [
                            114.1460609,
                            22.2477926,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Aberdeen Safety Zone",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Adm. av Ghee Hin-triaden.<br><br>Zonen behärskas av den sleeka singapo-<br>reanska Ghee Hin-triaden, som försöker<br>upprätthålla en atmosfär av corporate och<br>elegans medan de tvingar alla att vörda<br>deras förfadersandar och diverse obskyra<br>daoist-gudar. I själva verket har de dock<br>tagit över zonen för att röka ut sina ärke-<br>fiender Chi Sin, och deras fasad håller på<br>att krackelera i en orgie av våld och mord. Chi Sin i sin tur klänger sig kvar i ett hopp om att kunna profitera på den stegrande konflikten mellan Cyberport och Freedom Island-zonerna. ",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.1404994,
                            22.2485026,
                            0
                        ],
                        [
                            114.1382034,
                            22.2479035,
                            0
                        ],
                        [
                            114.138301,
                            22.2473491,
                            0
                        ],
                        [
                            114.1377484,
                            22.2445786,
                            0
                        ],
                        [
                            114.1376143,
                            22.2428408,
                            0
                        ],
                        [
                            114.1398245,
                            22.2421059,
                            0
                        ],
                        [
                            114.1426435,
                            22.240301,
                            0
                        ],
                        [
                            114.1460848,
                            22.2399063,
                            0
                        ],
                        [
                            114.1452023,
                            22.2408422,
                            0
                        ],
                        [
                            114.1439376,
                            22.2420683,
                            0
                        ],
                        [
                            114.1428218,
                            22.2447694,
                            0
                        ],
                        [
                            114.1466617,
                            22.2456278,
                            0
                        ],
                        [
                            114.1460609,
                            22.2477926,
                            0
                        ],
                        [
                            114.1459184,
                            22.2498316,
                            0
                        ],
                        [
                            114.1404994,
                            22.2485026,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "The Magazine Island Customs Area",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Adm. av Constantin Rodriguez.<br><br>En bisarr zon, sedan kollapsen styrd av den ambitiösa amerikanska legosoldaten Constantin Rodriguez, som lyckades roffa åt sig territorier på grund av teknikaliteter i Frihamnsavtalet. Han förlorade fort alla dessa till triaderna och megakorparna, men återvände flera år senare med en Virginia-class atomubåt han förvärvat från USAs kollaps. Eftersom han slagits på Kinas sida under Kollapsen och sågs som en hjälte av Gränskommandots soldater lyfte de inte ett finger för att stoppa honom när han började torpedera 88K-triadens containerfartyg. <br><br>Numera har Rodriguez gått psyko efter multipla mordförsök - det faktum att priset på hans huvud är uppe i 800 000€ från 88K-triaden gör att varenda drägg och pro i Pärldeltat är ute efter honom. Han har därför parkerat sig med sin posse och deras atomubåt utanför Magazine Island, och försvinner ibland, när paranoian tar överhanden, ned i havsdjupen. Annars tar han tull via virren på transporter längst motorvägen mellan Cyberport och Aberdeen, och eftersom han sitter på multipla tomahawk-missiler, torpeder och laservapen finner de flesta det för bäst att spela med. 0,5€ per fordon är ju inte så mycket, trots allt... ",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.1149286,
                            22.2784588,
                            0
                        ],
                        [
                            114.1163019,
                            22.2715488,
                            0
                        ],
                        [
                            114.1269985,
                            22.274428,
                            0
                        ],
                        [
                            114.1271829,
                            22.2743518,
                            0
                        ],
                        [
                            114.1307146,
                            22.2736004,
                            0
                        ],
                        [
                            114.130976,
                            22.2740719,
                            0
                        ],
                        [
                            114.1339172,
                            22.2781815,
                            0
                        ],
                        [
                            114.1338535,
                            22.2789059,
                            0
                        ],
                        [
                            114.133747,
                            22.2826087,
                            0
                        ],
                        [
                            114.1291765,
                            22.2868775,
                            0
                        ],
                        [
                            114.1206793,
                            22.2847729,
                            0
                        ],
                        [
                            114.1149286,
                            22.2784588,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Kennedy",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Adm. av Biologic 63%, Ayala 18%, Millenia Surveillance Electronics Inc 13%, Chow Tai Fook 6% <br><br>Zonen ägs av det indiska megaföretaget<br>Biologic som gjort sig ett namn på datorer<br>och deck inspirerade av biologi och i vissa<br>fall byggda med biologiska komponenter.<br>Här finns en av deras serverhallar. I de<br>säkrare delarna av zonen råder rigorös<br>klimatkontroll, med luftslussar, krav på<br>ansiktsmask och mikrobiologiska scan-<br>ners. Ingen vill att datorerna smittas av ett<br>faktiskt virus – eller tvärtom.",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.0078889,
                            22.3642121,
                            0
                        ],
                        [
                            114.0066873,
                            22.3670696,
                            0
                        ],
                        [
                            114.0001641,
                            22.3689745,
                            0
                        ],
                        [
                            113.9978468,
                            22.3724469,
                            0
                        ],
                        [
                            113.9970957,
                            22.374084,
                            0
                        ],
                        [
                            113.9953147,
                            22.3754828,
                            0
                        ],
                        [
                            113.9890491,
                            22.3769512,
                            0
                        ],
                        [
                            113.984543,
                            22.3732209,
                            0
                        ],
                        [
                            113.987547,
                            22.3675061,
                            0
                        ],
                        [
                            113.9873754,
                            22.3635374,
                            0
                        ],
                        [
                            113.9952289,
                            22.3631802,
                            0
                        ],
                        [
                            114.0000783,
                            22.3598067,
                            0
                        ],
                        [
                            114.0078889,
                            22.3642121,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Gold Coast",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Adm. av Jawharat Al Khalij Ltd 63%, Jardine Matheson 16%, Radomir Condello 14%, Chow Tai Fook 7%. <br><br>P11-maffian, ett kluster av anrika brottsfamiljer från södra Europa, har en ytterst låg profil i<br>PRD, men av någon anledning äger de denna<br>kustzon genom sina bulvaner Jawharat Al Khalij ltd samt multipla lokala hotell-mikrokorpar., som de tagit över en efter en. Zonen är ett typiskt tillhåll förbättre bemedlade pensionärer som vill andas lite halvfrisk havsluft. Sannolikt används zonens hotellrörelse för att tvätta pengar åt P11. Zonboss är Radomir Condello från den gamla serbiska maffian, som numera gått i pension genom att sköta denna zon åt P11. Han är en kapplöpningsfantast och syns ofta i Happy Valley. ",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.1626163,
                            22.333653,
                            0
                        ],
                        [
                            114.1624106,
                            22.3352249,
                            0
                        ],
                        [
                            114.154644,
                            22.3413336,
                            0
                        ],
                        [
                            114.1509748,
                            22.3445189,
                            0
                        ],
                        [
                            114.1511035,
                            22.3456998,
                            0
                        ],
                        [
                            114.151133,
                            22.3470419,
                            0
                        ],
                        [
                            114.1511303,
                            22.3475703,
                            0
                        ],
                        [
                            114.1501111,
                            22.3473421,
                            0
                        ],
                        [
                            114.1479087,
                            22.3468538,
                            0
                        ],
                        [
                            114.1430898,
                            22.3432591,
                            0
                        ],
                        [
                            114.1433086,
                            22.3404372,
                            0
                        ],
                        [
                            114.1445853,
                            22.3374104,
                            0
                        ],
                        [
                            114.1438987,
                            22.3316545,
                            0
                        ],
                        [
                            114.1468894,
                            22.3306095,
                            0
                        ],
                        [
                            114.1548929,
                            22.3263021,
                            0
                        ],
                        [
                            114.1552563,
                            22.3256639,
                            0
                        ],
                        [
                            114.1577962,
                            22.3282022,
                            0
                        ],
                        [
                            114.1626163,
                            22.333653,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Platinum Zone",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Adm. av: 88K-triaden, Wo Hop To-triaden, Chi Sin-triaden, en myriad mikrokorpar. <br><br>Det märks knappast på ytan i Shamshuipos folkmyller, smuts och kaos, men den mytomspunna maffian Syndikatet har stort inflytande bakom kulisserna. Det är inte vad någon skulle vänta sig, eftersom Syndikatet är kända för att söka sig till mer lyxade områden, men på något vis har triadernas och företagens rivalitet i Shamshuipo låtit flera Syndikatet-kontrollerade bulvaner och volocorps slå sig in. Om du har rätt edges och går in på VIP-våningen på nattklubben DW Lounge eller kontorsskrapan Tins Enterprise Center så möter du snart en rekryterare (eller en brutal ronin) redo att ta dig till nästa steg.",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.1430898,
                            22.3432591,
                            0
                        ],
                        [
                            114.1393926,
                            22.3403875,
                            0
                        ],
                        [
                            114.1401329,
                            22.3398814,
                            0
                        ],
                        [
                            114.141195,
                            22.3395539,
                            0
                        ],
                        [
                            114.1433086,
                            22.3404372,
                            0
                        ],
                        [
                            114.1430898,
                            22.3432591,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "CLP Power Zone",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Adm. av: HSTC  <br><br>En extremt intetsägande och obetydlig zon, som dock ger Weihu-keiretsun möjlighet att spärra av Shamshuipos motorvägar om lusten skulle falla på. HSTC lade nyligen ned dotterbolaget CLP Power Hong Kong Ltd, och tog därmed direkt kontroll över zonen. ",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.1717162,
                            22.3031875,
                            0
                        ],
                        [
                            114.1736206,
                            22.3033672,
                            0
                        ],
                        [
                            114.1734222,
                            22.3051639,
                            0
                        ],
                        [
                            114.1730145,
                            22.3060373,
                            0
                        ],
                        [
                            114.1737333,
                            22.3066379,
                            0
                        ],
                        [
                            114.1746703,
                            22.3070986,
                            0
                        ],
                        [
                            114.1732218,
                            22.3076173,
                            0
                        ],
                        [
                            114.1722258,
                            22.3082796,
                            0
                        ],
                        [
                            114.171625,
                            22.3089942,
                            0
                        ],
                        [
                            114.1708096,
                            22.3090538,
                            0
                        ],
                        [
                            114.169844,
                            22.3089347,
                            0
                        ],
                        [
                            114.1689214,
                            22.3090736,
                            0
                        ],
                        [
                            114.1676768,
                            22.3043687,
                            0
                        ],
                        [
                            114.1681596,
                            22.3030139,
                            0
                        ],
                        [
                            114.1717162,
                            22.3031875,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Jordan",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Adm. av 88K-triaden.<br><br>Ett mikrokosm av plebsens Hong Kong, fyllt med åldrade high-rises, kontorsbyggnader, gatumarknader, hotell, restauranger och ett närmast oändligt antal små butiker och mikrokorpar.<br><br>88K upprätthåller ordningen och ser till att Jordan är mindre våldsamt och sunkigt än Mongkok och Shamshuipo, allt under löftet att den som stör ordningen får handen instoppad i en nudelkvarn. Det fungerar förvånansvärt bra! Enda problemet är att AIerna hos megakorparna InterWorld och Yumacom, var för sig, har prioriterat upp Jordan i sina algoritmer, och bägge korparna försöker nu slå sig in på 88K-domäner. 88K lär sätta hårt mot hårt, för Jordan är juvelen i deras krona i Pärldeltat. I vad som förmodligen är ett försök att jävlas med korparna har 88K infört en regel att endast kantonesiska och mandarin får talas på gatan i Jordan. ",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.1730379,
                            22.3010247,
                            0
                        ],
                        [
                            114.1734187,
                            22.2998186,
                            0
                        ],
                        [
                            114.176219,
                            22.3005284,
                            0
                        ],
                        [
                            114.1762619,
                            22.300841,
                            0
                        ],
                        [
                            114.1758757,
                            22.3009006,
                            0
                        ],
                        [
                            114.1755323,
                            22.3010346,
                            0
                        ],
                        [
                            114.1750656,
                            22.3012282,
                            0
                        ],
                        [
                            114.1747759,
                            22.301392,
                            0
                        ],
                        [
                            114.1745024,
                            22.3012977,
                            0
                        ],
                        [
                            114.1730379,
                            22.3010247,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Koreatown",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Adm. av Samsundai.<br><br>Samsundais enda officiella zon i Pärldeltat. Mega-megakorpen har egentligen en mycket begränsad närvaro här, även om den äger majoriteten av byggnaderna samt tar hand om infrastrukturen. Zonens själ och hjärta är istället dess brokiga flora av koreanska näringsidkare. Många av deras butiker och restauranger har funnits i Hong Kong i över 100 år nu, och är verkliga institutioner på stadens gatujello-scen. Deras enda problem är trakasserier från yakuza-gruppen Dojima-gumi, som börjat bli stark på Kowloon. ",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.18412,
                            22.2983258,
                            0
                        ],
                        [
                            114.1885617,
                            22.2999537,
                            0
                        ],
                        [
                            114.1879609,
                            22.3013831,
                            0
                        ],
                        [
                            114.1864803,
                            22.3008868,
                            0
                        ],
                        [
                            114.1855791,
                            22.3008868,
                            0
                        ],
                        [
                            114.1844848,
                            22.30045,
                            0
                        ],
                        [
                            114.183369,
                            22.2997155,
                            0
                        ],
                        [
                            114.18412,
                            22.2983258,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Harbour Front Horizons",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Adm. av Yumacom 79%, Griemann Okker GmbH 21%. <br><br>En liten zon som endast innehåller ett enormt bostadskomplex med vidunderlig utsikt, samt en stor tech-butik och ett konferenscenter, bägge signerade Yumacom. Även Griemann Okker GmbH har dock en liten andel. Alla Yumacom och Griemann Okker-produkter kan inhandlas till en atta 5% rabatt. Övriga elektronikbrands är antingen förbjudna eller belagda med en 20 % serviceavgift.<br><br>Allt innehav av cybertech måste deklare-<br>ras vid zongränsen, för att skydda Yumacoms IPs.",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.1924248,
                            22.3100177,
                            0
                        ],
                        [
                            114.1954825,
                            22.3195063,
                            0
                        ],
                        [
                            114.194954,
                            22.3197895,
                            0
                        ],
                        [
                            114.1936827,
                            22.3204446,
                            0
                        ],
                        [
                            114.1936381,
                            22.3203372,
                            0
                        ],
                        [
                            114.1932294,
                            22.3204392,
                            0
                        ],
                        [
                            114.1877041,
                            22.3144246,
                            0
                        ],
                        [
                            114.1875896,
                            22.3088228,
                            0
                        ],
                        [
                            114.1903791,
                            22.3088327,
                            0
                        ],
                        [
                            114.1906993,
                            22.3088184,
                            0
                        ],
                        [
                            114.1918768,
                            22.3098832,
                            0
                        ],
                        [
                            114.1924248,
                            22.3100177,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Dynasty Drive",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Adm. av Royal Group<br><br>Zonen styrs av en av megakorpen Royal<br>Groups ägarfamiljer, al-Qasimi (shejkerna<br>av Sharjah och Ras al Khaimah). Dessa<br>försöker gentrifiera zonen med aggressivt<br>luftkonditionerade shoppinggallerior, lyx-<br>iga boutiques och könssegregerade span,<br>vilket inte gillas av lokala islamofober.<br>Normsen är dock supernöjda, eftersom<br>diverse hypermediala arabiska kungligheter av och till besöker zonen och drar till sig horder av paparazzis och b-celebs.",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.1969744,
                            22.2724248,
                            0
                        ],
                        [
                            114.1978112,
                            22.2694264,
                            0
                        ],
                        [
                            114.2023904,
                            22.2664642,
                            0
                        ],
                        [
                            114.2060382,
                            22.2687676,
                            0
                        ],
                        [
                            114.205695,
                            22.2718653,
                            0
                        ],
                        [
                            114.2014034,
                            22.2733744,
                            0
                        ],
                        [
                            114.2005193,
                            22.2737138,
                            0
                        ],
                        [
                            114.1969744,
                            22.2724248,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Test Range Zero",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Adm. av Ultech<br><br>Zonen ägs av vapentillverkaren Ultech<br>som använder den för att testa nya vapen<br>samt som provskjutbana för framtida<br>kunder. Random explosioner sker ibland<br>och rasrisken är alltid ett problem som<br>förbipasserande måste hantera. Detta irriterar invånarna i grannzonen Jardine's Lookout, som upprepade gånger försökt övertyga Ultech att sluta med provskjutningar, eller ännu hellre sälja zonen till dem. Hittils har Ultech inte hörsammat detta, men Aga Khan Development Network, Jardine's Lookout's bossar, ger sig inte så lätt, och ryktas planera nån slags kupp mot Ultech. ",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.1773659,
                            22.456598,
                            0
                        ],
                        [
                            114.1749411,
                            22.4557056,
                            0
                        ],
                        [
                            114.1710573,
                            22.4560824,
                            0
                        ],
                        [
                            114.1686326,
                            22.4571136,
                            0
                        ],
                        [
                            114.1669374,
                            22.4561221,
                            0
                        ],
                        [
                            114.167431,
                            22.4558445,
                            0
                        ],
                        [
                            114.1672593,
                            22.4505496,
                            0
                        ],
                        [
                            114.1697269,
                            22.4505298,
                            0
                        ],
                        [
                            114.1735234,
                            22.4505719,
                            0
                        ],
                        [
                            114.1768723,
                            22.4491717,
                            0
                        ],
                        [
                            114.1772586,
                            22.4519877,
                            0
                        ],
                        [
                            114.1779023,
                            22.4538519,
                            0
                        ],
                        [
                            114.1773659,
                            22.456598,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Tai Po Centre",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Adm. av The Society for Truth and Light 75%, Indonesian Group Travel Agency 10%, Jawharat Al Khalij Ltd 8%, Sun Hung Kai Properties 7% <br><br>Zonen ägs och kontrolleras i varje detalj<br>av frikyrkliga STL, som sakta tagit över alla korpars filialer i zonen. I Tai Po Center finns både både begravningsbyrå, megakyrka, skolor och<br>bostäder i STLs direkta regi. Dekadens och felaktig religion straffas med publik prygling och deporta-tion av Sentry. Den lokala STL-falangen hatar augs och bedriver regelbundet häxjakter på sådana. De expanderar ständigt,<br>men nu behöver de hjälp för att infiltrera<br>och exponera grannzonen. ",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.1769498,
                            22.3127313,
                            0
                        ],
                        [
                            114.1772684,
                            22.3125836,
                            0
                        ],
                        [
                            114.1776385,
                            22.3126407,
                            0
                        ],
                        [
                            114.1810288,
                            22.3092263,
                            0
                        ],
                        [
                            114.1822948,
                            22.3084521,
                            0
                        ],
                        [
                            114.1849744,
                            22.3104481,
                            0
                        ],
                        [
                            114.1874206,
                            22.3142992,
                            0
                        ],
                        [
                            114.1878553,
                            22.3227886,
                            0
                        ],
                        [
                            114.1857295,
                            22.325024,
                            0
                        ],
                        [
                            114.1780406,
                            22.3215068,
                            0
                        ],
                        [
                            114.1792667,
                            22.3196192,
                            0
                        ],
                        [
                            114.1789663,
                            22.318309,
                            0
                        ],
                        [
                            114.1782367,
                            22.3163637,
                            0
                        ],
                        [
                            114.1772068,
                            22.3152124,
                            0
                        ],
                        [
                            114.1769498,
                            22.3127313,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Ken Sir Gardens",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Adm. av Ken Sir 80%, Gold & Sons Brokering 10%<br>MediPlant 48h Clinic 5%, Jardine Matheson 5%<br><br>Ken Sir köpte majoriteten av zonen från Jardine Matheson 2064, och har sedan dess rustat upp den med diverse udda botanik-projekt. Rykten cirkulerar givetvis att de exotiska plantor som odlas här har någon form av roll i Ken Sirs påstådda projekt att göra sig odödlig genom en mix av biotech och daoistisk alkemi, men det kanske bara är så att siren gillar vackra trädgårdar? Vem vet? ",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.1407974,
                            22.2877333,
                            0
                        ],
                        [
                            114.140733,
                            22.2861648,
                            0
                        ],
                        [
                            114.1422887,
                            22.28614,
                            0
                        ],
                        [
                            114.1423005,
                            22.2876741,
                            0
                        ],
                        [
                            114.1407974,
                            22.2877333,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Gaybarhood",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Adm. av: Hong Kong Anti-Norm Association<br><br>Hong Kongs mest koolu gaybarer och queera etablissemang håller till den här lilla zonen, mellan de old-schooliga gaybarerna i Sheung Wan och det kartell-sponsrade kräm-överflödet i Shek Tong Tsui. Faktum är att La Únion-kartellen rört sig in på området, och ägarna av barer som Hmong Cock och Wink tros numera gå i dess ledband. <br><br>Givetvis är det knappt någon aibo som är vanilj-hetero nu år 2070, men Gaybarhood är zonen för peeps som lever för #Bizarro, #Retro och #Salpo. För att hålla sig väl med korparna i Central har man inte alltför anti-normiga bestämmelser i zonen, men på fredagkvällar samt alla helgdagar släpps endast queera eller bizarra peeps in i zonen. ",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.1816764,
                            22.3465442,
                            0
                        ],
                        [
                            114.1820198,
                            22.3442222,
                            0
                        ],
                        [
                            114.184423,
                            22.3433092,
                            0
                        ],
                        [
                            114.1858876,
                            22.3441505,
                            0
                        ],
                        [
                            114.1852117,
                            22.3461847,
                            0
                        ],
                        [
                            114.186982,
                            22.3477178,
                            0
                        ],
                        [
                            114.1865259,
                            22.3503149,
                            0
                        ],
                        [
                            114.1833716,
                            22.3511683,
                            0
                        ],
                        [
                            114.1803461,
                            22.3495608,
                            0
                        ],
                        [
                            114.18011,
                            22.3482708,
                            0
                        ],
                        [
                            114.1816764,
                            22.3465442,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Lion Rock Cavern 24",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Adm. av Biologic<br><br>Zonen ägs av det indiska megaföretaget<br>som gjort sig ett namn på datorer och<br>deck inspirerade av biologi och i vissa<br>fall byggda med biologiska komponenter.<br>Zonen är nästan helt i form av en gigantisk<br>grotta under berget Lion Rock. Här finns<br>Biologics serverhallar för både eget bruk<br>och som uthyrd resurs. I grottorna råder<br>rigorös klimatkontroll, med luftslussar,<br>krav på ansiktsmask och mikrobiologiska<br>scanners. Ingen vill att datorerna smittas<br>av ett faktiskt virus – eller tvärtom.",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.1651947,
                            22.3087515,
                            0
                        ],
                        [
                            114.1652161,
                            22.306409,
                            0
                        ],
                        [
                            114.1668469,
                            22.3060715,
                            0
                        ],
                        [
                            114.167555,
                            22.30895,
                            0
                        ],
                        [
                            114.1651947,
                            22.3087515,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Pu Tin",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Adm. av Yevgenia Nikolayeva-Hudson<br><br>Genom att bulta ned sina sentrytorn i hörnen på detta kvarter har bratvan, ryska maffian, claimat det som sitt. Zonens loggbarer, restauranger och porrklubbar har blivit »frivilligt« anslutna till deras försäkringsprogram. Zonen är en hub för handel med krom och bratvan kan bidra med det mesta i termer om cyber och flingor. Utöver<br>att ta marknadsandelar från andra OC:s är<br>målet att göra slut på sibiriska Röd grynings närvaro i Pärldeltat. <br><br>Bratvan tillåter ingen fysisk reklam i Pu Tin, utom sådan som hyllar Ryssland och en traddig rysk identitet. ",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.1597274,
                            22.3267532,
                            0
                        ],
                        [
                            114.1565323,
                            22.3233042,
                            0
                        ],
                        [
                            114.1577285,
                            22.321076,
                            0
                        ],
                        [
                            114.1594372,
                            22.323195,
                            0
                        ],
                        [
                            114.1599349,
                            22.3231801,
                            0
                        ],
                        [
                            114.1598958,
                            22.3241626,
                            0
                        ],
                        [
                            114.1619128,
                            22.3241825,
                            0
                        ],
                        [
                            114.1618485,
                            22.3251353,
                            0
                        ],
                        [
                            114.1597274,
                            22.3267532,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Steamer Park Lebensraum",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Alter-gänget Happy Hitlers regerar i<br>zonen. Zonens fysiska och virtuella rum är flitigt utsmyckade med hyllningar till gängets celeb-gudom men på det hela taget är det ett ganska lyxat ställe. Gänget är dock i desperat behov av kudi för att kunna skicka sina ledare till nästa Happy Hitlers i Berlin!<br><br>Strikt alkoholförbud råder givetvis i zonen, liksom ett krav på vegetarisk kosthållning. Schäfrar rör sig fritt i parken, som är fylld av gammal rostig Tredje riket-parafernalia. ",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.1748998,
                            22.2767013,
                            0
                        ],
                        [
                            114.1745838,
                            22.2760824,
                            0
                        ],
                        [
                            114.1742083,
                            22.2756257,
                            0
                        ],
                        [
                            114.173983,
                            22.2753676,
                            0
                        ],
                        [
                            114.1740366,
                            22.2744343,
                            0
                        ],
                        [
                            114.1751884,
                            22.2743876,
                            0
                        ],
                        [
                            114.1756819,
                            22.2748145,
                            0
                        ],
                        [
                            114.1775487,
                            22.2751322,
                            0
                        ],
                        [
                            114.1797213,
                            22.2745216,
                            0
                        ],
                        [
                            114.1807202,
                            22.2762992,
                            0
                        ],
                        [
                            114.1806397,
                            22.2762744,
                            0
                        ],
                        [
                            114.1794274,
                            22.2786571,
                            0
                        ],
                        [
                            114.1772387,
                            22.277982,
                            0
                        ],
                        [
                            114.175978,
                            22.2774161,
                            0
                        ],
                        [
                            114.1748998,
                            22.2767013,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "South Wan Chai",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Adm. av Chow Tai Fook 33%, Westover 33%, Sun Hung Kai Properties 33%,1% fluktuerande. <br><br>Det totala dödläget mellan södra Wan Chais ägare har resulterart i en uppgivenhet där ingen längre bryr sig om att göra långsiktiga investeringar i den förfallna zonen. Den är därmed ett fritt fält för triaderna och yakuzan,  och skotten från deras seigos hörs som ett avlägset ackompanjemang till musiken som ekar från zonens skumma barer och privata klubbar. ",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            113.3162246,
                            23.1048136,
                            0
                        ],
                        [
                            113.3157526,
                            23.101182,
                            0
                        ],
                        [
                            113.3153234,
                            23.0973925,
                            0
                        ],
                        [
                            113.3154971,
                            23.0956279,
                            0
                        ],
                        [
                            113.3273826,
                            23.0958332,
                            0
                        ],
                        [
                            113.3274255,
                            23.1020233,
                            0
                        ],
                        [
                            113.3262937,
                            23.1022232,
                            0
                        ],
                        [
                            113.3252369,
                            23.1024452,
                            0
                        ],
                        [
                            113.3205239,
                            23.1029147,
                            0
                        ],
                        [
                            113.3185508,
                            23.10364,
                            0
                        ],
                        [
                            113.3170028,
                            23.1047995,
                            0
                        ],
                        [
                            113.3162246,
                            23.1048136,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Nouveau Djibouti",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Adm. av Djiboutis regering.<br><br>När Eritrea invaderade Djibouti vände världssamfundet Djibouti ryggen. Invasionen ansågs berättigad eftersom Djiboutis finansminister råkat förolämpa Eritreas strongman Jumba i The Sandeep Show. Men hos Canaan Liberation Group fick Djibouti stöd, och exilregeringen tilläts muta in denna lilla zon i utbyte mot att de använde lite av sin € för att rustas upp zonen. Den består i hög grad av slitna high-rises från före Kollapsen, men här finns också en del grönområden, och numera en aktiv community av peeps från Afrikas horn. Somliga kallar det \"Somalitown\", trots att större delen av befolkningen är kineser och djiboutier, men det egentliga namnet är Nouveau Djibouti.  132 av mondes nationer har erkänt det som den legitima representanten för Djibouti, och därmed är det det närmaste någon zon i hela deltat kan komma att kalla sig för en stat. ",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            113.4888119,
                            23.1955081,
                            0
                        ],
                        [
                            113.4452211,
                            23.1928985,
                            0
                        ],
                        [
                            113.4416567,
                            23.1848335,
                            0
                        ],
                        [
                            113.4419549,
                            23.180319,
                            0
                        ],
                        [
                            113.4390779,
                            23.17739,
                            0
                        ],
                        [
                            113.4384325,
                            23.1741455,
                            0
                        ],
                        [
                            113.4349102,
                            23.1723117,
                            0
                        ],
                        [
                            113.4349102,
                            23.1700431,
                            0
                        ],
                        [
                            113.4328931,
                            23.1680112,
                            0
                        ],
                        [
                            113.4330004,
                            23.1664133,
                            0
                        ],
                        [
                            113.4336227,
                            23.1648154,
                            0
                        ],
                        [
                            113.4370788,
                            23.1432616,
                            0
                        ],
                        [
                            113.4573348,
                            23.1393154,
                            0
                        ],
                        [
                            113.4563254,
                            23.1368868,
                            0
                        ],
                        [
                            113.4705733,
                            23.1351504,
                            0
                        ],
                        [
                            113.4881037,
                            23.1434289,
                            0
                        ],
                        [
                            113.4922026,
                            23.1454107,
                            0
                        ],
                        [
                            113.4939192,
                            23.1454896,
                            0
                        ],
                        [
                            113.4970091,
                            23.1447004,
                            0
                        ],
                        [
                            113.5050772,
                            23.1401228,
                            0
                        ],
                        [
                            113.5126303,
                            23.1347558,
                            0
                        ],
                        [
                            113.5153769,
                            23.1183376,
                            0
                        ],
                        [
                            113.5141753,
                            23.1091805,
                            0
                        ],
                        [
                            113.5199261,
                            23.09568,
                            0
                        ],
                        [
                            113.5221142,
                            23.0859603,
                            0
                        ],
                        [
                            113.5296249,
                            23.0708879,
                            0
                        ],
                        [
                            113.5330582,
                            23.0700982,
                            0
                        ],
                        [
                            113.5378647,
                            23.0737306,
                            0
                        ],
                        [
                            113.5395813,
                            23.0762574,
                            0
                        ],
                        [
                            113.5461044,
                            23.0794159,
                            0
                        ],
                        [
                            113.5479927,
                            23.0783105,
                            0
                        ],
                        [
                            113.5443878,
                            23.0626752,
                            0
                        ],
                        [
                            113.5555881,
                            23.0734846,
                            0
                        ],
                        [
                            113.5574341,
                            23.0759416,
                            0
                        ],
                        [
                            113.6329651,
                            23.10626,
                            0
                        ],
                        [
                            113.6967306,
                            23.1253704,
                            0
                        ],
                        [
                            113.7150577,
                            23.1376809,
                            0
                        ],
                        [
                            113.7256813,
                            23.1387839,
                            0
                        ],
                        [
                            113.7347603,
                            23.1416228,
                            0
                        ],
                        [
                            113.7298712,
                            23.1499448,
                            0
                        ],
                        [
                            113.7245417,
                            23.1546177,
                            0
                        ],
                        [
                            113.7190423,
                            23.1602029,
                            0
                        ],
                        [
                            113.7198942,
                            23.1627104,
                            0
                        ],
                        [
                            113.7206538,
                            23.1681988,
                            0
                        ],
                        [
                            113.7225292,
                            23.170314,
                            0
                        ],
                        [
                            113.7215358,
                            23.1730088,
                            0
                        ],
                        [
                            113.7201132,
                            23.1742045,
                            0
                        ],
                        [
                            113.7163605,
                            23.1764122,
                            0
                        ],
                        [
                            113.7128648,
                            23.1903763,
                            0
                        ],
                        [
                            113.7077596,
                            23.2105713,
                            0
                        ],
                        [
                            113.6034114,
                            23.2033688,
                            0
                        ],
                        [
                            113.5684105,
                            23.2182232,
                            0
                        ],
                        [
                            113.4888119,
                            23.1955081,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Laoyao's Dominion",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Adm. av Li \"Laoyao\" Hewei.<br><br>Heter egentligen \"Northeast Buffer Zone\", men det verkar alla ha glömt. Ett gudsförgätet område av gamla förorter separerade av minfält och bombkratrar, men trots detta en ökänd zon i Pärldeltat. Li \"Laoyao\" Hewei, en gammal höjdare från Dongguans polismakt, kom på tidigt 40-tal på kant med sina kollegor, och förskansade sig istället i den här zonen med ett berg av gammalt krigsmateriel och en posse av trogna, korrupta och mer eller mindre psykopatiska gamla militärpoliser. Han har sedan dess härskat oinskränkt över zonen, och begått en mindre plantskola av skändligheter som ingen lyft ett finger för att stoppa eftersom de inte tekniskt sett är lagbrott i Pärldeltat, och ingen är sugen på att slösa € och manskap på att försöka röka ut honom - inte bara har han gott om krom samt lojalt manskap milt hjärntvättade genom någon sorts personkult, utan dessutom guanxi med Gränskommandot.<br><br>Kronan bland alla Laoyaos tvivelaktiga hobbies och intriger är hans bizarra, perversa ambition att avla barn med zesgos från alla länder i monde. Detta löser han genom att leja diverse kidnappare som tillfångatar livmoderbärare i olika länder och transporterar dem till hans zon, där de sedan hålls som sexslavar. Enligt egen utsago är han vid det här laget uppe i 173 nationaliter plus alla europeiska delstater, och har 100+ barn som hunnit bli tonåringar och hjälper honom behålla kontrollen över zonen. Denna \"bedrift\" har gjort honom till en legend inom vissa extrema falanger av amok-sfären, som troget följer hans poster på 99chan, men i övrigt är han avskydd av de flesta i deltat, och sticker aldrig näsan utanför sin zon, eftersom multipla fraktioner och stater vill se honom död. ",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            113.3274255,
                            23.1020233,
                            0
                        ],
                        [
                            113.3273826,
                            23.0958332,
                            0
                        ],
                        [
                            113.3336074,
                            23.0960226,
                            0
                        ],
                        [
                            113.3345006,
                            23.0987152,
                            0
                        ],
                        [
                            113.332957,
                            23.101728,
                            0
                        ],
                        [
                            113.3293097,
                            23.1016911,
                            0
                        ],
                        [
                            113.3274255,
                            23.1020233,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Swahilitown",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Adm. av Nimrod.<br><br>Många flyktingar från det krigshärjade östafrika har bosatt sig i det här området, som nu är fullt av swahili-kultur och blomstrande kommers, allt under ledning av Anastacia Nanthumbo och Ortega Saidi, två afrikanska korpar djupt insyltade i Nimrod. Detta vaktbolag patrullerar för övrigt zonens gator, men kommenterar inte de envisa ryktena om konstiga sjukdomar, spridda av flyktingar som skadats av madagaskiska biovapen. ",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.1500956,
                            22.2841923,
                            0
                        ],
                        [
                            114.1496074,
                            22.283686,
                            0
                        ],
                        [
                            114.1496879,
                            22.2836388,
                            0
                        ],
                        [
                            114.1499266,
                            22.2836091,
                            0
                        ],
                        [
                            114.1503209,
                            22.2835371,
                            0
                        ],
                        [
                            114.1504255,
                            22.2838275,
                            0
                        ],
                        [
                            114.1504926,
                            22.2839913,
                            0
                        ],
                        [
                            114.1500956,
                            22.2841923,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Man Mo Temple Zone (PLGTH) ",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Adm. av Pure Land of the Goddess Tin Hau<br><br>En av Pärldeltats minsta zoner. Dess koncept går helt ut på att vårda det klassiska Man Mo-templet, samt att få turister att lämna generösa offergåvor. Ett höghus i zonen huserar gratis boenden åt en grupp fromma gamla analoger som sköter om zonen ihop med Claire Yuen, en avhoppad Emperor Security-seco som blivit frälst. ",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.1699387,
                            22.3100521,
                            0
                        ],
                        [
                            114.1701479,
                            22.3089603,
                            0
                        ],
                        [
                            114.1708096,
                            22.3090538,
                            0
                        ],
                        [
                            114.1712852,
                            22.3090199,
                            0
                        ],
                        [
                            114.1711189,
                            22.3102755,
                            0
                        ],
                        [
                            114.169928,
                            22.3100869,
                            0
                        ],
                        [
                            114.1699387,
                            22.3100521,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "The Tin Hau Temple Zone (PLGTH)",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Adm. av Pure Land of the Goddess Tin Hau<br><br>Hong Kongs kanske främsta Tin Hau-tempel, och en stor turist-draw. De beväpnade väktarna slänger dock ut alla som på arbiträra grunder kränker zonen, tex femmes som visar låren, peeps som pratar för högt eller Khalsa Khowloons sikher. ",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.1708232,
                            22.275546,
                            0
                        ],
                        [
                            114.1706636,
                            22.2754057,
                            0
                        ],
                        [
                            114.1708205,
                            22.2753065,
                            0
                        ],
                        [
                            114.1709506,
                            22.2754815,
                            0
                        ],
                        [
                            114.1708232,
                            22.275546,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Hung Shing Temple (PLGTH)",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Adm. av: Tung Wah Group of Hospitals (i teorin) lokala analoger (i praktiken).<br><br>Ännu en del av den utspridda Pure Land of the Goddess Tin Hau-zonen, inpyrd av rökelse, fromhet och traditonalism. En klassisk kandidat till den minsta zonen i Pärldeltat. Trot' eller ej, men när templet byggdes låg det vid kusten. Massiv land reclamation i Wan Chai har genom åren pushat kusten allt längre bort. ",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.172797,
                            22.2765424,
                            0
                        ],
                        [
                            114.1719279,
                            22.2750333,
                            0
                        ],
                        [
                            114.1723721,
                            22.2746999,
                            0
                        ],
                        [
                            114.1728495,
                            22.2745262,
                            0
                        ],
                        [
                            114.173386,
                            22.2744468,
                            0
                        ],
                        [
                            114.1740366,
                            22.2744343,
                            0
                        ],
                        [
                            114.173983,
                            22.2753676,
                            0
                        ],
                        [
                            114.1743618,
                            22.2758082,
                            0
                        ],
                        [
                            114.1745838,
                            22.2760824,
                            0
                        ],
                        [
                            114.1746976,
                            22.2763009,
                            0
                        ],
                        [
                            114.1748998,
                            22.2767013,
                            0
                        ],
                        [
                            114.172797,
                            22.2765424,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Cactus Zone",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Adm. av Sentry<br><br>Wan Chais lilla \"Tex Mex Town\", som började med att Sentry gav lägenheter här åt pensionerade mexikanska veteraner. Sedermera kom andra latinos hit och öppnade tacobarer, butiker och en liten, mycket shady lucha libre-arena. <br><br>Kartellerna från Chek Tong Tsui har börjat få in fötterna i zonen, liksom triaderna och yakuzan från Wan Chai. Varenda salsa-kurs, kokain-provning och chiliodling på en takterass har nu organiserad brottslighet med i spelet. Sentry tycks ha övervägt att rensa upp i zonen, men då den är så nedgången har de istället hållit sig undan, och verkar hoppas att krimsen ska ta kål på varandra inom kort. ",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            113.1979275,
                            23.2057425,
                            0
                        ],
                        [
                            113.1950093,
                            23.1978536,
                            0
                        ],
                        [
                            113.1912327,
                            23.1950135,
                            0
                        ],
                        [
                            113.1860829,
                            23.1935934,
                            0
                        ],
                        [
                            113.1838513,
                            23.1904376,
                            0
                        ],
                        [
                            113.1852246,
                            23.1852304,
                            0
                        ],
                        [
                            113.1867695,
                            23.1787606,
                            0
                        ],
                        [
                            113.1853962,
                            23.1711857,
                            0
                        ],
                        [
                            113.1845427,
                            23.1679119,
                            0
                        ],
                        [
                            113.1819892,
                            23.167113,
                            0
                        ],
                        [
                            113.1825793,
                            23.1642328,
                            0
                        ],
                        [
                            113.1775045,
                            23.1599715,
                            0
                        ],
                        [
                            113.1747532,
                            23.1552456,
                            0
                        ],
                        [
                            113.1732083,
                            23.1501948,
                            0
                        ],
                        [
                            113.1783581,
                            23.139777,
                            0
                        ],
                        [
                            113.182478,
                            23.1323578,
                            0
                        ],
                        [
                            113.1868223,
                            23.125431,
                            0
                        ],
                        [
                            113.1924871,
                            23.1210896,
                            0
                        ],
                        [
                            113.1977228,
                            23.1210107,
                            0
                        ],
                        [
                            113.2053089,
                            23.1222544,
                            0
                        ],
                        [
                            113.2114888,
                            23.1211493,
                            0
                        ],
                        [
                            113.2112884,
                            23.1277818,
                            0
                        ],
                        [
                            113.213377,
                            23.1374092,
                            0
                        ],
                        [
                            113.2158375,
                            23.1492497,
                            0
                        ],
                        [
                            113.2184124,
                            23.1489341,
                            0
                        ],
                        [
                            113.2163429,
                            23.1570122,
                            0
                        ],
                        [
                            113.2194328,
                            23.1673497,
                            0
                        ],
                        [
                            113.2123471,
                            23.1744998,
                            0
                        ],
                        [
                            113.2085705,
                            23.183968,
                            0
                        ],
                        [
                            113.2099438,
                            23.1924889,
                            0
                        ],
                        [
                            113.2034207,
                            23.2038492,
                            0
                        ],
                        [
                            113.1979275,
                            23.2057425,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "UMB Island",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Adm. av UMB, claimad av Canaan Liberation Group.<br><br>Den skumma, Madagaskar-affilierade megakorpen UMB styr denna zon, som de klängt sig fast vid sedan de kastades ut från Canaan Market. Det verkade länge som att UMB gett upp sina intressen i Pärldeltat, men på pappret tillhörde zonen fortfarande dem, och en dag 2063 flög de in med en styrka vektorjets och ockuperade den. Canaan Liberation Group, deras ärkefiender, protesterade å det grövsta mot detta, men UMBs protektorat är effektivt en ö, och därför lättförsvarad, åtminstone mot resurssvaga fiender som CLG. Icke desto mindre har megakorpen låtit seeda de gamla kanalerna med minor, just in case...<br><br>UMB, sitt dåliga rykte till trots, styr inte tyranniskt över zonen, och dess invånare är fria att komma och gå som de vill - men gränsen mot Canaan Market är stängd, vilket ställer till mycket stora logistikproblem för vanliga plexiter. UMB lockar med en högre än genomsnittlig basinkomst för att hålla kvar sina peeps, och är snåla med att utfärda cert till utomstående - utom tillfälliga sådana till peeps som vill besöka deras flagship store, Guangzhou Unconventional Drugs & Therapies Emporium. ",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.1746847,
                            22.2500721,
                            0
                        ],
                        [
                            114.1754501,
                            22.249183,
                            0
                        ],
                        [
                            114.1773491,
                            22.250176,
                            0
                        ],
                        [
                            114.179924,
                            22.2531946,
                            0
                        ],
                        [
                            114.1791515,
                            22.2537507,
                            0
                        ],
                        [
                            114.1770487,
                            22.2505732,
                            0
                        ],
                        [
                            114.1759758,
                            22.2502157,
                            0
                        ],
                        [
                            114.1746847,
                            22.2500721,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Aberdeen Tunnel (Worldport)",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Adm. av Interworld<br><br>En viktig vägtunnel som mynnar ut i Happy Valley. Resten av zonen är under jorden, och därför inte utmarkerad på kartan.<br><br>Endast AI-drivna fordon är tillåtna i zonen. Blockers hänvisas till Wong Nai Chung Gap. ",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.2047948,
                            22.3344329,
                            0
                        ],
                        [
                            114.2050094,
                            22.331138,
                            0
                        ],
                        [
                            114.2074127,
                            22.3324679,
                            0
                        ],
                        [
                            114.2070809,
                            22.3328742,
                            0
                        ],
                        [
                            114.2065606,
                            22.3331273,
                            0
                        ],
                        [
                            114.2062387,
                            22.3336731,
                            0
                        ],
                        [
                            114.2058017,
                            22.333944,
                            0
                        ],
                        [
                            114.2053647,
                            22.3343936,
                            0
                        ],
                        [
                            114.205,
                            22.3345524,
                            0
                        ],
                        [
                            114.2047948,
                            22.3344329,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "East Kowloon Intersection",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Adm. av Highways Department. <br><br>En infastruktur-zon som vore folktom om det inte vore för den stora klättrarkolonin under autostradan. Highways Department är en något mystisk organisation som troget upprätthåller infrastruktur här och var i deltat, utan någon uppenbar profit. Deras vägarbetare dyker upp från ingenstans och fixar saker, och ingen verkar riktigt veta var de sedan tar vägen. ",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.1577962,
                            22.3282022,
                            0
                        ],
                        [
                            114.1552563,
                            22.3256639,
                            0
                        ],
                        [
                            114.1565323,
                            22.3233042,
                            0
                        ],
                        [
                            114.1578662,
                            22.3247433,
                            0
                        ],
                        [
                            114.1571455,
                            22.3252843,
                            0
                        ],
                        [
                            114.1576528,
                            22.3257731,
                            0
                        ],
                        [
                            114.1583801,
                            22.3252843,
                            0
                        ],
                        [
                            114.1597274,
                            22.3267532,
                            0
                        ],
                        [
                            114.1577962,
                            22.3282022,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Nam Cheong ",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Adm. av Kowloon Wohnungsunternehmen GmbH 60%, Tiong Woon Corporation Holding 40%. <br><br>Ett antal slitna bostadskomplex som ägs av dysfunktionella dotterbolag till Green Garden. Megakorpen har inte visat något större intresse för zonen förrän på sistone, då de börjat sin mystiska mobilisering i Shamshuipo. ",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.1576528,
                            22.3257731,
                            0
                        ],
                        [
                            114.1571455,
                            22.3252843,
                            0
                        ],
                        [
                            114.1578662,
                            22.3247433,
                            0
                        ],
                        [
                            114.1583801,
                            22.3252843,
                            0
                        ],
                        [
                            114.1576528,
                            22.3257731,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Nam Cheong Democratic Zone",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Adm. av Xinyu DiDemocratic Movement<br><br>Den gamla markägaren sålde byggnaden till Tetrahedron Yamashita, en entreprenör som sedermera kom att utveckla nomo-sympatier. Yamashita gick 2065 med i the Xinyu DiDemocratic Movement, och lade sina egendomar i HK till deras zoner.  Detta är givetvis lite av en vagel i ögat på Chi Sin-triaden, Green Garden, och andra aktörer som vill kontrollera Shamshuipo. ",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.1598958,
                            22.3241626,
                            0
                        ],
                        [
                            114.1599349,
                            22.3231801,
                            0
                        ],
                        [
                            114.1618983,
                            22.3232099,
                            0
                        ],
                        [
                            114.1619128,
                            22.3241825,
                            0
                        ],
                        [
                            114.1598958,
                            22.3241626,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "The Marvel Zone",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Adm. av: Xinyu DiDemocratic Movement<br><br>Den excentriska nomo-miljardären Tetrahedron Yamashitas ursprungliga properties på Kowloon, ett par äldre höghus. Han har sedermera joinat dem med mori-falangen Xinyu DiDemocratic Movement och deras zoner. ",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            113.8715742,
                            22.7594823,
                            0
                        ],
                        [
                            113.871145,
                            22.7585719,
                            0
                        ],
                        [
                            113.8671112,
                            22.744206,
                            0
                        ],
                        [
                            113.8478851,
                            22.7446809,
                            0
                        ],
                        [
                            113.8535669,
                            22.7314771,
                            0
                        ],
                        [
                            113.8528986,
                            22.7311056,
                            0
                        ],
                        [
                            113.8540063,
                            22.7262976,
                            0
                        ],
                        [
                            113.8532177,
                            22.7248355,
                            0
                        ],
                        [
                            113.8535287,
                            22.7239698,
                            0
                        ],
                        [
                            113.8562107,
                            22.7233467,
                            0
                        ],
                        [
                            113.8596524,
                            22.7225432,
                            0
                        ],
                        [
                            113.8609012,
                            22.7196872,
                            0
                        ],
                        [
                            113.8650447,
                            22.7161215,
                            0
                        ],
                        [
                            113.8659266,
                            22.7161185,
                            0
                        ],
                        [
                            113.8666578,
                            22.716057,
                            0
                        ],
                        [
                            113.869491,
                            22.7175702,
                            0
                        ],
                        [
                            113.8770878,
                            22.7174206,
                            0
                        ],
                        [
                            113.8857599,
                            22.7192768,
                            0
                        ],
                        [
                            113.8881632,
                            22.7313103,
                            0
                        ],
                        [
                            113.8806959,
                            22.7582234,
                            0
                        ],
                        [
                            113.8715742,
                            22.7594823,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "New Tankograd",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Adm. av: Танкоград милиция (Tankograd Militsyia)<br><br>En beväpnad grupp kinesiska gammelkommunister tog över zonen för att skydda dess sibiriska intressen i form av en enorm cache av tanks och annan militär eqip, som Sibirien bara behöver komma och hämta. Detta har visat sig minst sagt svårt, på grund av invecklat byråkratiskt, diplomatiskt och logistiskt mafan, men Gränskommandot har heller inte ingripit mot milisen, av rädsla för att skapa en incident med Kinas mäktiga granne Sibirien. New Tankograd existerar därför i ett extra konstigt vacuum, och de Sibirien-lojala invånarna har fortifierat sig i fallfärdiga bostadskomplex, börjat odla jorden, sätta ut minor och artilleripjäser, och i allmänhet settle down for the long run.  Enligt vissa rapporter råder det en cargo-kult-liknande stämning i zonen, där alla servar de parkerade tanksen med stor hängivelse, och väntar under närmast religiösa former på att Sibirien ska dyka upp och hämta dem alla till sitt socialistiska paradis. ",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            113.2731391,
                            23.0532456,
                            0
                        ],
                        [
                            113.2567145,
                            23.0468056,
                            0
                        ],
                        [
                            113.2547083,
                            23.0413953,
                            0
                        ],
                        [
                            113.2549339,
                            23.036301,
                            0
                        ],
                        [
                            113.2627021,
                            23.0329635,
                            0
                        ],
                        [
                            113.2655776,
                            23.028441,
                            0
                        ],
                        [
                            113.2697059,
                            23.0248111,
                            0
                        ],
                        [
                            113.2738257,
                            23.0301826,
                            0
                        ],
                        [
                            113.2768298,
                            23.0309724,
                            0
                        ],
                        [
                            113.2812072,
                            23.0297876,
                            0
                        ],
                        [
                            113.2835246,
                            23.0254431,
                            0
                        ],
                        [
                            113.2867862,
                            23.0202295,
                            0
                        ],
                        [
                            113.2918502,
                            23.0199135,
                            0
                        ],
                        [
                            113.2992316,
                            23.0228363,
                            0
                        ],
                        [
                            113.3042956,
                            23.0277338,
                            0
                        ],
                        [
                            113.3086086,
                            23.0331052,
                            0
                        ],
                        [
                            113.2884183,
                            23.0437292,
                            0
                        ],
                        [
                            113.2731391,
                            23.0532456,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Nanpu Heavenly Kingdom",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Inmutad av Nanpu Heavenly Kingdom, en synkretistisk religiös sekt som växte fram ur en mix av Falun Gong och en radikalkonservativ kristen rörelse på 2040-talet. Nanpu-sekten praktiserar åtskilliga bisarra seder, varav den märkligaste är att de syr in döda i jadedräkter, likt den Nanyue-kungen Zhaomo begravdes i, och dumpar dem i väldiga gravkomplex de har konstruerat i zonen. Hur de har råd med detta är oklart, då även journalister håller sig borta. Nanpuerna gillar nämligen att arrestera alla som försöker passera genom zonen och i bästa fall utsätta dem för långa bibelförhör, i värsta fall beslagta allt deras stuff. De har en egen liten drönarflotta som känner igen alla som är medlemmar i sekten, och omedelbart identifierar peeps som inte är det.<br><br>Mediaformaren Plum Dijkmans har antytt på Lagren att hon gjort ett scoop om Nanpu, som snart ska offentliggöras. Många väntar med spänning på att få götta sig i makabra anekdoter om ännu en konstig teo-grupp.",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            113.1977228,
                            23.1210107,
                            0
                        ],
                        [
                            113.1975921,
                            23.1111032,
                            0
                        ],
                        [
                            113.1995252,
                            23.1025382,
                            0
                        ],
                        [
                            113.1900839,
                            23.1030909,
                            0
                        ],
                        [
                            113.1750635,
                            23.1027751,
                            0
                        ],
                        [
                            113.1707744,
                            23.0753155,
                            0
                        ],
                        [
                            113.184422,
                            23.0600742,
                            0
                        ],
                        [
                            113.2024908,
                            23.0512692,
                            0
                        ],
                        [
                            113.212297,
                            23.0433116,
                            0
                        ],
                        [
                            113.2243343,
                            23.0419087,
                            0
                        ],
                        [
                            113.2375083,
                            23.043568,
                            0
                        ],
                        [
                            113.2389137,
                            23.0460755,
                            0
                        ],
                        [
                            113.229231,
                            23.0560167,
                            0
                        ],
                        [
                            113.2386887,
                            23.0606664,
                            0
                        ],
                        [
                            113.2509525,
                            23.0628189,
                            0
                        ],
                        [
                            113.2577237,
                            23.0692352,
                            0
                        ],
                        [
                            113.2496452,
                            23.0920491,
                            0
                        ],
                        [
                            113.245182,
                            23.0988389,
                            0
                        ],
                        [
                            113.2331657,
                            23.1072073,
                            0
                        ],
                        [
                            113.2328932,
                            23.1077398,
                            0
                        ],
                        [
                            113.2295704,
                            23.1134548,
                            0
                        ],
                        [
                            113.2245922,
                            23.1124681,
                            0
                        ],
                        [
                            113.2157516,
                            23.1176781,
                            0
                        ],
                        [
                            113.2116318,
                            23.1220986,
                            0
                        ],
                        [
                            113.2114888,
                            23.1211493,
                            0
                        ],
                        [
                            113.2053089,
                            23.1222544,
                            0
                        ],
                        [
                            113.1977228,
                            23.1210107,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Hailong Human Extinction Zone",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "När Hailongs strongwoman Lucille Kwan strök med i ett terrordåd kände sig invånvarna vilsna, och visste inte om de skulle ty sig till krigskommunisterna i Foshan eller flummarna i Canaan Market. Detta dilemma löste sig självt när Involuntary Human Extinction Movement, löst affilierade med Gaia Liberation Front, infiltrerade Hailong och tog makten med hjälp av sina AKs. Här har de nu förskansat sig, av någon anledning med support från närbelägna UMB, som tycks se dem som ett verktyg i zonpolitiken, om inte något större än så... Spekulationerna duggar givetvis täta på Lagren.<br><br>Under tiden går både Hailongs ockuperade befolkning, Foshan-krigsherrarna och Canaan Liberation Front och väntar spänt på att IHEM ska utföra någon fasansfull typ av terrordåd för att boosta sin agenda. Man har diskuterat att invadera Hailong och röka ut dödskulten, men kan inte enas om vem som isåfall skulle få området, och varken Foshan eller Canaan Market vill gå in först, nervösa för vilken slags resurser UMB kan ha försett IHEM med. <br><br>Särskilda bestämmelser:<br><br>Det är tekniskt sett olagligt att vara levande i zonen. Alla som andas måste därför betala 10€/dag i böter. <br><br>Vapeninnehav är förbjudet i zonen, om man inte är affilierad med IHEM.<br><br>Det är förbjudet att vara gravid i zonen. Aborter utförs på alla som ertappas. ",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            113.8562107,
                            22.7233467,
                            0
                        ],
                        [
                            113.8484001,
                            22.6935365,
                            0
                        ],
                        [
                            113.846515,
                            22.6827658,
                            0
                        ],
                        [
                            113.87887,
                            22.6649499,
                            0
                        ],
                        [
                            113.8973818,
                            22.6615655,
                            0
                        ],
                        [
                            113.8934256,
                            22.6719998,
                            0
                        ],
                        [
                            113.8910139,
                            22.6829093,
                            0
                        ],
                        [
                            113.8923872,
                            22.6905116,
                            0
                        ],
                        [
                            113.8890613,
                            22.7186994,
                            0
                        ],
                        [
                            113.8857599,
                            22.7192768,
                            0
                        ],
                        [
                            113.8761398,
                            22.7174213,
                            0
                        ],
                        [
                            113.869491,
                            22.7175702,
                            0
                        ],
                        [
                            113.8666578,
                            22.716057,
                            0
                        ],
                        [
                            113.8650447,
                            22.7161215,
                            0
                        ],
                        [
                            113.8609012,
                            22.7196872,
                            0
                        ],
                        [
                            113.8596524,
                            22.7225432,
                            0
                        ],
                        [
                            113.8562107,
                            22.7233467,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Fuyong AgriZone ",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Ett antal odlingar och växthus bland reservoarer och vissnande träd, med endast begränsad bebyggelse. Ingen vet varför de åldrade bönder som bor här tillåtits vara ifred, men en populär teori är att växthusen är fulla med cannabis och opiumvallmo, som de sedan smugglar till Mainland. Lop-87/y, en zesgo på 59475F0270B9.guru, försökte nyligen nysta i saken, men hennes ström gick ned i mitten av zonen och hon har inte hörts av sedan dess. ",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.2074127,
                            22.3324679,
                            0
                        ],
                        [
                            114.2128605,
                            22.3288543,
                            0
                        ],
                        [
                            114.2155715,
                            22.3306173,
                            0
                        ],
                        [
                            114.2143286,
                            22.3325012,
                            0
                        ],
                        [
                            114.2133844,
                            22.3325607,
                            0
                        ],
                        [
                            114.2107881,
                            22.3354387,
                            0
                        ],
                        [
                            114.2092817,
                            22.3349467,
                            0
                        ],
                        [
                            114.2086594,
                            22.3346093,
                            0
                        ],
                        [
                            114.208229,
                            22.3340551,
                            0
                        ],
                        [
                            114.2075369,
                            22.3331868,
                            0
                        ],
                        [
                            114.2070809,
                            22.3328742,
                            0
                        ],
                        [
                            114.2074127,
                            22.3324679,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Ping Shan Rrriot Zone",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Ägs officiellt av  Cataclysm Consultants, som i sin tur ägs av CK Hutchison Holdings, som i sin tur ingår i Ütopya-keiretsun. Dock är det är allmänt accepterat på Lagren att CC är en front för Gaia Liberation Group, och de har omvandlat hela zonen till  ett slags lajv för katastrofträning. Här kan Pärldeltats ivriga rrrioters hyra in sig  och träna mot företagets personal, fullt utkittade med riot control gear.  Zonen är full av utbrända bilvrak, gator plomberade av lastcontainrar och mycket annat spännande, som även gör den populär bland parkour-entusiaster. Att vistas här i något annat syfte än urbex eller rrriot-träning är dock inte tillrådigt. Risken är hög att Cataclysm Consultants misstar dig för en kund och batongar dig i fejset. ",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.2143286,
                            22.3325012,
                            0
                        ],
                        [
                            114.2155715,
                            22.3306173,
                            0
                        ],
                        [
                            114.2163796,
                            22.3311567,
                            0
                        ],
                        [
                            114.2185683,
                            22.3308788,
                            0
                        ],
                        [
                            114.2189428,
                            22.3321647,
                            0
                        ],
                        [
                            114.2181864,
                            22.3331273,
                            0
                        ],
                        [
                            114.2143286,
                            22.3325012,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "St Joseph's Anglo-Chinese Education Zone",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Hit skickar eurostyle-entusiaster utan feta bankkonto sina peepmins, så att de får bära skoluniform, spela rugby och utsättas för stenhård pennalism. <br><br>Särskilda bestämmelser: <br>Endast peeps i eurostyle-outfits får vistas i zonen.<br>Peepmins måste vara enrolled på St Joseph's för att få vistas i zonen. ",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            113.1220208,
                            23.1144769,
                            0
                        ],
                        [
                            113.1233942,
                            23.0778423,
                            0
                        ],
                        [
                            113.1536076,
                            23.0835277,
                            0
                        ],
                        [
                            113.1707744,
                            23.0753155,
                            0
                        ],
                        [
                            113.1750635,
                            23.1027751,
                            0
                        ],
                        [
                            113.1737808,
                            23.1059469,
                            0
                        ],
                        [
                            113.173695,
                            23.1082757,
                            0
                        ],
                        [
                            113.1736043,
                            23.1109853,
                            0
                        ],
                        [
                            113.1759217,
                            23.1165902,
                            0
                        ],
                        [
                            113.1785824,
                            23.1272462,
                            0
                        ],
                        [
                            113.1791834,
                            23.1275622,
                            0
                        ],
                        [
                            113.1690601,
                            23.1309703,
                            0
                        ],
                        [
                            113.1561855,
                            23.1299837,
                            0
                        ],
                        [
                            113.1486324,
                            23.1218929,
                            0
                        ],
                        [
                            113.135157,
                            23.1236295,
                            0
                        ],
                        [
                            113.1323675,
                            23.1241821,
                            0
                        ],
                        [
                            113.1283335,
                            23.1224257,
                            0
                        ],
                        [
                            113.1227384,
                            23.1213354,
                            0
                        ],
                        [
                            113.1216923,
                            23.1215476,
                            0
                        ],
                        [
                            113.1187419,
                            23.121518,
                            0
                        ],
                        [
                            113.1150491,
                            23.1212735,
                            0
                        ],
                        [
                            113.1114325,
                            23.1186843,
                            0
                        ],
                        [
                            113.1220208,
                            23.1144769,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Lucille Kwan Memorial Zone",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "En zon helgad åt minnet av Lucille Kwan, Hailongs populära strongwoman. De åldrade invånarna befinner sig numera i ett lågintensivt ställningskrig mot Involuntary Human Extinction Movement, som tagit över närbelägna Hailong, och där vanhelgat Mrs Kwans minne. (Många tror även att IHEM låg bakom terrordådet hon dog i). Tyvärr har IHEM mycket bättre krom, och mycket lägre medelålder...<br><br>Särskilda bestämmelser:<br><br>Alla i zonen måste bära en pin med ett porträtt av Lucille Kwan.<br><br>Alla i zonen måste erkänna Lucille Kwan som \"Eternal President\".<br><br>Klockan 07.00 måste alla gemensamt sjunga \"Ode till Lucille Kwan\" av Ip-Man Sakamoto.",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            113.3656679,
                            23.106067,
                            0
                        ],
                        [
                            113.3653138,
                            23.0950833,
                            0
                        ],
                        [
                            113.3810071,
                            23.0937447,
                            0
                        ],
                        [
                            113.3895902,
                            23.0867967,
                            0
                        ],
                        [
                            113.3990316,
                            23.0793745,
                            0
                        ],
                        [
                            113.4334234,
                            23.0906862,
                            0
                        ],
                        [
                            113.4325651,
                            23.0924232,
                            0
                        ],
                        [
                            113.4167722,
                            23.0970813,
                            0
                        ],
                        [
                            113.4012276,
                            23.1046659,
                            0
                        ],
                        [
                            113.3656679,
                            23.106067,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Shazai Port Zone",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Officiellt administrerad av Li & Fung, en i praktiken defunct mikrokorp som bara tycks existera i form av en postbox på Bermuda.<br><br>Istället har en grupp vrakare flyttat in, och försöker ofta kräva tull av folk som vill passera med båt djupare in i deltat. I gengäld får de dock en mängd praktisk information av vrakarna; i synnerhet aktuella tabeller över var odetonerade båtminor beräknas finnas, och tips på obskyra små vattenvägar som kan ta en till de mest egendomliga platser. ",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            113.4074074,
                            23.1201582,
                            0
                        ],
                        [
                            113.4012276,
                            23.1046659,
                            0
                        ],
                        [
                            113.4178122,
                            23.0969755,
                            0
                        ],
                        [
                            113.4325651,
                            23.0924232,
                            0
                        ],
                        [
                            113.4334234,
                            23.0906862,
                            0
                        ],
                        [
                            113.4401892,
                            23.0925224,
                            0
                        ],
                        [
                            113.4400175,
                            23.0959963,
                            0
                        ],
                        [
                            113.4432791,
                            23.1081542,
                            0
                        ],
                        [
                            113.4299572,
                            23.110199,
                            0
                        ],
                        [
                            113.4163283,
                            23.1187718,
                            0
                        ],
                        [
                            113.4086713,
                            23.1199481,
                            0
                        ],
                        [
                            113.4074074,
                            23.1201582,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "区956",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "En mycket sliten och improviserad hamn bland uppmuddrade kanaler i det översvämmade landskapet. Användes av Canaan Liberation Group för algodlingar, men är föremål för en konflikt med ChemCorp, som vill köpa upp zonen för att bygga Choo-cisterner och infrastruktur för bränslehandel med Kina.",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            113.4370788,
                            23.1432616,
                            0
                        ],
                        [
                            113.4271653,
                            23.1324486,
                            0
                        ],
                        [
                            113.4187324,
                            23.1327052,
                            0
                        ],
                        [
                            113.4174236,
                            23.1281469,
                            0
                        ],
                        [
                            113.4067208,
                            23.1313471,
                            0
                        ],
                        [
                            113.4075791,
                            23.1201779,
                            0
                        ],
                        [
                            113.4163283,
                            23.1187718,
                            0
                        ],
                        [
                            113.4299572,
                            23.110199,
                            0
                        ],
                        [
                            113.4432791,
                            23.1081542,
                            0
                        ],
                        [
                            113.4535788,
                            23.1177851,
                            0
                        ],
                        [
                            113.4563254,
                            23.1368868,
                            0
                        ],
                        [
                            113.4573348,
                            23.1393154,
                            0
                        ],
                        [
                            113.4370788,
                            23.1432616,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Jishanxiang",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Adm. av ChinaChem Group, ett lågt rankat bolag i den invecklade Weihu-strukturen.<br><br>Jishaxiang är nedgånget och ofta översvämmat, men känt för sina (med PRD-mått mätt) billiga lägenhetskomplex och sin sunkigt bohemiska vibe. Eurosar, ryssar och latinos kommer hit i hopp om att få jobb i någon av de mer lukrativa zonerna, och anon kommer hit med drömmar om att få vara med i New Town Utility Zone-projektet. Många kommer aldrig vidare, utan fastnar i Jishanxiang, där de blir sorgliga basare istället. <br><br>Zonen har dock suveräna pierogi, och alldeles särskilt färgstarka basare, som tycks styra sig själva i nån sorts anarkism, och fylla Jishanxiang med konstiga projekt i gränslandet mellan konst och allmänfarlig ödeläggelse. ",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            113.4012041,
                            23.1527423,
                            0
                        ],
                        [
                            113.3797155,
                            23.1517126,
                            0
                        ],
                        [
                            113.3784742,
                            23.1457533,
                            0
                        ],
                        [
                            113.3764607,
                            23.139794,
                            0
                        ],
                        [
                            113.4067208,
                            23.1313471,
                            0
                        ],
                        [
                            113.4174236,
                            23.1281469,
                            0
                        ],
                        [
                            113.4187324,
                            23.1327052,
                            0
                        ],
                        [
                            113.4271653,
                            23.1324486,
                            0
                        ],
                        [
                            113.4370788,
                            23.1432616,
                            0
                        ],
                        [
                            113.4346351,
                            23.1590556,
                            0
                        ],
                        [
                            113.4221039,
                            23.1581875,
                            0
                        ],
                        [
                            113.4173937,
                            23.157359,
                            0
                        ],
                        [
                            113.411482,
                            23.1555045,
                            0
                        ],
                        [
                            113.4012041,
                            23.1527423,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Dongpu Town",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Kontrolleras av nomo-gruppen Qingguo, 'Det smaragdgröna landet', som attraherat många lokala analoger. Qingguo är väldigt intresserade av hydropnisk odling och gröna stadsmiljöer, men det hela verkar ha gått något överstyr, för zonen ter sig närmast postapokalyptisk, med spruckna betongkomplex täckta av klätterväxter och asfaltsplaner som luckrats upp till förmån för vildvuxna trädgårdar. Grönskan domineras av ogräs, i synnerhet en särskilt ettrig och giftig form av jättelokor. Rykten säger att problemen har sin rot i ett  experimentiellt gödningsmedel som Qingguo fick av en utomstående aktör, men meningarna är delade om denna aktör var ChemCorp, Genetica, MADE/TND, eller Chongqing-regimen. <br><br>Det finns en stående quest på59475F0270B9.guru att utforska vad som egentligen sker i Dongpu Green Town, men ingen har lyckats till fullo än eftersom Qingguo gillar att våldsamt avhysa alla utomstående. ",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            113.2851138,
                            23.045853,
                            0
                        ],
                        [
                            113.2888904,
                            23.0434835,
                            0
                        ],
                        [
                            113.305799,
                            23.0346767,
                            0
                        ],
                        [
                            113.3090606,
                            23.0393369,
                            0
                        ],
                        [
                            113.3060136,
                            23.0410745,
                            0
                        ],
                        [
                            113.3067432,
                            23.0487752,
                            0
                        ],
                        [
                            113.2899203,
                            23.0525662,
                            0
                        ],
                        [
                            113.2851138,
                            23.045853,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Luoxi Industrial Zone",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Adm. av Rongyi Robotics / HSTC. <br><br>En ganska alldaglig zon, om det kan sägas om någon zon i Pärldeltat, där de två megakorparna driver fabriker som tillverkar mikrochip, kretskort, batterier, och dylikt. Säkerheten är dock tight, med konstant patrullerande robotvakter och drönare, och flera checkpoints där obehöriga bestämt avvisas. ",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            113.256843,
                            23.070574,
                            0
                        ],
                        [
                            113.2579168,
                            23.0693438,
                            0
                        ],
                        [
                            113.2588386,
                            23.0686886,
                            0
                        ],
                        [
                            113.2599114,
                            23.0691526,
                            0
                        ],
                        [
                            113.2575404,
                            23.0717782,
                            0
                        ],
                        [
                            113.256843,
                            23.070574,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Yin-heun's Island",
                "styleUrl": "#poly-000000-1200-76",
                "styleHash": "55df8305",
                "styleMapHash": {
                    "normal": "#poly-000000-1200-76-normal",
                    "highlight": "#poly-000000-1200-76-highlight"
                },
                "description": "Den åldrade survivalisten Yin-heun bor här i en fortifierad villa, och flingar kulor på alla som kommer i närheten. Det kan vara bäst att låta henne vara i fred.<br><br>En stark kandidat till Pärldeltats minst befolkade zon, ihop med Mandarin Oriental Abyssal Pool och liknande bisarra ställen. <br><br>Bestämmelser: Förbjudet att störa Yin-heun. Tresspassers will be shot. ",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#000000",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        114.0831027,
                        22.2869831,
                        0
                    ],
                    [
                        114.1122851,
                        22.2847594,
                        0
                    ],
                    [
                        114.1222415,
                        22.2812648,
                        0
                    ]
                ]
            },
            "properties": {
                "name": "Tunnel",
                "styleUrl": "#line-FFDD5E-6038-nodesc",
                "styleHash": "-52e2b0b",
                "styleMapHash": {
                    "normal": "#line-FFDD5E-6038-nodesc-normal",
                    "highlight": "#line-FFDD5E-6038-nodesc-highlight"
                },
                "stroke": "#ffdd5e",
                "stroke-opacity": 1,
                "stroke-width": 6.038
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.0431778,
                            22.2931866,
                            0
                        ],
                        [
                            114.0303031,
                            22.2893747,
                            0
                        ],
                        [
                            114.0304745,
                            22.2726951,
                            0
                        ],
                        [
                            114.0254966,
                            22.2560154,
                            0
                        ],
                        [
                            114.0319343,
                            22.254109,
                            0
                        ],
                        [
                            114.0373418,
                            22.2496604,
                            0
                        ],
                        [
                            114.0436927,
                            22.240445,
                            0
                        ],
                        [
                            114.0587989,
                            22.2623702,
                            0
                        ],
                        [
                            114.0802566,
                            22.2817505,
                            0
                        ],
                        [
                            114.0805999,
                            22.2882628,
                            0
                        ],
                        [
                            114.0431778,
                            22.2931866,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Vision Island",
                "styleUrl": "#poly-009D57-1-76",
                "styleHash": "-256c9a80",
                "styleMapHash": {
                    "normal": "#poly-009D57-1-76-normal",
                    "highlight": "#poly-009D57-1-76-highlight"
                },
                "description": "En rekordstor reclamation av vatten som<br>sammanförde ett antal öar öster om<br>Lantau till en helt ny ö. Detta såldes som<br>en slutgiltig lösning för trångboddheten,<br>en ö som skulle rymma mer än en miljon<br>invånare. Dessvärre höjdes havsnivån. Vi-<br>sion Island är fortfarande ett av de bättre<br>ställena i Hongkong, en stad i staden med<br>en modern infrastruktur och ren luft. Stora<br>delar av ön är dessvärre vattensjuka och<br>försvinner under vatten vid större tyfoner.",
                "stroke": "#009d57",
                "stroke-opacity": 1,
                "stroke-width": 0.001,
                "fill": "#009d57",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        113.585971,
                        22.2132233,
                        0
                    ],
                    [
                        113.6384994,
                        22.2370595,
                        0
                    ],
                    [
                        113.662532,
                        22.251677,
                        0
                    ],
                    [
                        113.6821014,
                        22.2764597,
                        0
                    ],
                    [
                        113.6917144,
                        22.284402,
                        0
                    ],
                    [
                        113.7212401,
                        22.2847197,
                        0
                    ]
                ]
            },
            "properties": {
                "name": "Hong Kong Macau Zhuhai bridge",
                "styleUrl": "#line-FFDD5E-6619",
                "styleHash": "33233494",
                "styleMapHash": {
                    "normal": "#line-FFDD5E-6619-normal",
                    "highlight": "#line-FFDD5E-6619-highlight"
                },
                "description": "The gap is a tunnel",
                "stroke": "#ffdd5e",
                "stroke-opacity": 1,
                "stroke-width": 6.619
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        113.7844115,
                        22.2828136,
                        0
                    ],
                    [
                        113.8451797,
                        22.2818605,
                        0
                    ],
                    [
                        113.8719588,
                        22.2745535,
                        0
                    ],
                    [
                        113.8973647,
                        22.2917084,
                        0
                    ]
                ]
            },
            "properties": {
                "name": "Bridge",
                "styleUrl": "#line-FFDD5E-7200-nodesc",
                "styleHash": "57876893",
                "styleMapHash": {
                    "normal": "#line-FFDD5E-7200-nodesc-normal",
                    "highlight": "#line-FFDD5E-7200-nodesc-highlight"
                },
                "stroke": "#ffdd5e",
                "stroke-opacity": 1,
                "stroke-width": 7.2
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        113.9600215,
                        22.3175162,
                        0
                    ],
                    [
                        113.9850528,
                        22.2870229,
                        0
                    ],
                    [
                        114.0039025,
                        22.2717737,
                        0
                    ],
                    [
                        114.0124535,
                        22.26816,
                        0
                    ],
                    [
                        114.0211759,
                        22.2661343,
                        0
                    ],
                    [
                        114.0285143,
                        22.2655781,
                        0
                    ]
                ]
            },
            "properties": {
                "name": "Bridge",
                "styleUrl": "#line-FFDD5E-5263-nodesc",
                "styleHash": "44619937",
                "styleMapHash": {
                    "normal": "#line-FFDD5E-5263-nodesc-normal",
                    "highlight": "#line-FFDD5E-5263-nodesc-highlight"
                },
                "stroke": "#ffdd5e",
                "stroke-opacity": 1,
                "stroke-width": 5.263
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        113.5362839,
                        22.508644,
                        0
                    ],
                    [
                        113.6440872,
                        22.5168902,
                        0
                    ],
                    [
                        113.7457108,
                        22.561284,
                        0
                    ]
                ]
            },
            "properties": {
                "name": "Bridge",
                "styleUrl": "#line-FFDD5E-7394-nodesc",
                "styleHash": "-4a76c5ed",
                "styleMapHash": {
                    "normal": "#line-FFDD5E-7394-nodesc-normal",
                    "highlight": "#line-FFDD5E-7394-nodesc-highlight"
                },
                "stroke": "#ffdd5e",
                "stroke-opacity": 1,
                "stroke-width": 7.394
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        113.8178086,
                        22.6037619,
                        0
                    ],
                    [
                        113.7518906,
                        22.5650886,
                        0
                    ]
                ]
            },
            "properties": {
                "name": "Tunnel",
                "styleUrl": "#line-FFDD5E-7006-nodesc",
                "styleHash": "29297713",
                "styleMapHash": {
                    "normal": "#line-FFDD5E-7006-nodesc-normal",
                    "highlight": "#line-FFDD5E-7006-nodesc-highlight"
                },
                "stroke": "#ffdd5e",
                "stroke-opacity": 1,
                "stroke-width": 7.006
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        113.9603505,
                        22.3204734,
                        0
                    ],
                    [
                        113.9675603,
                        22.3350821,
                        0
                    ],
                    [
                        113.9479909,
                        22.3646126,
                        0
                    ],
                    [
                        113.9551993,
                        22.3844545,
                        0
                    ],
                    [
                        113.9725372,
                        22.3977075,
                        0
                    ],
                    [
                        113.9848106,
                        22.435752,
                        0
                    ],
                    [
                        113.9815074,
                        22.4462045,
                        0
                    ],
                    [
                        113.9565117,
                        22.4798254,
                        0
                    ],
                    [
                        113.8998996,
                        22.5109087,
                        0
                    ],
                    [
                        113.8178086,
                        22.6037619,
                        0
                    ]
                ]
            },
            "properties": {
                "name": "Bridge",
                "styleUrl": "#line-FFDD5E-8556-nodesc",
                "styleHash": "512c1c11",
                "styleMapHash": {
                    "normal": "#line-FFDD5E-8556-nodesc-normal",
                    "highlight": "#line-FFDD5E-8556-nodesc-highlight"
                },
                "stroke": "#ffdd5e",
                "stroke-opacity": 1,
                "stroke-width": 8.556
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.1924489,
                            22.3142215,
                            0
                        ],
                        [
                            114.2016328,
                            22.3156507,
                            0
                        ],
                        [
                            114.1958821,
                            22.3208118,
                            0
                        ],
                        [
                            114.1924489,
                            22.3142215,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Reclaimed land",
                "styleUrl": "#poly-009D57-1-76-nodesc",
                "styleHash": "10019af",
                "styleMapHash": {
                    "normal": "#poly-009D57-1-76-nodesc-normal",
                    "highlight": "#poly-009D57-1-76-nodesc-highlight"
                },
                "stroke": "#009d57",
                "stroke-opacity": 1,
                "stroke-width": 0.001,
                "fill": "#009d57",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.1549409,
                            22.3167767,
                            0
                        ],
                        [
                            114.1551125,
                            22.3032777,
                            0
                        ],
                        [
                            114.1570866,
                            22.3031983,
                            0
                        ],
                        [
                            114.1602624,
                            22.3068511,
                            0
                        ],
                        [
                            114.1599191,
                            22.3166179,
                            0
                        ],
                        [
                            114.1549409,
                            22.3167767,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Reclaimed land",
                "styleUrl": "#poly-009D57-1-76-nodesc",
                "styleHash": "10019af",
                "styleMapHash": {
                    "normal": "#poly-009D57-1-76-nodesc-normal",
                    "highlight": "#poly-009D57-1-76-nodesc-highlight"
                },
                "stroke": "#009d57",
                "stroke-opacity": 1,
                "stroke-width": 0.001,
                "fill": "#009d57",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.1890157,
                            22.2877777,
                            0
                        ],
                        [
                            114.1833508,
                            22.286507,
                            0
                        ],
                        [
                            114.1830075,
                            22.2823771,
                            0
                        ],
                        [
                            114.1860116,
                            22.2834096,
                            0
                        ],
                        [
                            114.1899598,
                            22.2852363,
                            0
                        ],
                        [
                            114.1890157,
                            22.2877777,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Reclaimed land",
                "styleUrl": "#poly-009D57-1-76-nodesc",
                "styleHash": "10019af",
                "styleMapHash": {
                    "normal": "#poly-009D57-1-76-nodesc-normal",
                    "highlight": "#poly-009D57-1-76-nodesc-highlight"
                },
                "stroke": "#009d57",
                "stroke-opacity": 1,
                "stroke-width": 0.001,
                "fill": "#009d57",
                "fill-opacity": 0.2980392156862745
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        113.1186311,
                        23.3039354,
                        0
                    ],
                    [
                        113.4452211,
                        23.1928985,
                        0
                    ],
                    [
                        113.7077596,
                        23.2105713,
                        0
                    ],
                    [
                        113.7263489,
                        23.1365715,
                        0
                    ],
                    [
                        113.8169861,
                        23.1289943,
                        0
                    ],
                    [
                        113.8540649,
                        23.10626,
                        0
                    ],
                    [
                        113.9749146,
                        23.1151016,
                        0
                    ],
                    [
                        114.0133667,
                        23.0797318,
                        0
                    ],
                    [
                        114.0373428,
                        23.0956698,
                        0
                    ],
                    [
                        114.0765381,
                        23.098681,
                        0
                    ],
                    [
                        114.1012573,
                        23.0595163,
                        0
                    ],
                    [
                        114.1314697,
                        23.0456164,
                        0
                    ],
                    [
                        114.0964981,
                        23.0084855,
                        0
                    ],
                    [
                        114.059315,
                        22.9705564,
                        0
                    ],
                    [
                        114.0809622,
                        22.8891404,
                        0
                    ],
                    [
                        114.1239821,
                        22.8587461,
                        0
                    ],
                    [
                        114.1781616,
                        22.8578274,
                        0
                    ],
                    [
                        114.2035679,
                        22.8201731,
                        0
                    ],
                    [
                        114.1822815,
                        22.8103648,
                        0
                    ],
                    [
                        114.2584991,
                        22.7635188,
                        0
                    ],
                    [
                        114.316864,
                        22.7343903,
                        0
                    ],
                    [
                        114.4281005,
                        22.7255238,
                        0
                    ],
                    [
                        114.3924729,
                        22.6886222,
                        0
                    ],
                    [
                        114.3772888,
                        22.6399964,
                        0
                    ],
                    [
                        114.4285796,
                        22.6447436,
                        0
                    ],
                    [
                        114.4423142,
                        22.6366771,
                        0
                    ],
                    [
                        114.4456414,
                        22.6325839,
                        0
                    ],
                    [
                        114.4475952,
                        22.6285699,
                        0
                    ],
                    [
                        114.4314457,
                        22.5995899,
                        0
                    ],
                    [
                        114.4388999,
                        22.5518321,
                        0
                    ],
                    [
                        114.4522384,
                        22.5341664,
                        0
                    ],
                    [
                        114.4408571,
                        22.4866906,
                        0
                    ],
                    [
                        114.3960155,
                        22.3291642,
                        0
                    ],
                    [
                        114.3579407,
                        22.2236497,
                        0
                    ],
                    [
                        114.2897415,
                        22.0875486,
                        0
                    ],
                    [
                        113.4083878,
                        22.0495394,
                        0
                    ],
                    [
                        113.0744326,
                        22.595771,
                        0
                    ],
                    [
                        113.00053,
                        22.7643072,
                        0
                    ],
                    [
                        112.9139604,
                        22.8704802,
                        0
                    ],
                    [
                        112.8027827,
                        23.101987,
                        0
                    ],
                    [
                        112.8240069,
                        23.1618344,
                        0
                    ],
                    [
                        112.8123312,
                        23.2176904,
                        0
                    ],
                    [
                        112.8480403,
                        23.2684851,
                        0
                    ],
                    [
                        112.9016563,
                        23.2295028,
                        0
                    ],
                    [
                        112.9524667,
                        23.3115028,
                        0
                    ],
                    [
                        113.0826723,
                        23.3165474,
                        0
                    ]
                ]
            },
            "properties": {
                "name": "Pearl River Delta Special Economic Zone",
                "styleUrl": "#line-000000-1200",
                "styleHash": "-5055c3e4",
                "styleMapHash": {
                    "normal": "#line-000000-1200-normal",
                    "highlight": "#line-000000-1200-highlight"
                },
                "description": "Detta är ungefär det formella området som Kina låter företagen agera fritt och i princip kan stifta sina egna lagar. Yttre gränserna är luddiga på de ställen där stängsel eller floder inte avgränsar, då det inte finns något fysiskt som avgränsar.",
                "stroke": "#000000",
                "stroke-opacity": 1,
                "stroke-width": 1.2
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            114.0122822,
                            22.4830837,
                            0
                        ],
                        [
                            114.008849,
                            22.4745184,
                            0
                        ],
                        [
                            114.0205219,
                            22.4554822,
                            0
                        ],
                        [
                            114.0431812,
                            22.4557995,
                            0
                        ],
                        [
                            114.0555408,
                            22.4795942,
                            0
                        ],
                        [
                            114.0363148,
                            22.4967239,
                            0
                        ],
                        [
                            114.0263584,
                            22.5014818,
                            0
                        ],
                        [
                            114.0122822,
                            22.4830837,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Flooding",
                "styleUrl": "#poly-9FC3FF-1-77-nodesc",
                "styleHash": "27d21cbd",
                "styleMapHash": {
                    "normal": "#poly-9FC3FF-1-77-nodesc-normal",
                    "highlight": "#poly-9FC3FF-1-77-nodesc-highlight"
                },
                "stroke": "#9fc3ff",
                "stroke-opacity": 1,
                "stroke-width": 0.001,
                "fill": "#9fc3ff",
                "fill-opacity": 0.30196078431372547
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            113.954604,
                            22.4859706,
                            0
                        ],
                        [
                            113.9635304,
                            22.5186405,
                            0
                        ],
                        [
                            113.9600971,
                            22.5398876,
                            0
                        ],
                        [
                            113.9504841,
                            22.5291059,
                            0
                        ],
                        [
                            113.9370945,
                            22.5107116,
                            0
                        ],
                        [
                            113.9432743,
                            22.4916804,
                            0
                        ],
                        [
                            113.954604,
                            22.4859706,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Flooding",
                "styleUrl": "#poly-9FC3FF-1-77-nodesc",
                "styleHash": "27d21cbd",
                "styleMapHash": {
                    "normal": "#poly-9FC3FF-1-77-nodesc-normal",
                    "highlight": "#poly-9FC3FF-1-77-nodesc-highlight"
                },
                "stroke": "#9fc3ff",
                "stroke-opacity": 1,
                "stroke-width": 0.001,
                "fill": "#9fc3ff",
                "fill-opacity": 0.30196078431372547
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            113.5840873,
                            22.3935279,
                            0
                        ],
                        [
                            113.6262703,
                            22.49987,
                            0
                        ],
                        [
                            113.6445121,
                            22.5622993,
                            0
                        ],
                        [
                            113.7063102,
                            22.6054108,
                            0
                        ],
                        [
                            113.7516425,
                            22.7194544,
                            0
                        ],
                        [
                            113.8047982,
                            22.7863108,
                            0
                        ],
                        [
                            113.7390564,
                            22.80229,
                            0
                        ],
                        [
                            113.5816366,
                            22.7350252,
                            0
                        ],
                        [
                            113.5476661,
                            22.7505801,
                            0
                        ],
                        [
                            113.5246712,
                            22.7863924,
                            0
                        ],
                        [
                            113.5445839,
                            22.8284841,
                            0
                        ],
                        [
                            113.5911041,
                            22.8142439,
                            0
                        ],
                        [
                            113.6220286,
                            22.83301,
                            0
                        ],
                        [
                            113.6528617,
                            22.9544447,
                            0
                        ],
                        [
                            113.7076054,
                            23.0738677,
                            0
                        ],
                        [
                            113.6868483,
                            23.1474115,
                            0
                        ],
                        [
                            113.5597188,
                            23.0957285,
                            0
                        ],
                        [
                            113.3988822,
                            23.1131218,
                            0
                        ],
                        [
                            113.3885107,
                            23.0624401,
                            0
                        ],
                        [
                            113.4907404,
                            23.059767,
                            0
                        ],
                        [
                            113.5276956,
                            22.9684756,
                            0
                        ],
                        [
                            113.4918838,
                            22.9063037,
                            0
                        ],
                        [
                            113.3628717,
                            22.9002103,
                            0
                        ],
                        [
                            113.2358162,
                            22.928863,
                            0
                        ],
                        [
                            113.2306337,
                            23.0171177,
                            0
                        ],
                        [
                            113.1348083,
                            22.976411,
                            0
                        ],
                        [
                            113.035849,
                            22.8880301,
                            0
                        ],
                        [
                            112.9203121,
                            22.9008457,
                            0
                        ],
                        [
                            112.9282126,
                            22.8582597,
                            0
                        ],
                        [
                            113.0676915,
                            22.8060492,
                            0
                        ],
                        [
                            113.0800895,
                            22.6599148,
                            0
                        ],
                        [
                            113.1381842,
                            22.5648781,
                            0
                        ],
                        [
                            113.0318152,
                            22.5208244,
                            0
                        ],
                        [
                            112.9996519,
                            22.4691297,
                            0
                        ],
                        [
                            113.0773226,
                            22.4085736,
                            0
                        ],
                        [
                            113.1901697,
                            22.2899246,
                            0
                        ],
                        [
                            113.3221169,
                            22.2651978,
                            0
                        ],
                        [
                            113.3226688,
                            22.1979991,
                            0
                        ],
                        [
                            113.1090266,
                            22.0582604,
                            0
                        ],
                        [
                            113.1594556,
                            21.9890847,
                            0
                        ],
                        [
                            113.3320364,
                            22.074785,
                            0
                        ],
                        [
                            113.3802093,
                            22.0829298,
                            0
                        ],
                        [
                            113.4689653,
                            22.0515032,
                            0
                        ],
                        [
                            113.470748,
                            22.1098748,
                            0
                        ],
                        [
                            113.5267757,
                            22.1246502,
                            0
                        ],
                        [
                            113.522104,
                            22.1357538,
                            0
                        ],
                        [
                            113.5000938,
                            22.1328683,
                            0
                        ],
                        [
                            113.4780824,
                            22.1433365,
                            0
                        ],
                        [
                            113.4641958,
                            22.1505524,
                            0
                        ],
                        [
                            113.4357395,
                            22.1900931,
                            0
                        ],
                        [
                            113.4996461,
                            22.210235,
                            0
                        ],
                        [
                            113.4794201,
                            22.2912549,
                            0
                        ],
                        [
                            113.3867815,
                            22.2677517,
                            0
                        ],
                        [
                            113.3388299,
                            22.3884602,
                            0
                        ],
                        [
                            113.3175446,
                            22.4798593,
                            0
                        ],
                        [
                            113.3443238,
                            22.5084075,
                            0
                        ],
                        [
                            113.3415772,
                            22.5299733,
                            0
                        ],
                        [
                            113.3721329,
                            22.5426576,
                            0
                        ],
                        [
                            113.444574,
                            22.5784842,
                            0
                        ],
                        [
                            113.4878327,
                            22.5708758,
                            0
                        ],
                        [
                            113.5703543,
                            22.533128,
                            0
                        ],
                        [
                            113.5731001,
                            22.4836425,
                            0
                        ],
                        [
                            113.5415153,
                            22.4366921,
                            0
                        ],
                        [
                            113.5497583,
                            22.3928928,
                            0
                        ],
                        [
                            113.5840873,
                            22.3935279,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Flooding",
                "styleUrl": "#poly-9FC3FF-1-77-nodesc",
                "styleHash": "27d21cbd",
                "styleMapHash": {
                    "normal": "#poly-9FC3FF-1-77-nodesc-normal",
                    "highlight": "#poly-9FC3FF-1-77-nodesc-highlight"
                },
                "stroke": "#9fc3ff",
                "stroke-opacity": 1,
                "stroke-width": 0.001,
                "fill": "#9fc3ff",
                "fill-opacity": 0.30196078431372547
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            113.9347393,
                            22.3236578,
                            0
                        ],
                        [
                            113.9292462,
                            22.331915,
                            0
                        ],
                        [
                            113.9010937,
                            22.3255634,
                            0
                        ],
                        [
                            113.8909657,
                            22.3149238,
                            0
                        ],
                        [
                            113.8931973,
                            22.3100007,
                            0
                        ],
                        [
                            113.9347393,
                            22.3236578,
                            0
                        ]
                    ]
                ]
            },
            "properties": {
                "name": "Reclaimed",
                "styleUrl": "#poly-7CCFA9-1200-77-nodesc",
                "styleHash": "2c4cbad5",
                "styleMapHash": {
                    "normal": "#poly-7CCFA9-1200-77-nodesc-normal",
                    "highlight": "#poly-7CCFA9-1200-77-nodesc-highlight"
                },
                "stroke": "#7ccfa9",
                "stroke-opacity": 1,
                "stroke-width": 1.2,
                "fill": "#7ccfa9",
                "fill-opacity": 0.30196078431372547
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        113.4758443,
                        22.3128383,
                        0
                    ],
                    [
                        113.4256252,
                        22.2992138,
                        0
                    ],
                    [
                        113.3866098,
                        22.2677517,
                        0
                    ],
                    [
                        113.3551662,
                        22.2493574,
                        0
                    ],
                    [
                        113.3146541,
                        22.2430021,
                        0
                    ]
                ]
            },
            "properties": {
                "name": "Partly flooded road",
                "styleUrl": "#line-FFDD5E-7394-nodesc",
                "styleHash": "-4a76c5ed",
                "styleMapHash": {
                    "normal": "#line-FFDD5E-7394-nodesc-normal",
                    "highlight": "#line-FFDD5E-7394-nodesc-highlight"
                },
                "stroke": "#ffdd5e",
                "stroke-opacity": 1,
                "stroke-width": 7.394
            }
        },
        {
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": [
                    [
                        113.5815877,
                        22.2124355,
                        0
                    ],
                    [
                        113.5540867,
                        22.2145034,
                        0
                    ],
                    [
                        113.4758443,
                        22.3128383,
                        0
                    ],
                    [
                        113.5053701,
                        22.3865058,
                        0
                    ],
                    [
                        113.5413436,
                        22.4366921,
                        0
                    ],
                    [
                        113.5362839,
                        22.508644,
                        0
                    ]
                ]
            },
            "properties": {
                "name": "Expressway",
                "styleUrl": "#line-FFDD5E-8556-nodesc",
                "styleHash": "512c1c11",
                "styleMapHash": {
                    "normal": "#line-FFDD5E-8556-nodesc-normal",
                    "highlight": "#line-FFDD5E-8556-nodesc-highlight"
                },
                "stroke": "#ffdd5e",
                "stroke-opacity": 1,
                "stroke-width": 8.556
            }
        }
    ]
};