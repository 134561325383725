function buildPopup(feature) {
    if (!feature.properties.description) {
        return `<h3>${feature.properties.name}</h3>`
    }
    return `<h3>${feature.properties.name}</h3>
    <p>${feature.properties.description}</p>`
}

function addIdentifiers(features) {
    let id = 0;
    return features.map((f) => {
        f.id = id++;
        return f;
    });
}

module.exports = {
    buildPopup,
    addIdentifiers
}