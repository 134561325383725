import maplibregl from "maplibre-gl";
import mapstyle from './mapstyle.js';

import prd from './data/prd.js';
import { buildPopup } from "./util.js";

var map;

map = new maplibregl.Map({
    container: "map",
    style: mapstyle,
    center: [114.109497, 22.396428],
    zoom: 9,
    maxBounds: [112.38,21.149,115.626,23.802]

});

map.on('load', () => {
    map.addSource('prd', {
        type: 'geojson',
        data: prd
    });

    // Polygons
    map.addLayer({
        id: 'prd-polygons',
        type: 'fill',
        source: 'prd',
        paint: {
            'fill-color': ['get', 'fill'],
            'fill-opacity': ['case', ['boolean', ['feature-state', 'selected'], false], 1, ['get', 'fill-opacity']]
        },
        filter: ['==', '$type', 'Polygon']
    });

    map.addLayer({
        id: 'prd-polygon-stroke',
        type: 'line',
        source: 'prd',
        paint: {
            'line-color': ['get', 'stroke'],
            'line-width': ['get', 'stroke-width'],
            'line-opacity': ['get', 'stroke-opacity']
        },
        filter: ['==', '$type', 'Polygon']
    })

    map.on('click', 'prd-polygons', (e) => {
        new maplibregl.Popup()
            .setLngLat(e.lngLat)
            .setHTML(buildPopup(e.features[0]))
            .addTo(map);
    });
         
    // Change the cursor to a pointer when
    // the mouse is over the states layer.
    map.on('mouseenter', 'prd-polygons', () => {
        map.getCanvas().style.cursor = 'pointer';
    });
        
    // Change the cursor back to a pointer
    // when it leaves the states layer.
    map.on('mouseleave', 'prd-polygons', () => {
        map.getCanvas().style.cursor = '';
    });

    map.loadImage('https://docs.mapbox.com/mapbox-gl-js/assets/custom_marker.png', (e, image) => {
        map.addImage('custom-marker', image);

        // // Points
        map.addLayer({
            id: 'prd-points',
            type: 'circle',
            source: 'prd',
            paint: {
                'circle-color': 'white',
                'circle-opacity': 0.85,
                'circle-radius': 8,
                'circle-stroke-width': 1,
                'circle-stroke-color': '#aaa',
                'circle-stroke-opacity': 0.5
            },
            filter: ['==', '$type', 'Point']
        })

        map.on('click', 'prd-points', (e) => {
            new maplibregl.Popup()
                .setLngLat(e.lngLat)
                .setHTML(buildPopup(e.features[0]))
                .addTo(map);
        });

        map.on('mouseenter', 'prd-points', () => {
            map.getCanvas().style.cursor = 'pointer';
        });
            
        // Change the cursor back to a pointer
        // when it leaves the states layer.
        map.on('mouseleave', 'prd-points', () => {
            map.getCanvas().style.cursor = '';
        });

    })
})


map.addControl(new maplibregl.NavigationControl());